import React, { useEffect } from 'react';
import AutomationMetricsContainer from './AutomationMetrics';
import { selectedProjectProps } from 'pages/integration/interface';
import { useAutomationMetrics } from 'pages/reportingV2/hooks/useAutomationMetrics';
const perPageTicketLimit = 6;

const AutomationMetricsV2: React.FC<{
  selectedProject: selectedProjectProps;
}> = ({ selectedProject }) => {
  const {
    ticketListOffset,
    defaultGroup,
    setDefaultGroup,
    platformsToFilter,
    fetchAutomationMetrics,
    fetchAutomationTicketTableByAgent,
    setTicketListOffset,
    shouldFetchData,
    dateRange,
    handleTicketListPagination,
  } = useAutomationMetrics();

  const handleTicketListNext = () => {
    handleTicketListPagination(ticketListOffset + perPageTicketLimit);
  };

  const handleTicketListPrevious = () => {
    handleTicketListPagination(ticketListOffset - perPageTicketLimit);
  };

  const handleAgentSelect = (agentId: number) => {
    fetchAutomationTicketTableByAgent(agentId);
  };

  useEffect(() => {
    if (!shouldFetchData) return;
    fetchAutomationMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  return (
    <AutomationMetricsContainer
      defaultGroup={defaultGroup}
      setDefaultGroup={setDefaultGroup}
      limit={perPageTicketLimit}
      offset={ticketListOffset}
      setOffset={setTicketListOffset}
      handleTicketListNext={handleTicketListNext}
      handleTicketListPrevious={handleTicketListPrevious}
      handleAgentSelect={(agentId: number) => handleAgentSelect(agentId)}
      selectedProject={selectedProject}
    />
  );
};

export default AutomationMetricsV2;
