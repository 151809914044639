import React from 'react';
import useTranslation from 'components/customHooks/useTranslation';
import FacebookLoginForBusiness from 'utilities/FacebookLoginForBusiness';
import instagramLogo from '../../../assets/icons/instaChat.svg';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

interface Props {
  processInstagramResponse: (response: any) => any;
  processInstagramResponseForBusiness: (response: any) => any;
  loading: boolean;
}

const InstagramConnection: React.FC<Props> = ({
  processInstagramResponse,
  processInstagramResponseForBusiness,
  loading = false,
}) => {
  const { t } = useTranslation();

  const renderInstagramLoginButton = () => {
    return (
      <>
        <FacebookLoginForBusiness
          handleFacebookToken={processInstagramResponseForBusiness}
          platformType='instagramBusiness'
          buttonText={t('Continue with Instagram')}
          buttonClassName='instagram-button-connect'
        />
        <FacebookLoginForBusiness
          handleFacebookToken={processInstagramResponse}
          platformType='instagramUser'
          buttonText={t('Login with Instagram')}
          buttonClassName='instagram-button-connect'
        />
      </>
    )
  };

  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>fetching page list...</span>
          </div>
        )}
        {!loading && (
          <>
            <img
              src={instagramLogo}
              className='m-auto text-center'
              alt={'instagram logo'}
            />
            <p className='mt-3 text-xl font-medium text-center'>
              Connect With Instagram!
            </p>
            <p className='mt-3 text-sm font-normal text-center text-gray-00'>
              Connect your Instagram account, you can edit the details later.
              Please provide us the required permission to get started!
            </p>
            <br />
            <div className='flex justify-center'>
              {renderInstagramLoginButton()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InstagramConnection;
