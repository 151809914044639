import { ISaveSelectedConditionDataTypes } from 'pages/automationWorkflow/interface';
import {
  React,
  SideSheet,
  ActionsView,
  TriggerViews,
  ConditionsView,
  useTriggerActions,
  useAutomationWorkflow,
  useReactFlowGraphServices,
} from '../../../../export';
import { Base85Conversion } from 'components/jinja/utils/base85encoder';

interface Props {}

const GenericSideSheet: React.FC<Props> = () => {
  const { fetchSelectedTriggerDetailsData } = useTriggerActions();
  const {
    addActionNode,
    automationNodes,
    addTriggerNode,
    addConditionNode,
    clearPreviousActionsSaveData,
    clearPreviousConditonSaveData,
  } = useReactFlowGraphServices();
  const {
    showSlider,
    selectedTrigger,
    isValidNodeType,
    selectedNodeType,
    handleSideSheetClose,
    selectedSaveActionsData,
    selectedSaveConditionList,
    renderingChangeUIOfActions,
    renderingChangeUIOfCondition,
  } = useAutomationWorkflow();

  const {
    general: {
      delay: { valueOfDelay = null },
    },
    whatsapp: { selectedTemplate = null },
    eCommerce: {
      removeTags = {},
      addTagsOnCustomer = {},
      removeTagsOnCustomer = {},
      removeTagsOnOrder = {},
      addTagsOnOrder = {},
      discount = {},
    },
  } = selectedSaveActionsData;

  const triggerSelectionData = {
    'trigger-node': {
      confirmText: 'Save Trigger',
      title: 'Select Trigger',
      description:
        'All the triggers available here, but you can select one at once.',
    },
    'condition-node': {
      confirmText: 'Save Condition',
      title: 'Add Conditions',
      description: 'Add and manage one or multiple conditions from here.',
    },
    'action-node': {
      confirmText: 'Save Action',
      title: 'Select Action',
      description: 'You can add and edit actions from here.',
    },
  };

  const handleConfirm = (nodeType: string) => {
    if (nodeType === 'trigger-node') {
      addTriggerNode();
      handleSideSheetClose();
      fetchSelectedTriggerDetailsData();
    }
    if (nodeType === 'condition-node') {
      // We intentionally pass the previous nodes list to this function because using the automationNodes list directly would result in data loss.
      // This is due to a flawed data flow mapping between the UI and the Redux store;
      addConditionNode(automationNodes);
      handleSideSheetClose();
      //Remove previous conditions data
      clearPreviousConditonSaveData();
    }
    if (nodeType === 'action-node') {
      addActionNode(automationNodes);
      handleSideSheetClose();
      clearPreviousActionsSaveData();
    }
  };

  const renderSidesheetChildrenComponent = (nodeType: string) => {
    switch (nodeType) {
      case 'trigger-node':
        return <TriggerViews />;
      case 'condition-node':
        return <ConditionsView />;
      case 'action-node':
        return <ActionsView />;
      default:
        return null;
    }
  };

  const isFooterDisabled = (nodeType: string): boolean => {
    switch (nodeType) {
      case 'trigger-node':
        return true;
      case 'condition-node':
        return !(
          renderingChangeUIOfCondition === 'addActionElement' ||
          renderingChangeUIOfCondition === 'add_condition_list'
        );
      case 'action-node':
        if (renderingChangeUIOfActions === 'actionElement') {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  const isDisableOrEnableSaveButton = React.useCallback(
    (nodeType: string): boolean => {
      switch (nodeType) {
        case 'trigger-node':
          if (selectedTrigger === null) {
            return true;
          } else {
            return false;
          }
        case 'condition-node':
          const areConditionsSelected = selectedSaveConditionList.every(
            (conditionGroup: ISaveSelectedConditionDataTypes[]) =>
              conditionGroup.every(
                (singleCondition: ISaveSelectedConditionDataTypes) =>
                  ['exists', 'not_exists'].includes(
                    singleCondition?.col_1?.compare_type ?? ''
                  )
                    ? true
                    : singleCondition?.col_1?.label &&
                      singleCondition?.col_2 &&
                      singleCondition?.col_2.length > 0
              )
          );
          return !areConditionsSelected;

        case 'action-node':
          const isDelayNotNull = valueOfDelay !== null;
          const isWhatsappDataValid =
            selectedTemplate &&
            Array.isArray(selectedTemplate.variables) &&
            (selectedTemplate.variables.length === 0 ||
              selectedTemplate.variables.every(
                (variable: any) =>
                  variable?.fallbackValue !== undefined &&
                  variable?.fallbackValue !== null &&
                  variable?.fallbackValue?.length !== 0 &&
                  variable?.item &&
                  (Base85Conversion.isBase85Encoded(variable?.variableValue) ||
                    variable?.variableCategory) &&
                  variable?.variableValue !== ''
              ));
          const isDiscountValid =
            discount?.component?.definition?.stored_data?.discount_payload &&
            Object.values(
              discount?.component?.definition?.stored_data?.discount_payload
            ).every(
              (value) => value !== undefined && value !== null && value !== ''
            );
          const isAddTagsOnCustomerValid =
            addTagsOnCustomer?.extranalData?.payload &&
            addTagsOnCustomer?.extranalData?.payload?.plain_tag.length !== 0;
          const isAddTagsOnOrderValid =
            addTagsOnOrder?.extranalData?.payload &&
            addTagsOnOrder?.extranalData?.payload?.plain_tag.length !== 0;

          const isRemoveTagsOnCustomerValid =
            removeTagsOnCustomer?.extranalData?.payload &&
            removeTagsOnCustomer?.extranalData?.payload?.plain_tag.length !== 0;

          const isRemoveTagsOnOrderValid =
            removeTagsOnOrder?.extranalData?.payload &&
            removeTagsOnOrder?.extranalData?.payload?.plain_tag.length !== 0;

          const isRemoveTagsValid =
            removeTags?.component?.definition?.stored_data
              ?.remove_tags_payload &&
            Object.keys(
              removeTags?.component?.definition?.stored_data
                ?.remove_tags_payload
            ).reduce((isValid, key) => {
              if (key === 'add_variable') return isValid;
              const value =
                removeTags?.component?.definition?.stored_data
                  ?.remove_tags_payload[key];
              return (
                isValid &&
                value !== undefined &&
                value !== null &&
                value !== '' &&
                (Array.isArray(value) ? value.length > 0 : true)
              );
            }, true);

          return !(
            isDelayNotNull ||
            isWhatsappDataValid ||
            isDiscountValid ||
            isAddTagsOnCustomerValid ||
            isAddTagsOnOrderValid ||
            isRemoveTagsValid ||
            isRemoveTagsOnCustomerValid ||
            isRemoveTagsOnOrderValid
          );
        default:
          return true;
      }
    },
    [
      discount,
      removeTags,
      valueOfDelay,
      addTagsOnOrder,
      selectedTrigger,
      selectedTemplate,
      removeTagsOnOrder,
      addTagsOnCustomer,
      removeTagsOnCustomer,
      selectedSaveConditionList,
    ]
  );

  // const sideSheetInformation = {};

  const isConfirmCloseSideSheet = () => {
    handleSideSheetClose();
    if (selectedNodeType === 'condition-node') {
      clearPreviousConditonSaveData();
    }

    if (selectedNodeType === 'action-node') {
      clearPreviousActionsSaveData();
    }
  };

  const shouldShowDocumentUrl = () => {
    return selectedTrigger?.trigger_code === 'woocommerce_cart_abandonment';
  };

  return (
    <>
      <SideSheet
        open={showSlider && isValidNodeType()}
        hasMinimizeButton={false}
        handleClose={isConfirmCloseSideSheet}
        hideCancel={true}
        closeOnExternalClick={false}
        title={triggerSelectionData[selectedNodeType]?.title || ''}
        description={triggerSelectionData[selectedNodeType]?.description || ''}
        confirmText={triggerSelectionData[selectedNodeType]?.confirmText || ''}
        handleConfirm={() => handleConfirm(selectedNodeType)}
        hasLeftActionElement={true}
        hideLeftActionElement={true}
        disableConfirm={isDisableOrEnableSaveButton(selectedNodeType)}
        hasFooter={isFooterDisabled(selectedNodeType)}
        dividerContainerClassNames='mb-0'
        showDocumentUrl={shouldShowDocumentUrl()}
      >
        {renderSidesheetChildrenComponent(selectedNodeType)}
      </SideSheet>
    </>
  );
};

export default GenericSideSheet;
