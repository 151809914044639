import { FC, useEffect } from 'react';
import useWhatsappMetrics from '../../hooks/useWhatsappMetrics';
import { WhatsappBspIcon } from 'pages/integration/utils/content';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { WhatsappGreenTickIcon } from 'pages/integration/assets/icons/socialIcons';
import { AccountQualityInsights } from './AccountQualityInsights';
import { ConversationReports } from './ConversationReports';
import { ConversationOverview } from './ConversationOverview';
import { IWhatsappAccountInfo } from 'pages/integration/interface';
import dayjs from 'dayjs';

interface WaMetricsAccountOverviewProps {}

export const WaMetricsAccountOverview: FC<
  WaMetricsAccountOverviewProps
> = () => {
  const {
    platformsToFilter,
    dateRange,
    whatsappAccountInfo,
    shouldFetchData,
    isWaAccountInfoLoading,
    whatsappMetricsReport,
    shouldFetchWhatsappMetricsData,
    fetchWhatsappAccountOverView,
    isFetchWhatsappConversationMetricsLoading,
  } = useWhatsappMetrics();

  const isAccountOverviewLoading =
    isWaAccountInfoLoading || isFetchWhatsappConversationMetricsLoading;

  useEffect(() => {
    if (
      (Array.isArray(platformsToFilter) &&
        platformsToFilter.at(0)?.platform_type !== 'whatsapp_bsp') ||
      !shouldFetchData ||
      !shouldFetchWhatsappMetricsData
    ) {
      return;
    }
    fetchWhatsappAccountOverView(platformsToFilter[0]?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  const renderQualityRatingView = (
    type: IWhatsappAccountInfo['quality_rating']
  ) => {
    switch (type) {
      case 'GREEN':
        return (
          <div className='border border-border text-green-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-green-500 mr-1'></span>{' '}
            High
          </div>
        );
      case 'YELLOW':
        return (
          <div className='border border-border text-gray-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-gray-500 mr-1'></span>{' '}
            Medium
          </div>
        );
      case 'RED':
        return (
          <div className='border border-border text-red-500 py-0.5 px-1.5 rounded text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-red-500 mr-1'></span>{' '}
            Low
          </div>
        );

      default:
        return null;
    }
  };

  const renderAccountSummary = () => {
    if (isWaAccountInfoLoading) {
      return (
        <div className='h-10 w-full bg-gray-200 animate-pulse rounded'></div>
      );
    }

    const formattedStartDate = dayjs(dateRange.from).format('Do MMM YYYY');
    const formattedEndDate = dayjs(dateRange.to).format('Do MMM YYYY');

    return (
      <div className='flex justify-between items-center'>
        <p className='flex items-center text-textSecondary'>
          Showing WhatsApp templates report from {formattedStartDate} -{' '}
          {formattedEndDate} in UTC/GMT-0 time zone.
        </p>
        <div className='flex items-center gap-2'>
          <img src={WhatsappBspIcon} alt='wa-bsp-icon' className='w-5 h-5' />
          <div className='flex gap-1 w-fit'>
            <TooltipProviderCustomised
              content={whatsappAccountInfo?.business_name}
              minContentLength={20}
            >
              <p className='text-textPrimary max-w-[200px] font-medium w-fit truncate'>
                {whatsappAccountInfo?.business_name}
              </p>
            </TooltipProviderCustomised>
            <p className='text-textSecondary font-medium'>
              ({whatsappAccountInfo?.display_phone_number})
            </p>
            {whatsappAccountInfo?.green_tick && (
              <TooltipProviderCustomised
                className='ltr:mr-3 rtl:ml-3'
                content='This account is verified from Meta.'
              >
                <WhatsappGreenTickIcon />
              </TooltipProviderCustomised>
            )}
          </div>
          {renderQualityRatingView(whatsappAccountInfo?.quality_rating)}
        </div>
      </div>
    );
  };

  return (
    <div className='p-6 bg-white shadow-s flex flex-col gap-6 h-full w-full'>
      <div className='flex flex-col gap-4'>
        {renderAccountSummary()}
        <ConversationOverview
          conversationOverviewData={whatsappMetricsReport}
          currency={whatsappAccountInfo?.currency}
          isLoading={isAccountOverviewLoading}
        />
      </div>
      <ConversationReports
        isLoading={isAccountOverviewLoading}
        conversationReportsData={whatsappMetricsReport}
      />
      <AccountQualityInsights />
    </div>
  );
};
