import useTranslation from 'components/customHooks/useTranslation';
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-gb';

interface LastUsedTimeDisplayProps {
  timestamp: string | null;
}

export const LastUsedTimeDisplay = ({
  timestamp,
}: LastUsedTimeDisplayProps) => {
  const { dashboardLanguage, t } = useTranslation();

  const formattedDate = timestamp
    ? moment
        .unix(Number(timestamp))
        .locale(dashboardLanguage === 'arabic' ? 'ar' : 'en')
        .format('MMM D, YYYY, h:mm A')
    : t('N/A');

  return (
    <div className='flex'>
      <span className='max-w-[400px] text-sm truncate font-normal'>
        {formattedDate}
      </span>
    </div>
  );
};
