import CopyToClipboard from 'components/utilityComponent/CopyToClipboard';
import { Button } from 'libraryV2/ui/button';
import { ChevronLeft, SquareArrowOutUpRight } from 'lucide-react';
import useThrottledEffect from 'pages/automationWorkflow/components/automationDashboard/hooks/useThrottleEffect';
import {
  SearchBar,
  TriggerTags,
  TriggerDetails,
  UtilityContents,
  ConditionHeader,
  useTriggerActions,
  ConditionDropDown,
  useAutomationWorkflow,
  LinearLoaderComponent,
  config,
} from 'pages/automationWorkflow/export';
import { truncateText } from 'pages/automationWorkflow/utility/function';
import { useState } from 'react';

const TriggersView = () => {
  const [selectedEcommerceType, setSelectedEcommerceType] = useState('');
  const [showCartAbandonDetails, setCartAbandonDetails] = useState(false);

  const {
    conditionallyRenderingTriggerUI,
    orderStatusChange,
    selectedEcommerce,
  } = useAutomationWorkflow();
  const {
    changeOrderStatus,
    updateTriggerInfo,
    isLoadingAutomatioViewDataApi,
    updateConditionallyRenderingTriggerUI,
    resetSelectedTrigger,
  } = useTriggerActions();

  useThrottledEffect(
    () => {
      if (!!selectedEcommerce) {
        updateTriggerInfo();
      }
    },
    [selectedEcommerce],
    1500
  );

  const url = `${config.automationWorkflow.getBaseUrl()}/api/ecommerce/projects/${
    selectedEcommerce?.alice_store_id
  }/woocommerce/webhooks/woocommerce_cart_abandonment`;

  const settingsUrl =
    selectedEcommerce?.ecommerce_type === 'woocommerce'
      ? `${selectedEcommerce?.store_url}wp-admin/admin.php?page=woo-cart-abandonment-recovery&action=settings`
      : '';

  const renderTriggerAndChannelView = () => {
    return (
      <>
        {showCartAbandonDetails ? (
          <div>
            <div className='p-2 border-b'>
              <Button
                variant={'ghost'}
                className='flex justify-between space-x-1 items-start'
                onClick={() => {
                  setCartAbandonDetails(!showCartAbandonDetails);
                  resetSelectedTrigger();
                }}
              >
                <ChevronLeft /> Cart Abondoned
              </Button>
            </div>
            <div className='mt-2.5 ml-5 p-2'>
              <p className='font-medium text-base text-gray-800'>
                To activate the "Cart Abandoned" trigger, please follow these
                steps:
              </p>
              <ol className='mt-3 ml-6 text-sm font-normal text-textPrimary !list-decimal space-y-4'>
                <li>
                  <p>
                    <span className='font-medium'>
                      Install Required Plugin:
                    </span>{' '}
                    <span className='font-normal'>
                      First, you need to install the necessary plugin from the
                      WordPress Plugin Directory.
                    </span>{' '}
                    <a
                      href='https://wordpress.org/plugins/myaliceai/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='inline-flex items-center text-link'
                    >
                      Click here to install the plugin
                      <SquareArrowOutUpRight className='h-3.5 w-3.5 ml-1' />
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <span className='font-medium'>Copy Webhook:</span>{' '}
                    <span className='font-normal'>
                      After installing the plugin, copy the webhook URL provided
                      below.
                    </span>
                  </p>
                  <div className='border rounded-md px-2 flex items-center justify-between mt-1'>
                    <span className='text-textSecondary'>
                      {truncateText(url, 70)}
                    </span>
                    <CopyToClipboard textToCopy={url} />
                  </div>
                </li>
                <li>
                  <p>
                    <span className='font-medium'>Access Plugin Settings:</span>{' '}
                    <span className='font-normal'>After installation,</span>{' '}
                    <a
                      href={settingsUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='inline-flex items-center text-link'
                    >
                      Navigate to this link
                      <SquareArrowOutUpRight className='h-3.5 w-3.5 ml-1' />
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <span className='font-medium'>Enable Webhook:</span>{' '}
                    <span className='font-normal'>
                      On the plugin settings page, find the Enable
                      Webhook option then tick the checkbox next to Enable
                      Webhook.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className='font-medium'>Set Webhook URL:</span>{' '}
                    <span className='font-normal'>
                      In the Webhook URL field, paste the URL provided in step 2
                      then click the Save button to apply the changes.
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    <span className='font-medium'>Note:</span>{' '}
                    <span className='font-normal'>
                      The "Cart Abandoned" trigger will not function until you
                      complete the steps above.
                    </span>
                  </p>
                </li>
              </ol>
            </div>
          </div>
        ) : (
          <>
            <div className='p-3 sticky top-0 z-20 w-full'>
              <SearchBar
                getSearchResult={(data) => console.log(data)}
                searchPlaceholder='Search...'
              />
            </div>
            {!!selectedEcommerceType && (
              <div className='p-2'>
                <Button
                  variant={'ghost'}
                  className='flex justify-between space-x-1 items-start'
                  onClick={() => {
                    setSelectedEcommerceType('');
                  }}
                >
                  <ChevronLeft /> Back
                </Button>
              </div>
            )}
            <div
              className='grid grid-cols-5 grid-flow-col'
              style={{ height: 'calc(100vh - 216px)' }}
            >
              <div className='col-span-2 border-r'>
                <TriggerTags
                  selectedEcommerceType={selectedEcommerceType}
                  setSelectedEcommerceType={setSelectedEcommerceType}
                />
              </div>
              <div className='col-span-3 overflow-auto'>
                {isLoadingAutomatioViewDataApi ? (
                  <LinearLoaderComponent />
                ) : (
                  <TriggerDetails
                    setCartAbandonDetails={setCartAbandonDetails}
                    selectedEcommerceType={selectedEcommerceType}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  const renderingOrderStatusChangeView = () => {
    return (
      <>
        <div>
          <ConditionHeader
            title='Order Status Change'
            previousUIAction={() =>
              updateConditionallyRenderingTriggerUI('triggerElement')
            }
          />
        </div>
        <div className='bg-gray-50 h-screen'>
          <div className='grid grid-cols-2 gap-4 p-4'>
            <div className='p-4 bg-white rounded-md'>
              <h2 className='text-gray-600 text-sm font-medium mb-2'>
                Status From
              </h2>
              <ConditionDropDown
                activeValue={orderStatusChange?.statusForm}
                options={UtilityContents.OrderStatusData}
                onChange={({ id }) =>
                  changeOrderStatus('form_status', id as string)
                }
              />
            </div>
            <div className='p-4 bg-white rounded-md'>
              <h2 className='text-gray-600 text-sm font-medium mb-2'>
                To Status
              </h2>
              <ConditionDropDown
                activeValue={orderStatusChange?.toStatus}
                options={UtilityContents.OrderStatusData}
                onChange={(data) => changeOrderStatus('toStatus', data?.id)}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSidesheetChildElement = (elementType: string) => {
    switch (elementType) {
      case 'triggerElement':
        return renderTriggerAndChannelView();
      case 'order_created':
        return renderingOrderStatusChangeView();
      case 'order_status_change':
        return renderingOrderStatusChangeView();
      case 'cart_abandoned':
        return renderingOrderStatusChangeView();
      default:
        return null;
    }
  };

  return renderSidesheetChildElement(conditionallyRenderingTriggerUI);
};

export default TriggersView;
