import React, { useEffect } from 'react';
import './assets/styles/reporting.scss';

import ReportingNav from './component/Nav/ReportingNav';

import AnalyticsLogs from './component/WhatsappMetrics/components/TemplateAnalytics/logs';

import { useParams } from '@reach/router';
import { Loader2 } from 'lucide-react';
import NoPermissionModal from './component/CommonComponent/NoPermissionModal';
import ReportingMatricesTabs from './component/Nav/ReportingMatricesTabs';
import { useReporting } from './hooks/useReporting';

const ReportingContainer = () => {
  // hooks
  const routeParams = useParams();
  const {
    selectedProject,
    isReportingLoading,
    currentReportingTab,
    updateStateData,
    platformList,
    dateRange,
    handleUrlQueryParams,
    selectedReportingViewMode,
    updateProjectInformation,
    updateCurrentReportingTab,
  } = useReporting();

  const [hasProjectPermission, setHasProjectPermission] = React.useState(true);
  // constants
  const urlProjectId = routeParams.projectId;

  // Filter the platform list based on the current tab
  const filterPlatformList = () => {
    if (
      currentReportingTab === 'whatsapp-metrics' &&
      Array.isArray(platformList)
    ) {
      return platformList.filter(
        (platform: any) => platform.platform_type === 'whatsapp_bsp'
      );
    } else {
      return platformList;
    }
  };
  const queries = handleUrlQueryParams(platformList);
  //1. this effect is to handle the url query params to initialize the state
  useEffect(() => {
    // check permission
    const handleUrlQueryParamsAndUpdateProjectInfo = async () => {
      const currentProject = await updateProjectInformation(
        parseInt(urlProjectId)
      );
      if (!currentProject) {
        setHasProjectPermission(false);
        return;
      }

      if (!queries) {
        return;
      }
      // update current reporting tab
      updateCurrentReportingTab(queries.tab || 'customer-metrics');
      // update date range
      updateStateData('dateRange', {
        from: queries.from,
        to: queries.to,
      });
      // update platforms to filter
      updateStateData('platformsToFilter', queries.platforms);
      updateStateData('shouldFetchData', true);
    };
    handleUrlQueryParamsAndUpdateProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // rendering different views
  if (isReportingLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <Loader2 className='w-12 h-12 text-primary animate-spin' />
      </div>
    );
  }
  if (!hasProjectPermission) {
    return <NoPermissionModal hasProjectPermission={hasProjectPermission} />;
  }

  return (
    <div
      data-testid='reporting-page-container'
      className='ltr:border-l rtl:border-r border-border'
    >
      {selectedReportingViewMode === 'reporting' && (
        <div className='w-full h-full flex-1'>
          <ReportingNav
            platformList={filterPlatformList() ?? []}
            dateRange={dateRange}
            setDateRange={(range) => updateStateData('dateRange', range)}
            updateStateData={updateStateData}
          />
          <ReportingMatricesTabs
            selectedProject={selectedProject}
            updateCurrentReportingTab={updateCurrentReportingTab}
            currentReportingTab={queries?.tab || 'customer-metrics'}
          />
        </div>
      )}
      {selectedReportingViewMode === 'analyticsLogs' && <AnalyticsLogs />}
    </div>
  );
};

export default ReportingContainer;
