'use client';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { useDebounce } from 'components';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Input } from 'libraryV2/ui/input';
import { PlusIcon, Search, Trash } from 'lucide-react';
import { useEffect, useState } from 'react';
import useWebhooks from '../../hooks/useWebhooks';
import WebhookModal, { IWebhookData } from '../webhookModal';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  setLocalWebhookData: (data: IWebhookData[]) => void;
}

export function DataTableToolbar<TData>({
  table,
  setLocalWebhookData,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);
  const isFiltered = table.getState().columnFilters.length > 0;
  const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);
  const [open, setOpen] = useState(false);
  const {
    setWebhookModalAction,
    bulkDeleteWebhook,
    handleWebhookSearch,
    searchQuery,
    updateSearchQuery,
    handlePageChange,
    limit,
    offset,
    webhookList,
  } = useWebhooks();

  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  const handleBulkDelete = () => {
    updateSearchQuery('');
    const selectedWebhooks = table
      .getSelectedRowModel()
      .rows.map((v) => v.original) as IWebhookData[];
    const webhookIds = selectedWebhooks.map((r) => Number(r?.id)) as number[];
    bulkDeleteWebhook(webhookIds)
      .then(() => {
        table.resetRowSelection();

        // If current page becomes empty after deletion and not on first page,
        // automatically navigate to previous page
        const totalRows = webhookList.length;
        const currentPageRows =
          offset + limit > totalRows ? totalRows % limit : limit;

        const itemsLeftInPage = currentPageRows - selectedRows.length;

        if (itemsLeftInPage <= 0 && offset > 0) {
          const newOffset = Math.max(0, offset - limit);
          handlePageChange(limit, newOffset);
        }
      })
      .finally(() => {
        setDeleteModal(false);
      });
  };

  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete')} {selectedRows.length} {t('Webhooks?')}
          </DialogTitle>

          <p className='text-zinc-500 rtl:text-right text-sm font-normal'>
            {t(`This will permanently delete`)} {selectedRows.length}{' '}
            {t('Webhooks.')} {t('Are you sure you want to delete?')}
          </p>

          <DialogFooter className='rtl:gap-2 rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-[#F4F4F5] text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-[#F4F4F5]'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              onClick={() => handleBulkDelete()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const renderSelectionToolbarView = () => {
    return (
      <div className='flex gap-2'>
        <Button
          size='sm'
          variant='outline'
          className='flex gap-2 text-white bg-[#FF0000] hover:bg-red-600 hover:text-white'
          disabled={!selectedRows.length}
          onClick={() => setDeleteModal(true)}
        >
          <Trash className='w-4 h-4' />
          <span>{t('Delete')}</span>
        </Button>

        <Button
          size='sm'
          variant='outline'
          className='flex gap-2'
          onClick={() => table.resetRowSelection()}
        >
          <span>{t('Cancel')}</span>
        </Button>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleWebhookSearch(debouncedSearchTerm);
        if (response.status) {
          setLocalWebhookData(response.data);
        } else {
          setLocalWebhookData([]);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        <div className='flex relative items-center'>
          <Search className='absolute left-2 mt-1 h-4 w-4 text-zinc-500 transform top-1/2 -translate-y-1/2' />
          <Input
            placeholder={t('Search webhooks')}
            value={searchQuery}
            onChange={(e) => updateSearchQuery(e.target.value)}
            className='pl-7 h-8 w-[150px] lg:w-[250px]'
          />
        </div>
        {isFiltered && (
          <Button
            variant='ghost'
            onClick={() => table.resetColumnFilters()}
            className='h-8 px-2 mt-[5px] lg:px-3 bg-primary text-white'
          >
            {t('Reset')}
            <Cross2Icon className='ml-2 h-4 w-4' />
          </Button>
        )}
      </div>
      {selectedRows.length > 0 ? (
        renderSelectionToolbarView()
      ) : (
        <Button
          className='text-white hover:text-white bg-primary gap-1'
          onClick={() => {
            setOpen(true);
            setWebhookModalAction('CREATE');
          }}
        >
          <PlusIcon strokeWidth='2.5' className='text-white h-4 w-4' />
          <span>{t('Create New Webhook')}</span>
        </Button>
      )}
      {open && (
        <WebhookModal actionType='CREATE' isOpen={open} setIsOpen={setOpen} />
      )}
      {renderCardDeleteModal()}
    </div>
  );
}
