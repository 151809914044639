import {
  PlayIcon,
  ShippingIcon,
  CartAbandoned,
  ConditionNodeIcon,
  OrderFulfillmentIcon,
  ActionNodeIcon,
  cloneDeep,
} from '../export';

import {
  NodeType,
  IProductDataTypes,
  IProductSaveDataTypes,
  IUtilityFunctionOptions,
  INodeDecoratorItemsData,
  IAliasObject,
  IOrder,
} from '../interface';

export const getNewNodeId = (currentNodeId: string | null) => {
  if (currentNodeId === null) return '';
  // Extract the numeric part from the current node ID
  const currentNumber = parseInt(currentNodeId.split('-')[1]);

  // Increment the numeric part
  const nextNumber = currentNumber + 1;

  // Create the new node ID
  const nextNodeId = `node-${nextNumber}`;

  return nextNodeId;
};

export const storeOrderCreatedCode = [
  'woocommerce_order_created',
  'shopify_order_created',
  'salla_order_created',
  'zid_order_created',
];

export const storeOrderAddTagCode = [
  'woocommerce_add_tag_on_order',
  'shopify_add_tag_on_order',
  'salla_add_tag_on_order',
  'zid_add_tag_on_order',
];

export const storeOrderRemoveTagCode = [
  'woocommerce_remove_tag_from_order',
  'shopify_remove_tag_from_order',
  'salla_remove_tag_from_order',
  'zid_remove_tag_from_order',
];

export const storeCustomerAddTagCode = [
  'woocommerce_add_tag_on_customer',
  'shopify_add_tag_on_customer',
  'salla_add_tag_on_customer',
  'zid_add_tag_on_customer',
];

export const storeCustomerRemoveTagCode = [
  'woocommerce_remove_tag_from_customer',
  'shopify_remove_tag_from_customer',
  'salla_remove_tag_from_customer',
  'zid_remove_tag_from_customer',
];

export const getTriggerIcon = (triggerName: string) => {
  switch (triggerName) {
    case 'cart_abandoned':
      return <CartAbandoned />;
    case 'order_status_change':
      return <OrderFulfillmentIcon />;
    case 'woocommerce_order_created':
    case 'shopify_order_created':
    case 'salla_order_created':
    case 'zid_order_created':
      return <ShippingIcon />;
    default:
      return null;
  }
};

export const nodeItemsData: INodeDecoratorItemsData = {
  'trigger-node': {
    label: 'Trigger point',
    background: 'bg-primary',
    icon: <PlayIcon className='w-5 h-5 text-white' />,
    renderIcon: (strokeColor = '') => {
      return strokeColor ? (
        <PlayIcon className={`w-5 h-5 text-${strokeColor}`} />
      ) : (
        <PlayIcon className='w-5 h-5 text-white' />
      );
    },
  },
  'condition-node': {
    label: 'Condition',
    background: 'bg-[#FB923C]',
    icon: <ConditionNodeIcon />,
    renderIcon: (strokeColor = '') => {
      return strokeColor ? (
        <ConditionNodeIcon strokeColor={strokeColor} />
      ) : (
        <ConditionNodeIcon />
      );
    },
  },
  'action-node': {
    label: 'Action',
    background: ' bg-indigo-400',
    icon: <ActionNodeIcon />,
    renderIcon: (strokeColor = '') => {
      return strokeColor ? (
        <ActionNodeIcon strokeColor={strokeColor} />
      ) : (
        <ActionNodeIcon />
      );
    },
  },
};

/**
 * Converts an array of IProductDataTypes to an array of IProductSaveDataTypes.
 * Each element in the resulting array retains specific properties from the original product data.
 *
 * @param productsData - Array of IProductDataTypes to be converted.
 * @returns An array of IProductSaveDataTypes with selected properties from the original data.
 */
export const convertToProductSaveData = (
  productsData: IProductDataTypes[]
): IProductSaveDataTypes[] => {
  // Initialize an empty array to store the converted data
  const convertedProducts: IProductSaveDataTypes[] = [];

  // Iterate through each element in the original array
  productsData?.forEach((element: IProductDataTypes) => {
    // Map selected properties to a new object and add it to the result array
    convertedProducts.push({
      label: element?.product_name,
      value: element?.product_id,
      product_id: element?.product_id,
      product_link: element?.product_link,
      product_name: element?.product_name,
    });
  });

  // Return the array of converted data
  return convertedProducts;
};

export const activeDropdownValue = (
  value: any,
  defaultLabelObject: { label: string },
  type: string
) => {
  if (value === null || value === undefined) {
    // Return [defaultLabelObject] for col2 and defaultLabelObject for col1 when value is null or undefined
    return type === 'col2' ? [defaultLabelObject] : defaultLabelObject;
  }

  if (type === 'col1' && Object.keys(value).length === 0) {
    return defaultLabelObject;
  }

  if (type === 'col2' && value.length === 0) {
    return [defaultLabelObject];
  }

  return value;
};

export const activeSingleDropdownValueV2 = (
  value: any,
  defaultLabelObject: { value: string },
  type: string
) => {
  if (value === null || value === undefined) {
    // Return [defaultLabelObject] for col2 and defaultLabelObject for col1 when value is null or undefined
    return type === 'col2' ? { ...defaultLabelObject } : defaultLabelObject;
  }

  if (type === 'col1' && !Object.keys(value)) {
    return { label: defaultLabelObject.value, value: '' };
  }

  if (type === 'col2' && value.length === 0) {
    return { ...defaultLabelObject };
  }

  const val = { value: value[0]?.definition.compare_to.value };

  return !!value && !!val ? val : { ...defaultLabelObject };
};

export const findAlias = (component: IOrder): IAliasObject | null => {
  const inputSchema = !!component?.data?.col_2_label_options
    ? component?.data?.col_2_label_options[0]?.definition?.input_schema ?? []
    : [];
  const findInNested = (item: IAliasObject): IAliasObject | null => {
    if (item.mark) {
      return item;
    }
    return item.next ? findInNested(item.next) : null;
  };

  for (let item of inputSchema) {
    //@ts-ignore
    if (item.column === 2) {
      const found = findInNested(item);
      if (found) return found;
    }
  }

  return null; // If no matching object is found
};

//Highlights specific patterns in a given text by wrapping them in a span element.
export const highlightAttributeFromText = (text: string) => {
  return text
    .split(/({{[^{}]*}})/)
    .map((item: string, index: number) => {
      if (item.match(/{{[^{}]*}}/)) {
        return `<span id=${
          item + '_' + index
        } class='px-1 py-1 rounded-md text-[#FB923C]'>${item}</span>`;
      } else {
        return item;
      }
    })
    .join('');
};

/** Extracts the PDF file name from the given URL. */
export const getPdfNameFromUrl = (pdfUrl: string) =>
  pdfUrl.split('/').pop() || '';

export const convertToMilliseconds = (
  time: string,
  timaStamp: string
): number | undefined => {
  const delayInMinutes = parseInt(time, 10);

  if (isNaN(delayInMinutes)) {
    return undefined; // Invalid input, return undefined or handle accordingly
  }

  switch (timaStamp.toLowerCase()) {
    case 'second':
      return delayInMinutes;
    case 'minute':
      return delayInMinutes * 60;
    case 'hours':
      return delayInMinutes * 60 * 60;
    case 'days':
      return delayInMinutes * 24 * 60 * 60;
    case 'weeks':
      return delayInMinutes * 7 * 24 * 60 * 60;
    case 'months':
      // Assuming an average of 30 days in a month for simplicity
      return delayInMinutes * 30 * 24 * 60 * 60;
    default:
      return undefined; // Invalid timeOfDelay, return undefined or handle accordingly
  }
};

const processMultiChoiceConditionObj = (
  conditionNodeData: any,
  options: IUtilityFunctionOptions
) => {
  const result = {
    name: conditionNodeData?.component_name,
    col_1: conditionNodeData?.external_data_storage?.col_1,
    col_2: conditionNodeData?.external_data_storage?.col_2,
    representation_type:
      conditionNodeData?.external_data_storage?.representation_type,
    selectedComparator:
      conditionNodeData?.external_data_storage?.selectedComparator,
    component_name: conditionNodeData?.component_name,
    component_code: conditionNodeData?.component_code,
    component_type: conditionNodeData?.component_type,
    component_icon: conditionNodeData?.component_icon ?? '',
    component: {
      component_name: conditionNodeData?.component_name,
      component_code: conditionNodeData?.component_code,
      component_type: conditionNodeData?.component_type,
      component_icon: conditionNodeData?.component_icon ?? '',
      definition: conditionNodeData?.definition,
    },
  };
  if (options && options?.isLogView) {
    (result as any).component_computation_info =
      conditionNodeData.component_computation_info;
  }
  return result;
};

const processTwoColumnComparatorSingleChoiceConditionObj = (
  conditionNodeData: any,
  options: IUtilityFunctionOptions
) => {
  const result = {
    name: conditionNodeData?.component_name,
    col_1: conditionNodeData?.external_data_storage?.col_1,
    col_2: [
      {
        ...conditionNodeData?.external_data_storage?.col_2[0],
        definition: conditionNodeData?.definition,
        component_type: conditionNodeData?.component_type,
        component_code: conditionNodeData?.component_code,
        select_Type: conditionNodeData?.component_code,
      },
    ],
    representation_type:
      conditionNodeData?.external_data_storage?.representation_type,
    selectedComparator:
      conditionNodeData?.external_data_storage?.selectedComparator,
    component_name: conditionNodeData?.component_name,
    component_code: conditionNodeData?.component_code,
    component_type: conditionNodeData?.component_type,
    component_icon: conditionNodeData?.component_icon ?? '',
    component: {
      component_name: conditionNodeData?.component_name,
      component_code: conditionNodeData?.component_code,
      component_type: conditionNodeData?.component_type,
      component_icon: conditionNodeData?.component_icon ?? '',
      definition: conditionNodeData?.definition,
    },
  };
  if (options && options?.isLogView) {
    (result as any).component_computation_info =
      conditionNodeData.component_computation_info;
  }
  return result;
};

const processInputFieldConditionObj = (
  conditionNodeData: any,
  options: IUtilityFunctionOptions
) => {
  let result: any = {
    name: conditionNodeData?.component_name,
    representation_type:
      conditionNodeData?.external_data_storage?.representation_type,
    selectedComparator:
      conditionNodeData?.external_data_storage?.selectedComparator,
    component_name: conditionNodeData?.component_name,
    component_code: conditionNodeData?.component_code,
    component_type: conditionNodeData?.component_type,
    col_1: conditionNodeData?.external_data_storage?.col_1,
    col_2: [
      {
        label: conditionNodeData?.external_data_storage?.col_2[0]?.label,
        definition: [
          {
            definition: conditionNodeData?.definition,
            component_name: conditionNodeData?.component_name,
            component_code: conditionNodeData?.component_code,
            component_type: conditionNodeData?.component_type,
            component_icon: conditionNodeData?.component_icon,
          },
        ],
      },
    ],
  };
  if (options && options?.isLogView) {
    result = {
      ...result,
      component_computation_info: {
        ...conditionNodeData.component_computation_info,
      },
    };
  }
  return result;
};

export const handleSelectedAutomationNodes = (
  nodes: any[],
  options: IUtilityFunctionOptions
) => {
  // The array to store the formatted nodes
  const formattedNodes: any[] = [];

  // Iterate through each node in the original array
  nodes.forEach((originalNode: any) => {
    // If the node is a TRIGGER type, push it directly to the formatted array
    if (originalNode.type === NodeType.TRIGGER) {
      formattedNodes.push(originalNode);
    }

    // If the node is a CONDITION type and has an array of data
    if (
      originalNode.type === NodeType.CONDITION &&
      Array.isArray(originalNode.data)
    ) {
      // Create a shallow copy of the original node
      const formattedNodeCopy = { ...originalNode };
      // Log View:remove last entry and save it for result view
      if (options?.isLogView) {
        formattedNodeCopy.component_computation_info = originalNode.data
          .pop()
          ?.at(0).component_computation_info;
      }
      const formattedDataArray = formatConditionData(
        originalNode.data,
        options ?? []
      );

      // Update the data property of the formatted node with the new data array
      formattedNodeCopy.data = [...formattedDataArray];

      // Push the fully formatted condition node to the array
      formattedNodes.push(formattedNodeCopy);
    }

    // If the node is an ACTION type
    if (originalNode.type === NodeType.ACTION) {
      // Create a shallow copy of the original node
      const formattedNodeCopy = structuredClone(originalNode);

      if (isDelayOperator(originalNode)) {
        // If the action node is a delay operator
        const tempDelayObj = createDelayObject(originalNode);
        formattedNodeCopy.data = tempDelayObj;
        formattedNodes.push(formattedNodeCopy);
      }

      // If the action node is a send_whatsapp_template
      if (isSendWhatsAppTemplate(originalNode)) {
        const tempWhatsAppObj = createWhatsAppTemplateObject(originalNode);
        formattedNodeCopy.data = tempWhatsAppObj;
        formattedNodes.push(formattedNodeCopy);
      }

      // If the action node is a add tags
      if (originalNode?.data[0]?.component_name === 'Add Tags on Customer') {
        const addTagsObj = createAddTagsObject(originalNode);
        formattedNodeCopy.data = addTagsObj;
        formattedNodes.push(formattedNodeCopy);
      }
      // If the action node is a add tags
      if (originalNode?.data[0]?.component_name === 'Remove tag') {
        const removeTagsObj = createRemoveTagsObject(originalNode);
        formattedNodeCopy.data = removeTagsObj;
        formattedNodes.push(formattedNodeCopy);
      }

      // If the action node is a add tags
      if (originalNode?.data[0]?.component_name === 'Discount Forms') {
        const discountObj = createDiscountObject(originalNode);
        formattedNodeCopy.data = discountObj;
        formattedNodes.push(formattedNodeCopy);
      }

      // If the action node is a add tags on customer
      if (
        storeCustomerAddTagCode.includes(originalNode?.data[0]?.component_code)
      ) {
        const addTagsOnCustomerObj =
          createAddTagsOnCustomerObject(originalNode);
        formattedNodeCopy.data = addTagsOnCustomerObj;
        formattedNodes.push(formattedNodeCopy);
      }
      // If the action node is a add tags on customer
      if (
        storeOrderAddTagCode.includes(originalNode?.data[0]?.component_code)
      ) {
        const addTagsOnOrderObj = createAddTagsOnOrderObject(originalNode);
        formattedNodeCopy.data = addTagsOnOrderObj;
        formattedNodes.push(formattedNodeCopy);
      }
      // If the action node is a add tags on customer
      if (
        storeCustomerRemoveTagCode.includes(
          originalNode?.data[0]?.component_code
        )
      ) {
        const removeTagsOnCustomerObj =
          createRemoveTagsFromCustomerObject(originalNode);
        formattedNodeCopy.data = removeTagsOnCustomerObj;
        formattedNodes.push(formattedNodeCopy);
      }

      // If the action node is a add tags on customer
      if (
        storeOrderRemoveTagCode.includes(originalNode?.data[0]?.component_code)
      ) {
        const removeTagsOnOrderObj =
          createRemoveTagsFromOrderObject(originalNode);
        formattedNodeCopy.data = removeTagsOnOrderObj;
        formattedNodes.push(formattedNodeCopy);
      }
    }
  });

  // Return the array of formatted nodes
  return formattedNodes;
};

const createAddTagsOnCustomerObject = (addTagsOnCustomerData: any) => {
  const customerData = addTagsOnCustomerData?.data[0];
  const { currentRoute, currentScreen, extranalData, routingModel, screens } =
    customerData?.external_data_storage;
  return {
    component_computation_info:
      addTagsOnCustomerData?.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      addTagsOnCustomer: {
        routingModel,
        screens,
        component: {
          component_code: customerData?.component_code,
          component_icon: customerData?.component_icon,
          component_name: customerData?.component_name,
          component_type: customerData?.component_type,

          definition: customerData?.definition,
        },
        currentRoute: currentRoute,
        currentScreen: currentScreen,
        extranalData,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createAddTagsOnOrderObject = (addTagsOnCustomerData: any) => {
  const customerData = addTagsOnCustomerData?.data[0];
  const { currentRoute, currentScreen, extranalData, routingModel, screens } =
    customerData?.external_data_storage;
  return {
    component_computation_info:
      addTagsOnCustomerData?.data.at(0).component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      addTagsOnOrder: {
        routingModel,
        screens,
        component: {
          component_code: customerData?.component_code,
          component_icon: customerData?.component_icon,
          component_name: customerData?.component_name,
          component_type: customerData?.component_type,

          definition: customerData?.definition,
        },
        currentRoute: currentRoute,
        currentScreen: currentScreen,
        extranalData,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createRemoveTagsFromCustomerObject = (removeTagsOnCustomerData: any) => {
  const customerData = removeTagsOnCustomerData?.data[0];
  const { currentRoute, currentScreen, extranalData, routingModel, screens } =
    customerData?.external_data_storage;
  return {
    component_computation_info:
      removeTagsOnCustomerData?.data.at(0).component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      removeTagsOnCustomer: {
        routingModel,
        screens,
        component: {
          component_code: customerData?.component_code,
          component_icon: customerData?.component_icon,
          component_name: customerData?.component_name,
          component_type: customerData?.component_type,

          definition: customerData?.definition,
        },
        currentRoute: currentRoute,
        currentScreen: currentScreen,
        extranalData,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createRemoveTagsFromOrderObject = (removeTagsOnCustomerData: any) => {
  const customerData = removeTagsOnCustomerData?.data[0];
  const { currentRoute, currentScreen, extranalData, routingModel, screens } =
    customerData?.external_data_storage;
  return {
    component_computation_info:
      removeTagsOnCustomerData?.data.at(0).component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      removeTagsOnOrder: {
        routingModel,
        screens,
        component: {
          component_code: customerData?.component_code,
          component_icon: customerData?.component_icon,
          component_name: customerData?.component_name,
          component_type: customerData?.component_type,

          definition: customerData?.definition,
        },
        currentRoute: currentRoute,
        currentScreen: currentScreen,
        extranalData,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createAddTagsObject = (addTagsData: any) => {
  return {
    component_computation_info:
      addTagsData.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      addTags: {
        component: addTagsData?.data[0],
        ...addTagsData?.data[0]?.external_data_storage,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createRemoveTagsObject = (removeTagsData: any) => {
  return {
    component_computation_info:
      removeTagsData.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      removeTags: {
        component: removeTagsData?.data[0],
        ...removeTagsData?.data[0]?.external_data_storage,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createDiscountObject = (discountData: any) => {
  return {
    component_computation_info:
      discountData.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {
      discount: {
        component: discountData?.data[0],
        ...discountData?.data[0]?.external_data_storage,
      },
    },
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const formatConditionData = (data: any[], options: IUtilityFunctionOptions) => {
  const finalResult = data.map((dataElement: any) => {
    return dataElement.map((conditionElement: any) => {
      return formatCondition(conditionElement, options);
    });
  });
  return finalResult;
};

const formatCondition = (
  conditionElement: any,
  options: IUtilityFunctionOptions
) => {
  const { representation_type } = conditionElement;
  switch (representation_type) {
    case 'two_column_comparator_single_choice':
    case 'two_column_comparator_single_choice_data':
    case 'two_column_comparator_single_choice_api':
      return processTwoColumnComparatorSingleChoiceConditionObj(
        conditionElement,
        options
      );
    case 'two_column_comparator_multi_choice':
      return processMultiChoiceConditionObj(conditionElement, options);
    case 'two_column_comparator_multi_choice_api':
      return processMultiChoiceConditionObj(conditionElement, options);
    case 'two_column_comparator_input_field':
    case 'two_column_comparator_string_input_field':
      return processInputFieldConditionObj(conditionElement, options);
    default: {
      // Handle any other cases or provide a default behavior
      if (options?.isLogView) {
        return conditionElement;
      }
      return null;
    }
  }
};

const isDelayOperator = (node: any) => {
  return node?.data[0]?.component_code === 'delay_operator';
};

const isSendWhatsAppTemplate = (node: any) => {
  return node?.data[0]?.component_code === 'send_whatsapp_template';
};

const createDelayObject = (node: any) => {
  return {
    component_computation_info: node.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: node?.data[0]?.external_data_storage?.valueOfDelay,
        timeOfDelay: node?.data[0]?.external_data_storage?.timeOfDelay,
      },
      data: node?.data[0],
    },
    eCommerce: {},
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  };
};

const createWhatsAppTemplateObject = (node: any) => {
  return {
    component_computation_info: node.data?.at(0)?.component_computation_info,
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {},
    whatsapp: {
      selectedTemplate: node?.data[0]?.external_data_storage?.selectedTemplate,
      phoneVariable: node?.data[0]?.external_data_storage?.phoneVariable,
      selectedChannelId:
        node?.data[0]?.external_data_storage?.selectedChannelId,
      selectedWhatsappTemplateData:
        node?.data[0]?.external_data_storage?.selectedWhatsappTemplateData,
      data: {
        component: {
          component_code: node?.data[0]?.component_code,
          component_icon: node?.data[0]?.component_icon,
          component_name: node?.data[0]?.component_name,
          component_type: node?.data[0]?.component_type,
          definition: node?.data[0]?.definition,
        },
      },
    },
  };
};

export const updateUserWorkflowListAfterRename = (
  userActionFlowList: any,
  getUpdateRes: any
) => {
  const updatedData: any[] = [];
  for (let i = 0; i < userActionFlowList.length; i++) {
    if (userActionFlowList[i]?.id === getUpdateRes?.dataSource?.id) {
      updatedData.push({
        ...userActionFlowList[i],
        name: getUpdateRes?.dataSource?.name,
      });
    } else {
      updatedData.push(userActionFlowList[i]);
    }
  }

  return updatedData;
};

export const findNextTrueMarkedObject = (obj: any) => {
  while (obj && obj.mark !== true) {
    obj = obj.next;
  }
  return obj;
};

export const createMultiChoiceStringArr = (value: any[]) => {
  return value.map((item) => item?.value);
};

export const findEachValues = (value: any) => {
  const result: string[] = [];

  const recursiveFindEach = (obj: any) => {
    if (obj?.return_data_format?.each) {
      result.push(...obj.return_data_format.each);
    }

    Object.values(obj ?? {}).forEach((nestedValue) => {
      if (typeof nestedValue === 'object') {
        recursiveFindEach(nestedValue);
      }
    });
  };

  recursiveFindEach(value);

  return result;
};

export const createMultiChoiceIdArr = (value: any[], type: string) => {
  return value.map((item) => item?.[type]);
};

export const findEntityValueFromReturnExtra = (
  value: any
): string | undefined => {
  let entityValue: string | undefined;

  const recursiveFindEntity = (obj: any) => {
    if (obj?.return_extra?.[0]?.entity && obj.return_extra[0]?.each) {
      entityValue = obj.return_extra[0].entity;
    }

    Object.entries(obj ?? {}).forEach(([key, nestedValue]) => {
      if (typeof nestedValue === 'object' && !entityValue) {
        recursiveFindEntity(nestedValue);
      }
    });
  };

  recursiveFindEntity(value);
  return entityValue;
};

export const iterateUntilTrueMark = (
  currentObject: any,
  targetColumnMarkTrueSchema: any,
  createMultiChoiceString: any
) => {
  while (currentObject !== undefined) {
    if (targetColumnMarkTrueSchema?.entity in currentObject) {
      currentObject[targetColumnMarkTrueSchema?.entity] =
        createMultiChoiceString;
      break;
    }

    currentObject = currentObject.next;
  }
};

export const iterateMultiChoiseApiTypeUntilTrueMark = (
  currentObject: any,
  targetColumnMarkTrueSchema: any,
  createMultiChoiceString: any
) => {
  while (currentObject !== undefined) {
    if (targetColumnMarkTrueSchema?.entity in currentObject) {
      currentObject[targetColumnMarkTrueSchema?.entity] =
        createMultiChoiceString;
      break;
    }

    targetColumnMarkTrueSchema = targetColumnMarkTrueSchema.next;
  }
};

// Function to update a nested property in an object based on a path array
export function updateNestedProperty(obj: any, path: any, value: any) {
  // Split the path into an array if it's a string
  if (typeof path === 'string') {
    path = path.split('.');
  }

  // Create a reference to the original object
  let current = obj;

  // Iterate through the path, except for the last element
  for (let i = 0; i < path.length - 1; i++) {
    // If the property doesn't exist, create an empty object
    if (!current[path[i]]) {
      current[path[i]] = {};
    }
    // Move the reference to the next level
    current = current[path[i]];
  }

  // Set the value at the last element of the path
  current[path[path.length - 1]] = value;

  // Return the updated object
  return obj;
}

export function resetObjectValues(obj: any) {
  // Iterate over the object keys
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = '';
    }
  }
  return obj;
}

export function getValueFromPath(obj: any, path: any) {
  const keys = path.split('.');
  let value = obj;
  for (let key of keys) {
    value = value[key];
    if (value === undefined) {
      return undefined; // Return undefined if any key is not found
    }
  }
  return value;
}

interface ValidationResult {
  success: boolean;
  error?: string;
  data?: any;
}

interface Pattern {
  type: string;
  key: string;
  title: string;
  subtitle?: string;
  value: string;
}

export const isEmtyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const validateResponseObject = (
  obj: Record<string, any>,
  pattern: Pattern
): ValidationResult => {
  // Check if the object is empty
  if (isEmtyObject(obj)) {
    return { success: false, error: 'No Result Found. Try different keyword' };
  }

  // Check if the specified property exists in the object
  const keys = pattern.key.split('.');
  let currentObj = obj;
  for (const key of keys) {
    if (key.includes('[')) {
      const arrayKey = key.split('[')[0];
      const index = parseInt(key.split('[')[1].replace(']', ''), 10);
      currentObj = currentObj[arrayKey];
      if (!currentObj || !Array.isArray(currentObj) || !currentObj[index]) {
        return {
          success: false,
          error: `Property "${pattern.key}" not found.`,
        };
      }
      currentObj = currentObj[index];
    } else {
      currentObj = key === '' ? currentObj : currentObj[key];
      if (!currentObj) {
        return {
          success: false,
          error: `Property "${pattern.key}" not found.`,
        };
      }
    }
  }

  // Check if the property has a unique pattern
  if (pattern.type === 'dict' && typeof currentObj !== 'object') {
    return {
      success: false,
      error: `Property "${pattern.key}" is not a dictionary.`,
    };
  } else if (pattern.type === 'list' && !Array.isArray(currentObj)) {
    return {
      success: false,
      error: `Property "${pattern.key}" is not a list.`,
    };
  }

  // Check if title, subtitle, and value exist in array elements
  if (Array.isArray(currentObj) && currentObj.length > 0) {
    const firstElement = currentObj[0];
    if (!firstElement.hasOwnProperty(pattern.title)) {
      return {
        success: false,
        error: `Array elements do not have required properties: ${pattern.title} `,
      };
    }
    if (!firstElement.hasOwnProperty(pattern.value)) {
      return {
        success: false,
        error: `Array elements do not have required properties: ${pattern.value}`,
      };
    }
  }

  // If all checks pass, return success object
  return { success: true, data: currentObj };
};

export const getListFromKey = (
  obj: Record<string, any> | any[],
  key: string
): any[] | null => {
  if (isEmtyObject(obj)) {
    return null;
  }

  if (key === '') return Array.isArray(obj) ? obj : null;

  const keys = key.split('.');
  let currentObj: any = obj;

  for (const k of keys) {
    if (k.includes('[')) {
      const arrayKey = k.split('[')[0];
      const index = parseInt(k.split('[')[1].replace(']', ''), 10);
      currentObj = currentObj[arrayKey];
      if (!currentObj || !Array.isArray(currentObj) || !currentObj[index]) {
        return null;
      }
      currentObj = currentObj[index];
    } else {
      currentObj = currentObj[k];
      if (!currentObj || !Array.isArray(currentObj)) {
        return null;
      }
    }
  }

  return currentObj;
};

export const mapResponseData = (
  //@ts-ignore
  response: AxiosResponse,
  pattern: Pattern
): any => {
  const { data } = response;

  return mapListData(data, pattern.key, pattern);
};

const mapListData = (data: any, key: string, pattern: Pattern): any => {
  let result = getListFromKey(data, key);

  if (!result) return [];

  return result.map((item: any) => mapDataByPattern(item, pattern));
};

const mapDataByPattern = (data: any, pattern: Pattern): any => {
  const mappedData: any = {};

  if (pattern.title) {
    mappedData.title = data[pattern.title];
  }

  if (pattern.subtitle) {
    mappedData.subtitle = data[pattern.subtitle];
  }

  mappedData.value = data[pattern.value];

  return mappedData;
};

export const mapPlainTagsToDynamicTags = ({
  plainTags,
  webhookData,
}: {
  plainTags: string[];
  webhookData: Record<string, string>;
}) => {
  const result: Record<string, string> = {};

  plainTags
    .filter((v) => !(v.startsWith('{{') && v.endsWith('}}')))
    .forEach((v) => {
      result[v] = `(Static)`;
    });
  return { ...result, ...webhookData };
};

export const getArrayProperties = (
  obj: Record<string, any>
): Record<string, any[]> => {
  const result: Record<string, any[]> = {};
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};

export const insertValueAtMarkedPath = (obj: any, value: any) => {
  const traverse = (node: any) => {
    if (!node) return false;
    if (node.mark === true) {
      // Found the mark: true, insert value
      node = { ...value };
      return true; // Stop further traversal
    } else {
      traverse(node?.next ?? {});
    }
    return false;
  };
  if (obj.hasOwnProperty('next')) traverse(obj?.next);
  return obj;
};

const jinjaCodeMapping = (objData: any, value: any) => {
  const obj = cloneDeep(objData);
  if (!obj.input_schema || !Array.isArray(obj.input_schema)) {
    throw new Error('Invalid input schema format.');
  }

  const schema = obj.input_schema[0]; // Take the 0 index of input_schema

  const traverseAndInsert = (target: any, schemaPath: any, value: any) => {
    if (schemaPath.hasOwnProperty('entity')) {
      const targetObject = target[schemaPath['entity']];
      const nextObj = findNextTrueMarkedObject(targetObject);
      nextObj['entity'] = value;
      nextObj['value'] = value;
      target[schemaPath['entity']] = insertValueAtMarkedPath(
        target[schemaPath['entity']],
        nextObj
      );
    } else {
      throw new Error('Invalid input schema format. Entity missing ');
    }
  };

  traverseAndInsert(obj, schema, value);

  return obj;
};

export const GenerateJinjaCodeVariable = (obj: any, value: string) => {
  try {
    return jinjaCodeMapping(obj, value) ?? obj;
  } catch (Exception) {
    console.error('Jinja Mapping exception: ', Exception);
    return obj;
  }
};

export const truncateText = (text: string, maxLength: number) => {
  try {
    if (text?.length <= maxLength) {
      return text;
    }
    const extensionMatch = text.match(/\.(\w+)$/);
    if (extensionMatch) {
      const extension = extensionMatch[0];
      const baseLength = maxLength - extension.length;
      return `${text.slice(0, baseLength)}...${extension}`;
    }
    return `${text.slice(0, maxLength)}...`;
  } catch (err) {
    console.error('Error truncating text:', err);
    return '--';
  }
};
