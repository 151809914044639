import dayJs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import React, { useEffect } from 'react';
import { feedChannels } from '../../../../../../utilities/utils';
import DatalabListPopoverView from '../../../datalab/DatalabListPopoverView';
import ChatboxNavElements from './ChatboxNavElements';
import ChatboxReplyOptions from './ChatboxReplyOptions';
import SavedReplies from './SavedReplies';
import WhatappTemplateMessage from './WhatappTemplateMessage';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { toaster } from 'evergreen-ui';
import { SelectedTeamInterface } from 'index';
import { Button } from 'library';
import useCommentEdit from 'pages/inbox/hooks/useCommentEdit';
import {
  CustomerReplyBlockInterface,
  GroupMemberInterface,
  IDatalabList,
  ISavedReplyAttachemnt,
  ITicketQueueData,
  SavedRepliesInterface,
  SendMessageRequestBodyInterface,
  TicketActionInterface,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import AttachmentPreview from './AttachmentPreview';
import MentionPopup from './MentionPopup';

dayJs.extend(relativeTime);
dayJs.extend(updateLocale);

dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%dm',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 mo',
    MM: '%d mo',
    y: '1 y',
    yy: '%d y',
  },
});
interface Props {
  userId: number;
  lineCount: number;
  selectedQueueType: string;
  chatboxActiveNav: string;
  conversationLoading: boolean;
  botConversationLoading: boolean;
  ticketIsResolvedStatus: number;
  ticketQueue: ITicketQueueData;
  selectedTicket: TicketInterface;
  savedReplies: SavedRepliesInterface[];
  datalabList: IDatalabList[];
  ticketActions: TicketActionInterface[];
  selectedProject: SelectedTeamInterface;
  conversationBlockDataForReply: CustomerReplyBlockInterface | null;

  handleEcommerceIconClick: () => void;
  setLineCount: (value: number) => void;

  updateSateData: (key: string, value: string) => void;
  handleMessengerChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => void;
  handleFeedChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => void;
  handleTicketAssignment: (agentId: number) => void;
  updateShowUpgradeModal: (payload: boolean) => void;
  fetchGroupMembers: (customer_id: number) => Promise<GroupMemberInterface>;
  handleCloseCustomerReply: () => void;
  handleConversationBlockDataForReply: (value: null) => void;
  updateSelectedNav: (navId: number) => void;
  handleRightbarAppearence: (value: boolean) => void;
  updateInboxDatalabStateData: (key: string, value: any) => void;
  savedReplyAttachments: SavedRepliesInterface['attachments'];
  setSavedReplyAttachments: React.Dispatch<
    React.SetStateAction<ISavedReplyAttachemnt[]>
  >;
}

export const Chatbox: React.FC<Props> = ({
  userId,
  lineCount,
  datalabList,
  ticketQueue,
  savedReplies,
  savedReplyAttachments,
  setSavedReplyAttachments,
  ticketActions,
  selectedTicket,
  selectedProject,
  updateSelectedNav,
  chatboxActiveNav,
  selectedQueueType,
  fetchGroupMembers,
  conversationLoading,
  handleRightbarAppearence,
  botConversationLoading,
  ticketIsResolvedStatus,
  conversationBlockDataForReply,
  setLineCount,
  updateSateData,
  updateShowUpgradeModal,
  handleTicketAssignment,
  handleEcommerceIconClick,
  handleFeedChannelMessage,
  handleCloseCustomerReply,
  handleMessengerChannelMessage,
  updateInboxDatalabStateData,
  handleConversationBlockDataForReply,
}) => {
  const { t } = useTranslation();
  const { updateComment, commentEditData, cancelCommentEditMode } =
    useCommentEdit();
  const [userSuggestions, setUserSuggestions] = React.useState<
    GroupMemberInterface[]
  >([]);
  // Telegram Group Mention
  const [showMentionPopup, setShowMentionPopup] = React.useState(false);
  const [mentionStart, setMentionStart] = React.useState(-1);
  const [mentionQuery, setMentionQuery] = React.useState('');
  const [mentionIds, setMentionIds] = React.useState<string[]>([]);

  const [inputValue, setInputValue] = React.useState('');
  const [inputEvent, setInputEvent] = React.useState<any>(null);
  const [showableSavedReply, setShowableSavedReply] =
    React.useState(savedReplies);
  const [isSavedReplyOpened, setIsSavedReplyOpened] = React.useState(false);

  const [isOpenWhatsappMessage, setIsOpenWhatsappMessage] =
    React.useState(false);
  const [isDatalabOpened, setIsDatalabOpened] = React.useState(false);
  const [showableDatalabList, setShowableDatalabList] =
    React.useState(datalabList);
  const savedReplyHeightOffset = savedReplyAttachments?.length ? 50 : 0;
  let platformType = selectedTicket?.customer_platform_type;
  let isResolved = selectedTicket?.is_resolved;
  let selectedNavIsNote = chatboxActiveNav === 'Note';
  let isSelfAssigned = selectedTicket?.assigned_agent === userId || false;

  let isDirectMessageAllowed = () => {
    if (!Array.isArray(ticketActions)) {
      return false;
    }

    let directAction = ticketActions.filter(
      (actionItem) =>
        actionItem.action === 'direct_message' && actionItem.is_allowed
    );

    return directAction.length > 0;
  };

  useEffect(() => {
    if (platformType === 'facebook_feed') {
      if (commentEditData?.isEditing && commentEditData?.commentText !== null) {
        setInputValue(commentEditData?.commentText);
      }
    }
    //eslint-disable-next-line
  }, [commentEditData]);

  const setInitialState = () => {
    setLineCount(1);
    setInputValue('');
    setInputEvent(null);
    setIsSavedReplyOpened(false);
    setIsOpenWhatsappMessage(false);
    setShowableSavedReply(savedReplies);
    setShowMentionPopup(false);
    setIsDatalabOpened(false);
    setShowableDatalabList(datalabList);
  };

  const textareaRef = React.useRef(null);

  const handleChildClick = () => {
    setTimeout(() => {
      //@ts-ignore
      textareaRef.current.focus();
    }, 500);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    setInputEvent(event);
    if (event.key === 'Enter' && isSavedReplyOpened) {
      event.preventDefault();
      setIsSavedReplyOpened(false);
      return;
    }
    if (event.key === 'Enter' && event.shiftKey) {
      setLineCount(lineCount + 1);
      return;
    }
    if (event.key === 'Enter') {
      // TODO: to be refactored
      event.preventDefault();
      if (!inputValue.trim()) {
        toaster.warning(t('Sending an empty message is not allowed.'));
        setInputValue('');
        return;
      }
      const isFeedChannel = feedChannels.includes(platformType);
      if (feedChannels.includes(platformType) && commentEditData.isEditing) {
        updateComment(inputValue);
      } else if (isFeedChannel) {
        const payload: SendMessageRequestBodyInterface = {
          text: inputValue,
          audio: null,
          image: null,
          action:
            chatboxActiveNav === 'Comment' ? 'public_reply' : 'direct_message',
        };
        if (platformType === 'instagram_feed') {
          handleFeedChannelMessage(payload);
        } else {
          payload['attachments'] = savedReplyAttachments;
          handleFeedChannelMessage(payload);
        }
      } else {
        let messageRequestPayload: any = {
          text: inputValue,
          audio: null,
          image: null,
          template: null,
          attachments: savedReplyAttachments,
          action: selectedNavIsNote ? 'write_note' : 'direct_message',
        };

        if (!!conversationBlockDataForReply) {
          messageRequestPayload.reply_to_message_id =
            conversationBlockDataForReply?.payload;
        }
        if (platformType === 'telegram_group' && mentionIds.length !== 0) {
          messageRequestPayload.mention_ids = mentionIds;
        }
        handleMessengerChannelMessage(messageRequestPayload);
        handleConversationBlockDataForReply(null);
      }
      setInputValue('');
      setLineCount(1);
      setInputEvent(null);
      setIsOpenWhatsappMessage(false);
      setIsSavedReplyOpened(false);
      setMentionIds([]);
    }
    if (
      inputValue === '' &&
      event.key === '/' &&
      selectedTicket.customer_platform_type === 'whatsapp_bsp'
    ) {
      setIsOpenWhatsappMessage(true);
      setIsSavedReplyOpened(false);
    }

    if (event.key === '#') {
      setIsSavedReplyOpened(true);
      setIsOpenWhatsappMessage(false);
    }
  };

  const processSendReplyData = (value: string) => {
    if (!textareaRef) return;
    const caretPosition = textareaRef?.current.selectionStart;
    const textBeforeCaret = value.substring(0, caretPosition);

    // Find the last occurrence of "#"
    const lastHashIndex = textBeforeCaret.lastIndexOf('#');

    let textAfterHash = '';
    if (lastHashIndex !== -1) {
      // Extract the text after "#"
      textAfterHash = textBeforeCaret.slice(lastHashIndex + 1).trim();
    }

    let savedData = savedReplies;

    if (textAfterHash.length !== 0 && isSavedReplyOpened) {
      savedData = savedData.filter((reply) =>
        reply.title.toLowerCase().includes(textAfterHash.toLowerCase())
      );
    }
    setShowableSavedReply(savedData);
  };

  const processDatalabListOnInputChange = (value: string) => {
    let updatedDatalabList: IDatalabList[] = datalabList;
    if (value.length !== 0) {
      updatedDatalabList = datalabList.filter((datalab: IDatalabList) =>
        datalab?.title.toLowerCase().includes(value.toLowerCase())
      );
    }
    setShowableDatalabList(updatedDatalabList);
  };

  const handleMentionFeature = (inputText: string) => {
    // Detect mentions starting with '@'
    const mentionIndex = inputText.lastIndexOf('@');
    if (mentionIndex !== -1) {
      setShowMentionPopup(true);
      setMentionStart(mentionIndex + 1); // +1 to exclude the '@' character
      setMentionQuery(inputText.substring(mentionIndex + 1)); // Get the text after '@'
    } else {
      setShowMentionPopup(false);
      setMentionStart(-1);
      setMentionQuery('');
    }
  };

  //handle mention button click event
  const handleMentionSelect = (member: GroupMemberInterface) => {
    const mentionText =
      member?.username.length !== 0 ? member?.username : member?.name;
    const newText = `${inputValue.slice(0, mentionStart - 1)}@${mentionText} `;
    setInputValue(newText);
    setShowMentionPopup(false);
    setMentionStart(-1);
    setMentionQuery('');
    setMentionIds(
      member?.username.length !== 0
        ? [...mentionIds]
        : [...mentionIds, member.primary_id]
    );
    textareaRef?.current.focus();
    textareaRef?.current.setSelectionRange(
      textareaRef?.current.value.length,
      textareaRef?.current.value.length
    );
  };

  //check if mention popup show or not
  const handleInputChange = (e: any) => {
    let lineCountLocal = e.target.value.split('\n')?.length || lineCount;
    if (lineCountLocal > 8) {
      lineCountLocal = 8;
    }
    if (e.target.value === '') {
      setIsDatalabOpened(false);
      setIsSavedReplyOpened(false);
      setIsOpenWhatsappMessage(false);
    }
    setInputValue(e.target.value);
    setLineCount(lineCountLocal);
    if (isSavedReplyOpened) processSendReplyData(e.target.value);
    if (isDatalabOpened) processDatalabListOnInputChange(e.target.value);

    handleMentionFeature(e.target.value);
  };

  const handleSendButton = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    const isFeedChannel = feedChannels.includes(platformType);

    if (isFeedChannel && commentEditData.isEditing) {
      updateComment(inputValue);
    } else if (isFeedChannel) {
      const payload: SendMessageRequestBodyInterface = {
        text: inputValue,
        audio: null,
        image: null,
        action:
          chatboxActiveNav === 'Comment' ? 'public_reply' : 'direct_message',
      };
      if (platformType === 'instagram_feed') {
        handleFeedChannelMessage(payload);
      } else {
        payload['attachments'] = savedReplyAttachments;
        handleFeedChannelMessage(payload);
      }
    } else {
      let messageRequestPayload: any = {
        text: inputValue,
        audio: null,
        image: null,
        attachments: savedReplyAttachments,
        template: null,
        action: selectedNavIsNote ? 'write_note' : 'direct_message',
      };

      if (!!conversationBlockDataForReply) {
        messageRequestPayload.reply_to_message_id =
          conversationBlockDataForReply?.payload;
      }
      handleMessengerChannelMessage(messageRequestPayload);

      handleCloseCustomerReply();
    }
    setInputValue('');
    setLineCount(1);
    setIsOpenWhatsappMessage(false);
    setIsSavedReplyOpened(false);
    setShowMentionPopup(false);
  };

  const mentionSuggestions = () => {
    const suggestions = userSuggestions.filter((user) =>
      user.name.toLowerCase().includes(mentionQuery.toLowerCase())
    );

    if (suggestions.length === 0) {
      setShowMentionPopup(false);
    }

    return suggestions;
  };

  const renderInputTextArea = () => {
    return (
      <div
        // inline style is being used so that we can control the chatbox height according to line height
        // TODO: check if we can do it with tailwind css
        style={{ height: 104 + (lineCount - 1) * 16 }}
        className='px-4 py-3 h-14'
      >
        {showMentionPopup && (
          <MentionPopup
            suggestions={mentionSuggestions()}
            onMentionSelect={handleMentionSelect}
            handleClose={() => {
              setShowMentionPopup(false);
            }}
          />
        )}
        <textarea
          ref={textareaRef}
          disabled={shouldDisbaleChatboxArea()}
          id='reply-input'
          autoFocus={true}
          // inline style is being used so that we can control the chatbox height according to line height
          // TODO: check if we can do it with tailwind css
          style={{ height: 85 + (lineCount - 1) * 16 }}
          value={inputValue}
          className={`w-full border-none outline-none text-sm resize-none focus:border-0 focus:border-none focus:outline-none focus:outline-0 without-ring scroll-none ${
            selectedNavIsNote ? 'bg-yellow-100' : 'bg-white'
          } ${
            shouldDisbaleChatboxArea() ? 'cursor-not-allowed' : 'cursor-text'
          }`}
          placeholder={
            selectedNavIsNote
              ? t('Your note is visible to you and your teammates only...')
              : isResolved
              ? t('Send a new message to reopen the chat')
              : t('Type # for saved replies or send a new message')
          }
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onKeyDown={setInputEvent}
        />
      </div>
    );
  };

  const rederReplyOptionsAndSendButtonView = () => {
    return (
      <div
        className={`flex  h-10 px-4 ${
          chatboxActiveNav !== 'Note' ? 'justify-between' : 'justify-end'
        }`}
      >
        {chatboxActiveNav !== 'Note' && (
          <div className='flex items-center'>
            <ChatboxReplyOptions
              selectedProject={selectedProject}
              selectedTicket={selectedTicket}
              chatboxActiveNav={chatboxActiveNav}
              isSavedReplyOpened={isSavedReplyOpened}
              isDatalabListOpened={isDatalabOpened}
              setIsDatalabListOpened={(value: boolean) => {
                setIsDatalabOpened(value);
                setIsSavedReplyOpened(false);
                setIsOpenWhatsappMessage(false);
              }}
              handleChildClick={handleChildClick}
              setIsSavedReplyOpened={(value: boolean) => {
                setIsSavedReplyOpened(value);
                setIsDatalabOpened(false);
                setIsOpenWhatsappMessage(false);
              }}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleEcommerceIconClick={() => {
                handleEcommerceIconClick();
                setIsSavedReplyOpened(false);
                setIsOpenWhatsappMessage(false);
              }}
              handleWhatsapptemplatePopover={() => {
                setIsOpenWhatsappMessage(!isOpenWhatsappMessage);
                setIsSavedReplyOpened(false);
              }}
              shouldDisbaleChatboxArea={shouldDisbaleChatboxArea}
              handleMessengerChannelMessage={handleMessengerChannelMessage}
              handleFeedChannelMessage={handleFeedChannelMessage}
              updateShowUpgradeModal={updateShowUpgradeModal}
              conversationBlockDataForReply={conversationBlockDataForReply}
              handleConversationBlockDataForReply={
                handleConversationBlockDataForReply
              }
            />
          </div>
        )}

        <div className='flex justify-center items-center py-0 mb-[8px]'>
          {commentEditData?.isEditing && (
            <Button
              intent='default'
              size='sm'
              className='ltr:mr-2 rtl:ml-2'
              onClick={() => cancelCommentEditMode()}
            >
              {t('Cancel')}
            </Button>
          )}

          <Button
            intent='primary'
            size='sm'
            disabled={shouldDisbaleChatboxArea()}
            onClick={handleSendButton}
            isDisabled={inputValue.length === 0}
            className={`${
              shouldDisbaleChatboxArea() ? 'cursor-not-allowed' : 'cursor-text'
            }`}
          >
            {commentEditData?.isEditing
              ? t('Save Comment')
              : selectedNavIsNote
              ? t('Save Note')
              : t('Send')}
          </Button>
        </div>
      </div>
    );
  };

  const renderAssignToMeView = () => {
    return (
      <div className='absolute flex flex-col items-center justify-center w-full h-[140px] bg-white/[.7]'>
        <Button intent='primary' onClick={() => handleTicketAssignment(userId)}>
          {t('Assign to me')}
        </Button>
        <span className='mt-3 text-xs font-medium text-gray-500'>
          {t('Click the button to join the conversation')}
        </span>
      </div>
    );
  };

  const renderPlatformRestrictionView = () => {
    return (
      <div className='absolute flex items-center text-red-500 justify-center w-full h-[144px] bg-red-50 rounded-b-md'>
        <ExclamationCircleIcon className='w-4 h-4 ' />
        <span className='text-sm font-medium ltr:ml-2 rtl:mr-2'>
          {t(
            'You can not reply this conversation due to platform restrictions.'
          )}
        </span>
      </div>
    );
  };

  const rederWhatsTeplateSendView = () => {
    return (
      <div className='absolute flex flex-col items-center justify-center w-full h-[144px] bg-white/[.7]'>
        <Button intent='primary' onClick={() => setIsOpenWhatsappMessage(true)}>
          {t('Send a message template')}
        </Button>
        <span className='mt-3 text-xs font-medium text-gray-500'>
          {t(
            'The 24 hr session is finished. Please send a message template to restart the conversation.'
          )}
        </span>
      </div>
    );
  };

  const renderChatboxActions = () => {
    if (
      !isDirectMessageAllowed() &&
      chatboxActiveNav !== 'Note' &&
      selectedTicket?.customer_platform_type !== 'whatsapp_bsp'
    ) {
      return renderPlatformRestrictionView();
    }
    if (selectedQueueType === 'unassigned' || chatboxActiveNav === 'Note') {
      return null;
    }

    if (
      userId !== selectedTicket?.assigned_agent &&
      (selectedTicket?.assigned_agent !== 0 ||
        selectedTicket?.assigned_group !== 0)
    ) {
      return renderAssignToMeView();
    } else if (
      (isSelfAssigned || ticketQueue.type === 'unassigned') &&
      platformType === 'whatsapp_bsp' &&
      chatboxActiveNav !== 'Note' &&
      !isDirectMessageAllowed()
    ) {
      return rederWhatsTeplateSendView();
    }
    return null;
  };

  const shouldDisbaleChatboxArea = () => {
    if (selectedQueueType === 'unassigned') return false;
    if (chatboxActiveNav === 'Note') return false;
    return (
      conversationLoading ||
      botConversationLoading ||
      userId !== selectedTicket?.assigned_agent ||
      (!isDirectMessageAllowed() && chatboxActiveNav !== 'Note')
    );
  };

  const fetchGroupMemberList = async () => {
    const list = await fetchGroupMembers(selectedTicket?.customer_id);
    setUserSuggestions([...list]);
  };

  React.useEffect(() => {
    if (selectedTicket?.customer_platform_type === 'telegram_group') {
      fetchGroupMemberList();
    }

    if (
      feedChannels.includes(selectedTicket?.customer_platform_type) &&
      chatboxActiveNav !== 'edit-comment'
    ) {
      setInputValue(`@${selectedTicket?.customer_full_name} `);
      setLineCount(1);
      setIsSavedReplyOpened(false);
      setIsDatalabOpened(false);
      return;
    }
    if (chatboxActiveNav !== 'edit-comment') setInitialState();
    // eslint-disable-next-line
  }, [selectedTicket?.id, chatboxActiveNav]);

  useEffect(() => {
    // Set focus to the end of the text after each change
    if (textareaRef.current && !!inputValue) {
      textareaRef?.current.focus();
      textareaRef?.current.setSelectionRange(
        textareaRef?.current.value.length,
        textareaRef?.current.value.length
      );
    }

    //eslint-disable-next-line
  }, [textareaRef?.current]);

  React.useEffect(() => {
    setShowableSavedReply(savedReplies);
    // eslint-disable-next-line
  }, [savedReplies]);

  React.useEffect(() => {
    setShowableDatalabList(datalabList);
    // eslint-disable-next-line
  }, [datalabList]);

  const handleDeleteSavedReplyAttachments = (index: number) => {
    setSavedReplyAttachments(
      savedReplyAttachments.filter((_, idx) => idx !== index)
    );
  };

  return (
    <>
      {(conversationLoading || botConversationLoading) && (
        <div
          data-testid='middle-chat-box2'
          style={{ height: 178 + (lineCount - 1) * 16 }}
          className={`w-auto mx-4 border border-gray-200 rounded-lg bottom-2 bg-gray-100 animate-pulse`}
        />
      )}
      {!conversationLoading && !botConversationLoading && (
        <div
          data-testid='middle-chat-box'
          className={`w-auto mx-4 border h-auto border-gray-200 rounded-lg bottom-2`}
          // inline style is being used so that we can control the chatbox height according to line height
          // TODO: check if we can do it with tailwind css
          style={{
            height: 178 + savedReplyHeightOffset + (lineCount - 1) * 16,
          }}
        >
          <div className='h-8 border-b border-gray-200 border-solid rounded-t-lg bg-gray-50'>
            <ChatboxNavElements
              activeTab={chatboxActiveNav}
              selectedTicket={selectedTicket}
              updateSateData={updateSateData}
              ticketIsResolvedStatus={ticketIsResolvedStatus}
              ticketActions={ticketActions}
              selectedQueueType={selectedQueueType}
            />
          </div>
          <div className='relative'>
            <div
              className={`absolute w-full rounded-b-lg ${
                selectedNavIsNote ? 'bg-yellow-100' : 'bg-white'
              }`}
            >
              {renderInputTextArea()}
              {Array.isArray(savedReplyAttachments) &&
                savedReplyAttachments.length > 0 &&
                chatboxActiveNav !== 'Note' && (
                  <AttachmentPreview
                    attachmentLists={savedReplyAttachments}
                    handleRemoveAttachment={handleDeleteSavedReplyAttachments}
                  />
                )}
              {rederReplyOptionsAndSendButtonView()}
            </div>
            {/* TODO: have to check if userId exist in smart group  */}
            {renderChatboxActions()}
          </div>
          {isOpenWhatsappMessage && (
            <WhatappTemplateMessage
              isOpenWhatsappMessage={isOpenWhatsappMessage}
              setIsOpenWhatsappMessage={setIsOpenWhatsappMessage}
              setInputValue={setInputValue}
            />
          )}
          {isSavedReplyOpened && (
            <SavedReplies
              platformType={platformType}
              inputEvent={inputEvent}
              inputValue={inputValue}
              setInputValue={setInputValue}
              savedReplies={showableSavedReply}
              setSavedReplyAttachments={setSavedReplyAttachments}
              setIsSavedReplyOpened={(value: boolean) => {
                setIsSavedReplyOpened(value);
                document.getElementById('reply-input')?.focus();
              }}
            />
          )}
          {isDatalabOpened && (
            <DatalabListPopoverView
              inputEvent={inputEvent}
              inputValue={inputValue}
              setInputValue={setInputValue}
              datalabList={showableDatalabList}
              updateSelectedNav={updateSelectedNav}
              setIsDatalabListOpened={setIsDatalabOpened}
              handleRightbarAppearence={handleRightbarAppearence}
              updateInboxDatalabStateData={updateInboxDatalabStateData}
            />
          )}
        </div>
      )}
      {inputValue.length !== 0 && (
        <span className='flex px-4 text-xs font-medium text-gray-500 rtl:text-right'>
          {platformType === 'telegram_group' &&
            `${t('Enter @ to mention someone in group chat')}, `}{' '}
          {t('Shift + Enter to add a new line')}
        </span>
      )}
    </>
  );
};

export default Chatbox;
