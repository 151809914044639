import useTranslation from 'components/customHooks/useTranslation';
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import { CustomerInfoInterface } from 'pages/inbox/inboxInterface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useDispatch } from 'react-redux';

type TPlatforms = 'whatsapp_bsp' | 'facebook_messenger';

interface ChatExpiryTimeBarProps {
  platformType: TPlatforms;
  customerInformation: CustomerInfoInterface;
}

export const ChatExpiryTimeBar = ({
  platformType,
  customerInformation,
}: ChatExpiryTimeBarProps) => {
  const { t, dashboardLanguage } = useTranslation();
  const dispatch = useDispatch();

  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [progress, setProgress] = useState<number>(100);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const timerRef = useRef<NodeJS.Timeout>();
  const lastUpdateRef = useRef<number>(0);
  const hasDispatchedRef = useRef<boolean>(false);

  // Store current values in refs to avoid stale closures
  const timeRemainingRef = useRef(timeRemaining);
  timeRemainingRef.current = timeRemaining;

  // Set moment locale based on current language with fallback to English
  useEffect(() => {
    moment.locale('en');

    if (dashboardLanguage === 'arabic') {
      moment.locale('ar');
    } else {
      moment.locale('en');
    }
  }, [dashboardLanguage]);

  /**
   * Memoized function to get the last message date from the customer information.
   * Converts the timestamp to seconds if it is in milliseconds.
   *
   * @returns {moment.Moment | null} - The moment object of the last message date or null if no timestamp is available.
   */
  const lastMessageDate = useMemo(() => {
    const timestamp = Number(customerInformation?.last_message_time);
    if (!timestamp) return null;

    const timestampInSeconds =
      String(timestamp).length > 10 ? Math.floor(timestamp / 1000) : timestamp;

    return moment.unix(timestampInSeconds);
  }, [customerInformation?.last_message_time]);

  /**
   * Memoized function to calculate the expiry date based on the last message date and platform type.
   * For 'whatsapp_bsp' platform, it adds 24 hours to the last message date.
   * For other platforms, i.e. 'facebook_messenger', it adds 7 days to the last message date.
   *
   * @returns {moment.Moment | null} - The moment object of the expiry date or null if no last message date is available.
   */
  const expiryDate = useMemo(() => {
    if (!lastMessageDate) return null;

    return platformType === 'whatsapp_bsp'
      ? lastMessageDate.clone().add(24, 'hours')
      : lastMessageDate.clone().add(7, 'days');
  }, [lastMessageDate, platformType]);

  /**
   * Memoized function to determine the update interval based on the expiry date and platform type.
   * For 'whatsapp_bsp' platform, it updates every second if the remaining time is 5 minutes or less,
   * every minute if the remaining time is 1 hour or less, and every hour otherwise.
   * For other platforms, i.e. 'facebook_messenger', it updates every second if the remaining time is 5 minutes or less,
   * every minute if the remaining time is 1 hour or less, every hour if the remaining time is 1 day or less,
   * and every day otherwise.
   *
   * @returns {number} - The update interval in milliseconds or 0 if no expiry date is available.
   */
  const getUpdateInterval = useCallback(() => {
    if (!expiryDate) return 0;

    const remainingTime = moment.duration(expiryDate.diff(moment()));

    if (platformType === 'whatsapp_bsp') {
      if (remainingTime.asMinutes() <= 5) return 1000;
      if (remainingTime.asHours() <= 1) return 60000; // update every minute
      return 3600000; // update every hour
    } else {
      if (remainingTime.asMinutes() <= 5) return 1000; // Update every second
      if (remainingTime.asHours() <= 1) return 60000; // Update every minute
      if (remainingTime.asDays() <= 1) return 3600000; // Update every hour
      return 86400000; // Update every day
    }
  }, [expiryDate, platformType]);

  /**
   * Callback function to handle the expiry of the ticket.
   * Updates the state data to disable direct messaging and sets the expired and loading states.
   */
  const handleExpiry = useCallback(() => {
    if (!hasDispatchedRef.current) {
      dispatch.inbox.updateSateData({
        key: 'ticketActions',
        value: {
          direct_message: false,
        },
      });
      hasDispatchedRef.current = true;
    }

    setIsExpired(true);
    setIsLoading(false);
  }, [dispatch.inbox]);

  /**
   * Formats the given duration into a human-readable string based on the platform type and dashboard language.
   * For 'whatsapp_bsp' platform, it formats the duration into seconds, minutes, or hours.
   * For 'facebook_messenger' platform, it formats the duration into seconds, minutes, hours, or days.
   *
   * @param {moment.Duration} duration - The duration to format.
   * @returns {string} - The formatted duration string.
   */
  const formatDuration = useCallback(
    (duration: moment.Duration): string => {
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.minutes());
      const seconds = Math.floor(duration.seconds());
      const totalMinutes = duration.asMinutes();
      const days = Math.ceil(duration.asDays());

      moment.locale(dashboardLanguage === 'arabic' ? 'ar' : 'en');

      if (platformType === 'whatsapp_bsp') {
        if (totalMinutes <= 1) {
          const secondWord = moment
            .localeData()
            .relativeTime(seconds, false, 'ss', false);
          return `${secondWord}`;
        } else if (totalMinutes <= 5) {
          const minuteWord = moment
            .localeData()
            .relativeTime(minutes, false, 'mm', false);
          const secondWord = moment
            .localeData()
            .relativeTime(seconds, false, 'ss', false);

          return `${minuteWord} ${t('and')} ${secondWord}`;
        } else if (hours <= 1) {
          return moment
            .duration(Math.floor(totalMinutes), 'minutes')
            .humanize();
        } else {
          return moment.duration(hours, 'hours').humanize();
        }
      } else {
        // Facebook Messenger expiration formatting
        if (totalMinutes <= 5) {
          // Use the same approach for exact time in Facebook case
          const minuteWord = moment
            .localeData()
            .relativeTime(minutes, false, 'mm', false);
          const secondWord = moment
            .localeData()
            .relativeTime(seconds, false, 'ss', false);

          return `${minuteWord} ${t('and')} ${secondWord}`;
        } else if (hours <= 1) {
          return moment
            .duration(Math.floor(totalMinutes), 'minutes')
            .humanize();
        } else if (days <= 1) {
          return moment.duration(hours, 'hours').humanize();
        } else {
          return moment.duration(days, 'days').humanize();
        }
      }
    },
    [platformType, dashboardLanguage, t]
  );

  /**
   * Calculates the remaining time until the ticket expires.
   * If the last message date or expiry date is not available, it handles the expiry.
   * Updates the time remaining and progress based on the platform type.
   *
   * @returns {boolean} - Returns false if the expiry date has passed or is not available, true otherwise.
   */
  const calculateTimeRemaining = useCallback(() => {
    if (!lastMessageDate || !expiryDate) {
      handleExpiry();
      return false;
    }

    const now = moment();
    if (now.isAfter(expiryDate)) {
      handleExpiry();
      return false;
    }

    const duration = moment.duration(expiryDate.diff(now));
    const newTimeString = formatDuration(duration);

    if (newTimeString !== timeRemainingRef.current) {
      setTimeRemaining(newTimeString);
      setProgress(
        platformType === 'whatsapp_bsp'
          ? (duration.asSeconds() / (24 * 60 * 60)) * 100
          : (duration.asDays() / 7) * 100
      );
    }

    setIsLoading(false);
    return true;
  }, [lastMessageDate, expiryDate, platformType, handleExpiry, formatDuration]);

  /**
   * useEffect hook to set up a timer that periodically checks the remaining time until the ticket expires.
   * It clears any existing timer, sets the loading state, and calculates the time remaining.
   * If the time remaining is valid, it sets an interval to update the remaining time and progress.
   * The interval is adjusted based on the platform type and remaining time.
   * The timer is cleared when the component is unmounted.
   */
  useEffect(() => {
    let mounted = true;

    const setupTimer = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (!mounted) return;

      setIsLoading(true);
      hasDispatchedRef.current = false;

      if (calculateTimeRemaining() && mounted) {
        const interval = getUpdateInterval();
        if (interval > 0) {
          timerRef.current = setInterval(() => {
            if (!mounted) return;

            const now = Date.now();
            if (now - lastUpdateRef.current >= interval) {
              lastUpdateRef.current = now;

              if (!calculateTimeRemaining()) {
                if (timerRef.current) {
                  clearInterval(timerRef.current);
                }
                return;
              }

              const newInterval = getUpdateInterval();
              if (newInterval !== interval && mounted) {
                setupTimer();
              }
            }
          }, Math.min(1000, interval));
        }
      }
    };

    setupTimer();

    return () => {
      mounted = false;
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [calculateTimeRemaining, getUpdateInterval]);

  if (isLoading || isExpired) {
    return null;
  }

  return (
    <div className='sticky z-[9] mt-2 bottom-0 left-0 right-0 w-full'>
      <div className='absolute inset-0 bg-white/30 backdrop-blur-[2px]' />
      <div className='relative p-1 flex items-center justify-center w-full bg-yellow-50'>
        <div className='flex items-center gap-2 justify-center'>
          <span className='text-gray-700 font-medium'>
            {t('This chat will expire in')} {timeRemaining}
          </span>

          <div className='w-4 h-4 rtl:order-first'>
            <CircularProgressbar
              className='flex-2'
              value={progress}
              maxValue={100}
              strokeWidth={12}
              styles={buildStyles({
                pathColor: '#EAB308',
                trailColor: '#e4e4e4',
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatExpiryTimeBar;
