import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { ExternalLinkIcon, InfoIcon } from 'lucide-react';
import { ICsatSurvey } from 'pages/reporting/interface';
import { CSAT_METRICES_CONTENT } from 'pages/reporting/utility/content';
import { convertMinutesToHumanReadable } from 'pages/reporting/utility/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'libraryV2/ui/card';

interface SingleCsatDataCardProps {
  cardData: ICsatSurvey;
  renderCardTitle: () => JSX.Element | React.ReactNode | React.ReactElement;
}

export const SingleCsatDataCard: React.FC<SingleCsatDataCardProps> = ({
  cardData,
  renderCardTitle = () => null,
}) => {
  const currentValue = cardData?.current_value || 0;
  const growthDirection = cardData?.growth_direction || '';
  const growthValue =
    cardData?.growth_value === 'N/A' ? '0%' : cardData?.growth_value;
  const previousValue = cardData?.previous_value || 0;
  const dateRangeInMinuets = convertMinutesToHumanReadable(cardData.span) || '';
  return (
    <Card className='h-fit bg-white'>
      <CardHeader className='pb-0'>
        <CardTitle> {renderCardTitle()}</CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent>
        <section>
          <p className='text-textPrimary font-bold text-2xl leading-8'>
            {currentValue}
          </p>
          <div className='text-textSecondary'>
            <span
              className={cn('text-textPrimary', {
                'text-primary': growthDirection === '+',
                'text-error': growthDirection === '-',
              })}
            >
              {growthDirection && growthDirection}
              {growthValue}
            </span>{' '}
            from <span className='text-textPrimary'>{previousValue}</span>{' '}
            <p
              className={cn('inline-block', {
                hidden: !dateRangeInMinuets.length,
              })}
            >
              of previous{' '}
              <span className='text-textPrimary'>{dateRangeInMinuets}</span>
            </p>
          </div>
        </section>
      </CardContent>
    </Card>
  );
};

export const LoadingCsatDataCard: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Card className='h-fit bg-white'>
      <CardHeader className='pb-2'>
        <CardTitle>
          <div className='flex items-center gap-1'>
            <p className='text-sm font-medium leading-5 text-textPrimary'>
              {title}
            </p>
            <InfoIcon className='w-3.5 h-3.5 text-textSecondary-disable' />
          </div>
        </CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent>
        <section className='space-y-3'>
          <p className='text-textPrimary font-bold text-2xl py-2 bg-zinc-100 animate-pulse leading-8 w-24 rounded'></p>
          <div className='text-textSecondary py-2 bg-zinc-100 animate-pulse rounded'></div>
        </section>
      </CardContent>
    </Card>
  );
};

interface CsatDataCardsProps {}

type TCsatCardDatSource = keyof typeof CSAT_METRICES_CONTENT.dataCard;

export const CsatDataCards: React.FC<CsatDataCardsProps> = () => {
  const {
    isLoading,
    totalCsatSurveyData,
    totalCsatResponseData,
    avgResponseData,
    avgRatingsData,
  } = useSelector((state: any) => ({
    isLoading: state?.loading?.models?.reporting,
    totalCsatSurveyData: state.reporting?.csatTotalCount ?? {},
    totalCsatResponseData: state.reporting?.csatTotalResponseCount ?? {},
    avgResponseData: state.reporting?.csatRespondedCount ?? {},
    avgRatingsData: state.reporting?.csatAvgRating ?? {},
  }));

  const renderTotalSentCardHeader = (cardDataSource: TCsatCardDatSource) => {
    const title = CSAT_METRICES_CONTENT.dataCard[cardDataSource].title;
    const tooltipContent =
      CSAT_METRICES_CONTENT.dataCard[cardDataSource].toolTip;

    switch (cardDataSource) {
      case 'totalSent': {
        return (
          <div className='flex w-full justify-between'>
            <div className='flex gap-1'>
              <p className='text-sm font-medium leading-5 text-textPrimary'>
                {title}
              </p>
              <TooltipProviderCustomised content={tooltipContent}>
                <InfoIcon className='w-3.5 h-3.5 text-textSecondary-disable' />
              </TooltipProviderCustomised>
            </div>
            <ExternalLinkIcon className='w-5 hidden h-5 text-textSecondary-disable' />
          </div>
        );
      }
      default: {
        return (
          <div className='flex gap-1'>
            <p className='text-sm font-medium leading-5 text-textPrimary'>
              {title}
            </p>
            <TooltipProviderCustomised content={tooltipContent}>
              <InfoIcon className='w-3.5 h-3.5 text-textSecondary-disable' />
            </TooltipProviderCustomised>
          </div>
        );
      }
    }
  };

  if (isLoading) {
    return (
      <div className='w-full grid grid-cols-4 gap-4'>
        {[
          'Total CSAT Surveys Sent',
          'Total Response',
          'Avg. Response Rate',
          'Avg. Rating',
        ].map((t) => (
          <LoadingCsatDataCard title={t} />
        ))}
      </div>
    );
  }

  return (
    <div className='w-full grid grid-cols-4 gap-4'>
      <SingleCsatDataCard
        key={'csat-total-sent'}
        cardData={totalCsatSurveyData}
        renderCardTitle={() => renderTotalSentCardHeader('totalSent')}
      />
      <SingleCsatDataCard
        key={'csat-total-response'}
        cardData={totalCsatResponseData}
        renderCardTitle={() => renderTotalSentCardHeader('totalResponse')}
      />
      <SingleCsatDataCard
        key={'csat-avg-response'}
        cardData={avgResponseData}
        renderCardTitle={() => renderTotalSentCardHeader('averageResponse')}
      />
      <SingleCsatDataCard
        key={'csat-avg-rating'}
        cardData={avgRatingsData}
        renderCardTitle={() => renderTotalSentCardHeader('averageRating')}
      />
    </div>
  );
};

export default CsatDataCards;
