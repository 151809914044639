import React, { useState } from 'react';
// import Header from './Header';
import Navbar from './DeprecatedNavbar';
import { connect } from 'react-redux';
import AnnouncementBanner from '../../../pages/dashboard/components/AnnouncementBanner';
import { Link } from '@reach/router';
import { subscriptionDetailsType } from 'pages/billing/interface';
import LiveChatModal from '../../elements/LiveChatModal';
import config from '../../../utilities/config';
import axios from '../../../utilities/httpClient';
import BillingModal from '../../../pages/billing/component/Modal';
import UpgradeModal from '../../../pages/billing/component/UpgradeModal';
import usePartner from 'components/customHooks/usePartner';

interface Prop {
  children?: React.ReactNode;
  navActiveId: number;
  selectedProject: SelectedTeamInterface;
  subscriptionDetails: subscriptionDetailsType;
  agentAccess: [AgentListInterface];
  shouldShowTrialBanner: boolean;
  is_verified: boolean;
  date_joined: string;
  userEmail: string;
  trialRemaining: number;
  spentTimeForBanner: number;
  showResendCountDownForEmail: boolean;
  CTATextForEmailVerification: string;
  showEmailBanner: boolean;
  showTokenValidationBanner: boolean;
  showLiveChatBanner: boolean;
  showUpgradeModal: boolean;
  updateShouldShowTrialBanner: (shouldShowTrialBanner: boolean) => void;
  updateShowEmailBanner: (condition: boolean) => void;
  updateTimerForBanner: (time: number) => void;
  updateSelectedNav: (navID: number) => void;
  resendRegisterVerifyLink: (payload: { email: string }) => void;
  updateShowResendCountDownForEmail: (condition: boolean) => void;
  updateCTATextForEmailVerification: (payload: string) => void;
  updateShowTokenValidationBanner: (payload: boolean) => void;
  updateShowLiveChatBanner: (payload: boolean) => void;
  updateShowUpgradeModal: (payload: boolean) => void;
  updateAgentAccess: (payload: any) => void;
  updateIsUsingNewInbox: (data: any) => void;
  updateAgentAccessList: (payload: any) => void;
}

interface trialBannerPropertiesType {
  CTAText: string;
  bgColor: string;
  textcolor: string;
  CTAColor: string;
  prioritySegment: string;
  displayText: string;
  showCrossIcon: boolean;
}

interface emailBannerPropertiesType {
  bgColorForEmailVerification: string;
  textcolorForEmailVerification: string;
  CTAColorForEmailVerification: string;
  prioritySegmentForEmailVerification: string;
  displayTextForEmailVerification: string;
  showCrossIconForEmailVerification: boolean;
}

interface tokenValidationBannerPropertiesType {
  CTAText: string;
  bgColor: string;
  textcolor: string;
  CTAColor: string;
  prioritySegment: string;
  displayText: string;
  showCrossIcon: boolean;
}

interface liveChatBannerPropertiesType {
  CTAText: string;
  bgColor: string;
  textcolor: string;
  CTAColor: string;
  prioritySegment: string;
  displayText: string;
  showCrossIcon: boolean;
}

const DashboardLayout: React.FC<Prop> = ({
  selectedProject,
  subscriptionDetails,
  agentAccess,
  shouldShowTrialBanner,
  children,
  is_verified,
  date_joined,
  userEmail,
  trialRemaining,
  spentTimeForBanner,
  showResendCountDownForEmail,
  CTATextForEmailVerification,
  showEmailBanner,
  showTokenValidationBanner,
  showLiveChatBanner,
  showUpgradeModal,
  updateSelectedNav,
  updateShouldShowTrialBanner,
  updateCTATextForEmailVerification,
  updateShowEmailBanner,
  updateTimerForBanner,
  resendRegisterVerifyLink,
  updateShowResendCountDownForEmail,
  updateShowTokenValidationBanner,
  updateShowLiveChatBanner,
  updateShowUpgradeModal,
  updateAgentAccess,
  updateIsUsingNewInbox,
  updateAgentAccessList
}) => {
  const { isPartnerRestricted } = usePartner();
  const shouldDisableTrialBanner = isPartnerRestricted(
    'index/function/showTrialBanner'
  );
  let billingEndDate =
    subscriptionDetails?.project_billing_info?.subscription_plan
      ?.billing_end_date;
  let currentTime = new Date().getTime() / 1000;
  let trialAvailed = subscriptionDetails?.trial_availed;
  let trialBannerProperties: trialBannerPropertiesType;
  let emailBannerProperties: emailBannerPropertiesType;
  let tokenValidationBannerProperties: tokenValidationBannerPropertiesType;
  let liveChatBannerProperties: liveChatBannerPropertiesType;
  // adding 7 extra day with the joining date to show the email banner for 7 extra days after joining date.
  let sevenDayAfterUserJoined = Number(date_joined) + 7 * 24 * 60 * 60;
  let invalidTokenPlatformsCount =
    selectedProject?.meta_invalid_token_hellobar_data?.count;
  let invalidTokenPlatforms =
    selectedProject?.meta_invalid_token_hellobar_data?.invalid_platforms;
  let webChatIntegratedCount = selectedProject?.livechat_hellobar_data?.count;

  const [showLiveChatModal, setShowLiveChatModal] = useState(false);

  function isPaidProject() {
    return selectedProject?.is_pro && selectedProject?.subscription_anchor_time;
  }

  function isProjectCreator() {
    return selectedProject?.creator.email === userEmail;
  }

  const showTrialBanner = () => {
    return (
      !shouldDisableTrialBanner &&
      !!trialAvailed &&
      !!trialRemaining &&
      !!billingEndDate &&
      shouldShowTrialBanner &&
      trialRemaining < 15 &&
      !isPaidProject() &&
      isProjectCreator()
    );
  };

  const handleShouldShowTrialBanner = (condition: boolean) => {
    updateShouldShowTrialBanner(condition);
    if (!condition && trialRemaining === 0) {
      localStorage.setItem('showTrialBannerAfterTrialEnds', '');
    } else if (!condition && trialRemaining > 0 && trialRemaining < 5) {
      localStorage.setItem('showTrialBannerAfterTrialEnds', 'true');
    }
  };

  /*
  Giving email banner more importance than trial banner using z-index. So, If email and trial banner both appears on the
  page, then email banner will be shown on top of trial banner.
  For urgent, z-index = 19, 20 for trial, email banner simultaneously.
  For warning, z-index = 9, 10 for trial, email banner simultaneously.
  Note: Though these 2 banners will not appear at the same time, but if any other banner needs to be shown at the same time,
  then we will show them based on z-index.
  */

  if (trialRemaining === 1) {
    trialBannerProperties = {
      CTAText: 'Subscribe now',
      bgColor: 'bg-red-50',
      textcolor: 'text-red-700 text-base font-semibold',
      CTAColor: 'text-[#92400E] text-base font-bold',
      prioritySegment: 'urgent z-[6]',
      displayText:
        'Your trial ends today. Subscribe now to continue using MyAlice without disruptions.',
      showCrossIcon: false,
    };
  } else if (trialRemaining > 1 && trialRemaining < 4) {
    trialBannerProperties = {
      CTAText: 'Subscribe now',
      bgColor: 'bg-[#FFFBEB]',
      textcolor: 'text-red-700 text-base font-semibold',
      CTAColor: 'text-[#92400E] text-base font-bold',
      prioritySegment: 'urgent z-[6]',
      displayText: `Your trial ends in ${trialRemaining} days. Subscribe now to continue using MyAlice without disruptions.`,
      showCrossIcon: false,
    };
  } else {
    trialBannerProperties = {
      CTAText: 'Subscribe now',
      bgColor: 'bg-blue-50',
      textcolor: 'text-blue-800 text-base font-semibold',
      CTAColor: 'text-blue-900 text-base font-bold',
      prioritySegment: 'urgent z-[6]',
      displayText: `${trialRemaining} days left of free trial. Skip and unlock all features.`,
      showCrossIcon: false,
    };

    if (
      trialRemaining === 0 &&
      localStorage.getItem('showTrialBannerAfterTrialEnds') === ''
    ) {
      updateShouldShowTrialBanner(false);
    }
  }

  // showing a text in the first 5 days and will show another text in the following 2 days.
  if (currentTime < sevenDayAfterUserJoined - 2 * 24 * 60 * 60) {
    emailBannerProperties = {
      bgColorForEmailVerification: 'bg-[#FFFBEB]',
      textcolorForEmailVerification: 'text-[#B45309] font-normal',
      CTAColorForEmailVerification: 'text-[#92400E]',
      prioritySegmentForEmailVerification: 'warning z-[3]',
      displayTextForEmailVerification: `We have sent a verification mail to ${userEmail}.`,
      showCrossIconForEmailVerification: true,
    };
  } else {
    emailBannerProperties = {
      bgColorForEmailVerification: 'bg-red-50',
      textcolorForEmailVerification: 'text-red-700 font-normal',
      CTAColorForEmailVerification: 'text-red-800',
      prioritySegmentForEmailVerification: 'urgent z-[7]',
      displayTextForEmailVerification: `Please verify the email sent at ${userEmail} to continue using our services.`,
      showCrossIconForEmailVerification: false,
    };
    updateShowEmailBanner(true);
  }

  function showEmailVerificationBanner() {
    return (
      !!showEmailBanner &&
      !!!is_verified &&
      !!date_joined &&
      sevenDayAfterUserJoined > currentTime &&
      isProjectCreator()
    );
  }

  React.useEffect(() => {
    if (showResendCountDownForEmail) {
      const intervalId = setInterval(() => {
        if (spentTimeForBanner < 1) {
          updateShowResendCountDownForEmail(false);
          updateCTATextForEmailVerification('Resend code');
          clearInterval(intervalId);
        } else {
          updateTimerForBanner(spentTimeForBanner - 1);
          updateCTATextForEmailVerification(
            `Request new code in ${spentTimeForBanner} seconds.`
          );
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [
    spentTimeForBanner,
    showResendCountDownForEmail,
    updateTimerForBanner,
    updateShowResendCountDownForEmail,
    updateCTATextForEmailVerification,
  ]);

  React.useEffect(() => {
    if (!!isUserRoleAdmin(agentAccess)) {
      updateShowTokenValidationBanner(true);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (
      localStorage.getItem('projectId') &&
      localStorage.getItem('projectId') !== String(selectedProject?.id)
    ) {
      localStorage.setItem('projectId', selectedProject?.id);
      updateShowTokenValidationBanner(false);
      updateShowLiveChatBanner(false);

      // if selected project is changed, get the agent access roles for that project from BE and check user roles.
      async function checkIsUserAdmin() {
        try {
          const res = await axios.get(
            config.settings.agentAccess(selectedProject?.id)
          );
          if (res.status === 200 && res.data.success) {
            updateAgentAccess(res.data.dataSource);
            updateAgentAccessList(res.data.dataSource);
            updateIsUsingNewInbox(res.data.dataSource);

            if (!!isUserRoleAdmin(res.data.dataSource)) {
              updateShowTokenValidationBanner(true);
              updateShowLiveChatBanner(true);
            }
          }
        } catch (err: any) {}
      }

      checkIsUserAdmin();
    } else {
      localStorage.setItem('projectId', selectedProject?.id);
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  function handleShowResendCode() {
    resendRegisterVerifyLink({ email: userEmail });
    updateShowResendCountDownForEmail(true);
    updateCTATextForEmailVerification(`Request new code in 60 seconds.`);
    updateTimerForBanner(59);
  }

  function getPlatformType() {
    if (
      invalidTokenPlatforms &&
      invalidTokenPlatforms[0]?.platform_type?.includes('facebook')
    ) {
      return 'Facebook';
    } else if (
      invalidTokenPlatforms &&
      invalidTokenPlatforms[0]?.platform_type?.includes('instagram')
    ) {
      return 'Instagram';
    } else {
      return '';
    }
  }

  function getPlatformTitle() {
    if (invalidTokenPlatforms && invalidTokenPlatforms[0]?.title) {
      return invalidTokenPlatforms[0]?.title;
    } else {
      return '';
    }
  }

  function isUserRoleAdmin(agentAccess: [AgentListInterface]) {
    for (let i = 0; i < agentAccess?.length; i++) {
      if (
        agentAccess[i]?.role?.name === 'Admin' &&
        agentAccess[i]?.admin?.email === userEmail
      ) {
        return true;
      }
    }
    return false;
  }

  function showInvalidTokenBanner() {
    return (
      !!showTokenValidationBanner &&
      !!invalidTokenPlatformsCount &&
      !!invalidTokenPlatforms &&
      !!getPlatformTitle() &&
      !!getPlatformType() &&
      !!isUserRoleAdmin(agentAccess)
    );
  }

  if (invalidTokenPlatformsCount > 1) {
    tokenValidationBannerProperties = {
      CTAText: 'Go to Integrations',
      bgColor: 'bg-red-50',
      textcolor: 'text-red-700 font-normal',
      CTAColor: 'text-red-800',
      prioritySegment: 'urgent z-[9]',
      displayText: `Your channel token has become invalid for ${invalidTokenPlatformsCount} channels. Please reconnect your channels.`,
      showCrossIcon: true,
    };
  } else {
    tokenValidationBannerProperties = {
      CTAText: 'Go to Integrations',
      bgColor: 'bg-red-50',
      textcolor: 'text-red-700 font-normal',
      CTAColor: 'text-red-800',
      prioritySegment: 'urgent z-[9]',
      displayText: `Your ${getPlatformType()} token has become invalid for ${getPlatformTitle()}. Please reconnect your channel.`,
      showCrossIcon: true,
    };
  }

  function shouldShowLiveChatBanner() {
    return (
      !!webChatIntegratedCount &&
      !!showLiveChatBanner &&
      !!isUserRoleAdmin(agentAccess)
    );
  }

  liveChatBannerProperties = {
    CTAText: 'Switch to New Live Chat',
    bgColor: 'bg-[#FFFBEB]',
    textcolor: 'text-[#B45309] font-normal',
    CTAColor: 'text-[#92400E]',
    prioritySegment: 'warning z-[8]',
    displayText: `Switch your existing Webchat now and get early access to new features.`,
    showCrossIcon: true,
  };

  return (
    <div
      className={`flex flex-col h-screen overflow-hidden bg-gray-50
      ${
        showTrialBanner()
          ? 'banner-added'
          : showEmailVerificationBanner()
          ? 'banner-added'
          : showInvalidTokenBanner()
          ? 'banner-added'
          : shouldShowLiveChatBanner()
          ? 'banner-added'
          : ''
      }`}
    >
      {/* <Header /> */}
      {/* Bottom section */}
      <div className={'relative box-border h-[40px] hello-banner hidden'}>
        {showEmailVerificationBanner() && (
          <AnnouncementBanner
            bgColor={emailBannerProperties.bgColorForEmailVerification}
            showCrossIcon={
              emailBannerProperties.showCrossIconForEmailVerification
            }
            prioritySegment={
              emailBannerProperties.prioritySegmentForEmailVerification
            }
            updateShouldShowBanner={updateShowEmailBanner}
          >
            <p
              className={`text-base ${emailBannerProperties.textcolorForEmailVerification}`}
            >
              <span>
                {emailBannerProperties.displayTextForEmailVerification}&nbsp;
              </span>

              <span className='font-medium underline underline-offset-8'>
                {showResendCountDownForEmail && (
                  <span
                    className={`${emailBannerProperties.CTAColorForEmailVerification} font-medium`}
                  >
                    {CTATextForEmailVerification}
                  </span>
                )}
                {!showResendCountDownForEmail && (
                  <button
                    className={`${emailBannerProperties.CTAColorForEmailVerification} underline underline-offset-8 font-medium`}
                    onClick={() => {
                      handleShowResendCode();
                    }}
                  >
                    {CTATextForEmailVerification}
                    <span> &rarr; </span>
                  </button>
                )}
              </span>
            </p>
          </AnnouncementBanner>
        )}

        {showTrialBanner() && (
          <AnnouncementBanner
            bgColor={trialBannerProperties.bgColor}
            showCrossIcon={trialBannerProperties.showCrossIcon}
            prioritySegment={trialBannerProperties.prioritySegment}
            updateShouldShowBanner={handleShouldShowTrialBanner}
          >
            <p className={trialBannerProperties.textcolor}>
              <span>{trialBannerProperties.displayText}&nbsp;</span>

              <span className='ml-2 underline underline-offset-8'>
                <Link
                  onClick={() => {
                    updateSelectedNav(-1);
                  }}
                  to='/settings/billing'
                  className={trialBannerProperties.CTAColor}
                >
                  {trialBannerProperties.CTAText}
                  <span> &rarr; </span>
                </Link>
              </span>
            </p>
          </AnnouncementBanner>
        )}

        {showInvalidTokenBanner() && (
          <AnnouncementBanner
            bgColor={tokenValidationBannerProperties.bgColor}
            showCrossIcon={tokenValidationBannerProperties.showCrossIcon}
            prioritySegment={tokenValidationBannerProperties.prioritySegment}
            updateShouldShowBanner={updateShowTokenValidationBanner}
          >
            <div>
              <p
                className={`text-base ${tokenValidationBannerProperties.textcolor}`}
              >
                <span>{tokenValidationBannerProperties.displayText}&nbsp;</span>

                <span className='font-medium underline underline-offset-8'>
                  <Link
                    onClick={() => {
                      updateSelectedNav(2);
                    }}
                    to='/integrations/integrated'
                    className={tokenValidationBannerProperties.CTAColor}
                  >
                    {tokenValidationBannerProperties.CTAText}
                    <span> &rarr; </span>
                  </Link>
                </span>
              </p>
            </div>
          </AnnouncementBanner>
        )}

        {shouldShowLiveChatBanner() && (
          <AnnouncementBanner
            bgColor={liveChatBannerProperties.bgColor}
            showCrossIcon={liveChatBannerProperties.showCrossIcon}
            prioritySegment={liveChatBannerProperties.prioritySegment}
            updateShouldShowBanner={updateShowLiveChatBanner}
          >
            <p className={`text-base ${liveChatBannerProperties.textcolor}`}>
              <span className=''>
                {liveChatBannerProperties.displayText}&nbsp;
              </span>

              {/* <span className='font-medium underline underline-offset-8'> */}
              <button
                className={`underline underline-offset-8 font-medium ${liveChatBannerProperties.CTAColor}`}
                onClick={() => {
                  setShowLiveChatModal(true);
                }}
              >
                {liveChatBannerProperties.CTAText}
              </button>
              {/* </span> */}
            </p>
          </AnnouncementBanner>
        )}

        {showLiveChatModal && (
          <LiveChatModal
            setShowLiveChatModal={setShowLiveChatModal}
          ></LiveChatModal>
        )}
      </div>

      <div className='flex flex-1 order-5 min-h-0 overflow-hidden'>
        <Navbar />
        {/* Main area */}
        <main className='flex w-full min-w-0 overflow-y-auto border-t border-gray-200 content-holder lg:flex'>
          <div style={{ width: '100%', overflowY: 'auto' }}>
            {!!children && children}
          </div>
        </main>
      </div>

      {showUpgradeModal && (
        <BillingModal>
          <UpgradeModal />
        </BillingModal>
      )}
    </div>
  );
};

const mapState = <
  T extends { auth: any; dashboard: any; newBilling: any; integration: any }
>(
  state: T
) => ({
  remaining: state.auth.trial_remaining,
  isPro:
    state.dashboard.selectedProject?.is_pro || state.auth.trial_remaining > 0,
  showTrial: state.auth.trial_remaining > 0,
  trialRemaining: state.auth.trial_remaining,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  navIsOpen: state.dashboard.navIsOpen,
  navActiveId: state.dashboard.navActiveId,
  permissionList: state.dashboard.permission.list || [],
  agentAccess: state.dashboard.agentAccess,
  subscriptionDetails: state.newBilling.subscriptionDetails,
  shouldShowTrialBanner: state.newBilling.shouldShowTrialBanner,
  is_verified: state.auth.is_verified,
  date_joined: state.auth.date_joined,
  userEmail: state.auth.email,
  spentTimeForBanner: state.auth.spentTimeForBanner,
  showEmailBanner: state.auth.showEmailBanner,
  showResendCountDownForEmail: state.auth.showResendCountDownForEmail,
  CTATextForEmailVerification: state.auth.CTATextForEmailVerification,
  showTokenValidationBanner: state.auth.showTokenValidationBanner,
  showLiveChatBanner: state.auth.showLiveChatBanner,
  showUpgradeModal: state.dashboard.showUpgradeModal,
});

const mapDispatch = (dispatch: any) => ({
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
  updateNavToggle: () => dispatch.dashboard.updateNavToggle(),
  updateShouldShowTrialBanner: (shouldShowTrialBanner: boolean) =>
    dispatch.newBilling.updateShouldShowTrialBanner(shouldShowTrialBanner),
  updateTimerForBanner: (time: number) =>
    dispatch.auth.updateTimerForBanner(time),
  updateShowResendCountDownForEmail: (condition: boolean) =>
    dispatch.auth.updateShowResendCountDownForEmail(condition),
  resendRegisterVerifyLink: (payload: object) =>
    dispatch.auth.resendRegisterVerifyLink(payload),
  updateCTATextForEmailVerification: (payload: string) =>
    dispatch.auth.updateCTATextForEmailVerification(payload),
  updateShowEmailBanner: (condition: boolean) =>
    dispatch.auth.updateShowEmailBanner(condition),
  updateShowTokenValidationBanner: (condition: boolean) =>
    dispatch.auth.updateShowTokenValidationBanner(condition),
  updateShowLiveChatBanner: (condition: boolean) =>
    dispatch.auth.updateShowLiveChatBanner(condition),
  updateShowUpgradeModal: (payload: boolean) =>
    dispatch.dashboard.updateShowUpgradeModal(payload),
  updateAgentAccess: (payload: any) =>
    dispatch.dashboard.updateAgentAccess(payload),
  updateIsUsingNewInbox: (data: any) =>
    dispatch.settings.updateIsUsingNewInbox(data),
  updateAgentAccessList: (payload: any) => 
    dispatch.settingsUserManagementConfig.updateAgentAccessList(payload),
});

export default connect(mapState, mapDispatch)(DashboardLayout);
