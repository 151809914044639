import React, { useMemo } from 'react';
import './assets/styles/reporting.scss';
import { connect } from 'react-redux';
import {
  checkPermission,
  getQueryParameters,
  reportingTab,
} from '../../utilities/utils';
import ReportingNav from './component/Nav/ReportingNav';
import CustomerMetrics from './component/Screen/CustomerMetrics';
import AutomationMetrics from './component/Screen/AutomationMetrics';
import AgentMetrics from './component/Screen/AgentMetrics';
import { SelectedTeamInterface } from 'index';
import WhatsappMetrics from './component/WhatsappMetrics';
import useWhatsappMetrics from './component/WhatsappMetrics/hooks/useWhatsappMetrics';
import AnalyticsLogs from './component/WhatsappMetrics/components/TemplateAnalytics/logs';
import { kebabCase } from 'lodash';
import { appendQueryParamsToUrl } from './utility/utils';
import dayjs from 'dayjs';
import CsatMetrics from './component/CSAT';
import useCsatReporting from './hooks/useCsatReporting';
import { useParams } from '@reach/router';
import { Loader2 } from 'lucide-react';
import NoPermissionModal from './component/CommonComponent/NoPermissionModal';

interface permissionListProps {
  id: number;
  short_code: string | number;
  verbose: string;
}

interface dataRangeProps {
  startDate: string;
  endDate: string;
  key: string;
}

interface platformDataProps {
  id: number | null;
  type: string;
  title: string;
  is_sla_connected: boolean;
}

interface platformProps {
  id: number;
  projects: any;
  is_sla_connected: boolean;
}

interface props {
  isLoading: boolean;
  projects: SelectedTeamInterface[];
  selectedProject: SelectedTeamInterface;
  setSelectedProject: (project: any) => Promise<void>;
  selectedPlatform: platformProps;
  platformList: platformProps[];
  setSelectedPlatform: (val: any) => void;
  permissionList: permissionListProps[];
  clearState: () => void;
  currentTab: { id: number; name: string };
  updateStateData: (key: string, value: any) => void;
  dateRange: dataRangeProps;
  platformData: platformDataProps;
  selectedReportingViewMode: string;
  //user matrics
  fetchUserReportData: (projectId: number, endpoint: string) => void;
  fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) => void;
  fetchUserReportDataUserMetricsLineGraph: (
    projectId: number,
    endpoint: string
  ) => void;
  fetchUserReportDataDefaultAppend: (
    projectId: number,
    endpoint: string
  ) => void;
  fetchUserReportHeatmap: (projectId: number) => void;
  fetchAgentList: (teamId: number) => Promise<{
    result: any;
    error: any;
    statusCode: number;
  }>;
  fetchAutomationTicketTable: (
    projectId: number,
    groupId: number | null,
    limit: number,
    offset: number
  ) => void;
  fetchIntentReporting: (projectId: number) => void;
  //csat
  fetchCsatStack: (teamId: number, url: string) => void;
  fetchCsatResponseList: (
    teamId: number,
    search: string,
    limit: number,
    offset: number
  ) => void;
  fetchCsatResponseDist: (teamId: number) => void;
  updateSelectedNav: (navId: number) => void;
  isBusinessHourEnabled: boolean;
}

const Reporting: React.FC<props> = ({
  isLoading,
  projects,
  selectedProject,
  setSelectedProject,
  selectedPlatform,
  platformList,
  setSelectedPlatform,
  permissionList,
  currentTab,
  updateStateData,
  dateRange,
  platformData,
  clearState,
  fetchUserReportData,
  fetchUserReportHeatmap,
  fetchAgentList,
  fetchAutomationTicketTable,
  fetchUserReportDataOnTabClick,
  fetchUserReportDataUserMetricsLineGraph,
  fetchUserReportDataDefaultAppend,
  fetchIntentReporting,
  updateSelectedNav,
  selectedReportingViewMode,
}) => {
  const [hasProjectPermission, setHasProjectPermission] = React.useState(true);
  const readAccess = 'read:analytics';
  const {
    isWhatsappBsbIntegration,
    updateSelectedPlatformData,
    updateIsWhatsappBsbIntegration,
  } = useWhatsappMetrics();

  const { fetchAllCsatData, updatePlatformsToFilter } = useCsatReporting();
  const routeParams = useParams();
  const projectId = routeParams.projectId;
  const [localDateRange, setLocalDateRange] = React.useState([
    {
      startDate:
        getQueryParameters('start') || new Date(dateRange[0]?.startDate),
      endDate: getQueryParameters('end') || new Date(dateRange[0]?.endDate),
      key: 'selection',
    },
  ]);

  //Customer Matrices Local State
  const [currentKeywordsIndex, setCurrentKeywordsIndex] = React.useState({
    id: 0,
    name: 'Keywords',
  });
  const [currentButtonsIndex, setCurrentButtonsIndex] = React.useState({
    id: 0,
    name: 'Buttons',
  });

  const [defaultGroup, setDefaultGroup] = React.useState({
    id: null,
    email: '',
    avatar: null,
    full_name: 'All Agents',
  });

  const perPageTicketLimit = 6;

  const [ticketListOffset, setTicketListOffset] = React.useState(0);

  const fetchUserMetrics = () => {
    fetchUserReportData(selectedProject?.id, 'get_new_customer_count');
    fetchUserReportData(selectedProject?.id, 'get_interactive_customer_count');
    fetchUserReportData(
      selectedProject?.id,
      'get_triggered_message_count/referral'
    );
    fetchUserReportData(
      selectedProject?.id,
      'get_triggered_message_count/intent'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_language_metrics'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_timezone_metrics'
    );
    fetchUserReportDataUserMetricsLineGraph(
      selectedProject?.id,
      'get_country_metrics'
    );

    fetchUserReportHeatmap(selectedProject?.id);
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get_gender_metrics');
    fetchUserReportDataDefaultAppend(
      selectedProject?.id,
      'get_postback_and_text_message_count'
    );
    if (currentKeywordsIndex?.id === 0) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/keyword'
      );
    } else if (currentKeywordsIndex?.id === 1) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/intent'
      );
    }
    //   1
    if (currentButtonsIndex.id === 0) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/postback'
      );
    } else if (currentButtonsIndex.id === 1) {
      fetchUserReportDataDefaultAppend(
        selectedProject?.id,
        'get_triggered_message_list/referral'
      );
    }
  };

  const fetchAutomationMetrics = () => {
    fetchUserReportData(selectedProject?.id, 'unique-ticket-count');
    fetchUserReportData(selectedProject?.id, 'get_ticket_created_count');
    fetchUserReportData(selectedProject?.id, 'get_ticket_resolved_count');
    if (selectedProject?.id === 943) {
      // Only Available to MIBS
      fetchUserReportData(
        selectedProject?.id,
        'get-avg-process-first-response-time'
      );
    }

    fetchUserReportDataDefaultAppend(
      selectedProject?.id,
      'get_textual_message_vs_automation'
    );
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get_tag_cloud');
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup?.id,
      perPageTicketLimit,
      ticketListOffset
    );
  };

  const fetchAgentMetrics = () => {
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_average_first_response_time'
    );
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_average_resolution_time'
    );
    fetchUserReportData(selectedProject?.id, 'get_agent_average_response_time');
    fetchUserReportData(
      selectedProject?.id,
      'get_agent_sales_conversion_ratio'
    );
    // fetchUserReportData(selectedProject?.id, 'get_agent_average_online_time');
    fetchUserReportDataDefaultAppend(selectedProject?.id, 'get-agent-table');
  };

  const fetchAnalyticsData = async () => {
    switch (currentTab.id) {
      case 0:
        fetchUserMetrics();
        break;
      case 1:
        fetchAutomationMetrics();
        break;
      case 2:
        fetchAgentMetrics();
        break;
      case 3:
        fetchAllCsatData(true);
        break;
    }
  };

  const handleCustomerMetricsOnTabChange = (tab: any, pointer: number) => {
    if (pointer === 0) {
      setCurrentKeywordsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/keyword'
          );
          break;
        case 1:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/intent'
          );
          break;
      }
    } else if (pointer === 1) {
      setCurrentButtonsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/postback'
          );
          break;
        case 1:
          fetchUserReportDataOnTabClick(
            selectedProject?.id,
            'get_triggered_message_list/referral'
          );
          break;
      }
    }
  };

  const handleTicketListNext = () => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup.id,
      perPageTicketLimit,
      ticketListOffset + perPageTicketLimit
    );
    setTicketListOffset(ticketListOffset + perPageTicketLimit);
  };

  const handleTicketListPrevious = () => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      defaultGroup.id,
      perPageTicketLimit,
      ticketListOffset - perPageTicketLimit
    );
    setTicketListOffset(ticketListOffset - perPageTicketLimit);
  };

  const handleAgentSelect = (agentId: number) => {
    fetchAutomationTicketTable(
      selectedProject?.id,
      agentId,
      perPageTicketLimit,
      ticketListOffset
    );
  };

  const updateInformation = async () => {
    const _projectId = parseInt(projectId);
    const project = projects.filter((p) => _projectId === p.id)[0];
    let projectInfo;
    if (!!selectedProject && _projectId !== selectedProject.id) {
      projectInfo = await setSelectedProject(project);
      if (
        project?.platforms?.length > 0 &&
        project?.platforms.filter(
          (platform: any) => platform.id === selectedPlatform.id
        ).length === 0
      ) {
        await setSelectedPlatform(project.platforms[0]);
      }
    }
    const hasPermission = checkPermission(permissionList, readAccess);

    if (hasPermission) {
      const { error } = await fetchAgentList(_projectId);
      if (error) {
        setHasProjectPermission(false);
      }
      fetchIntentReporting(_projectId);
    }
    return projectInfo;
  };

  const hasSLAConnectionOnAnyPlatform = platformList?.some(
    (platform) => platform?.is_sla_connected === true
  );

  const handleDateRangChange = () => {
    updateStateData('dateRange', localDateRange);
    appendQueryParamsToUrl({
      start: dayjs(localDateRange[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(localDateRange[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    setTimeout(() => {
      fetchAnalyticsData();
    }, 500);
  };

  const handleUrlQueryParams = async (plist = []) => {
    const tabNameSlug = getQueryParameters('tab');
    const platformId = getQueryParameters('platform');
    const formatedReportingTabsWithSlug = reportingTab.map((v) => ({
      ...v,
      urlSlug: kebabCase(v.name),
    }));
    // if tab is csat update in a specific state
    if (platformId) {
      if (tabNameSlug === 'csat-reports') {
        const platformIds = platformId.split(',');
        const targetPlatforms = plist.filter((p) =>
          platformIds.includes(p?.id?.toString())
        );
        updatePlatformsToFilter(targetPlatforms);
      } else {
        const targetPlatform = platformList.find(
          (p) => p.id === Number(platformId)
        );
        updateSelectedPlatformData(targetPlatform);
      }
    }
    const hasValidTab = formatedReportingTabsWithSlug.find(
      (v) => v.urlSlug === tabNameSlug
    );
    if (hasValidTab) {
      updateStateData('currentTab', {
        id: hasValidTab.id,
        name: hasValidTab.name,
      });
    }
    const hasStartDate = getQueryParameters('start');
    const hasEndDate = getQueryParameters('end');
    if (hasStartDate || hasEndDate) {
      updateStateData('dateRange', localDateRange);
    }
    appendQueryParamsToUrl({
      start: dayjs(localDateRange[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(localDateRange[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
  };

  // Filter the platform list based on the current tab
  const filterPlatformList = () => {
    if (
      currentTab?.name === 'WhatsApp Metrics' &&
      Array.isArray(platformList)
    ) {
      return platformList.filter(
        (platform: any) => platform.platform_type === 'whatsapp_bsp'
      );
    } else {
      return platformList;
    }
  };

  // Determine the initial selected platform based on the current tab and platform list
  const getInitialSelectedPlatform = useMemo(() => {
    if (
      currentTab?.name !== 'WhatsApp Metrics' ||
      !Array.isArray(platformList) ||
      platformList.length === 0
    ) {
      return platformData;
    }

    const whatsappBspPlatforms = platformList.filter(
      (platform: any) => platform.platform_type === 'whatsapp_bsp'
    );

    const selectedPlatform =
      whatsappBspPlatforms.find(({ id }) => id === platformData?.id) ||
      whatsappBspPlatforms[0];

    if (selectedPlatform) {
      updateSelectedPlatformData(selectedPlatform);
    }

    appendQueryParamsToUrl({
      platform: selectedPlatform.id,
    });

    return selectedPlatform;

    // eslint-disable-next-line
  }, [currentTab?.name, platformData?.id]);

  React.useEffect(() => {
    const fetchProjectData = async () => {
      const projectInfo = await updateInformation();
      await updateSelectedNav(5);
      await handleUrlQueryParams(projectInfo?.platforms ?? []);
    };

    fetchProjectData();
    return () => {
      clearState();
    };
    // eslint-disable-next-line
  }, [projectId]);

  React.useEffect(() => {
    const hasPermission = checkPermission(permissionList, readAccess);

    if (hasPermission) {
      fetchAnalyticsData();
    }
    if (platformData?.id) {
      appendQueryParamsToUrl({
        platform: platformData?.id,
      });
    }
    appendQueryParamsToUrl({
      start: dayjs(localDateRange[0].startDate).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(localDateRange[0].endDate).format('YYYY-MM-DDTHH:mm:ss'),
    });
    // eslint-disable-next-line
  }, [currentTab]);

  React.useEffect(() => {
    if (Array.isArray(platformList)) {
      const getWhatsappBsbData = platformList.filter(
        (platform: any) => platform.platform_type === 'whatsapp_bsp'
      );
      if (getWhatsappBsbData.length > 0) {
        updateIsWhatsappBsbIntegration(false);
      } else {
        updateIsWhatsappBsbIntegration(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <Loader2 className='w-10 h-10 text-primary animate-spin' />
      </div>
    );
  }
  if (!hasProjectPermission) {
    return <NoPermissionModal hasProjectPermission={hasProjectPermission} />;
  }

  return (
    <div data-testid='reporting-data'>
      {selectedReportingViewMode === 'reporting' && (
        <div>
          <ReportingNav
            platformList={filterPlatformList() ?? []}
            selectedPlatformLocal={getInitialSelectedPlatform}
            setSelectedPlatformLocal={(val: any) => {
              updateStateData('platformData', val);
              appendQueryParamsToUrl({
                platform: val?.id,
              });
            }}
            dateRange={localDateRange}
            setDateRange={setLocalDateRange}
            finalDateRange={dateRange}
            currentTab={currentTab}
            updateStateData={updateStateData}
            fetchAnalyticsData={() => fetchAnalyticsData()}
            onDateFilter={() => handleDateRangChange()}
          />
          {{
            0: (
              <CustomerMetrics
                currentKeywordsIndex={currentKeywordsIndex}
                currentButtonsIndex={currentButtonsIndex}
                onTabChange={(tab: any, pointer: number) =>
                  handleCustomerMetricsOnTabChange(tab, pointer)
                }
                selectedProject={selectedProject}
              />
            ),
            1: (
              <AutomationMetrics
                defaultGroup={defaultGroup}
                setDefaultGroup={setDefaultGroup}
                limit={perPageTicketLimit}
                offset={ticketListOffset}
                setOffset={setTicketListOffset}
                handleTicketListNext={handleTicketListNext}
                handleTicketListPrevious={handleTicketListPrevious}
                handleAgentSelect={(agentId: number) =>
                  handleAgentSelect(agentId)
                }
                selectedProject={selectedProject}
              />
            ),
            2: (
              <AgentMetrics
                isSLAConnected={hasSLAConnectionOnAnyPlatform}
                handleBusinessHourToggle={(val: boolean) => {
                  updateStateData('isBusinessHourEnabled', val);
                  setTimeout(() => {
                    fetchAnalyticsData();
                  }, 500);
                }}
              />
            ),
            3: <CsatMetrics selectedProject={selectedProject} />,
            4: !isWhatsappBsbIntegration && <WhatsappMetrics />,
          }[currentTab.id] || <div>Not Found </div>}
        </div>
      )}
      {selectedReportingViewMode === 'analyticsLogs' && <AnalyticsLogs />}
    </div>
  );
};

const mapState = (state: any) => ({
  isLoading: state?.loading?.effects?.reporting?.fetchAgentList,
  projects: state.dashboard.projects,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  platformList: state.dashboard.platformList,
  permissionList: state.dashboard.permission?.list || [],
  currentTab: state.reporting.currentTab,
  dateRange: state.reporting.dateRange,
  platformData: state.reporting.platformData,
  isBusinessHourEnabled: state.reporting.isBusinessHourEnabled,
  selectedReportingViewMode: state.reporting.selectedReportingViewMode,
});

const mapDispatch = (dispatch: any) => ({
  setSelectedProject: async (project: any) =>
    await dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: async (platform: any) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  updateStateData: (key: string, value: any) =>
    dispatch.reporting.updateStateData({ key, value }),

  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),

  //user metrics
  fetchUserReportData: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportData({ projectId, endpoint }),
  fetchUserReportDataUserMetricsLineGraph: (
    projectId: number,
    endpoint: string
  ) =>
    dispatch.reporting.fetchUserReportDataUserMetricsLineGraph({
      projectId,
      endpoint,
    }),
  fetchUserReportDataDefaultAppend: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportDataDefaultAppend({
      projectId,
      endpoint,
    }),
  fetchUserReportDataOnTabClick: (projectId: number, endpoint: string) =>
    dispatch.reporting.fetchUserReportDataOnTabClick({ projectId, endpoint }),
  fetchUserReportHeatmap: (projectId: number) =>
    dispatch.reporting.fetchUserReportHeatmap(projectId),
  fetchAgentList: async (teamId: number) =>
    await dispatch.reporting.fetchAgentList(teamId),
  fetchAutomationTicketTable: (
    projectId: number,
    groupId: number | null,
    limit: number,
    offset: number
  ) =>
    dispatch.reporting.fetchAutomationTicketTable({
      projectId,
      groupId,
      limit,
      offset,
    }),
  clearState: () => dispatch.reporting.clearState(),
  fetchIntentReporting: (projectId: number) =>
    dispatch.reporting.fetchIntentReporting(projectId),
  //csat
  fetchCsatStack: (teamId: number, url: string) =>
    dispatch.reporting.fetchCsatStack({ teamId, url }),
  fetchCsatResponseList: (
    teamId: number,
    search: string,
    limit: number,
    offset: number
  ) =>
    dispatch.reporting.fetchCsatResponseList({
      teamId,
      search,
      limit,
      offset,
    }),
  fetchCsatResponseDist: (teamId: number) =>
    dispatch.reporting.fetchCsatResponseDist(teamId),
});

const ReportingContainer = connect(mapState, mapDispatch)(Reporting);

export default ReportingContainer;
