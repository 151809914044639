import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'libraryV2/ui/dialog';
import { PlusIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { UserRoleSelect } from './UserRoleSelect';
import MultipleEmailInput from './MultipleEmailInput';
import useInviteTeamMembers from '../../hooks/useInviteTeamMembers';
import { Table } from '@tanstack/react-table';

interface InviteTeamMembersProps<TData> {
  table?: Table<TData>;
}
const InviteTeamMembers: React.FC<InviteTeamMembersProps<any>> = ({
  table,
}) => {
  const {
    setEmails,
    emails,
    emailInputValue,
    handleEmailInputChange,
    handleEmailsChange,
    setEmailInputValue,
    setFormState,
    setAndSyncSelectedRole,
    handleInviteTeamMemberSubmit,
    selectedRoleData,
    clearSelectedRoleData,
    getFormValidationStatus,
  } = useInviteTeamMembers();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      resetData();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleCancel = () => {
    resetData();
  };
  const resetData = () => {
    setFormState([]);
    setEmails([]);
    setEmailInputValue('');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='text-white bg-primary hover:bg-primary-hover'>
          <PlusIcon className='text-white h-4 w-4 mr-[2.5px]' />
          Add New Member
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px] bg-white'>
        <DialogHeader>
          <DialogTitle>Invite Team Members</DialogTitle>
          <DialogDescription className='subtitle-regular'>
            Enter emails to invite your team members.
          </DialogDescription>
        </DialogHeader>
        <div className='grid gap-1.5 pb-4'>
          <div className='items-center'>
            <MultipleEmailInput
              emails={emails}
              emailInputValue={emailInputValue}
              handleEmailInputChange={handleEmailInputChange}
              handleEmailsChange={handleEmailsChange}
              setEmailInputValue={setEmailInputValue}
              setFormState={setFormState}
            />
          </div>
          <span className='subtitle-regular mt-1.5 mb-2'>
            To invite multiple team members make sure you have a comma or a
            space after every email.
          </span>

          <UserRoleSelect setAndSyncSelectedRole={setAndSyncSelectedRole} />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button type='button' onClick={handleCancel} variant='outline'>
              Cancel
            </Button>
          </DialogClose>
          <Button
            type='submit'
            onClick={async () => {
              if (selectedRoleData && getFormValidationStatus()) {
                await handleInviteTeamMemberSubmit();
                clearSelectedRoleData();
                table?.resetRowSelection();
                setOpen(false);
              }
            }}
            disabled= {!selectedRoleData || !getFormValidationStatus() || emails.length === 0}
            variant={`${getFormValidationStatus() && selectedRoleData ? 'ghost' : 'outline'}`}
            className={`${
              getFormValidationStatus() && selectedRoleData && emails.length !== 0
                ? 'text-white bg-primary'
                : 'bg-[#F4F4F5] text-[#A1A1AA]'
            }`}
          >
            Invite
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InviteTeamMembers;
