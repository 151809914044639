import dayJs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayJs.extend(relativeTime);
dayJs.extend(updateLocale);
dayJs.extend(advancedFormat);

dayJs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

// Function to get relative or formatted time
export const getRelativeTime = (timeStamp: string) => {
  const start = dayJs.unix(parseInt(timeStamp));
  const now = dayJs(); // Get current time
  const oneYearAgo = now.subtract(1, 'year');

  if (start.isBefore(oneYearAgo)) {
    return start.format('MMM D, YYYY');
  }

  const hours = now.diff(start, 'hours') % 24;
  const minutes = now.diff(start, 'minutes') % 60;
  if (now.diff(start, 'days') === 0 && hours > 0) {
    return `${hours}h ${minutes}m ago`;
  }

  return start.from(now);
};

export const userRolesLabels = [
  {
    value: 'supervisor',
    label: 'Supervisor',
  },
  {
    value: 'developer',
    label: 'Developer',
  },
  {
    value: 'agent',
    label: 'Agent',
  },
  {
    value: 'marketer',
    label: 'Marketer',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'owner',
    label: 'Owner',
  },
];
