import { ArrowPathIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import FacebookLoginForBusiness from 'utilities/FacebookLoginForBusiness';
import facebookLogo from '../../../assets/icons/facebookLogo.svg';

interface Props {
  processFacebookResponse: (response: any) => any;
  processFacebookResponseForBusiness: (response: any) => any;
  loading: boolean;
}

const FacebookConnection: React.FC<Props> = ({
  processFacebookResponse,
  processFacebookResponseForBusiness,
  loading = false,
}) => {
  const { t } = useTranslation();

  const renderFacebookLoginButton = () => {
    return (
      <>
        <FacebookLoginForBusiness
          handleFacebookToken={processFacebookResponseForBusiness}
          platformType='facebookBusiness'
          buttonText={t('Continue with Facebook')}
          buttonClassName='facebook-button-connect'
        />
        <FacebookLoginForBusiness
          handleFacebookToken={processFacebookResponse}
          platformType='facebookUser'
          buttonText={t('Login with Facebook')}
          buttonClassName='facebook-button-connect'
        />
      </>
    )
  };

  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>{t('fetching page list...')}</span>
          </div>
        )}
        {!loading && (
          <>
            <img
              src={facebookLogo}
              className='m-auto text-center'
              alt={'facebook logo'}
            />
            <p className='mt-3 text-xl font-medium text-center'>
              {t('Connect With Facebook!')}
            </p>
            <p className='mt-3 text-sm font-normal text-center text-gray-00'>
              {t(
                'Connect your facebook account, you can edit the details later. Please provide us the required permission to get started!'
              )}
            </p>
            <br />
            <div className='flex justify-center'>
              {renderFacebookLoginButton()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FacebookConnection;
