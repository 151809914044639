import {
  useActions,
  useAutomationWorkflow,
} from 'pages/automationWorkflow/export';
import { IAssignVariableTypes } from 'pages/automationWorkflow/interface';
import { useEffect, useState } from 'react';
import useJinjaCodeUtility from '../../../hooks/useJinjaCodeCompiler';
import JinjaNestedVariableSelector from '../../common/jinja/JinjaNestedVariableSelector';
import { Input } from 'libraryV2/ui/input';
import { getArrayProperties } from 'pages/automationWorkflow/utility/function';

const AssignVariablesV2 = () => {
  const [variables2, setVariables] = useState([]);

  const { loading, sampleData } = useJinjaCodeUtility();
  const {
    updateWhatsappTemplateVariables,
    updateSelectedAssignVariableFallbackValue,
    updateSelectedAssignVariableCategoryAndValue,
  } = useActions();

  const {
    triggerInfo,
    selectedSaveActionsData,
    fetchWhatsappTemplateVariables,
  } = useAutomationWorkflow();

  const { whatsapp: { selectedTemplate: { variables = [] } = {} } = {} } =
    selectedSaveActionsData;

  const getWhatsAppTemplateVariable = async (
    selectedChannelId: string,
    templateId: string
  ) => {
    const res = await fetchWhatsappTemplateVariables(
      selectedChannelId,
      templateId
    );
    if (!!res) {
      const mappedVariables = res.map((val: string, index: number) => {
        return {
          ...variables[index],
          item: `{{${val}}}`,
        };
      });
      setVariables(mappedVariables);
      updateWhatsappTemplateVariables(mappedVariables);
    }
  };

  useEffect(() => {
    if (!!selectedSaveActionsData) {
      const { whatsapp } = selectedSaveActionsData;
      if (!!whatsapp) {
        const { selectedChannelId, selectedTemplate } = whatsapp;
        getWhatsAppTemplateVariable(selectedChannelId, selectedTemplate?.id);
      }
    }
    //eslint-disable-next-line
  }, [selectedSaveActionsData?.whatsapp?.selectedTemplate]);

  const renderJinjaComponents = (
    variable: IAssignVariableTypes,
    index: number
  ) => {
    return (
      <JinjaNestedVariableSelector
        title={variable?.key}
        triggerInfo={getArrayProperties(triggerInfo)}
        value={variable?.variableValue}
        onChange={(value) => {
          updateSelectedAssignVariableCategoryAndValue('category', '', index);
          updateSelectedAssignVariableCategoryAndValue('value', value, index);
        }}
        key={index}
        sampleDataFormat={sampleData}
        sampleDataLoading={loading}
        variables={variables2}
        variableIndex={index}
        url={''}
        updateSelectedAssignVariableCategoryAndValue={(value) => {
          updateSelectedAssignVariableCategoryAndValue(
            'category',
            value?.category,
            index
          );
          updateSelectedAssignVariableCategoryAndValue(
            'value',
            value?.value,
            index
          );
        }}
      />
    );
  };

  return (
    <>
      {!!variables2 && variables2.length > 0 && (
        <div className='p-4 bg-white rounded mt-6'>
          <p className='text-gray-600 text-sm font-medium'>Assign Variables</p>
          <p className='text-gray-500 text-sm'>
            Use Variable+Text to include a longer message.
          </p>
          <div className='mt-4 grid grid-flow-row gap-4'>
            {variables2.map(
              (variable: IAssignVariableTypes, variableIndex: number) => (
                <div className='grid grid-cols-2 gap-2' key={variableIndex}>
                  <div className='col-span-1'>
                    {renderJinjaComponents(variable, variableIndex)}
                  </div>

                  <div className='col-span-1 flex flex-col justify-end h-full'>
                    <div className='flex-grow mt-9 '>
                      <Input
                        type='text'
                        className='w-full h-full shadow-sm border border-gray-300 mt-0 rounded-md text-sm align-top text-left text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
                        placeholder='Fallback value'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          updateSelectedAssignVariableFallbackValue(
                            e.target.value,
                            variableIndex
                          )
                        }
                        value={variables2[variableIndex]?.fallbackValue ?? ''}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AssignVariablesV2;
