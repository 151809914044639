import { FC } from 'react';
import {
  Checkbox,
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  DropdownField,
  LOCAL_UTILS,
  TicketPriorityLabel,
  usePolicy,
} from '../../export';

import useTranslation from 'components/customHooks/useTranslation';
import { IPolicy } from '../../interface';

interface Props {
  priorityList: Array<string | number>;
  handlePriorityListUpdate: (updatedList: Array<number | string>) => void;
}

const PrioritySelection: FC<Props> = ({
  priorityList,
  handlePriorityListUpdate,
}) => {
  const { t } = useTranslation();
  const { inputFieldClass, defaultPolicies, getPriorityLabel } = usePolicy();

  const PriorityItems = () => (
    <Command>
      <CommandList asChild>
        <CommandGroup>
          {defaultPolicies.map((policy: IPolicy, index) => (
            <CommandItem
              asChild
              key={index}
              value={`${getPriorityLabel(policy.priority)}`}
            >
              <div className={'flex items-center space-x-2'}>
                <Checkbox
                  id={`${policy.priority}`}
                  checked={priorityList.includes(policy?.priority)}
                  className={` rtl:ml-2
                    ${
                      priorityList.includes(policy?.priority)
                        ? 'bg-primary text-white border-transparent'
                        : ''
                    }
                      `}
                  onCheckedChange={() => {
                    let localPriorityList = [...priorityList];
                    const index = localPriorityList.indexOf(policy.priority);
                    if (index !== -1) {
                      localPriorityList.splice(index, 1);
                    } else {
                      localPriorityList.push(policy.priority);
                    }
                    handlePriorityListUpdate(localPriorityList);
                  }}
                />
                <div className='flex items-center w-[80%] ltr:space-x-2'>
                  <TicketPriorityLabel
                    priority={policy.priority}
                    htmlFor={`${policy.priority}`}
                    labelTag={true}
                  />
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return (
    <DropdownField
      value={LOCAL_UTILS.getPriorityLabelList(priorityList)}
      children={<PriorityItems />}
      childrenName={t('priority')}
      popOverContentClass='w-52'
      buttonClass={`${inputFieldClass} w-52 ltr:mr-2 rtl:ml-2`}
    />
  );
};

export default PrioritySelection;
