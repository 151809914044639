import { toaster } from 'evergreen-ui';
import { cloneDeep } from 'lodash';
import config from '../../../utilities/config';
import axios from '../../../utilities/httpClient';
import { CreateTicketAudio, UpdateTicketAudio } from '../utils/contents';
import {
  checkIsPrimaryFilterMatched,
  checkIsSecondaryFilterMatch,
  getTicketCountStringForWindowTabTitle,
  shouldInsertTicketOnQueue,
  shouldRemoveTicketFromQueue,
  shouldUpdateTicketListFromPusher,
} from '../utils/functions';

export type ChannelListProperty = {
  id: number,
  name: string,
  alice_store_id: number,
  ecommerce_type: string,
};

const initialState = {
  selectedTicket: null,
  ticketList: [],
  limit: 100,
  offset: 0,
  totalTicketCount: 0,
  agentGroup: [],
  allTicketTags: [],
  agentList: [],
  savedFilterList: [],
  ticketFilterData: {
    channels: [],
    startDate: '',
    endDate: '',
    ticketStatus: 0,
    tags: [],
    priorities: [],
    agents: [],
    groups: [],
  },
  conversationData: [],
  ticketActions: [],
  chatboxActiveNav: '',
  previouslySavedFilterData: {
    oldData: {},
    updateAble: false,
    indexOfSavedData: -1,
  },
  searchTicketResult: [],
  customerInformation: null,
  searchState: {
    searchQuery: '',
    isSearchApplied: false,
    leftbarPreviousState: [],
  },
  selectedQueueType: '',
  selectedSecondaryQueueType: 'all',
  ticketIsResolvedStatus: 0,
  ticketQueue: {
    type: '',
    isResolved: false,
    privateView: '',
  },
  isBulkActive: false,
  bulkData: {
    action: '',
    tickets: [],
    assign_user: 0,
    assign_group: 0,
    tags: [],
    priorities: [],
  },
  ticketOrder: 'desc',
  ticketsBadgeCount: null,
  hasFilterApplied: false,

  savedReplies: [],
  selectedProject: null,
  projectAttributes: [],
  customerAttribute: null,
  whatsappTemplates: null,
  whatsappCustomerList: [],
  integratedChannelList: [],
  whatsappTemplateDetails: null,
  commentEdit: {
    isEditing: false,
    conversationId: '',
    commentText: '',
  },
  channelList: [],
};

export const inbox = {
  state: {
    ...initialState,
  },
  reducers: {
    updateTicketFilterData(state, updatedData) {
      /* payload = {
        key: string,
        value: string | number | []
      } */
      return {
        ...state,
        ticketFilterData: {
          ...state.ticketFilterData,
          [updatedData.key]: updatedData.value,
        },
      };
    },
    updateAllTagsData(state, updatedData) {
      return { ...state, allTicketTags: updatedData };
    },
    updateSelectedProjectOnInbox(state, updatedData) {
      return { ...state, selectedProject: updatedData };
    },

    updateIntegratedChannelList(rootState, payload) {
      return { ...rootState, integratedChannelList: payload };
    },

    updateSelectedTicket(rootState, newTicketData) {
      return { ...rootState, selectedTicket: { ...newTicketData } };
    },

    updateAgentGroup(state, updatedData) {
      return { ...state, agentGroup: updatedData };
    },
    updateAssignableAgentList(state, updatedData) {
      return { ...state, agentList: updatedData };
    },
    updateSateData(state, updatedData) {
      return { ...state, [updatedData.key]: updatedData.value };
    },
    updateBulkData(state, updateData) {
      return { ...state, bulkData: updateData };
    },
    /**
     * Note: Any modification of this function is prohibited unless approved by senior engineers.
     * updateTicketList:
     * This function updates the ticket list based on whether a search, primary, and secondary filters match.
     *
     * Step 1: Ticket Update Check
     * - If the ticket matches the primary and secondary filters and the search is not applied, updates or adds the ticket.
     *
     * Step 2: Existing Ticket Update
     * - Updates ticket if it already exists in the list.
     *
     * Step 3: New Ticket Handling
     * - If the ticket is new, appends it based on pinned status and ticket order.
     */
    updateTicketList(state, payloadData) {
      let totalLocal = state.totalTicketCount;
      let localTicketList = cloneDeep(state.ticketList);

      let isTicketSearchApplied = state.searchState.isSearchApplied;
      let isSecondaryFilterMatch = checkIsSecondaryFilterMatch(
        payloadData.ticketData?.is_replied,
        state.selectedSecondaryQueueType
      );

      let isPrimaryFilterMatched = checkIsPrimaryFilterMatched(
        state.hasFilterApplied,
        state.ticketFilterData,
        payloadData.ticketData
      );

      let shouldUpdate = shouldUpdateTicketListFromPusher({
        userId: payloadData.userId,
        projectId: state.selectedProject?.id,
        queueType: state.selectedQueueType,
        newticketData: payloadData.ticketData,
        resolveStatus: state.ticketIsResolvedStatus,
      });

      // Step 1: Ticket Update Check
      if (
        shouldUpdate &&
        !isTicketSearchApplied &&
        isPrimaryFilterMatched &&
        isSecondaryFilterMatch
      ) {
        const ticketIndex = localTicketList.findIndex(
          (ticket) => ticket?.id === payloadData?.ticketData?.id
        );

        // Step 2: Existing Ticket Update
        if (ticketIndex > -1) {
          localTicketList[ticketIndex] = payloadData.ticketData;
        } else {
          // Step 3: New Ticket Handling
          totalLocal = totalLocal + 1;
          let pinnedTickets = [];
          let otherTickets = [];

          localTicketList.forEach((ticket) => {
            if (ticket.is_pinned) {
              pinnedTickets.push(ticket);
            } else {
              otherTickets.push(ticket);
            }
          });

          if (state.ticketOrder === 'desc') {
            otherTickets = [payloadData.ticketData, ...otherTickets];
          } else {
            otherTickets = [...otherTickets, payloadData.ticketData];
          }

          localTicketList = [...pinnedTickets, ...otherTickets];
          if (document.visibilityState === 'hidden') CreateTicketAudio.play();
        }
        getTicketCountStringForWindowTabTitle(totalLocal);
      }

      return {
        ...state,
        ticketList: localTicketList,
        totalTicketCount: totalLocal,
      };
    },
    updateTicketListForRepliedUnrepliedQueue(state, payload) {
      let localTicketList = cloneDeep(state.ticketList);
      let localTicketCount = state.totalTicketCount;

      if (state.selectedSecondaryQueueType === 'replied') {
        localTicketList = localTicketList.filter((ticket) => ticket.is_replied);
      } else if (state.selectedSecondaryQueueType === 'unreplied') {
        localTicketList = localTicketList.filter(
          (ticket) => !ticket.is_replied
        );
      }

      let removedTicketCount = state.ticketList.length - localTicketList.length;

      getTicketCountStringForWindowTabTitle(
        localTicketCount - removedTicketCount
      );
      return {
        ...state,
        ticketList: localTicketList,
        totalTicketCount: localTicketCount - removedTicketCount,
      };
    },

    /**
     *Note: Any modification of this function is prohibited unless approved by senior engineers.
     * payload:{userId,ticketData}
     * updateSingleTicket:
     * This function updates the ticket list in three main steps: insertion, removal, and update.
     *
     * Step 1: Ticket Insertion
     * - If the ticket is not in the list (checked by `ticketIndex === -1`),
     *   the search filter is not applied (`!isTicketSearchApplied`), and the
     *   ticket meets the queue conditions (determined by `shouldInsertTicketOnQueue`),
     *   then it inserts the ticket at the beginning of the list.
     *
     * Step 2: Ticket Removal
     * - If the ticket meets removal conditions (`shouldRemoveTicketFromQueue`)
     *   and is found in the list (`ticketIndex > -1`), it removes the ticket.
     *
     * Step 3: Ticket Update
     * - If the ticket should be updated (determined by `shouldUpdateTicketListFromPusher`),
     *   it applies the necessary changes:
     *   - If the ticket is the currently selected ticket, it updates its details.
     *   - If the ticket is not selected and globla search not, it may be reordered depending on its "pinned" status.
     *   - If a secondary filter is active, tickets not matching this filter are removed from the list.
     */
    updateSingleTicket(state, payload) {
      const {
        ticketList,
        totalTicketCount,
        selectedTicket,
        selectedProject,
        selectedQueueType,
        ticketIsResolvedStatus,
        selectedSecondaryQueueType,
      } = state;

      const { ticketData, userId } = payload;

      let updatedTicketList = [...ticketList];
      let updatedTicketCount = totalTicketCount;

      const ticketIndex = updatedTicketList.findIndex(
        (ticket) => ticket?.id === ticketData?.id
      );

      let isTicketSearchApplied = state.searchState.isSearchApplied;

      // Step 1: Ticket Insertion
      const shouldInsert =
        ticketIndex === -1 &&
        !isTicketSearchApplied &&
        shouldInsertTicketOnQueue({
          userId,
          projectId: selectedProject?.id,
          queueType: selectedQueueType,
          newticketData: ticketData,
          resolveStatus: ticketIsResolvedStatus,
        });

      if (shouldInsert) {
        updatedTicketList = [ticketData, ...updatedTicketList];
        updatedTicketCount += 1;

        return {
          ...state,
          ticketList: updatedTicketList,
          totalTicketCount: updatedTicketCount,
        };
      }

      // Step 2: Ticket Removal
      const shouldRemove = shouldRemoveTicketFromQueue({
        userId,
        projectId: selectedProject?.id,
        queueType: selectedQueueType,
        newticketData: ticketData,
        resolveStatus: ticketIsResolvedStatus,
      });

      if (shouldRemove && ticketIndex > -1) {
        updatedTicketList = updatedTicketList.filter(
          (ticket) => ticket.id !== ticketData.id
        );
        updatedTicketCount -= 1;

        return {
          ...state,
          ticketList: updatedTicketList,
          totalTicketCount: updatedTicketCount,
        };
      }

      // Step 3: Ticket Update
      const shouldUpdate = shouldUpdateTicketListFromPusher({
        userId,
        projectId: selectedProject?.id,
        queueType: selectedQueueType,
        newticketData: ticketData,
        resolveStatus: ticketIsResolvedStatus,
      });

      if (shouldUpdate) {
        const isSecondaryFilterMatch = checkIsSecondaryFilterMatch(
          ticketData?.is_replied,
          selectedSecondaryQueueType
        );

        // If the ticket is currently selected, update its details
        if (selectedTicket?.id === ticketData?.id) {
          if (ticketIndex > -1) {
            updatedTicketList[ticketIndex] = ticketData;
          }

          return {
            ...state,
            ticketList: updatedTicketList,
            totalTicketCount:
              selectedQueueType === 'all'
                ? totalTicketCount
                : updatedTicketList.length,
            selectedTicket: {
              ...selectedTicket,
              card_text: ticketData.card_text,
              card_timestamp: ticketData.card_timestamp,
              assigned_agent: ticketData.assigned_agent,
              assigned_group: ticketData.assigned_group,
              is_replied: ticketData.is_replied,
            },
          };
        } else if (ticketIndex > -1) {
          // If the ticket is not selected, remove it from the list temporarily
          updatedTicketList.splice(ticketIndex, 1);
        }

        // Reorder tickets based on "pinned" status if search is not applied
        if (!isTicketSearchApplied) {
          const pinTicketList = updatedTicketList.filter(
            (ticket) => ticket?.is_pinned
          );
          const nonPinTicketList = updatedTicketList.filter(
            (ticket) => !ticket?.is_pinned
          );

          updatedTicketList = ticketData?.is_pinned
            ? [ticketData, ...pinTicketList, ...nonPinTicketList]
            : [...pinTicketList, ticketData, ...nonPinTicketList];

          // Filter tickets based on secondary filter if it's applied
          if (!isSecondaryFilterMatch && selectedSecondaryQueueType !== 'all') {
            updatedTicketList = updatedTicketList.filter(
              (ticket) => ticket.id !== ticketData.id
            );
            updatedTicketCount = updatedTicketList.length;
            getTicketCountStringForWindowTabTitle(updatedTicketCount);
          } else {
            updatedTicketCount = updatedTicketList.length;
          }
        }
      }

      return {
        ...state,
        ticketList: updatedTicketList,
        totalTicketCount: updatedTicketCount,
      };
    },
    //Note: Any modification of this function is prohibited unless approved by senior engineers.
    updateLeftbarTicketAssign(state, payload) {
      let ticketListLocal = cloneDeep(state.ticketList);
      let selectedTicket = cloneDeep(state.selectedTicket);

      ticketListLocal = ticketListLocal.map((ticket) => {
        if (ticket.id === selectedTicket.id) {
          if (!!payload.data) {
            ticket.assigned_agent = payload.data?.admin_id;
            ticket.assigned_group = 0;
            ticket.is_replied = payload.data?.is_replied || ticket.is_replied;
          }
        }
        if (!!payload.data) {
          selectedTicket.assigned_agent = payload.data?.admin_id;
          selectedTicket.assigned_group = 0;
        }
        return ticket;
      });
      const isSelfAssigned = payload.data?.admin_id === payload.authId;
      return {
        ...state,
        ticketList: ticketListLocal,
        selectedTicket: selectedTicket,
        selectedQueueType: isSelfAssigned ? 'self' : state.selectedQueueType,
      };
    },
    updateConversationDataOnScroll(state, newConversationData) {
      let localConversationData = cloneDeep(state.conversationData);
      if (newConversationData.length !== 0) {
        return {
          ...state,
          conversationData: localConversationData.concat(newConversationData),
        };
      }
    },
    updateFeedCommentData(state, payload) {
      let localConversationData = cloneDeep(state.conversationData);
      let parentComment = localConversationData.parent_comment_data;
      let replyIndex = localConversationData.replies.findIndex(
        (commentData) => commentData.conversation_id === payload.comment_id
      );
      if (replyIndex !== -1) {
        localConversationData.replies[replyIndex].status = payload.action;
        if (payload.action === 'edit') {
          localConversationData.replies[replyIndex].dataV2.text = payload.text;
        }
      }
      if (parentComment.conversation_id === payload.comment_id) {
        localConversationData.parent_comment_data.status = payload.action;
        if (payload.action === 'edit') {
          localConversationData.parent_comment_data.dataV2.text = payload.text;
        }
      }
      return {
        ...state,
        conversationData: localConversationData,
      };
    },
    updateConversationData(state, newConversation) {
      let localConversationData = cloneDeep(state.conversationData);
      const shouldInsert =
        state.selectedTicket.customer_id === newConversation.customer_id;

      let duplicateMessage = localConversationData.findIndex(
        (chatData) =>
          chatData.conversation_id === newConversation.conversation_id
      );

      if (duplicateMessage > -1 && shouldInsert) {
        return {
          ...state,
          conversationData: [newConversation, ...state.conversationData],
        };
      }

      return {
        conversationData: localConversationData,
        ...state,
      };
    },
    addConversationData(state, payload) {
      // TODO: to be refactored
      // payload = {event,adminId}
      let chatDataLocal = cloneDeep(state.conversationData);
      const shouldInsert = !!state.selectedTicket
        ? state.selectedTicket.customer_id === payload.event.customer_id
        : false;

      const { event, adminId } = payload;
      if (
        event.admin_id === adminId &&
        event.source === 'admin' &&
        shouldInsert
      ) {
        //checking admin message before inserting to conversationData
        const isAvailable = state.conversationData.filter(
          (chat) => chat.pusher_key === event.pusher_key
        ).length;

        if (isAvailable === 0) chatDataLocal = [event, ...chatDataLocal];
        else {
          chatDataLocal = chatDataLocal.map((chat) => {
            if (chat.pusher_key === event.pusher_key) {
              chat = event;
            }
            return chat;
          });
        }
      } else {
        //checking customer message before inserting to conversationData
        let duplicateMessage = chatDataLocal.findIndex(
          (chatData) =>
            chatData.conversation_id === payload.event.conversation_id
        );
        if (duplicateMessage === -1 && shouldInsert) {
          chatDataLocal = [payload.event, ...state.conversationData];
        }
        if (document.visibilityState === 'hidden') UpdateTicketAudio.play();
      }
      return { ...state, conversationData: chatDataLocal };
    },
    addFeedReply(state, payload) {
      let feedDataLocal = [...state.conversationData.replies];
      let newFeedData = {};
      let availableConversation = feedDataLocal.filter(
        (replyData) => replyData.conversation_id === payload.conversation_id
      );

      if (availableConversation.length === 0) {
        newFeedData = payload;
      } else newFeedData = feedDataLocal;

      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          replies: [...newFeedData, ...state.conversationData.replies],
        },
      };
    },
    addFeedReplyViaPusher(state, payload) {
      let feedDataLocal = [...state.conversationData.replies];
      const { event, adminId } = payload;
      if (event.admin_id === adminId && event.source === 'admin') {
        //checking admin reply before inserting to conversationData
        const isAvailable = feedDataLocal.filter(
          (reply) => reply.pusher_key === event.pusher_key
        ).length;
        if (isAvailable === 0) feedDataLocal = [event, ...feedDataLocal];
        else {
          feedDataLocal = feedDataLocal.map((reply) => {
            if (reply.pusher_key === event.pusher_key) {
              reply = event;
            }
            return reply;
          });
        }
      }

      return {
        ...state,
        conversationData: {
          ...state.conversationData,
          replies: [...feedDataLocal],
        },
      };
    },
    addEmailConversationData(state, payload) {
      // TODO: to be refactored
      // payload = {event,adminId}
      let chatDataLocal = cloneDeep(state.conversationData);
      const shouldInsert = !!state.selectedTicket
        ? state.selectedTicket.customer_id === payload.event.customer_id
        : false;

      const { event, adminId } = payload;
      if (
        event.admin_id === adminId &&
        event.source === 'admin' &&
        shouldInsert
      ) {
        //checking admin message before inserting to conversationData
        const isAvailable = state.conversationData.filter(
          (chat) => chat.pusher_key === event.pusher_key
        ).length;

        if (isAvailable === 0) chatDataLocal = [event, ...chatDataLocal];
        else {
          chatDataLocal = chatDataLocal.map((chat) => {
            if (chat.pusher_key === event.pusher_key) {
              chat = event;
            }
            return chat;
          });
        }
      } else {
        //checking customer message before inserting to conversationData
        let duplicateMessage = chatDataLocal.findIndex(
          (chatData) =>
            chatData.conversation_id === payload.event.conversation_id
        );
        if (duplicateMessage === -1 && shouldInsert) {
          chatDataLocal = [payload.event, ...state.conversationData];
        }
        if (document.visibilityState === 'hidden') UpdateTicketAudio.play();
      }
      return { ...state, conversationData: chatDataLocal };
    },
    // useing old reducer method. needs refactor
    //Note: Any modification of this function is prohibited unless approved by senior engineers.
    updateTicketAppend(state, updatedData) {
      //payload: ticket & offset
      // data: res.data.dataSource,
      //   offset: offset,
      if (updatedData.data.length > 0) {
        if (state.offset !== updatedData.offset) {
          //prevent entries if api called same offset twice
          return {
            ...state,
            ticketList: [...state.ticketList, ...updatedData.data],
            totalTicketCount: updatedData.totalTicketCount,
            offset: updatedData.offset,
          };
        }
      }
      return { ...state };
    },

    //Note: Any modification of this function is prohibited unless approved by senior engineers.
    removeTicketFromListOnAgentAssignment(state, payload) {
      let ticketCount = state.totalTicketCount;
      let localTicketList = cloneDeep(state.ticketList);

      const ticketIndex = localTicketList.findIndex(
        (ticket) => ticket.id === payload.ticketData.id
      );
      if (ticketIndex > -1) {
        localTicketList = localTicketList.filter(
          (ticket) => ticket.id !== payload.ticketData.id
        );
        ticketCount = ticketCount > 0 ? ticketCount - 1 : 0;
      }
      getTicketCountStringForWindowTabTitle(ticketCount);
      return {
        ...state,
        ticketList: localTicketList,
        totalTicketCount: ticketCount,
        selectedTicket: localTicketList.length > 0 ? localTicketList[0] : null,
      };
    },

    //Note: Any modification of this function is prohibited unless approved by senior engineers.
    removeTicketFromListOnRemoveEvent(state, payload) {
      let ticketCount = state.totalTicketCount;
      let localTicketList = cloneDeep(state.ticketList);

      let shouldUpdate = shouldUpdateTicketListFromPusher({
        userId: payload.userId,
        projectId: payload.projectId,
        queueType: state.selectedQueueType,
        newticketData: payload.ticketData,
        resolveStatus: state.ticketIsResolvedStatus,
      });

      if (!shouldUpdate) {
        return { ...state };
      }

      const ticketIndex = localTicketList.findIndex(
        (ticket) => ticket.id === payload?.ticketData?.id
      );

      const selectedTicketIndex = localTicketList.findIndex(
        (ticket) => ticket?.id === state?.selectedTicket?.id
      );

      /**
       * Retrieves the updated selected ticket based on the current state and payload.
       *
       * This method determines the appropriate ticket to select based on the following rules:
       * - If there are no tickets (ticketCount === 0), returns null.
       * - If the selected ticket's ID is different from the payload ticket's ID, returns the current selected ticket.
       * - If there are less than 2 tickets, or the selected ticket is at the beginning or end of the local ticket list,
       *   returns the first ticket in the local ticket list.
       * - Otherwise, returns the ticket at the selectedTicketIndex from the local ticket list.
       *
       * @returns {Object|null} The updated selected ticket object or null if there are no tickets.
       */
      const getUpdatedSelectedTicket = () => {
        if (ticketCount === 0) return null;

        const { selectedTicket } = state;
        const { id } = selectedTicket || {};
        const { ticketData } = payload || {};
        const { id: ticketDataId } = ticketData || {};

        if (id !== ticketDataId) {
          return selectedTicket;
        }

        const isEdgeIndex =
          selectedTicketIndex === 0 ||
          selectedTicketIndex === localTicketList.length - 1;

        return isEdgeIndex
          ? localTicketList[0]
          : localTicketList[selectedTicketIndex];
      };

      if (ticketIndex > -1) {
        localTicketList = localTicketList.filter(
          (ticket) => ticket.id !== payload.ticketData.id
        );
        ticketCount = ticketCount > 0 ? ticketCount - 1 : 0;
      }

      return {
        ...state,
        ticketList: localTicketList,
        totalTicketCount: ticketCount,
        selectedTicket: getUpdatedSelectedTicket(),
      };
    },
    updateTicketTag(state, payload) {
      let { tag } = payload;
      let localTicket = { ...state.selectedTicket };
      let localTicketList = [...state.ticketList];
      let localTicketIndex = localTicketList.findIndex(
        (ticket) => ticket.id === localTicket.id
      );
      localTicketList[localTicketIndex].tags = tag;
      return {
        ...state,
        selectedTicket: {
          ...state.selectedTicket,
          tags: tag,
        },
        ticketList: localTicketList,
      };
    },
    updateCustomerInformationState(state, payload) {
      return { ...state, customerInformation: payload };
    },
    updateWhatsappTemplates(state, payload) {
      return { ...state, whatsappTemplates: payload };
    },
    updateWhatsappTemplateDetails(state, payload) {
      return { ...state, whatsappTemplateDetails: payload };
    },
    clearState(state) {
      return { ...initialState };
    },
    updateWhatsappCustomerList(state, payload) {
      return { ...state, whatsappCustomerList: payload };
    },
    updateCustomerAttributeState(state, payload) {
      return { ...state, customerAttribute: payload };
    },
    updateChannelList(state, payload) {
      return { ...state, channelList: payload };
    },
  },
  effects: (dispatch) => ({
    async fetchTicketsList(requestPayload) {
      /*
      requestPayload = {
        projectId: number;
        limit: number;
        queue: QueueType //go to `src/typings/index.d.ts` for global scope type
        offset: number;
        isResolved: boolean;
        isReplied: boolean;
        search: string;
        channels: any;
        agents: any;
        tags: any;
        start: string;
        end: string;
        ticketOrder: string;

      }
       */
      try {
        const res = await axios.get(
          config.inbox.tickets('edge', requestPayload.projectId),
          {
            params: requestPayload.queryParameters,
          }
        );
        if (res.data.success) {
          let pinnedTickets = [];
          let otherTickets = [];

          res.data.dataSource.forEach((ticket) => {
            if (ticket.is_pinned) {
              pinnedTickets.push(ticket);
            } else {
              otherTickets.push(ticket);
            }
          });

          let updatedTicketList = [...pinnedTickets, ...otherTickets];

          dispatch.inbox.updateSateData({
            key: 'ticketList',
            value: updatedTicketList,
          });
          dispatch.inbox.updateSateData({
            key: 'offset',
            value: 0,
          });
          dispatch.inbox.updateSateData({
            key: 'totalTicketCount',
            value: res.data.total,
          });
          dispatch.inbox.updateSateData({
            key: 'selectedTicket',
            value: updatedTicketList.length !== 0 ? updatedTicketList[0] : null,
          });
        }
        return true;
      } catch (err) {
        // all
        dispatch.inbox.updateSateData({
          key: 'ticketList',
          value: [],
        });
        dispatch.inbox.updateSateData({
          key: 'totalTicketCount',
          value: 0,
        });
        dispatch.inbox.updateSateData({
          key: 'selectedTicket',
          value: null,
        });

        return false;
      }
    },
    async fetchTicketsListOnScroll(requestPayload) {
      /*
      requestPayload = {
        projectId: number;
        limit: number;
        queue: QueueType //go to `src/typings/index.d.ts` for global scope type
        offset: number;
        isResolved: boolean;
        search: string;
        channels: any;
        agents: any;
        tags: any;
        start: string;
        end: string;
        ticketOrder: string;

      }
       */
      try {
        const res = await axios.get(
          config.inbox.tickets('edge', requestPayload.projectId),
          {
            params: requestPayload.queryParameters,
          }
        );
        if (res.data.success) {
          dispatch.inbox.updateTicketAppend({
            data: res.data.dataSource,
            totalTicketCount: res.data.total,
            offset: requestPayload.queryParameters.offset,
          });
        }
        return res.data;
      } catch (err) {
        // all
        console.log(err);
        return [];
      }
    },
    async fetchAgentGroup(projectId) {
      try {
        const res = await axios.get(
          config.inbox.agentGroups('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateAgentGroup(res.data.dataSource);
          return true;
        }
      } catch (err) {
        dispatch.inbox.updateAgentGroup([]);
      }
    },

    async fetchAssignableAgentList(projectId) {
      /*
      payload= projectId : number
       */
      try {
        const res = await axios.get(
          config.inbox.assignableAgentsByAccess('edge', projectId)
        );
        if (res.data.success) {
          dispatch.inbox.updateAssignableAgentList(res.data.dataSource);
        } else {
          dispatch.inbox.updateAssignableAgentList([]);
        }
      } catch (err) {
        // console.log(err.response);
      }
    },

    async fetchAllTicketTag(projectId) {
      /*
      payload = {body: object}
       */
      try {
        const res = await axios.get(
          config.inbox.getAllTicketTags('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateAllTagsData(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        dispatch.inbox.updateAllTagsData([]);
      }
    },

    async assignTicketTag(payload, rootState) {
      // payload = {body: object, tags: Object[]}
      try {
        const res = await axios.post(
          config.inbox.updateTicketTag(
            'edge',
            rootState.inbox.selectedTicket.id
          ),
          payload.body
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateTicketTag({
            tag: payload.tags.tagList,
          });
        }
        toaster.success('Successful', {
          description: 'New tags applied',
          duration: 1,
        });
      } catch (err) {
        toaster.danger('Failed', {
          description: 'Unable to apply tags',
        });
      }
    },

    async createTicketTag(payload, rootState) {
      // payload = {name: string}
      try {
        const res = await axios.post(
          config.inbox.createTicketTag(
            'edge',
            rootState.inbox.selectedTicket.project_id
          ),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateAllTagsData(
            rootState.inbox.allTicketTags,
            res.data.dataSource
          );
          return res.data.dataSource;
        }
        return false;
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          toaster.danger('Failed', {
            description: err.response?.data?.error || 'Failed to create Tag',
          });
          return false;
        }
      }
    },

    async fetchTicketConversation(query) {
      try {
        const res = await axios.get(
          config.inbox.ticketConversation(
            'edge',
            query.ticketId,
            query.parameter
          ),
          { signal: query.abortSignal }
        );
        if (res.data.success && !query.abortSignal.aborted) {
          dispatch.inbox.updateSateData({
            key: 'conversationData',
            value: res.data.dataSource,
          });
          dispatch.inbox.updateSateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        dispatch.inbox.updateSateData({
          key: 'conversationData',
          value: [],
        });
        dispatch.inbox.updateSateData({
          key: 'ticketActions',
          value: [],
        });
      }
    },

    async fetchTicketConversationOnScroll(query) {
      try {
        const res = await axios.get(
          config.inbox.ticketConversation(
            'edge',
            query.ticketId,
            query.parameter
          )
        );
        if (res.data.success && res.data.dataSource.length !== 0) {
          dispatch.inbox.updateConversationDataOnScroll(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },

    async fetchBotConversation(query) {
      try {
        const res = await axios.get(
          config.inbox.botConversation(
            'edge',
            query.customerId,
            query.parameter
          ),
          { signal: query.abortSignal }
        );
        if (res.data.success && !query.abortSignal.aborted) {
          dispatch.inbox.updateSateData({
            key: 'conversationData',
            value: res.data.dataSource,
          });
          dispatch.inbox.updateSateData({
            key: 'ticketActions',
            value: res.data.actions,
          });
        }
      } catch (err) {
        dispatch.inbox.updateSateData({
          key: 'conversationData',
          value: [],
        });
        dispatch.inbox.updateSateData({
          key: 'ticketActions',
          value: [],
        });
      }
    },

    async fetchBotConversationOnScroll(query) {
      try {
        const res = await axios.get(
          config.inbox.botConversation(
            'edge',
            query.customerId,
            query.parameter
          )
        );
        if (res.data.success && res.data.dataSource.length !== 0) {
          dispatch.inbox.updateConversationDataOnScroll(res.data.dataSource);
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },

    async sendMessegengerChannelMessage(payload, rootState) {
      /*
      payload = {
        text: string | null;
        image: string | null;
        audio: string | null;
        template: string | null;
        action: string;
        attribute_data: {
         attribute_keys...
        }
      }
       */
      try {
        const res = await axios.post(
          config.inbox.sendMessengerChat(
            'edge',
            rootState?.inbox?.selectedTicket?.id
          ),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.addConversationData({
            event: res.data.dataSource,
            adminId: rootState.auth.id,
          });
          if (payload.action !== 'write_note')
            if (rootState?.inbox?.selectedTicket?.assigned_agent === 0) {
              dispatch.inbox.updateLeftbarTicketAssign({
                data: res.data.dataSource,
                authId: rootState.auth.id,
              });
              dispatch.inbox.updateSateData({
                key: 'ticketQueue',
                value: {
                  type: 'self',
                  isResolved: false,
                  privateView: '',
                },
              });
            }

          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to send message' });
          return false;
        }
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          if (!!err.response?.data?.error) {
            toaster.danger('Failed', {
              description: err.response.data.error || 'Failed to Send Message',
            });
          }
        }
        return false;
      }
    },

    async sendFeedChannelMessage(payload, rootState) {
      /*
      payload = {
        text: string | null;
        image: string | null;
        audio: string | null;
        template: string | null;
        action: string;}
       */
      try {
        const res = await axios.post(
          config.inbox.sendFeedThread(
            'edge',
            rootState?.inbox?.selectedTicket?.id
          ),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.addFeedReply([res.data.dataSource]);
          if (payload.action !== 'write_note')
            if (rootState?.inbox?.selectedTicket?.assigned_agent === 0) {
              dispatch.inbox.updateLeftbarTicketAssign({
                data: res.data.dataSource,
                authId: rootState.auth.id,
              });
              dispatch.inbox.updateSateData({
                key: 'ticketQueue',
                value: {
                  type: 'self',
                  isResolved: false,
                  privateView: '',
                },
              });
            }
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          if (!!err.response?.data?.error) {
            toaster.danger('Failed', {
              description: err.response.data.error || 'Failed to Send Message',
            });
          }
        }
        return false;
      }
    },

    async sendEmailChannelMessage(payload, rootState) {
      /*
      payload = {
        text: string | null;
        image: string | null;
        audio: string | null;
        template: string | null;
        action: string;}
       */
      try {
        const res = await axios.post(
          config.inbox.sendGmailThread(
            'edge',
            rootState?.inbox?.selectedTicket?.id
          ),
          payload
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.addEmailConversationData({
            event: res.data.dataSource,
            adminId: rootState.auth.id,
          });
          dispatch.inbox.updateSateData({
            key: 'ticketQueue',
            value: {
              type: 'self',
              isResolved: false,
              privateView: '',
            },
          });
          return true;
        } else {
          toaster.danger('Failed', { description: 'Failed to Post Message' });
          return false;
        }
      } catch (err) {
        if (!!err && err?.response?.status !== 200) {
          if (!!err.response?.data?.error) {
            toaster.danger('Failed', {
              description: err.response.data.error || 'Failed to Send Message',
            });
          }
        }
        return false;
      }
    },

    async updateFeedCommentStatus(payload, rootState) {
      /*
      payload = {
        action: string, // hide, unhide,edit, remove
	      comment_id: string
      }
      */
      try {
        const res = await axios.post(
          config.inbox.sendFeedThread(
            'edge',
            rootState?.inbox?.selectedTicket?.id
          ),
          payload
        );
        if (res.status === 200) {
          dispatch.inbox.updateFeedCommentData(payload);
          if (payload?.action === 'remove') {
            toaster.success('Success', {
              description: `Comment deleted successfully`,
            });
          } else {
            toaster.success('Success', {
              description: `This may take 1-2 mins. Please wait…`,
            });
          }
        } else {
          toaster.danger('Failed', {
            description: 'Failed to update comment',
          });
        }
      } catch (err) {
        toaster.danger('Failed', {
          description: 'Failed to update comment',
        });
      }
    },

    async updateTicketPinStatus(payload, state) {
      try {
        const res = await axios.post(
          config.inbox.pinTicket('edge', payload.ticketId),
          {
            is_pinned: payload.isPinned,
          }
        );
        if (res.status !== 200) {
          return {
            success: false,
            msg:
              res.status < 500
                ? res.data?.error
                : 'Please try again after sometime',
          };
        }
        const updatedTicketData = cloneDeep(res.data.dataSource);
        let oldData = {};
        let updatedTicketList = state.inbox.ticketList.filter((ticket) => {
          if (ticket.id === updatedTicketData.id) {
            oldData = { ...ticket };
            oldData.is_pinned = updatedTicketData.is_pinned;
            return false;
          } else return true;
        });
        if (oldData.is_pinned) {
          updatedTicketList = [oldData, ...updatedTicketList];
        } else {
          updatedTicketList.push(oldData);
        }

        dispatch.inbox.updateSateData({
          key: 'ticketList',
          value: updatedTicketList,
        });
        return {
          success: true,
          msg: updatedTicketData.is_pinned
            ? 'Ticket pinned successfully'
            : 'Ticket unpinned successfully',
        };
      } catch (err) {
        console.log(err);
        return { success: false, msg: 'Please try again after sometime' };
      }
    },

    async resolveTicket(payload) {
      try {
        const res = await axios.post(
          config.inbox.ticketResolve('edge', payload.ticketId),
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          dispatch.inbox.removeTicketFromListOnRemoveEvent({
            id: payload.ticketId,
          });
          toaster.success('Success', {
            description: `Successfully Resolved this Ticket`,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: `Failed to Resolve Ticket`,
          });
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async holdTicket(payload) {
      try {
        const res = await axios.post(
          config.inbox.ticketHoldAction('edge', payload.ticketId),
          { status: payload.status }
        );
        if (res.data.success && res.status === 200) {
          toaster.success('Success', {
            description: `Ticket ${
              payload.status ? 'held' : 'unheld'
            } successfully.`,
          });
          //update state of the ticket
          const newTicketData = {
            ...payload.ticketData,
            is_on_hold: payload.status,
          };
          dispatch.inbox.updateSingleTicket({
            ticketData: newTicketData,
            userId: payload.userId,
          });

          dispatch.inbox.updateSelectedTicket(newTicketData);

          return true;
        } else {
          toaster.danger('Failed', {
            description: `Failed to ${
              payload.status ? 'held' : 'unheld'
            } Ticket.`,
          });
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    async assignTicket(payload) {
      /*
        payload = ticketId: number, currentTicket: number
       */
      try {
        let body = {
          agent_id: payload.agentId,
          group_id: payload.groupId,
          note: payload.note,
        };

        const res = await axios.post(
          config.inbox.assignTicket(payload.ticketId),
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Success', {
            description: `Chat Assigned Successfully`,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Assign ticket to This Agent/Group',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Assign ticket to This Agent/Group',
        });
        return false;
      }
    },

    async fetchTicketFilterData(projectId) {
      try {
        const res = await axios.get(
          config.inbox.getTicketFilterData(projectId)
        );

        if (res.data.success)
          dispatch.inbox.updateSateData({
            key: 'savedFilterList',
            value: [...res.data.dataSource],
          });
      } catch (error) {
        console.error(error);
      }
    },
    async modifyTicketFilterData(payload) {
      try {
        const res = await axios.post(
          config.inbox.updateTicketFilterData(payload.projectId),
          { inbox_preset_filters: payload.filterList }
        );
        if (res.data.success) {
          dispatch.inbox.updateSateData({
            key: 'savedFilterList',
            value: [...res.data.dataSource],
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchWhatsappTemplateDetails(payload) {
      // payload = {
      //   templateId: string,
      //   chaannelId: string,
      // }
      try {
        const res = await axios.get(
          config.inbox.whatsappTemplateDetails(
            'edge',
            payload.templateId,
            payload.channelId
          )
        );
        if (res.data.success) {
          // dispatch.inbox.updateWhatsappTemplateDetails(res.data.dataSource);
          return res.data.dataSource;
        }
      } catch (err) {
        return false;
      }
    },
    async getWhatsAppMesssageTemplates(platformId) {
      try {
        const res = await axios.get(
          config.inbox.getWhatsAppMesssageTemplates(platformId)
        );
        if (res.data.success) {
          dispatch.inbox.updateWhatsappTemplates(
            res.data.dataSource.whatsapp_message_templates
          );
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    async reopenClosedTicket(payload) {
      /**
       * payload= {ticketId: number, note: string, agentId: number| null, groupId: number| null}
       */
      try {
        const { ticketId, note, agentId, groupId } = payload;
        const res = await axios.post(
          config.inbox.reopenTicket('edge', ticketId),
          {
            note: note,
            agent_id: agentId,
            group_id: groupId,
          }
        );
        if (res.data.success) {
          toaster.success('Successfully Ticket Created');
        } else {
          toaster.danger(res.data?.error || 'Ticket Reopen Failed');
        }
        return res.data.success;
      } catch (err) {
        toaster.danger(err.response?.data?.error || 'Ticket Reopen Failed');
        return false;
      }
    },

    /**
     *
     * @param {projectId:number, key:string} payload  key is the search string
     *
     */
    async searchCustomer(payload, state) {
      try {
        if (!payload.key) {
          dispatch.inbox.updateSateData({
            key: 'searchTicketResult',
            value: [],
          });
        } else {
          const res = await axios.get(
            config.inbox.searchCustomer('edge', payload.projectId, payload.key)
          );

          dispatch.inbox.updateSateData({
            key: 'searchTicketResult',
            value: res.data.dataSource,
          });
        }
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },

    /**
     *
     * @param {projectId:number, key:string} payload  key is the search string
     *
     */
    async searchTicketByParamsData(payload, state) {
      try {
        const res = await axios.get(
          config.inbox.searchCustomer('edge', payload.projectId, payload.key)
        );

        return [...res.data.dataSource];
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    async getCustomerInformation(payload) {
      try {
        const res = await axios.get(
          config.inbox.customerInformation('edge', payload.customer_id)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateCustomerInformationState(res.data.data);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateCustomerInformation(payload) {
      try {
        const res = await axios.patch(
          config.inbox.customerAttributeUpdate('edge', payload.customer_id),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateCustomerInformationState(res.data.data);
        }
        return true;
      } catch (err) {
        console.log(err);
        dispatch.inbox.updateCustomerInformationState(null);
      }
      return false;
    },
    async fetchSavedReplies(projectId, rootState) {
      try {
        const res = await axios.get(
          config.inbox.getSavedReplies('edge', projectId)
        );
        if (res.status === 200) {
          dispatch.inbox.updateSateData({
            key: 'savedReplies',
            value: res.data.dataSource,
          });
        }
      } catch (err) {}
    },
    async updateCustomerAttribute(payload) {
      try {
        const res = await axios.post(
          config.inbox.customerAttributeUpdate('edge', payload.customer_id),
          payload.data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateCustomerAttributeState(
            res.data.dataSource.variable
          );
          return true;
        }
      } catch (err) {
        console.log(err);
        dispatch.inbox.updateCustomerAttributeState(null);
        return false;
      }
    },
    async createWhatsappTicket(payload) {
      /**
       * payload= {teamId: number, waChannelId: string, phone: string}
       */
      try {
        const { teamId, whatsappChannelId, phone } = payload;
        const res = await axios.post(
          config.inbox.createWhatsAppTicket('edge', teamId),
          {
            platform_id: whatsappChannelId,
            customer_phone: phone,
          }
        );

        if (res.data.success) {
          toaster.success('Successfully Ticket Created');
          return res.data;
        } else {
          return { success: false, error: res.data?.error };
        }
      } catch (err) {
        return { success: false, error: err.response?.data?.error };
      }
    },
    async getWhatsappCustomerList(payload) {
      try {
        const { teamId, whatsappChannelId, searchName } = payload;
        const res = await axios.post(
          config.inbox.getWhatsappCustomerList(
            'edge',
            teamId,
            whatsappChannelId,
            searchName
          )
        );
        if (res.data.success) {
          dispatch.inbox.updateWhatsappCustomerList(res.data.dataSource);
          return res.data;
        } else {
          return { success: false };
        }
      } catch (err) {
        return { success: false };
      }
    },
    async fetchProjectAttributes(teamId) {
      /*
      teamId: number
       */
      try {
        const res = await axios.get(
          config.inbox.projectAttributes('edge', teamId)
        );
        let attributeData = [];
        if (res.data.success) attributeData = res.data.dataSource;

        dispatch.inbox.updateSateData({
          key: 'projectAttributes',
          value: attributeData,
        });
      } catch (err) {
        dispatch.inbox.updateSateData({
          key: 'projectAttributes',
          value: [],
        });
      }
    },
    async applyBulkAction(payload) {
      try {
        const res = await axios.post(
          config.inbox.bulkAction('edge', payload.projectId),
          { ...payload.data }
        );
        if (res.data.success) {
          return { ...res.data };
        }
        return { success: false };
      } catch (exception) {
        console.error(exception);
        return { success: false };
      }
    },
    async fetchTicketsBadgeCount(projectId) {
      try {
        const res = await axios.get(
          config.inbox.ticketsBadgeCount('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.inbox.updateSateData({
            key: 'ticketsBadgeCount',
            value: res.data.dataSource,
          });
          return true;
        }
      } catch (err) {
        return false;
      }
    },
    async fetchAttachmentData(url) {
      try {
        const res = await axios.get(config.inbox.attachmentData('edge', url));
        if (res.status === 200) {
          return res;
        }
      } catch (err) {
        return '';
      }
    },
    async assignBotTicket(payload) {
      /*
        payload = customer_id: number, currentTicket: number
       */
      try {
        let body = {
          agent_id: payload.agentId,
          group_id: payload.groupId,
          note: payload.note,
        };

        const res = await axios.post(
          config.inbox.assignBotTicket(payload.customer_id),
          body
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Success', {
            description: `Chat Assigned Successfully`,
          });
          return true;
        } else {
          toaster.danger('Failed', {
            description: 'Failed to Assign ticket to This Agent/Group',
          });
          return false;
        }
      } catch (err) {
        console.error(err);
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error ||
            'Failed to Assign ticket to This Agent/Group',
        });
        return false;
      }
    },

    async fetchGroupMembers(customerId) {
      try {
        const res = await axios.get(
          config.inbox.groupMembers('edge', customerId)
        );
        if (res.status === 200) {
          return [...res.data.dataSource];
        } else return [];
      } catch (err) {
        return [];
      }
    },

    getCustomerInformationStoreData(payload = '', state) {
      return state.inbox.customerInformation;
    },

    async searchCustomerByPrimaryId({ projectId, query }) {
      try {
        const res = await axios.get(
          config.inbox.searchCustomerByPrimaryId(projectId),
          {
            params: { search: query },
          }
        );
        if (res.status === 200) {
          return [...res.data.dataSource];
        } else return [];
      } catch (err) {
        console.error('api error:', err);
        return [];
      }
    },
    async createTicketByPrimaryId({ projectId, payloadBody }) {
      try {
        const res = await axios.post(
          config.inbox.createTicketByPrimaryId(projectId),
          payloadBody
        );
        if (res.status >= 200) {
          return { ...res.data };
        } else return null;
      } catch (err) {
        console.error('api error:', err);
        return {
          success: false,
          error: err?.response?.data?.error || 'Ticket creation failed',
        };
      }
    },

    async getIntegratedChannelList(teamId) {
      try {
        const res = await axios.get(
          config.integration.getIntegratedChannelList(teamId)
        );
        if (res.status === 200) {
          const channelListFromResponse: ChannelListProperty[] =
            res.data.dataSource.map((channel) => ({
              name: channel.name,
              id: channel.id,
              alice_store_id: channel.alice_store_id,
              ecommerce_type: channel.ecommerce_type,
            }));
          dispatch.inbox.updateChannelList(channelListFromResponse);
          dispatch.inbox.updateIntegratedChannelList(res.data.dataSource);
          dispatch.channel.updateChannelList(res.data.dataSource);
          dispatch.dashboard.updatePlatformList(res.data.dataSource);
          if (res?.data?.dataSource) return res.data.dataSource.length !== 0;
        }
      } catch (err) {
        console.error(err?.response?.data?.error || '');
        dispatch.inbox.updateIntegratedChannelList([]);
        return false;
      }
    },

    async setTicketPriority(requestData) {
      try {
        const res = await axios.post(
          config.inbox.setTicketPriority('stable', requestData.ticketId),
          { priority: requestData.priority }
        );
        return res.data;
      } catch (err) {
        console.error('api error:', err);
        return {
          success: false,
          error: err?.response?.data?.error || 'Ticket creation failed',
        };
      }
    },
  }),
};
