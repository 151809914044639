import * as React from 'react';
import { CalendarIcon } from 'lucide-react';
import {
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { cva, VariantProps } from 'class-variance-authority';

import { Button, buttonVariants } from 'libraryV2/ui/button';
import { Calendar } from 'libraryV2/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';
import { cn } from 'libraryV2/utils';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const multiSelectVariants = cva(
  'flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium text-foreground ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground text-background',
        link: 'text-primary underline-offset-4 hover:underline text-background',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const getDateRangePresets = (today: Date) => [
  { label: 'Today', start: today, end: today },
  { label: 'Yesterday', start: subDays(today, 1), end: subDays(today, 1) },
  {
    label: 'This Week',
    start: startOfWeek(today, { weekStartsOn: 1 }),
    end: endOfWeek(today, { weekStartsOn: 1 }),
  },
  {
    label: 'Last Week',
    start: subDays(startOfWeek(today, { weekStartsOn: 1 }), 7),
    end: subDays(endOfWeek(today, { weekStartsOn: 1 }), 7),
  },
  {
    label: 'This Month',
    start: startOfMonth(today),
    end: endOfMonth(today),
  },
  {
    label: 'Last Month',
    start: startOfMonth(subDays(today, today.getDate())),
    end: endOfMonth(subDays(today, today.getDate())),
  },
  { label: 'This Year', start: startOfYear(today), end: endOfYear(today) },
  {
    label: 'Last Year',
    start: startOfYear(subDays(today, 365)),
    end: endOfYear(subDays(today, 365)),
  },
  {
    label: 'All Time',
    start: startOfYear(subDays(today, 3 * 365)),
    end: endOfYear(subDays(today, 0)),
  },
];

type DateRangePresetLabel =
  | 'Today'
  | 'Yesterday'
  | 'This Week'
  | 'Last Week'
  | 'This Month'
  | 'Last Month'
  | 'This Year'
  | 'Last Year'
  | 'All Time';

type DateRangePart = 'from' | 'to';
interface CalendarDateRangePickerProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof multiSelectVariants> {
  id?: string;
  className?: string;
  dateRange: DateRange | undefined;
  yearsRange?: number;
  hideTimeInput?: boolean;
  hideCalenderNavigation?: boolean;
  onDateSelect: (range: { from: Date; to: Date }) => void;
}

export const CalendarDateRangePicker = React.forwardRef<
  HTMLButtonElement,
  CalendarDateRangePickerProps
>(
  (
    {
      id = 'calendar-date-picker',
      className,
      dateRange,
      yearsRange = 10,
      hideTimeInput = false,
      hideCalenderNavigation = false,
      onDateSelect,
      variant,
      ...props
    },
    ref
  ) => {
    const today = new Date();
    const years = Array.from(
      { length: yearsRange + 1 },
      (_, i) => today.getFullYear() - yearsRange / 2 + i
    );

    const dateRangePresets = getDateRangePresets(today);

    const formatWithTz = (date: Date, fmt: string) =>
      format(date || new Date(), fmt);

    const [currentDateRange, setCurrentDateRange] = React.useState<
      DateRange | undefined
    >(dateRange);

    const [calenderViewDate, setCalenderViewDate] = React.useState(
      dateRange?.from
    );

    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const [selectedRange, setSelectedRange] =
      React.useState<DateRangePresetLabel | null>(null);

    const [monthFrom, setMonthFrom] = React.useState<Date | undefined>(
      dateRange?.from
    );

    const [yearFrom, setYearFrom] = React.useState<number | undefined>(
      dateRange?.from?.getFullYear()
    );

    const [monthTo, setMonthTo] = React.useState<Date | undefined>(
      dateRange?.to
    );

    const [yearTo, setYearTo] = React.useState<number | undefined>(
      dateRange?.to?.getFullYear()
    );
    const [fromTime, setFromTime] = React.useState('00:00');
    const [toTime, setToTime] = React.useState('23:59');

    const handleClose = () => setIsPopoverOpen(false);

    const handleTogglePopover = () => setIsPopoverOpen((prev) => !prev);
    // select date range from presets
    const handlePresetDateRange = (
      from: Date,
      to: Date,
      range: DateRangePresetLabel
    ) => {
      const [fromHours, fromMinutes] = fromTime.split(':');
      const [toHours, toMinutes] = toTime.split(':');

      const startDate = new Date(from);
      startDate.setHours(parseInt(fromHours), parseInt(fromMinutes), 0);

      const endDate = new Date(to);
      endDate.setHours(parseInt(toHours), parseInt(toMinutes), 59);

      setCurrentDateRange({ from: startDate, to: endDate });
      setCalenderViewDate(startDate);
      setSelectedRange(range);
    };

    const handleDateSelect = (range: DateRange | undefined) => {
      if (!range) {
        setSelectedRange(null);
        return;
      }
      let from = startOfDay(range.from as Date);
      let to = range.to ? endOfDay(range.to) : from;

      setCurrentDateRange({ from, to });
      setMonthFrom(from);
      setYearFrom(from.getFullYear());
      setMonthTo(to);
      setYearTo(to.getFullYear());
    };

    const handleMonthChange = (newMonthIndex: number, part: string) => {
      const isInvalidIndex =
        newMonthIndex < 0 || newMonthIndex > months.length - 1;
      if (part === 'from') {
        if (!yearFrom || isInvalidIndex) {
          return;
        }

        const newMonth = new Date(yearFrom, newMonthIndex, 1);
        const from = startOfMonth(newMonth);
        const to = dateRange?.to
          ? endOfDay(dateRange.to)
          : endOfMonth(newMonth);

        if (from <= to) {
          setCalenderViewDate(newMonth);
          setMonthFrom(newMonth);
          setMonthTo(dateRange?.to);
        }
      } else {
        if (!yearTo || isInvalidIndex) {
          return;
        }
        const newMonth = new Date(yearTo, newMonthIndex, 1);

        const from = dateRange?.from
          ? startOfDay(dateRange.from)
          : startOfMonth(newMonth);
        const to = endOfMonth(newMonth);
        if (from <= to) {
          setCalenderViewDate(new Date(yearTo, newMonthIndex - 1, 1));
          setMonthTo(newMonth);
          setMonthFrom(dateRange?.from);
        }
      }
    };

    const handleYearChange = (newYear: number, part: string) => {
      if (part === 'from') {
        if (years.includes(newYear)) {
          const newMonth = monthFrom
            ? new Date(newYear, monthFrom ? monthFrom.getMonth() : 0, 1)
            : new Date(newYear, 0, 1);
          const from = startOfMonth(newMonth);
          const to = dateRange?.to
            ? endOfDay(dateRange.to)
            : endOfMonth(newMonth);
          if (from <= to) {
            const newTo = new Date(
              newYear,
              monthTo ? monthTo.getMonth() - 1 : 0,
              1
            );

            setCalenderViewDate(newTo);
            setYearFrom(newYear);
          }
        }
      } else {
        if (years.includes(newYear)) {
          const newMonth = monthTo
            ? new Date(newYear, monthTo.getMonth(), 1)
            : new Date(newYear, 0, 1);
          const from = dateRange?.from
            ? startOfDay(dateRange.from)
            : startOfMonth(newMonth);
          const to = endOfMonth(newMonth);
          if (from <= to) {
            setCalenderViewDate(from);
            setYearTo(newYear);
          }
        }
      }
    };

    const handleTimeChange = (time: string, part: DateRangePart) => {
      const [hours, minutes] = time.split(':');
      if (part === 'from') {
        setFromTime(time);
        // update from date
        const newFromDate = currentDateRange?.from
          ? new Date(currentDateRange.from)
          : new Date();
        newFromDate.setHours(parseInt(hours), parseInt(minutes), 0);
        if (newFromDate <= (currentDateRange?.to || newFromDate)) {
          setCurrentDateRange({ from: newFromDate, to: currentDateRange?.to });
        }
      } else {
        setToTime(time);
        // update to date
        const newToDate = currentDateRange?.to
          ? new Date(currentDateRange.to)
          : new Date();
        newToDate.setHours(parseInt(hours), parseInt(minutes), 59);
        // @ts-expect-error
        if (newToDate >= currentDateRange?.from) {
          setCurrentDateRange({
            from: currentDateRange?.from,
            to: newToDate,
          });
        }
      }
    };

    const handleApply = () => {
      handleDateSelect(currentDateRange);
      if (currentDateRange?.from && currentDateRange?.to) {
        const withTime = {
          from: new Date(currentDateRange.from),
          to: new Date(currentDateRange.to),
        };
        withTime.from.setHours(parseInt(fromTime), 0, 0);
        withTime.to.setHours(parseInt(toTime), 59, 59);
        onDateSelect(withTime);
      }
      setCalenderViewDate(currentDateRange?.from);
      setIsPopoverOpen(false);
    };

    const renderMonthSelect = (part: DateRangePart) => {
      const currentMonth = part === 'from' ? monthFrom : monthTo;
      return (
        <Select
          onValueChange={(value) => {
            handleMonthChange(months.indexOf(value), part);
          }}
          value={currentMonth ? months[currentMonth.getMonth()] : undefined}
        >
          <SelectTrigger className='hidden sm:flex w-[122px] focus:ring-0 focus:ring-offset-0 font-medium hover:bg-accent hover:text-accent-foreground'>
            <SelectValue placeholder='Month' />
          </SelectTrigger>
          <SelectContent className='bg-white'>
            {months.map((month, idx) => (
              <SelectItem key={idx} value={month}>
                {month}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    };

    const renderYearSelect = (part: DateRangePart) => {
      const currentYear = part === 'from' ? yearFrom : yearTo;
      return (
        <Select
          onValueChange={(value) => {
            handleYearChange(Number(value), part);
            setSelectedRange(null);
          }}
          value={currentYear ? currentYear.toString() : undefined}
        >
          <SelectTrigger className='hidden sm:flex w-[122px] focus:ring-0 focus:ring-offset-0 font-medium hover:bg-accent hover:text-accent-foreground'>
            <SelectValue placeholder='Year' />
          </SelectTrigger>
          <SelectContent className='bg-white'>
            {years.map((year, idx) => (
              <SelectItem key={idx} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    };

    const renderDateRangePresets = () => (
      <div className='hidden md:flex flex-col gap-1 p-3 text-left border-r border-foreground/10'>
        {dateRangePresets.map(({ label, start, end }) => (
          <Button
            key={label}
            variant='ghost'
            size='sm'
            className={cn(
              'justify-start hover:bg-zinc-100 px-2.5 text-xs font-medium py-1.5 text-textPrimary ',
              selectedRange === label &&
                'bg-primary hover:bg-primary text-white hover:text-white'
            )}
            onClick={() => {
              handlePresetDateRange(start, end, label as DateRangePresetLabel);
              setMonthFrom(start);
              setYearFrom(start.getFullYear());
              setMonthTo(end);
              setYearTo(end.getFullYear());
            }}
          >
            {label}
          </Button>
        ))}
      </div>
    );

    const renderTimeInput = () => {
      if (hideTimeInput) return <div></div>;
      return (
        <div className='flex gap-3 items-center'>
          <input
            type='time'
            id='from-time'
            value={fromTime}
            onChange={(e) => handleTimeChange(e.target.value, 'from')}
            className='hide-indicator w-[100px] text-textSecondary-hover text-sm font-normal h-8 border-border rounded-lg px-2 focus:border-none focus:ring-primary focus:ring-1 focus:ring-offset-0 focus:outline-none'
          />
          <span className='text-textSecondary-hover text-sm font-normal'>
            {'- -'}
          </span>
          <input
            id='to-time'
            type='time'
            value={toTime}
            onChange={(e) => handleTimeChange(e.target.value, 'to')}
            className='hide-indicator w-[100px] text-textSecondary-hover text-sm font-normal h-8 border-border rounded-lg px-2 focus:border-none focus:ring-primary focus:ring-1 focus:ring-offset-0 focus:outline-none'
          />
        </div>
      );
    };

    return (
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            id='date'
            ref={ref}
            {...props}
            className={cn('w-fit flex gap-2', multiSelectVariants({ variant }))}
            onClick={handleTogglePopover}
            suppressHydrationWarning
          >
            <CalendarIcon className='h-4 w-4' />
            <span>
              {dateRange?.from ? (
                dateRange?.to ? (
                  <>
                    <span id={`firstDay-${id}`} className='date-part'>
                      {formatWithTz(dateRange.from, 'dd')}
                    </span>{' '}
                    <span id={`firstMonth-${id}`} className='date-part'>
                      {formatWithTz(dateRange.from, 'LLL')}
                    </span>
                    ,{' '}
                    <span id={`firstYear-${id}`} className='date-part'>
                      {formatWithTz(dateRange.from, 'y')}
                    </span>
                    {' - '}
                    <span id={`secondDay-${id}`} className='date-part'>
                      {formatWithTz(dateRange.to, 'dd')}
                    </span>{' '}
                    <span id={`secondMonth-${id}`} className='date-part'>
                      {formatWithTz(dateRange.to, 'LLL')}
                    </span>
                    ,{' '}
                    <span id={`secondYear-${id}`} className='date-part'>
                      {formatWithTz(dateRange.to, 'y')}
                    </span>
                  </>
                ) : (
                  <>
                    <span id='day' className='date-part'>
                      {formatWithTz(dateRange.from, 'dd')}
                    </span>{' '}
                    <span id='month' className='date-part'>
                      {formatWithTz(dateRange.from, 'LLL')}
                    </span>
                    ,{' '}
                    <span id='year' className='date-part'>
                      {formatWithTz(dateRange.from, 'y')}
                    </span>
                  </>
                )
              ) : (
                <span>Pick a date</span>
              )}
            </span>
          </Button>
        </PopoverTrigger>
        {isPopoverOpen && (
          <PopoverContent
            className='w-auto bg-white shadow-lg border border-foreground ltr:mr-4 rtl:ml-4 p-0'
            side='bottom'
            align='start'
            onInteractOutside={handleClose}
            onEscapeKeyDown={handleClose}
            style={{
              maxHeight: 'var(--radix-popover-content-available-height)',
              overflowY: 'auto',
            }}
          >
            <div className='flex'>
              {renderDateRangePresets()}
              <div className='flex flex-col gap-3 '>
                {!hideCalenderNavigation ? (
                  <div className='flex items-center gap-4 pt-3 px-3'>
                    <div className='flex gap-2 '>
                      {renderMonthSelect('from')}
                      {renderYearSelect('from')}
                    </div>
                    <div className='flex gap-2'>
                      {renderMonthSelect('to')}
                      {renderYearSelect('to')}
                    </div>
                  </div>
                ) : null}

                <Calendar
                  mode='range'
                  defaultMonth={monthFrom}
                  month={calenderViewDate}
                  onMonthChange={setCalenderViewDate}
                  selected={currentDateRange}
                  onSelect={setCurrentDateRange}
                  numberOfMonths={2}
                  showOutsideDays={true}
                  classNames={{
                    months:
                      'flex flex-col sm:flex-row space-y-4 sm:justify-between sm:space-y-0',
                    day_selected:
                      'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                    day_range_middle:
                      'bg-background-hover hover:bg-background-hover rounded-none text-primary-foreground',
                    day: cn(
                      buttonVariants({ variant: 'ghost' }),
                      'h-8 w-8 p-0 font-normal hover:bg-background-hover aria-selected:opacity-100 text-textPrimary text-sm'
                    ),
                  }}
                  className={className}
                />

                <div className='flex justify-between border-t h-14 border-border px-3'>
                  {renderTimeInput()}
                  <div className='flex gap-2 self-center'>
                    <Button
                      variant='ghost'
                      className='h-8 text-xs font-medium leading-4'
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant='default'
                      className='h-8 text-xs font-medium leading-4'
                      onClick={handleApply}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </PopoverContent>
        )}
      </Popover>
    );
  }
);

CalendarDateRangePicker.displayName = 'CalendarDateRangePicker';
