import { ColumnDef } from '@tanstack/react-table';
import { IWhatsappTemplateAnalyticsData } from 'pages/reportingV2/interface';
import { TemplateStatusView } from './TemplateOverviewTableHeader';

const defaultFallbackValue = '--';

export const templateOverviewTableColumns: ColumnDef<
  IWhatsappTemplateAnalyticsData,
  unknown
>[] = [
  {
    id: 'template-name',
    accessorKey: 'name',
    header: 'Templates Name',
    cell: (info) => (
      <span className='block text-sm truncate text-textPrimary max-w-[240px]'>
        {info.getValue() + ''}
      </span>
    ),
  },
  {
    id: 'template-id',
    accessorKey: 'id',
    header: 'Template ID',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-status',
    accessorKey: 'status',
    header: 'Status',
    cell: (info) => (
      <TemplateStatusView
        status={info.getValue() as IWhatsappTemplateAnalyticsData['status']}
      />
    ),
  },
  {
    id: 'template-sent-count',
    accessorKey: 'sent',
    header: 'Sent',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-delivered-count',
    accessorKey: 'delivered',
    header: 'Delivered',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-read-count',
    accessorKey: 'read',
    header: 'Read',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-cost',
    accessorKey: 'cost',
    header: 'Cost',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-order',
    accessorKey: 'order',
    header: 'Order',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-sales',
    accessorKey: 'sales',
    header: 'Sales',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-avg-sales',
    accessorKey: 'avg-sales',
    header: 'AVG. Sales Cnversion',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
  {
    id: 'template-type',
    accessorKey: 'category',
    header: 'Type',
    cell: (info) => info.getValue() ?? defaultFallbackValue,
  },
];
