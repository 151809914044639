import React from 'react';
import Modal from '../../common/Modal';
import Stepper from '../../common/Stepper';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { getModalCustomFooter } from '../../../utils/functions';
import {
  channelCreateAPiResponseType,
  facebookResponseDataProps,
  instagramConnectionRequestProps,
  instagramMessengerConnectionAPiResponseType,
  instagramPagesListProps
} from 'pages/integration/interface';

interface Props {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  fetchInstagramPagesForBusiness: (
    token: string,
    type: string
  ) => Promise<instagramPagesListProps[]>;
  fetchInstagramPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<instagramPagesListProps[]>;
  handleChannelCreate: (
    channelType: string,
    channelData: any
  ) => Promise<channelCreateAPiResponseType>;
  handleInstaConnectionValidation: (
    channelType: string,
    data: instagramConnectionRequestProps
  ) => Promise<instagramMessengerConnectionAPiResponseType>;
}

const initialPageData = {
  name: '',
  avatar: '',
  username: '',
  primary_id: '',
  secondary_id: '',
  is_connected: false,
};

const formSteps = [
  { id: 1, name: 'Login Instagram' },
  { id: 2, name: 'Choose Connectable Page' },
  { id: 3, name: 'General Information' },
];

const initialSettingData = {
  title: '',
  primary_id: '',
  is_published: true,
  connected_nlp_integration_id: '',
  ice_breaker: [{ id: 0, title: '', type: 'sequence', value: '' }],
};

const InstagramModal: React.FC<Props> = ({
  open,
  teamName,
  handleClose,
  fetchInstagramPages,
  fetchInstagramPagesForBusiness,
  handleChannelCreate,
  handleInstaConnectionValidation,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selectedPage, setSelectedPage] = React.useState(initialPageData);
  const [pageList, setPageList] = React.useState<
    instagramPagesListProps[] | null
  >(null);
  const [messengerSettingData, setMessengerSettingData] =
    React.useState(initialSettingData);

  const clearState = () => {
    setStep(1);
    setLoading(false);
    setSuccess(true);
    setErrorMessage('');
    setSelectedPage(initialPageData);
    setMessengerSettingData(initialSettingData);
  };

  const processInstagramResponseForBusiness = async (
    response: any
  ) => {
    setLoading(true);
    if (response.status !== 'unknown') {
      const data = await fetchInstagramPagesForBusiness(
        response.code,
        'instagram_messenger'
      );
      if (!!data) {
        data.forEach((item) => (item['name'] = item?.name || item?.username));
        setStep(2);
        setPageList(data);
        setLoading(false);
      } else {
        clearState();
        handleClose();
        setSuccess(false);
        setErrorMessage(
          t('Something went wrong while fetching pagelist. Try again later')
        );
      }
    } else {
      setSuccess(false);
      setErrorMessage(t("Couldn't Connect facebook"));
    }
  };

  const processInstagramResponse = async (
    response: facebookResponseDataProps
  ) => {
    setLoading(true);
    if (response.status !== 'unknown') {
      const data = await fetchInstagramPages(
        response.userID,
        response.accessToken,
        'instagram_messenger'
      );
      if (!!data) {
        data.forEach((item) => (item['name'] = item?.name || item?.username));
        setStep(2);
        setPageList(data);
        setLoading(false);
      } else {
        clearState();
        handleClose();
        setSuccess(false);
        setErrorMessage(
          t('Something went wrong while fetching pagelist. Try again later')
        );
      }
    } else {
      setSuccess(false);
      setErrorMessage(t("Couldn't Connect facebook"));
    }
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3: {
        setLoading(true);
        let iceBreaker = messengerSettingData.ice_breaker.filter(
          (item) => !!item.title && !!item.value
        );
        let instagramConnectionPayloadData = {
          title: messengerSettingData.title,
          primary_id: !!selectedPage && selectedPage.primary_id,
          name: !!selectedPage && selectedPage.name,
          username: !!selectedPage && selectedPage.username,
          avatar: !!selectedPage && selectedPage.avatar,
          secondary_id: !!selectedPage && selectedPage.secondary_id,
          ice_breaker: iceBreaker,
          is_published: messengerSettingData.is_published,
          is_connected: !!selectedPage && selectedPage.is_connected,
          connected_nlp_integration_id:
            messengerSettingData.connected_nlp_integration_id,
        };
        let connectionResponse = { status: 200, data: { error: '' } };
        await handleInstaConnectionValidation(
          'instagram_messenger',
          instagramConnectionPayloadData
        );
        if (connectionResponse.status === 200) {
          let res = await handleChannelCreate(
            'instagram_messenger',
            instagramConnectionPayloadData
          );
          if (res.status === 200) {
            setLoading(false);
            setLoading(false);
            handleClose();
            navigate(`/dashboard?channel_type=instagram_messenger`);
          } else {
            setLoading(false);
            setSuccess(false);
            setErrorMessage(
              res.status === 500
                ? t(
                    'Something went wrong while integrating the channel. Try again later.'
                  )
                : res.data.error
            );
          }
        } else {
          setLoading(false);
          setSuccess(false);
          setErrorMessage(
            connectionResponse.status === 500
              ? t(
                  'Something went wrong while integrating the channel. Try again later.'
                )
              : connectionResponse.data.error
          );
        }

        break;
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    const mandatoryKeys = ['title'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(messengerSettingData && messengerSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 1:
        return true;
      case 2:
        if (!!pageList && pageList.length) {
          if (selectedPage.primary_id.length) return false;
          else return true;
        }
        return true;
      case 3:
        return checkFinishButton();
      default:
        return false;
    }
  };

  return (
    <Modal
      open={open}
      teamName={teamName}
      title={
        isRtlLanguage ? t('Instagram Messenger to') : t('Instagram Messenger')
      }
      platfromType='instagram_messenger'
      closeOnExternalClick={false}
      handleClose={() => {
        clearState();
        handleClose();
      }}
      handleCancel={() => handleCancel(step)}
      disableConfirm={checkConfirmButtons()}
      handleConfirm={handleConfirm}
      hideCancel={step === 3 || step === 1}
      confirmText={step === 3 ? t('Finish') : t('Next')}
      customFooterContent={getModalCustomFooter(
        t('Confused about what to do?'),
        'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-instagram-messenger',
        t('Read Documentation')
      )}
    >
      <div className='p-6'>
        <Stepper steps={formSteps} currentStep={step} />
        <div className='mt-10'>
          {
            {
              1: (
                <Step1
                  loading={loading}
                  processInstagramResponse={processInstagramResponse}
                  processInstagramResponseForBusiness={processInstagramResponseForBusiness}
                />
              ),
              2: (
                <Step2
                  success={success}
                  pageList={pageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  platformType={'instagram_messenger'}
                />
              ),
              3: (
                <Step3
                  success={success}
                  errorMessage={errorMessage}
                  messengerSettingData={messengerSettingData}
                  handleChange={(
                    key: string,
                    value: string | string[] | boolean
                  ) => {
                    setMessengerSettingData({
                      ...messengerSettingData,
                      [key]: value,
                    });
                  }}
                />
              ),
            }[step]
          }
        </div>
      </div>
    </Modal>
  );
};
export default InstagramModal;
