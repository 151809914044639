import React from 'react';
import {
  LOCAL_UTILS,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  TicketPriorityLabel,
  usePolicy,
  useSLAConfig,
} from '../../export';

import useTranslation from 'components/customHooks/useTranslation';
import { IPolicy } from '../../interface';
import TimeInput from '../common/TimeInput';

const Policy: React.FC<{ policies: IPolicy[] }> = ({ policies = [] }) => {
  const { t } = useTranslation();
  const { handlePolicyDataOnChange } = useSLAConfig();
  const { inputFieldClass, defaultPolicies, tableHeaderClass } = usePolicy();
  const policyList = policies.length === 0 ? defaultPolicies : policies;

  return (
    <div className='overflow-x-auto'>
      <div className='mb-3'>
        <h5 className='mb-1 rtl:text-right text-textPrimary'>
          {t('Configure SLA Policy')}
        </h5>
        <span className='subtitle-medium rtl:text-right'>
          {t(
            'Set your response times, operational hours, and escalation preferences.'
          )}
        </span>
      </div>
      <div className='overflow-hidden border rounded-lg border-border-light'>
        <table className='min-w-full bg-white'>
          <thead>
            <tr className='w-full border-b'>
              <th className={`${tableHeaderClass} w-[15%] rtl:text-right`}>
                {t('Priority')}
              </th>
              <th className={`${tableHeaderClass} w-[28.33%] rtl:text-right`}>
                {t('First response time')}
              </th>
              <th className={`${tableHeaderClass} w-[28.33%] rtl:text-right`}>
                {t('Resolution time')}
              </th>
              <th className={`${tableHeaderClass} w-[28.33%] rtl:text-right`}>
                {t('Operational hours')}
              </th>
            </tr>
          </thead>
          <tbody>
            {policyList.map((policy: IPolicy, index: number) => (
              <tr
                key={index}
                className={`h-15 ${
                  index !== policyList.length - 1 ? 'border-b' : ''
                }`}
              >
                <td className='px-3 py-3.5 w-[15%]'>
                  <TicketPriorityLabel priority={policy.priority} />
                </td>
                <td className='px-3 py-3.5 w-[28.33%]'>
                  <TimeInput
                    initialDisplayValue={policy.first_response_time}
                    onChange={(time: any) => {
                      let FRT = LOCAL_UTILS.convertToTotalMinutes(time);
                      handlePolicyDataOnChange(
                        policy.priority,
                        'first_response_time',
                        FRT
                      );
                    }}
                  />
                </td>
                <td className='px-3 py-3.5 w-[28.33%]'>
                  <TimeInput
                    initialDisplayValue={policy.resolution_time}
                    onChange={(time: any) => {
                      let RT = LOCAL_UTILS.convertToTotalMinutes(time);
                      handlePolicyDataOnChange(
                        policy.priority,
                        'resolution_time',
                        RT
                      );
                    }}
                  />
                </td>
                <td className='px-3 py-3.5 w-[28.33%] rtl:text-right'>
                  <Select
                    value={
                      policy.should_consider_business_hour
                        ? 'Custom Business Hours'
                        : '24/7 Business Hours'
                    }
                    onValueChange={(value: string) => {
                      let isCustomeBusinessHours =
                        value === 'Custom Business Hours';
                      handlePolicyDataOnChange(
                        policy.priority,
                        'should_consider_business_hour',
                        isCustomeBusinessHours
                      );
                    }}
                  >
                    <SelectTrigger
                      className={`${inputFieldClass} h-8 rtl:text-right rtl:flex-row-reverse`}
                    >
                      <SelectValue
                        defaultValue={'Custom Business Hours'}
                        className='placeholder:subtitle-regular'
                        placeholder={t('Select operational hour')}
                      />
                    </SelectTrigger>
                    <SelectContent className='mt-1 bg-white'>
                      <SelectGroup className='text-sm text-textPrimary'>
                        <SelectItem
                          value='Custom Business Hours'
                          defaultChecked={true}
                          className='hover:bg-background-hover rtl:flex-row-reverse rtl:text-right'
                        >
                          {t('Custom Business Hours')}
                        </SelectItem>
                        <SelectItem
                          value='24/7 Business Hours'
                          className='hover:bg-background-hover rtl:text-right rtl:flex-row-reverse'
                        >
                          {t('24/7 Business Hours')}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Policy;
