import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';

interface Props {
  activeValue: string;
  options: { label: string; value: string }[];
  onChange: (value: any) => void;
}

const SingleSelectDropDown: React.FC<Props> = ({
  options,
  onChange,
  activeValue,
}) => {
  return (
    <Select
      value={activeValue}
      onValueChange={(value: string) => {
        onChange(value);
      }}
    >
      <SelectTrigger className='w-full  focus:ring-0 focus:ring-offset-0 '>
        <SelectValue className='text-gray-500' placeholder='Select an option' />
      </SelectTrigger>
      <SelectContent className='mt-1 bg-white z-50'>
        <SelectGroup>
          {options.map((option: { label: string; value: string }) => (
            <SelectItem value={option.value}>{option?.label}</SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default SingleSelectDropDown;
