import { WhatsappTemplateAnalyticsApiDataLimit } from 'pages/reporting/utility/content';
import { useDispatch, useSelector } from 'react-redux';

const useWhatsappMetrics = () => {
  const dispatch = useDispatch();
  const { selectedProject, platformData } = useSelector(
    (state: any) => state.dashboard
  );
  const {
    totalDataOfTemplateMetrics,
    isWhatsappBsbIntegration,
    whatsappTemplateMetrics,
    templateMetricsTableOffset,
  } = useSelector((state: any) => state.reporting);
  const {
    fetchWhatsappConversationMetrics: isFetchWhatsappConversationMetricsLoading,
    fetchTemplateMetrics: isFetchTemplateMetricsLoading,
  } = useSelector((state: any) => state.loading.effects.reporting);

  const updateSelectedPlatformData = (selectedData: any) => {
    dispatch.reporting.updateStateData({
      key: 'platformData',
      value: selectedData,
    });
  };

  const fetchWhatsappConversationMetrics = () => {
    dispatch.reporting.fetchWhatsappConversationMetrics({
      projectId: selectedProject?.id,
    });
  };

  const handleUpdateWhatsappAccountInfo = async (id: string) => {
    try {
      const res = await dispatch.integration.updateWhatsappAccountInfo(id);
      if (res?.success) {
        dispatch.reporting.updateStateData({
          key: 'whatsappAccountInfo',
          value: res?.dataSource,
        });
        return { success: res?.success, dataSource: res?.dataSource };
      } else {
        return { success: res?.success, dataSource: res?.dataSource };
      }
    } catch (error) {
      console.log(error);

      return { success: false };
    }
  };

  const updateIsWhatsappBsbIntegration = (value: boolean) => {
    dispatch.reporting.updateStateData({
      key: 'isWhatsappBsbIntegration',
      value: value,
    });
  };

  const updateSelectedReportingViewMode = (value: string) => {
    dispatch.reporting.updateStateData({
      key: 'selectedReportingViewMode',
      value: value,
    });
  };

  const fetchWhatsappTemplateMetrics = ({
    searchValue = '',
    statusFilter = [],
    typeFilter = [],
    offset,
  }: {
    searchValue?: string;
    statusFilter?: string[];
    typeFilter?: string[];
    offset: number;
  }) => {
    const payload: any = {
      limit: WhatsappTemplateAnalyticsApiDataLimit,
      offset: offset,
      search: searchValue,
    };

    // Conditionally add the status key if statusFilter has elements
    if (statusFilter.length > 0) {
      payload.status = `[${statusFilter}]`;
    }
    // Conditionally add the status key if statusFilter has elements
    if (typeFilter.length > 0) {
      payload.category = `[${typeFilter}]`;
    }

    // Dispatch the action with the constructed payload
    dispatch.reporting.fetchTemplateMetrics(payload);
  };

  const capitalizeWords = (str: string) => {
    return str
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char: string) => char.toUpperCase());
  };

  const updateSelectedWhatsappTemplateName = (value: string) => {
    dispatch.reporting.updateStateData({
      key: 'selectedWhatsappTemplateName',
      value: value,
    });
  };

  return {
    selectedProject,
    platformData,
    capitalizeWords,
    templateMetricsTableOffset,
    updateSelectedReportingViewMode,
    whatsappTemplateMetrics,
    isWhatsappBsbIntegration,
    updateSelectedPlatformData,
    totalDataOfTemplateMetrics,
    isFetchTemplateMetricsLoading,
    fetchWhatsappTemplateMetrics,
    updateIsWhatsappBsbIntegration,
    handleUpdateWhatsappAccountInfo,
    fetchWhatsappConversationMetrics,
    updateSelectedWhatsappTemplateName,
    isFetchWhatsappConversationMetricsLoading,
  };
};

export default useWhatsappMetrics;
