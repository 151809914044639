import { Router } from '@reach/router';
import { Spinner } from 'evergreen-ui';
import { DashboardLayout, Protected } from '../components';
import APIFuse from '../old/pages/api-fuse/APIFuse';
import Builder from '../old/pages/builder/Builder';
import DataLab from '../old/pages/data-lab/DataLab';
import InvitationAccept from '../pages/auth/InvitationAcceptIndex';
import Login from '../pages/auth/LoginIndex';
import DashboardV2 from '../pages/dashboardV2';
// import DataLabDetails from '../old/pages/data-lab-details/DataLabDetails';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import DataLabCreate from '../old/pages/data-lab/DataLabCreate';
// import DataLabsKeyCreator from '../old/pages/data-lab/DataLabsKeyCreator';
import DataLabForm from '../old/pages/data-lab-form/DataLabForm';
import ProfileSettings from '../old/pages/settings/pages/ProfileSettings';
import TeamInfoSettings from '../old/pages/settings/pages/TeamInfoSettings';
// import TeamUserSettings from '../old/pages/settings/pages/TeamUserSettings';
import TeamGroupSettings from '../old/pages/settings/pages/TeamGroupSettings';
// import BillingPlanSettings from '../old/pages/settings/pages/BillingPlanSettings';
import Settings from '../old/pages/settings/Settings';
import PasswordVerificationSuccess from '../pages/auth/components/PasswordVerficationSuccess';
import ForgetPassword from '../pages/auth/ForgetPasswordIndex';
import ReSendAccountVerificationLink from '../pages/auth/ResendAccountVerificationMailIndex';
import VerifyAccount from '../pages/auth/VerifyAccountIndex';
import VerifyPassword from '../pages/auth/VerifyPasswordIndex';
// import Register from '../pages/auth/RegisterIndex';
import RegisterV2 from '../pages/auth/RegisterIndexV2';

import Customer from '../old/pages/audience/Audience';
import PendingProjectSettings from '../old/pages/settings/pages/PendingProjectSettings';
import ProjectPermission from '../old/pages/settings/pages/ProjectPerimission';
// import ChannelSettings from '../old/pages/settings/pages/ChannelSettings';  //ChannelSettings will be deprecated soon
import CRM from '../old/pages/crm/CRM';
import Inbox from '../pages/inbox/index';
import Reporting from '../pages/reporting/Reporting';
import ReportingV2 from '../pages/reportingV2/Reporting';
// import Onboarding from '../pages/onboarding/Onboarding';
import BusinessHourSettings from 'old/pages/settings/pages/BusinessHourSettings';
import NotificationSettings from 'old/pages/settings/pages/NotificationSettings';
import ShopifyBillingPublicComponent from 'old/pages/settings/pages/ShopifyBillingPublicComponent';
import AppSumoRegister from 'pages/auth/AppSumoRegisterIndex';
import NewBilling from 'pages/billing/Billing';
import UpgradeBilling from 'pages/billing/UpgradeBilling';
import ShopifyConnectPage from 'pages/marketPlace/ShopifyConnectPage';
import Playground from '../library/Playground';
import ExpoRegisterIndex from '../pages/auth/ExpoRegisterIndex';
import Integration from '../pages/integration/index';
import ShopifyCallbackURL from '../pages/marketPlace/ShopifyCallbackURL';
import OnboardingV2 from '../pages/onboardingV2/index';
import TeamUserSettings from '../pages/settings/teamUserSettings';
// import RouteMiddleware from '../components/higherOrderComponents/RouteMiddleWare';
import NotFoundPage from 'components/globalComponents/NotFound';
import ChatBotSettings from 'old/pages/settings/pages/ChatBotSettings';
import DashboardPreferences from 'old/pages/settings/pages/DashboardPreferences';
import TagmanageMent from 'old/pages/settings/pages/TagManagement';
import VerifyOTP from 'pages/auth/VerifyOtp';
import DataLabFormBuilder from 'pages/datalab/datalabFormBuilder';
import DataLabTable from 'pages/datalab/datalabList';
import ZidIntegration from 'pages/integration/components/availableIntegrations/zid';
import InboxPreferences from 'pages/settings/inboxPreferences';
import SavedReplies from 'pages/settings/savedReplies';
import SLASettings from 'pages/settings/slaSettings';
import SLAConfiguration from 'pages/settings/slaSettings/component/slaCreationAndConfiguration/SLAConfiguration';
import TicketConfigurationSettings from 'pages/settings/ticketConfigurationSettings';
import WebhookSettings from 'pages/settings/webhooks';
import WorkflowAutomation from '../pages/automationWorkflow/index';
import Broadcast from '../pages/broadcast/index';

const Routes = () => {
  return (
    <PersistGate loading={<Spinner />} persistor={getPersistor()}>
      {/* <RouteMiddleware> */}
      <Router>
        <Login path='/' />
        <Playground path='/playground/61c564644616041104f195307d4e87a8' />
        <RegisterV2 path='/register' />
        <AppSumoRegister path='/register-appsumo' />
        <ExpoRegisterIndex path='/register-expo' />
        <ForgetPassword path='/reset' />
        <VerifyPassword path='/reset-verify' />
        <VerifyOTP path='/verify-otp' />
        <PasswordVerificationSuccess path='/reset-success' />
        <ReSendAccountVerificationLink path='/register-resend-mail' />
        <VerifyAccount path='/verify-registration' />
        <InvitationAccept path='/invitation' />
        <Protected path='onboarding'>
          <OnboardingV2 path='/' />
          <NotFoundPage default />
        </Protected>
        <Protected path='shopify-connect'>
          <ShopifyConnectPage path='/' />
        </Protected>
        <Protected path='zid-connect'>
          <ZidIntegration path='/' />
        </Protected>
        <Protected path='dashboard'>
          <DashboardLayout path='/'>
            <DashboardV2 path='/' />
            <NotFoundPage default />
          </DashboardLayout>
        </Protected>
        <Protected path='projects'>
          <DashboardLayout path='/'>
            <Builder path=':projectId/automation' />
            <APIFuse path=':projectId/api' />
            <Integration path=':projectId/integrations/:slug' />
            <DataLab path=':projectId/data-lab' />
            <DataLabCreate path=':projectId/data-lab/create' />
            <DataLabCreate path=':projectId/data-lab/edit-info/:labId' />
            <DataLabFormBuilder path=':projectId/data-lab/edit/:labId' />
            {/* <DataLabDetails path=':projectId/data-lab/:labId' /> */}
            <DataLabTable path=':projectId/data-lab/:labId' />
            <Reporting path=':projectId/reporting' />
            <ReportingV2 path=':projectId/reportingV2' />
            <Customer path=':projectId/customer' />
            <CRM path=':projectId/chat' />
            <Inbox path=':projectId/inbox' />
            <Broadcast path=':projectId/broadcast' />
            <WorkflowAutomation path=':projectId/workflow-automation' />
            <NotFoundPage default />
          </DashboardLayout>
        </Protected>
        <Protected path='settings'>
          <DashboardLayout path='/'>
            <Settings path='/'>
              <ProfileSettings path='profile' />
              <TeamInfoSettings path='team-info' />
              <TeamUserSettings path='team-user' />
              <TeamGroupSettings path='team-group' />
              {/* <BillingPlanSettings path='billing' /> */}
              <NewBilling path='billing' />
              <UpgradeBilling path='billing/upgrade' />
              <PendingProjectSettings path='permission/pending' />
              {/* <ChannelSettings path='channel' />
                //ChannelSettings will be deprecated soon */}
              <ProjectPermission path='permission/project' />
              {/* <MarketplaceSettings path='marketplace' /> */}
              <NotificationSettings path='notification' />
              <TicketConfigurationSettings path='ticket-config' />
              <InboxPreferences path='inbox-preferences' />
              <SLASettings path='sla-settings' />
              <SLAConfiguration path='sla-configuration' />
              <BusinessHourSettings path='business-hour' />
              <ChatBotSettings path='chatbot-settings' />
              <DashboardPreferences path='dashboard-preferences' />
              <TagmanageMent path='tags' />
              <WebhookSettings path='webhook' />
              {/* <CannedResponse path='saved-replies' /> */}
              <SavedReplies path='saved-replies' />
              <NotFoundPage default />
            </Settings>
          </DashboardLayout>
        </Protected>
        <Protected path='integrations'>
          <DashboardLayout path='/'>
            <Integration path='/:slug' />
          </DashboardLayout>
        </Protected>
        <DataLabForm path='/public/form/:labId' />
        <ShopifyCallbackURL path='/public/shopify-callback-url' />
        <ShopifyBillingPublicComponent path='/public/confirm-shopify-subscription/projects/:teamId/signature/:signature' />
        <NotFoundPage default />
      </Router>
      {/* </RouteMiddleware> */}
    </PersistGate>
  );
};

export default Routes;
