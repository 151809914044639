import { CheckIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'libraryV2/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';
import useCsatReporting from 'pages/reportingV2/hooks/useCsatReporting';
import { Avatar } from '@radix-ui/react-avatar';
import { AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import { getUserNameInitials } from 'pages/reportingV2/utility/utils';

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  children: any;
  filterScope: 'ratings' | 'agents';
}

interface ISelectionDataOptions {
  label: string;
  value: string | number;
  avatar?: string;
  isSelected?: boolean;
}

export function CsatTableFilterMenu<TData, TValue>({
  column,
  title,
  filterScope,
  children,
}: DataTableFacetedFilterProps<TData, TValue>) {
  const { csatRatingSelectionOptions, csatAgentSelectionOptions } =
    useCsatReporting();

  const options: ISelectionDataOptions[] =
    filterScope === 'ratings'
      ? csatRatingSelectionOptions
      : csatAgentSelectionOptions;

  const selectedValues = new Set(column?.getFilterValue() as string[]);

  const handleOnSelectOption = (option: ISelectionDataOptions) => {
    if (option.isSelected) {
      selectedValues.delete(option.value + '');
    } else {
      selectedValues.add(option.value + '');
    }
    const filterValues = Array.from(selectedValues);
    column?.setFilterValue(filterValues.length ? filterValues : undefined);
  };
  return (
    <Popover>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className='w-fit p-0 bg-white' align='start' side='top'>
        <Command>
          <CommandInput shouldHide={options.length <= 5} placeholder={title} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup className='max-h-[200px] overflow-auto'>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value + '');
                return (
                  <CommandItem
                    className='flex items-center gap-1'
                    key={option.value}
                    onSelect={() =>
                      handleOnSelectOption({
                        ...option,
                        isSelected,
                      } as ISelectionDataOptions)
                    }
                  >
                    <div
                      className={cn(
                        'mr-1 flex h-4 w-4 items-center justify-center rounded-md border border-textPrimary',
                        isSelected
                          ? 'bg-primary border-0 text-primary-foreground'
                          : 'opacity-50'
                      )}
                    >
                      <CheckIcon
                        stroke='2'
                        className={cn('h-4 w-4 text-white', {
                          hidden: !isSelected,
                        })}
                      />
                    </div>

                    {Object.hasOwn(option, 'avatar') ? (
                      <Avatar className='h-5 w-5 flex-shrink-0'>
                        <AvatarImage
                          className='rounded-full'
                          src={option?.avatar}
                        />

                        <AvatarFallback className='border-zinc-300 border font-medium opacity-100 text-xss p-[2px] bg-zinc-100'>
                          {getUserNameInitials(option.label)}
                        </AvatarFallback>
                      </Avatar>
                    ) : null}

                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className='justify-center text-center'
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
