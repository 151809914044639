import useTranslation from 'components/customHooks/useTranslation';
import { FC } from 'react';
import {
  AgentSelection,
  Button,
  CircleAlertIcon,
  LOCAL_UTILS,
  PrioritySelection,
  TargetSelection,
  TicketPriorityLabel,
  TimeInput,
  TrashIcon,
  useRemindersUtils,
} from '../../export';
import { IReminder } from '../../interface';

interface Props {
  index: number;
  lastIndex: number;
  reminder: IReminder;
  onDelete: () => void;
  handleRemindersDataOnChange: (
    key: keyof IReminder,
    value: string | number | Array<string | number>
  ) => void;
}

const ReminderRule: FC<Props> = ({
  index,
  reminder,
  onDelete,
  handleRemindersDataOnChange,
}) => {
  const { t } = useTranslation();
  const { checkReminderRuleCondition, checkForDuplicateReminder } =
    useRemindersUtils();
  let duplicationResult = checkForDuplicateReminder(index);
  let violatedConditions = checkReminderRuleCondition(index);
  return (
    <div className='mb-4'>
      <div className='flex justify-between mb-2'>
        <div className='flex flex-wrap items-center space-y-3'>
          <span className='mt-3 ltr:mr-2 rtl:ml-2'>
            {t('If tickets priority is/are')}
          </span>
          <PrioritySelection
            priorityList={reminder.priority}
            handlePriorityListUpdate={(updatedList) =>
              handleRemindersDataOnChange('priority', updatedList)
            }
          />
          <span className='ltr:mr-2 rtl:ml-2'>{t('and')}</span>
          <TargetSelection
            target={reminder.target}
            handleTargetUpdate={(selectedTarget) =>
              handleRemindersDataOnChange('target', selectedTarget)
            }
          />
          <span className='ltr:mr-2 rtl:ml-2'>{t('approaches in')}</span>
          <div className='ltr:mr-2 rtl:ml-2 w-52'>
            <TimeInput
              initialDisplayValue={reminder.time || ''}
              onChange={(time: any) => {
                let FRT = LOCAL_UTILS.convertToTotalMinutes(time);
                handleRemindersDataOnChange('time', FRT);
              }}
            />
          </div>
          <span className='ltr:mr-2 rtl:ml-2'>
            {t('then send reminder to')}
          </span>
          <div className='w-96'>
            <AgentSelection
              selectedAgentsList={reminder.persons}
              handleAgentListUpdate={(updatedList) =>
                handleRemindersDataOnChange('persons', updatedList)
              }
            />
          </div>
        </div>
        <Button
          variant='outline'
          onClick={onDelete}
          className='px-2.5 py-2 mt-3'
        >
          <TrashIcon className='w-4 h-4' />
        </Button>
      </div>

      {duplicationResult && (
        <div className='flex flex-wrap items-center bg-white rounded-lg p-2.5 mt-4 text-textSecondary text-xs font-medium'>
          <CircleAlertIcon className='w-4 h-4 ltr:mr-1 rtl:ml-1 error ' />
          <span className='ltr:mr-1 rtl:ml-1'>
            {t("Reminder can't be set for")}
          </span>
          {duplicationResult?.priority.map((result: number) => (
            <span className='ltr:mr-1 rtl:ml-1'>
              <TicketPriorityLabel priority={result} key={index} />
            </span>
          ))}
          {}
          <span>
            {t('priority tickets. You have already set')}{' '}
            <b>
              {duplicationResult?.target === 'resolution_time'
                ? t(`Resolution time `)
                : t(`First response time `)}{' '}
            </b>
            {t('for it.')}
          </span>
        </div>
      )}
      {violatedConditions && (
        <div className='flex flex-wrap items-center bg-white rounded-lg p-2.5 mt-4 text-textSecondary text-xs font-medium'>
          <CircleAlertIcon className='w-4 h-4 ltr:mr-1 rtl:ml-1 error ' />
          <span className='ltr:mr-1 rtl:ml-1'>
            {t("Reminders can't be set for")}
          </span>
          {violatedConditions?.priority.map((result: number, index) => (
            <span className='ltr:mr-1 rtl:ml-1'>
              <TicketPriorityLabel priority={result} key={index} />
            </span>
          ))}
          {}
          <span>
            {t(
              'priority tickets. You have set the approach time higher than or equal to the'
            )}{' '}
            <b>
              {violatedConditions?.target === 'resolution_time'
                ? t(` Resolution time. `)
                : t(` First response time. `)}
            </b>
          </span>
        </div>
      )}
    </div>
  );
};

export default ReminderRule;
