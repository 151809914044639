import React, { useEffect } from 'react';
import config, { status as Environemt } from './config';


const appId = config.misc.facebookAppId();

const FacebookLoginForBusiness = ({
  handleFacebookToken,
  platformType,
  buttonText = 'Continue with Facebook',
  buttonClassName = 'facebook-button-connect',
}) => {
  const liveConfiguration = {
    'facebookBusiness': {
      config_id: '1249814539687899',
      response_type: 'code',
      override_default_response_type: true,
    },
    'instagramBusiness': {
      config_id: '8615571125187462',
      response_type: 'code',
      override_default_response_type: true,
    },
    'facebookUser': {
      config_id: '544042875149799',
    },
    'instagramUser': {
      config_id: '1066710151592031',
    },
  };

  const stageConfiguration = {
    'facebookBusiness': {
      config_id: '1097612888434246',
    },
    'instagramBusiness': {
      config_id: '1480201942687697',
    },
    'facebookUser': {
      config_id: '1097612888434246',
    },
    'instagramUser': {
      config_id: '1480201942687697',
    },
  };

  const getConfiguration = () => {
    switch (Environemt) {
      case 'staging':
      case 'dev':
        return stageConfiguration[platformType];
      case 'production':
        return liveConfiguration[platformType];
      default:
        return null;
    }
  };

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: appId,
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v17.0', //Graph API version
      });
    };
  };
  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  useEffect(() => {
    setFbAsyncInit();
    loadSdkAsynchronously();
  });

  const launchFacebookLogin = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const res = response.authResponse;
          try {
            handleFacebookToken(res);
          } catch (err) {
            console.log(err.response);
          }
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      getConfiguration()
    );
  };
  return (
    <>
      <div className='flex items-center justify-center'>
        <button
          className={buttonClassName}
          onClick={() => launchFacebookLogin()}
        >
          {buttonText}
        </button>
      </div>
    </>
  );
};

export default FacebookLoginForBusiness;
