import { isValidEmail } from 'utilities/utils';
import { Badge } from 'libraryV2/ui/badge';
import { X as XIcon } from 'lucide-react';
import { FC, useMemo, useRef } from 'react';
import { InvitationsType } from '../../interface';
import useUserManagement from '../../hooks/useUserManagement';
import useInviteTeamMembers from '../../hooks/useInviteTeamMembers';

interface MultipleEmailInputProps {
  handleEmailsChange: (emails: string[]) => void;
  handleEmailInputChange: (e: any) => void;
  setFormState: (state: InvitationsType[]) => void;
  emails: string[];
  emailInputValue: string;
  setEmailInputValue: (email: string) => void;
  
}

const MultipleEmailInput: FC<MultipleEmailInputProps> = ({
  handleEmailsChange,
  handleEmailInputChange,
  setFormState,
  emails,
  emailInputValue,
  setEmailInputValue,
}) => {
  const { agentAccessList } = useUserManagement();
  const {
    duplicateEmails,
    invalidEmails,
    existingDuplicateEmails,
    setInvalidEmails,
    setDuplicateEmails,
    setExistingDuplicateEmails,
    validateForm
  } = useInviteTeamMembers();

  const emailInputRef = useRef<HTMLInputElement>(null);

  const agentEmailsSet = useMemo(() => {
    return new Set(agentAccessList.map((agent: any) => agent.admin.email));
  }, [agentAccessList]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      addEmails(emailInputValue);
    }
  };

  const addEmails = (input: string) => {
    const newEmails = input
      .split(/[\s,]+/)
      .map((email) => email.trim())
      .filter((email) => email.length > 0);

    const updatedEmails = [...emails, ...newEmails];
    handleEmailsChange(updatedEmails);
    const inviteData = updatedEmails.map((email) => {
      return {
        email,
        role_id: 0,
      };
    });

    setFormState(inviteData);

    const newInvalidEmails = newEmails.filter((email) => !isValidEmail(email));
    const newDuplicateEmails = newEmails.filter(
      (email) => emails.includes(email) && !duplicateEmails.includes(email)
    );
    const newExistingDuplicateEmails = newEmails.filter((email) =>
      agentEmailsSet.has(email)
    );

    setInvalidEmails((prevInvalid) => [...prevInvalid, ...newInvalidEmails]);
    setDuplicateEmails((prevDuplicates) => [
      ...prevDuplicates,
      ...newDuplicateEmails,
    ]);
    setExistingDuplicateEmails((prevDuplicates) => [
      ...prevDuplicates,
      ...newExistingDuplicateEmails,
    ]);
    setEmailInputValue('');
    validateForm();
  };

  const removeEmail = (index: number) => {
    const emailToRemove = emails[index];
    const updatedEmails = emails.filter((_, i) => i !== index);

    handleEmailsChange(updatedEmails);
    const inviteData = updatedEmails.map((email) => {
      return {
        email,
        role_id: 0,
      };
    });

    setFormState(inviteData);

    if (invalidEmails.includes(emailToRemove)) {
      setInvalidEmails(
        invalidEmails.filter((email) => email !== emailToRemove)
      );
    }
    if (duplicateEmails.includes(emailToRemove)) {
      setDuplicateEmails(
        duplicateEmails.filter((email) => email !== emailToRemove)
      );
    }
    if (existingDuplicateEmails.includes(emailToRemove)) {
      setExistingDuplicateEmails(
        existingDuplicateEmails.filter((email) => email !== emailToRemove)
      );
    }
    validateForm()
  };

  const getErrorMessages = () => {
    const messages = [];
    if (invalidEmails.length > 0) {
      messages.push('Make sure the email you entered is correct.');
    }
    if (duplicateEmails.length > 0) {
      messages.push('Some emails already exist in your team.');
    }
    if (existingDuplicateEmails.length > 0) {
      messages.push('This user already exists in your team.');
    }
    return messages;
  };

  const focusInput = () => {
    if(emailInputRef.current){
      emailInputRef.current.focus();
    }
  }

  const handleOnBlur = () => {
    addEmails(emailInputValue);
    validateForm();
  };
  return (
    <>
      <div className='w-full min-h-[80px] rounded-lg shadow-sm col-span-3 border border-[#DFDFE2]'
        onClick={focusInput}
      >
        <div className='flex flex-wrap items-start gap-2'>
          {emails.map((email, index) => (
            <span key={index} className='flex items-center px-2 py-1'>
              <Badge
                variant={
                  invalidEmails.includes(email) ||
                  duplicateEmails.includes(email) ||
                  existingDuplicateEmails.includes(email)
                    ? 'outline'
                    : 'ghost'
                }
                className={`px-1 font-medium flex items-center bg-[#F4F4F5] hover:bg-[#F4F4F5] ${
                  invalidEmails.includes(email) ||
                  duplicateEmails.includes(email) ||
                  existingDuplicateEmails.includes(email)
                    ? 'border border-[#FF5757] text-red-700'
                    : ' text-[#18181B]'
                } shadow-none`}
              >
                {email}
                <XIcon
                  className='w-3 h-3 ml-1 cursor-pointer'
                  onClick={() => removeEmail(index)}
                />
              </Badge>
            </span>
          ))}
        </div>
        <input
          type='text'
          value={emailInputValue}
          ref={emailInputRef}
          onChange={handleEmailInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          placeholder={`${
            emails.length > 0 ? '' : 'Enter team members email here'
          }`}
          className='w-full flex-1 p-2 text-sm border-none focus:ring-0 focus:outline-none rounded-lg focus:text-[#71717A]'
        />
      </div>
      {getErrorMessages().length > 0 && (
        <div className='mt-2 space-y-1 space-x-1'>
          {getErrorMessages().map((message, index) => (
            <p key={index} className='text-sm text-red-600'>
              {message}
            </p>
          ))}
        </div>
      )}
    </>
  );
};

export default MultipleEmailInput;
