import React from 'react';

import { Button } from 'libraryV2/ui/button';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { EllipsisIcon } from 'lucide-react';
import {
  ConversationBlockTextDataInterface,
  CustomerInfoInterface,
  CustomerInfoType,
  conversationStatusType,
} from 'pages/inbox/inboxInterface';
import { getLangDirection, isHTML } from 'pages/inbox/utils/functions';
import Linkify from 'react-linkify';
import Avatar from '../components/conversation/Avatar';
import MessageStatus from '../components/conversation/MessageStatus';
import Time from '../components/conversation/Time';
import DateBar from './DateBar';
import EmailDropdown from './EmailDropdown';

interface Props {
  blockData: ConversationBlockTextDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  enableDateBar: boolean;
  report?: any;
  customerInfo: CustomerInfoType;
  subject: string;
  meta: CustomerInfoInterface;
  conversationId: string;
}

const EmailBlock: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  enableDateBar,
  report,
  customerInfo,
  subject,
  meta,
  conversationId,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const iframeRef = React.useRef(null);
  const repliesIframeRef = React.useRef(null);

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
        return 'text-gray-900';
      case 'gmail':
        return 'text-[#3B82F6]';
      default:
        return 'text-green-500';
    }
  };

  const getEmailDetailsInfo = () => {
    const isCustomer = source === 'customer';
    const senderName = isCustomer
      ? customerInfo?.full_name
      : meta?.platform?.name;
    const senderEmail = isCustomer
      ? customerInfo?.primary_id
      : meta?.platform?.primary_id;
    const receiverName = isCustomer
      ? meta?.platform?.name
      : customerInfo?.full_name;
    const receiverEmail = isCustomer
      ? meta?.platform?.primary_id
      : customerInfo?.primary_id;

    return { senderName, senderEmail, receiverName, receiverEmail };
  };

  const handleHeight = (isReplyFrame = false) => {
    const iframe: any = isReplyFrame
      ? repliesIframeRef.current
      : iframeRef.current;
    const iframeDocument =
      iframe?.contentDocument || iframe?.contentWindow?.document;
    const height = iframeDocument?.body?.scrollHeight;
    iframe.style.height = `${height + 20}px`;
  };

  const renderEmailTextBody = () => {
    const currentMailText = blockData?.payload || '';
    const replyData = blockData?.text ?? '';

    if (!currentMailText) {
      return (
        <iframe
          ref={iframeRef}
          onLoad={() => handleHeight()}
          title='HTML content'
          srcDoc={replyData}
          style={{
            width: '100%',
            border: 'none',
          }}
        />
      );
    }
    return (
      <Collapsible open={isOpen} onOpenChange={setIsOpen} className='space-y-2'>
        <div className='flex items-center justify-between'>
          <CollapsibleTrigger asChild>
            <div className='w-full'>
              <iframe
                ref={iframeRef}
                onLoad={() => handleHeight()}
                title='HTML content'
                srcDoc={currentMailText}
                style={{
                  width: '100%',
                  borderRadius: '0.75rem',
                  border: '1px solid #e5e7eb',
                }}
              />
              <TooltipProviderCustomised
                content={
                  isOpen ? 'Hide expanded content' : 'Show trimmed content.'
                }
              >
                <Button
                  variant='ghost'
                  className='w-fit mt-2 h-0 rounded-full px-2 bg-zinc-200 text-zinc-500'
                >
                  <EllipsisIcon className='h-6 w-6 text-zinc-500' />
                </Button>
              </TooltipProviderCustomised>
            </div>
          </CollapsibleTrigger>
        </div>
        <CollapsibleContent className='space-y-2'>
          <iframe
            ref={repliesIframeRef}
            onLoad={() => handleHeight(true)}
            title='Email Reply content'
            srcDoc={replyData}
            style={{
              width: '100%',
              border: 'none',
              backgroundColor: '#f9fafb',
              borderRadius: '8px',
              padding: '8px',
            }}
          />
        </CollapsibleContent>
      </Collapsible>
    );
  };

  const renderText = () => {
    const textIsHTML = isHTML(blockData?.text || '');
    return (
      <>
        {blockData?.success ? (
          <>
            {['admin', 'bot'].includes(source) ? (
              <p
                data-testid='text-message-block'
                className={`w-auto h-auto ${
                  !!blockData?.success ? 'text-gray-900' : 'text-gray-500'
                } sm:text-sm break-words ${
                  !textIsHTML ? 'whitespace-pre-wrap' : ''
                }`}
              >
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      target='_blank'
                      className='text-link'
                      href={decoratedHref}
                      key={key}
                      rel='noreferrer noopener'
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  {textIsHTML ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: blockData?.text }}
                    />
                  ) : (
                    blockData?.text
                  )}
                </Linkify>
              </p>
            ) : (
              renderEmailTextBody()
            )}
          </>
        ) : (
          <p className='w-auto h-auto text-gray-500 break-words whitespace-pre-wrap sm:text-sm'>
            {blockData?.error}
          </p>
        )}
      </>
    );
  };

  return (
    <>
      <div
        dir={getLangDirection(blockData?.text)}
        className={`relative group ${
          getLangDirection(blockData?.text) === 'rtl' &&
          'flex-row-reverse justify-end'
        } flex w-full h-auto hover:bg-gray-100 pt-0.5 pb-1.5`}
      >
        <Avatar
          avatar={avatar}
          isRTL={getLangDirection(blockData?.text) === 'rtl'}
        />
        <div className='w-[60%]'>
          <div className='flex gap-2 items-center'>
            <div
              className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
            >
              <div className='p-0 hover:underline'>{name}</div>
            </div>
            <Time time={time} isMerged={false} />
            {['admin', 'bot', 'gmail'].includes(source) && (
              <MessageStatus
                success={isSent}
                isMerged={false}
                report={report}
              />
            )}
            <EmailDropdown
              senderName={getEmailDetailsInfo().senderName}
              receiverName={getEmailDetailsInfo().receiverName}
              senderEmail={getEmailDetailsInfo().senderEmail}
              receiverEmail={getEmailDetailsInfo().receiverEmail}
              date={time}
              subject={subject}
              emailInfo={!!blockData?.email_data ? blockData?.email_data : null}
              conversationId={conversationId}
            />
          </div>
          {renderText()}
        </div>
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default EmailBlock;
