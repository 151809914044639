import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useRoleDropdown = () => {
  const [userRoleId, setUserRoleId] = useState<number>(0);

  const dispatch = useDispatch();
  const userManagementStateData = useSelector(
    (state: any) => state.settingsUserManagementConfig
  );
  const { agentAccessList } = userManagementStateData;
  const updateUserRole = async (payload: any): Promise<any> => {
    const response = await dispatch.settingsUserManagementConfig.changeUserRole(
      payload
    );
    if (response.status) {
      updateLocalAgentAccessList(response.data.dataSource);
    }
  };

  const updateLocalAgentAccessList = (updatedAgent: any) => {
    const { project: _, ...newUpdatedAgent } = updatedAgent;
    const updatedAgentAccessList = agentAccessList.map((agent: any) => {
      if (agent?.admin.id === updatedAgent?.admin.id) {
        return newUpdatedAgent;
      }
      return agent;
    });

    dispatch.settingsUserManagementConfig.updateAgentAccessList(
      updatedAgentAccessList
    );
  };
  return {
    userRoleId,
    setUserRoleId,
    updateUserRole,
    updateLocalAgentAccessList,
  };
};

export default useRoleDropdown;
