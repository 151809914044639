import React from 'react';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import useWhatsappTemplateData, { IWhatsappCarouselCard } from 'pages/integration/hooks/useWhatsappTemplateData';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  actionType: WhatsappTemplateActionType;
  activeCard?: IWhatsappCarouselCard;
}

const WhatsappTemplateSampleVariables: React.FC<Props> = ({
  actionType,
  activeCard,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateTemplateBodyVariables,
    updateSingleCarouselCardData
  } = useWhatsappTemplateData();
  const isAuthenticationTemplate =
    whatsappTemplateState.category === 'AUTHENTICATION';
  const templateVariables = !!activeCard
    ? activeCard.bodyVariables || {}
    : whatsappTemplateState.bodyVariables;
  const handleVariableValueChange = (value: string, id: string) => {
    !!activeCard
    ? updateSingleCarouselCardData({
      ...activeCard,
      bodyVariables: { ...templateVariables, [id]: value },
    })
    : updateTemplateBodyVariables({ ...templateVariables, [id]: value })
  };

  const isTemplateActionView = actionType === 'VIEW';
  if (isAuthenticationTemplate) {
    return null;
  }
  return (
    <div className='mt-2'>
      <div className='mb-4'>
        <p className='text-gray-700 font-medium'>
          {t('Sample Variable Value')}
          <span className='text-red-500'>*</span>
        </p>
      </div>
      <div className='flex flex-col gap-3'>
        {Object.keys(templateVariables || {}).map((singleVariable, index) => (
          <div
            key={index + 1 + singleVariable}
            className='flex gap-2 w-full items-center'
          >
            <div className='text-indigo-400'>{`{{${singleVariable}}}`}</div>

            <input
              maxLength={60}
              type='text'
              disabled={isTemplateActionView}
              placeholder={t(`Type value for {{${singleVariable}}}`)}
              value={templateVariables[singleVariable]}
              onChange={(ev) =>
                handleVariableValueChange(ev.target.value, singleVariable)
              }
              name='footer-value'
              className={`border flex-grow border-gray-300 py-2 focus:border-none focus:ring-2 focus:ring-green-500 text-sm rounded-md
                  ${
                    isTemplateActionView
                      ? 'bg-gray-200 cursor-not-allowed'
                      : 'bg-white'
                  }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatsappTemplateSampleVariables;
