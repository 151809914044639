import React, { FC, useEffect, useState } from 'react';
import { Alert, AlertDescription } from 'libraryV2/ui/alert';
import { Info } from 'lucide-react';
import { UserManagementTable } from './component/userManagementTable/UserManagementTable';
import { createDymanicColumns } from './component/userManagementTable/DynamicColumn';
import { ISelectedProject, IUserManagement } from './interface';
import useUserManagement from './hooks/useUserManagement';
import { subscriptionDetailsType } from 'index';
const TeamUserSettings = () => {
  const [transformedData, setTransformedData] = useState<IUserManagement[]>([]);
  const {
    getAgentRoleList,
    getAgentAccessList,
    agentAccessList,
    subscriptionDetails,
    getSubscriptionDetails,
    selectedProject,
    clearUserManagementData,
    getTotalTeamMemberCount
  } = useUserManagement();

  useEffect(() => {
    initializeMethods();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject?.id]);

  useEffect(() => {
    setTransformedData(tableDataMapper());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentAccessList]);
  // This is a hacky solution to override old component CSS.
  // It directly modifies the padding of the '.layout__content' element.
  // When we move the settings to the new design, we will update it accordingly.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  React.useEffect(() => {
    const layoutContent: any = document.querySelector('.layout__content');
    if (layoutContent) {
      layoutContent.style.padding = '0';
    }
  }, []);

  const initializeMethods = () => {
    const fetchAgentRoleList = async () => await getAgentRoleList();
    const fetchAgentAccessList = async () => await getAgentAccessList();
    const fetchSubscriptionDetails = async () => await getSubscriptionDetails();
    clearUserManagementData();
    fetchAgentAccessList();
    fetchAgentRoleList();
    fetchSubscriptionDetails();
    setTransformedData(tableDataMapper());
  };

  function tableDataMapper(): IUserManagement[] {
    return agentAccessList.map((user: any) => ({
      id: user.admin.id,
      agents: user.admin.full_name,
      last_log_in: user.admin.last_online_time,
      user_role: user.role,
      role_name: user.role.name,
      email: user.admin.email,
      avatar: user.admin.avatar ? user.admin.avatar : '',
      is_confirmed: user.is_confirmed,
    }));
  }
  const columns = createDymanicColumns(selectedProject);

  return (
    <>
      <UserManagementHeader />
      <div className=' px-6'>
        <SubscriptonInfoAlert
          getTotalTeamMemberCount={getTotalTeamMemberCount}
          subscriptionDetails={subscriptionDetails}
          selectedProject={selectedProject}
        />
        {/* table */}
        <UserManagementTable
          columns={columns}
          data={transformedData}
          selectedProject={selectedProject}
        />
      </div>
    </>
  );
};

const UserManagementHeader = () => {
  return (
    <>
      <div className='px-4 py-2.5 mb-6 border-b border-border-light'>
        <h2 className='text-textPrimary'>User Management</h2>
      </div>

      <div className='px-6 mb-4'>
        <p className='w-[500px] subtitle-medium mt-[6px]'>
          Manage team roles and permissions to control access across the
          platform, ensuring the right tools and data for each user. 
          {/* A real doc link will be placed here. For now we will go live without it */}
          {/* <Link url='#' openInNewTab={true} showUnderline={true}>
            Learn More
          </Link> */}
        </p>
      </div>
    </>
  );
};
interface SubscriptonInfoAlertProps {
  subscriptionDetails: subscriptionDetailsType;
  selectedProject: ISelectedProject;
  getTotalTeamMemberCount: () => number;
}
const SubscriptonInfoAlert: FC<SubscriptonInfoAlertProps> = ({
  subscriptionDetails,
  selectedProject,
  getTotalTeamMemberCount
}) => {
  return (
    <>
      {selectedProject.is_using_new_billing && (
        <Alert className='flex items-center my-4 gap-2 flex-wrap bg-[#F0FDF4] border-[#DCFCE7] text-[#166534] p-3 rounded-md'>
          <Info size={16} className='text-[#166534]' />
          {subscriptionDetails && (
            <AlertDescription>
              Your subscription plan includes{' '}
              <b>{getTotalTeamMemberCount()}</b>{' '}
              team members. <b>{getTotalTeamMemberCount()}</b>/
              <b>{subscriptionDetails.project_billing_info.max_team_members}</b>{' '}
              members are already filled. You can invite{' '}
              <b>{`${
                subscriptionDetails.project_billing_info.max_team_members -
                getTotalTeamMemberCount()
              }`}</b>{' '}
              more member(s)!
            </AlertDescription>
          )}
        </Alert>
      )}
    </>
  );
};

export default TeamUserSettings;
