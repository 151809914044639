import { useState, useRef, useEffect } from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Button } from 'libraryV2/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
} from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';
import { X } from 'lucide-react';

interface MultiSelectProps<TOption> {
  options: TOption[];
  selectedOptions: TOption[] | undefined;
  onApplyFilter: (value: TOption[]) => void;
  onClearFilter: () => void;
  searchInputPlaceholder?: string;
  renderOption: (option: TOption, isSelected: boolean) => React.ReactNode;
  getOptionValue: (option: TOption) => string;
  renderTrigger: (
    option: TOption[] | undefined,
    isPopoverOpen: boolean
  ) => React.ReactNode;
  classNames?: {
    wrapper?: string;
    selectTrigger?: string;
    popoverContent?: string;
    commandInput?: string;
    commandItem?: string;
  };
}
export function MultiSelect<TOption>({
  options,
  selectedOptions,
  onApplyFilter,
  onClearFilter,
  renderOption,
  getOptionValue,
  searchInputPlaceholder = 'Search',
  renderTrigger,
  classNames,
}: MultiSelectProps<TOption>) {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [localSelectedPlatformList, setLocalSelectedPlatformList] = useState(
    selectedOptions || ([] as TOption[])
  );

  const commandListRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [shouldDisableApplyFilter, setShouldDisableApplyFilter] =
    useState(true);

  const sortedPlatformList = (
    allPlatformList: TOption[],
    selectedPlatforms: TOption[]
  ) => {
    if (!selectedPlatforms?.length) return allPlatformList;

    return selectedPlatforms.concat(
      allPlatformList.filter(
        (platform) =>
          !selectedPlatforms.some(
            (selected) => getOptionValue(selected) === getOptionValue(platform)
          )
      )
    );
  };

  const handleLocalPlatformSelect = (platform: TOption) => {
    if (commandListRef.current) {
      setScrollPosition(commandListRef?.current?.scrollTop);
    }
    if (shouldDisableApplyFilter) {
      setShouldDisableApplyFilter(false);
    }
    const removedPlatformList = localSelectedPlatformList.filter(
      (p) => getOptionValue(p) !== getOptionValue(platform)
    );
    if (removedPlatformList.length === localSelectedPlatformList.length) {
      setLocalSelectedPlatformList([...localSelectedPlatformList, platform]);
    } else {
      setLocalSelectedPlatformList(removedPlatformList);
    }
  };

  useEffect(() => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = scrollPosition;
    }
  }, [localSelectedPlatformList, scrollPosition]);

  const handleClearFilter = () => {
    if (shouldDisableApplyFilter) {
      setShouldDisableApplyFilter(false);
    }
    setLocalSelectedPlatformList([]);
    if (typeof onClearFilter === 'function') {
      onClearFilter();
    }
  };

  const handleApplyFilter = () => {
    onApplyFilter(localSelectedPlatformList);
    setIsPopOverOpen(false);
  };

  return (
    <div className={cn('w-fit', classNames?.wrapper)}>
      <Popover
        open={isPopOverOpen}
        onOpenChange={(openStatus) => {
          setIsPopOverOpen(openStatus);
          setShouldDisableApplyFilter(true);
          setLocalSelectedPlatformList(selectedOptions || []);
        }}
      >
        <PopoverTrigger asChild className={cn(classNames?.selectTrigger)}>
          {renderTrigger(selectedOptions, isPopOverOpen)}
        </PopoverTrigger>
        <PopoverContent
          className={cn('w-fit bg-white p-0', classNames?.popoverContent)}
        >
          <Command>
            <CommandInput
              shouldHide={options.length <= 5}
              placeholder={searchInputPlaceholder}
              className={cn(classNames?.commandInput)}
            />
            <CommandList>
              <CommandEmpty>No result found.</CommandEmpty>
              <CommandGroup
                className='max-h-[220px] overflow-auto'
                ref={commandListRef}
              >
                {sortedPlatformList(options, selectedOptions || [])?.map(
                  (singlePlatform, _) => {
                    const isSelected =
                      localSelectedPlatformList?.findIndex(
                        (v) =>
                          getOptionValue(v) === getOptionValue(singlePlatform)
                      ) !== -1;

                    return (
                      <CommandItem
                        className={cn(
                          'flex relative text-sm items-center gap-1',
                          classNames?.commandItem
                        )}
                        key={getOptionValue(singlePlatform)}
                        onSelect={() =>
                          handleLocalPlatformSelect(singlePlatform)
                        }
                      >
                        {renderOption(singlePlatform, isSelected)}
                      </CommandItem>
                    );
                  }
                )}
              </CommandGroup>
            </CommandList>
            {selectedOptions?.length || localSelectedPlatformList?.length ? (
              <div className='flex justify-between p-2 border-t'>
                <Button
                  variant='ghost'
                  disabled={!localSelectedPlatformList?.length}
                  size='sm'
                  onClick={handleClearFilter}
                >
                  Clear
                </Button>
                <PopoverClose asChild>
                  <Button
                    size='sm'
                    onClick={handleApplyFilter}
                    disabled={shouldDisableApplyFilter}
                  >
                    Apply Filter
                  </Button>
                </PopoverClose>
              </div>
            ) : null}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

interface MultiSelectOptionBadgeViewProps<TOption> {
  label: string;
  options: TOption[];
  getOptionLabel: (option: TOption) => string;
  onRemoveOption: (option: TOption) => void;
}

export const MultiSelectOptionBadgeView = <TOption,>({
  label,
  options,
  getOptionLabel,
  onRemoveOption,
}: MultiSelectOptionBadgeViewProps<TOption>) => {
  if (!Array.isArray(options) || !options?.length) return null;
  const renderSingleOption = (singleOption: TOption) => {
    return (
      <div
        key={getOptionLabel(singleOption)}
        className='bg-background-hover w-fit flex py-0.5 px-1 rounded-md text-sm font-medium text-textPrimary gap-1 items-center'
      >
        {getOptionLabel(singleOption)}
        <button
          onClick={() => onRemoveOption(singleOption)}
          className='p-0.5 rounded-sm hover:bg-background-hover'
        >
          <X
            strokeWidth={2.5}
            className='w-3.5 h-3.5 text-textPrimary-disable hover:text-red-500'
          />
        </button>
      </div>
    );
  };

  const renderOptions = () => {
    if (options.length === 1) {
      return renderSingleOption(options[0]);
    }

    return (
      <div className='flex flex-wrap gap-2'>
        {renderSingleOption(options[0])}
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant='outline'
              className='h-6 bg-background-hover py-0 px-2'
            >
              +{options.length - 1}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='bg-white min-w-[150px]'>
            <div className='flex flex-wrap flex-col gap-2'>
              {options.slice(1).map(renderSingleOption)}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  };

  return (
    <div className='flex items-center gap-2'>
      <span className='text-sm font-medium text-textSecondary'>{label}:</span>
      {renderOptions()}
    </div>
  );
};
