import { FC, useEffect } from 'react';
import { TemplateOverviewTableContainer } from './OverviewTable/TemplateOverviewTableContainer';
import useWhatsappMetrics from '../../hooks/useWhatsappMetrics';

interface WaMetricsTemplateOverviewProps {}

export const WaMetricsTemplateOverview: FC<
  WaMetricsTemplateOverviewProps
> = () => {
  // fetch template list by platform id
  const {
    platformsToFilter,
    fetchTemplateList,
    dateRange,
    whatsappTemplateData,
  } = useWhatsappMetrics();

  // fetch template list by platform, need it in table filter
  useEffect(() => {
    const currentWaChannelId = platformsToFilter[0]?.id + '';
    const statePlatformId = whatsappTemplateData?.platformId + '';
    if (currentWaChannelId && currentWaChannelId !== statePlatformId) {
      fetchTemplateList(+currentWaChannelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  return (
    <div className='flex flex-col gap-4 p-6'>
      <div className='flex flex-col gap-1.5'>
        <h3 className='text-textPrimary text-base font-semibold leading-6'>
          WhatsApp Template Analytics
        </h3>
        <p className='text-textSecondary text-sm font-normal leading-5'>
          Track your WhatsApp template activity and performance.
        </p>
      </div>

      <TemplateOverviewTableContainer
        whatsappTemplateList={whatsappTemplateData?.templateList || []}
      />
    </div>
  );
};
