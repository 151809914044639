import * as React from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import {
  Badge,
  Button,
  ChevronsUpDownIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TooltipProviderCustomised,
} from '../../export';

interface Props {
  value: Array<string | number>;
  children: any;
  childrenName: string;
  buttonClass?: string;
  popOverContentClass?: string;
}

const DropdownField: React.FC<Props> = ({
  value,
  children,
  buttonClass,
  childrenName,
  popOverContentClass,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const getButtonValue = () => {
    const getTooltipViewContent: any = () => (
      <div className='max-w-[300px] flex w-fit flex-col flex-wrap gap-1 bg-slate-800 p-2 text-white'>
        {value?.map((v: string | number, idx: number) => (
          <p key={idx + v.toString()}> {t(v.toString()) ?? ''} </p>
        ))}
      </div>
    );
    switch (value.length) {
      case 0:
        return (
          <span className='subtitle-regular'>{`${t(t('Select'))} ${t(
            childrenName
          )}`}</span>
        );
      case 1:
        return (
          <TooltipProviderCustomised content={getTooltipViewContent()}>
            <Badge className='text-xs font-medium bg-gray-100 shadow-none text-textPrimary hover:bg-gray-100'>
              {typeof value[0] === 'string' && value[0].length > 37
                ? `${t(value[0].substring(0, 37))}...`
                : t(value[0].toString()) ?? ''}
            </Badge>
          </TooltipProviderCustomised>
        );

      default:
        return (
          <TooltipProviderCustomised content={getTooltipViewContent()}>
            <Badge className='text-xs font-medium bg-gray-100 shadow-none text-textPrimary hover:bg-gray-100'>
              {`${value.length} ${t(childrenName)} ${t('selected')}`}
            </Badge>
          </TooltipProviderCustomised>
        );
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          role='combobox'
          variant='outline'
          aria-expanded={open}
          className={`justify-between px-3 py-2 ${buttonClass}`}
        >
          {getButtonValue()}
          <ChevronsUpDownIcon className='w-4 h-4 ltr:ml-2 rtl:mr-2 opacity-50 shrink-0' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={`p-0 bg-white ${popOverContentClass}`}>
        {children}
      </PopoverContent>
    </Popover>
  );
};

export default DropdownField;
