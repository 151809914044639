import { startCase } from 'lodash';

import { IWhatsappTemplateAnalyticsData } from '../interface';

export const WhatsappTemplateAnalyticsApiDataLimit = 10;
export const WhatsappTemplateAnalyticsLogsApiDataLimit = 10;
export const AllConversationDataTooltip = `The number of conversations between your business and accounts on WhatsApp. A conversation includes all messages delivered within a 24-hour period. A conversation starts when the first business message is delivered and ends 24 hours later. The first message can be initiated by the business (marketing, utility or authentication) or a business reply within 24 hours of receiving a message from an account (service).`;
export const ApproximateChargesTooltip = `Approximate Charges: The approximate total charges for conversations on WhatsApp. These charges may differ from what's shown on your invoices due to variations in data processing.
The charge for each paid conversation is determined by the rate assigned to the country or region of the WhatsApp account's phone number, and whether the conversation was initiated by the business (marketing, utility or authentication) or a business reply within 24 hours of receiving a message from an account (service).`;

export const WhatsappTemplateMetricsStatusData = [
  { id: 'approved', label: 'Approved' },
  { id: 'rejected', label: 'Rejected' },
  { id: 'pending', label: 'Pending' },
];

export const WhatsappTemplateMetricsCategoriesData = [
  { id: 'marketing', label: 'Marketing' },
  { id: 'authentication', label: 'Authentication' },
  { id: 'utility', label: 'Utility' },
  // { id: 'service', label: 'Service' },
  // { id: 'free', label: 'Free' },
];

export const AnalyticsLogsStatusData = [
  { id: 'delivered', label: 'Delivered' },
  { id: 'failed', label: 'Failed' },
  { id: 'pending', label: 'Pending' },
];

export const AnalyticsLogsSourceData = [
  { id: 'inbox', label: 'Inbox' },
  { id: 'broadcast', label: 'Broadcast' },
  { id: 'test_broadcast', label: 'Test Broadcast' },
  { id: 'chatbot', label: 'Chatbot' },
  { id: 'api', label: 'Api' },
  { id: 'automation', label: 'Automation' },
];

export const CSAT_METRICES_CONTENT = {
  dataCard: {
    totalSent: {
      title: 'Total CSAT Surveys Sent',
      toolTip: 'The total number of CSAT surveys sent to Customers.',
    },
    totalResponse: {
      title: 'Total Response',
      toolTip: 'The total number of responses received from Customers.',
    },
    averageResponse: {
      title: 'Avg. Response Rate',
      toolTip: 'The average rate of responses given by the Customers',
    },
    averageRating: {
      title: 'Avg. Rating',
      toolTip: 'The average of all CSAT ratings received from customers.',
    },
  },
  responseList: {
    title: 'List of Responses',
    toolTip: 'Necessary Information about CSAT Ratings with the details',
  },
  responseDistribution: {
    title: 'Response Distribution',
    toolTip: 'Distribution of CSAT Ratings given by the Customers',
  },
  ratings: [
    {
      rating: 5,
      color: '#22C55E',
      text: 'Very Satisfied',
    },
    {
      rating: 4,
      color: '#3B82F6',
      text: 'Satisfied',
    },
    {
      rating: 3,
      color: '#A78BFA',
      text: 'Neutral',
    },
    {
      rating: 2,
      color: '#FBBF24',
      text: 'Unsatisfied',
    },
    {
      rating: 1,
      color: '#FF5757',
      text: 'Very Unsatisfied',
    },
  ],
};

export type TemplateStatus = IWhatsappTemplateAnalyticsData['status'];
export const templateStatusOptions = (
  [
    'approved',
    'in_appeal',
    'pending',
    'rejected',
    'pending_deletion',
    'deleted',
    'disabled',
    'paused',
    'limit_exceeded',
  ] as const
).map((status: TemplateStatus) => ({
  label: startCase(status),
  value: status,
}));
export const templateTemplateTypeOptions: {
  label: string;
  value: IWhatsappTemplateAnalyticsData['category'];
}[] = [
  { label: 'Marketing', value: 'MARKETING' },
  { label: 'Authentication', value: 'AUTHENTICATION' },
  { label: 'Utility', value: 'UTILITY' },
];

export const statusColors: Record<TemplateStatus, string> = {
  approved: '#04B25F',
  pending: '#F59E0B',
  paused: '#6366F1',
  rejected: '#FF2323',
  in_appeal: '#8B5CF6',
  pending_deletion: '#F59E0B',
  deleted: '#FF2323',
  disabled: '#6B7280',
  limit_exceeded: '#FF2323',
};
