import { useDispatch, useSelector } from "react-redux";
import useUserManagement from "./useUserManagement";

const useTableRowActions = () => {
    const dispatch = useDispatch();
    const { getAgentAccessList, agentAccessList} = useUserManagement();
    const selectedProject = useSelector(
        (state: any) => state?.dashboard?.selectedProject
    );
    const deleteUserFromTeam = async(userId: number) => {
        const response = await dispatch.settingsUserManagementConfig
        .deleteUserFromTeam({ teamId:selectedProject?.id, userId: userId });
        if (response.status) {
          const updatedAgentAccessList = agentAccessList.filter(
            (agent: any) => agent?.admin.id !== userId
          );
          dispatch.settingsUserManagementConfig.updateAgentAccessList(
            updatedAgentAccessList
          );
        }
    }
    // have blockage here, not able to delete multiple users
    const deleteMultipleUserFromTeam = async(ids: number[]) => {
        const response = await dispatch.settingsUserManagementConfig
        .deleteMultipleUserFromTeam({ teamId:selectedProject?.id, ids })
        if(response.status){
            await getAgentAccessList();
        }
    }

    return {
        selectedProject,
        deleteUserFromTeam,
        deleteMultipleUserFromTeam
    };
};

export default useTableRowActions;