import { omit, isEqual } from 'lodash';
import { IDataLabField } from '../interface';
// Shorten the string
export const truncate = (str: string, n = 15) =>
  str.length > n ? `${str.substr(0, n)}...` : str;

export const platinum_color = '#E4E4E7';
export const tertiary_color = '#A1A1AA';
export const TRIGGER_PRIMARY_COLOR = '#18181B';

export const nextRandomId = () => {
  const currentTimestamp = new Date().getTime();

  return `random_${currentTimestamp + Math.floor(Math.random() * 1000)}`;
};

export const isDirtyDataLabField = (
  updatedField: IDataLabField,
  originalField: IDataLabField
) => {
  return !isEqual(
    omit(updatedField, ['serial']),
    omit(originalField, ['serial'])
  );
};
