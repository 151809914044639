import React, { useEffect } from 'react';
import AgentMetricsContainer from './AgentMetrics';
import { useAgentMetrics } from 'pages/reportingV2/hooks/useAgentMetrics';

const AgentMetricsV2: React.FC = () => {
  const {
    updateStateData,
    platformsToFilter,
    hasSLAConnectionOnAnyPlatform,
    fetchAgentMetrics,
    isBusinessHourEnabled,
    shouldFetchData,
    dateRange,
  } = useAgentMetrics();

  useEffect(() => {
    if (!shouldFetchData) return;
    fetchAgentMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, isBusinessHourEnabled, dateRange]);

  return (
    <AgentMetricsContainer
      isSLAConnected={hasSLAConnectionOnAnyPlatform}
      isBusinessHourEnabled={isBusinessHourEnabled}
      handleBusinessHourToggle={(val: boolean) => {
        updateStateData('isBusinessHourEnabled', val);
      }}
    />
  );
};

export default AgentMetricsV2;
