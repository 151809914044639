import { IUserRole } from "../../interface";

const RoleName = ({ role, roleName }: { roleName?: string, role?: IUserRole }) => {
    const displayName = role?.name === 'Marketeer' ? 'Marketer' : role?.name;
    return (
      <p
        className={`text-textPrimary text-sm ${
          roleName === role?.name && 'font-bold'
        }`}
      >
        {displayName}
      </p>
    );
};

export default RoleName;