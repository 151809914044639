import { VariantProps } from 'class-variance-authority';
import useTranslation from 'components/customHooks/useTranslation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'libraryV2/ui/alert-dialog';
import { buttonVariants } from 'libraryV2/ui/button';
import React from 'react';

interface DeleteAlertDialogProps {
  title: string;
  isOpened: boolean;
  description: string;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isConfirming?: boolean;
  confirmationVariant?: VariantProps<typeof buttonVariants>['variant'];
}

const WarningModal: React.FC<DeleteAlertDialogProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  isOpened,
  cancelTitle = 'No',
  confirmTitle = 'Yes! Delete',
  isConfirming = false,
  confirmationVariant = 'default',
}) => {
  const { t } = useTranslation();
  return (
    <AlertDialog open={isOpened} onOpenChange={onCancel}>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader className='rtl:text-right'>
          <AlertDialogTitle className='rtl:text-right'>
            {t(title)}
          </AlertDialogTitle>
          <AlertDialogDescription>{t(description)}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='rtl:justify-start'>
          <AlertDialogCancel
            onClick={onCancel}
            disabled={isConfirming}
            className='rtl:ml-2'
          >
            {t(cancelTitle)}
          </AlertDialogCancel>
          <AlertDialogAction
            className={`ltr:ml-1 rtl:mr-1`}
            onClick={() => {
              onConfirm();
              onCancel();
            }}
            variant={confirmationVariant}
            disabled={isConfirming}
          >
            {t(confirmTitle)}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default WarningModal;
