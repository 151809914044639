import useTranslation from 'components/customHooks/useTranslation';
import { FC } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  usePolicy,
} from '../../export';

interface Props {
  target: string;
  handleTargetUpdate: (target: string) => void;
}

const TargetSelection: FC<Props> = ({ target, handleTargetUpdate }) => {
  const { t } = useTranslation();
  const { inputFieldClass } = usePolicy();
  return (
    <div>
      <Select
        value={target}
        onValueChange={(value: string) => handleTargetUpdate(value)}
      >
        <SelectTrigger
          className={`${inputFieldClass} h-8 w-52 ltr:mr-2 rtl:ml-2 subtitle-regular  focus:border-primary active:border-primary focus-visible:outline-none rtl:flex-row-reverse`}
        >
          <SelectValue
            // defaultValue={target}
            placeholder={t('Select target')}
          >
            {target === 'resolution_time'
              ? t('Resolution time')
              : target === 'first_response_time'
              ? t('First response time')
              : ''}
          </SelectValue>
        </SelectTrigger>
        <SelectContent className='mt-1 bg-white'>
          <SelectGroup className='text-sm text-textPrimary'>
            <SelectItem
              value='first_response_time'
              className='hover:bg-background-hover rtl:flex-row-reverse'
            >
              {t('First response time')}
            </SelectItem>
            <SelectItem
              value='resolution_time'
              className='hover:bg-background-hover rtl:flex-row-reverse'
            >
              {t('Resolution time')}
            </SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default TargetSelection;
