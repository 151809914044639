import { useParams } from '@reach/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReprtingPlatforms } from '../interface';
import dayjs from 'dayjs';

const perPageTicketLimit = 6;

interface IAutomationMetricsPayload {
  projectId: number;
  platformIds: string;
  start: string;
  end: string;
  endpoint: string;
}

interface IAutomationTicketTablePayload
  extends Omit<IAutomationMetricsPayload, 'endpoint'> {
  groupId: number | null;
  limit: number;
  offset: number;
}

export const useAutomationMetrics = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const projectId = routeParams?.projectId;

  const dateRange = useSelector((state: any) => state.reportingV2.dateRange);
  const platformsToFilter: IReprtingPlatforms[] = useSelector(
    (state: any) => state.reportingV2.platformsToFilter
  );
  const shouldFetchData = useSelector(
    (state: any) => state.reportingV2.shouldFetchData
  );

  const [ticketListOffset, setTicketListOffset] = React.useState(0);

  const [defaultGroup, setDefaultGroup] = React.useState({
    id: null,
    email: '',
    avatar: null,
    full_name: 'All Agents',
  });

  const fetchUserReportData = (payload: IAutomationMetricsPayload) =>
    dispatch.reportingV2.fetchUserReportData(payload);

  const fetchAgentList = (teamId: number) =>
    dispatch.reportingV2.fetchAgentList(teamId);

  const fetchUserReportDataDefaultAppend = (
    payload: IAutomationMetricsPayload
  ) => dispatch.reportingV2.fetchUserReportDataDefaultAppend(payload);

  const fetchAutomationTicketTable = (payload: IAutomationTicketTablePayload) =>
    dispatch.reportingV2.fetchAutomationTicketTable(payload);

  const fetchAutomationMetrics = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
    };
    fetchUserReportData({ ...payload, endpoint: 'unique-ticket-count' });
    fetchUserReportData({ ...payload, endpoint: 'get_ticket_created_count' });
    fetchUserReportData({ ...payload, endpoint: 'get_ticket_resolved_count' });
    if (projectId === 943) {
      // Only Available to MIBS
      fetchUserReportData({
        ...payload,
        endpoint: 'get-avg-process-first-response-time',
      });
    }

    fetchUserReportDataDefaultAppend({
      ...payload,
      endpoint: 'get_textual_message_vs_automation',
    });
    fetchUserReportDataDefaultAppend({
      ...payload,
      endpoint: 'get_tag_cloud',
    });
    fetchAutomationTicketTable({
      ...payload,
      groupId: defaultGroup?.id,
      limit: perPageTicketLimit,
      offset: ticketListOffset,
    });
    fetchAgentList(projectId);
  };

  const fetchAutomationTicketTableByAgent = (agentId: number) => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
    };
    fetchAutomationTicketTable({
      ...payload,
      groupId: agentId,
      limit: perPageTicketLimit,
      offset: ticketListOffset,
    });
  };

  const handleTicketListPagination = (newOffset: number) => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
      groupId: defaultGroup?.id,
      limit: perPageTicketLimit,
      offset: newOffset,
    };
    fetchAutomationTicketTable(payload);
    setTicketListOffset(newOffset);
  };

  return {
    shouldFetchData,
    projectId,
    platformsToFilter,
    fetchAutomationMetrics,
    ticketListOffset,
    setTicketListOffset,
    defaultGroup,
    setDefaultGroup,
    fetchAutomationTicketTable,
    handleTicketListPagination,
    dateRange,
    fetchAutomationTicketTableByAgent,
  };
};
