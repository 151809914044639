import React, { useState } from 'react';
import AgentDetailsTable from '../AgentMetrics/AgentDetailsTable';
import DataStack from '../CommonComponent/DataStack';
import { analytics } from '../../../../utilities/content';
import useTranslation from 'components/customHooks/useTranslation';
import { SwitchComponent } from 'library';
import { useAgentMetrics } from 'pages/reportingV2/hooks/useAgentMetrics';
import { IAgentStats } from 'pages/reportingV2/interface';

// Type definitions for component props
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface DataStackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name?: string;
  value?: number | string;
}

interface Props {
  isSLAConnected: boolean;
  handleBusinessHourToggle: (val: boolean) => void;
  isBusinessHourEnabled: boolean;
}

// Functional component
const AgentMetricsContainer: React.FunctionComponent<Props> = ({
  isSLAConnected,
  handleBusinessHourToggle,
  isBusinessHourEnabled,
}) => {
  const {
    averageResolutionTime,
    averageFirstResponseTime,
    agentAverageResponseTime,
    averageSalesConversion,
    loader,
    agentStats,
    statLoader,
    exportLoader,
    selectedProject,
    exportAgentDetails,
  } = useAgentMetrics();
  const { t } = useTranslation();
  const perPageLimit = 6;
  const [offset, setOffset] = useState<number>(0);
  const [searchAgent, setSearchAgent] = useState<string>('');
  const [isSwitchClicked, setIsSwitchClicked] = useState<boolean>(
    isBusinessHourEnabled
  );

  // Prepare data for sales cards
  const salesCardData = [
    {
      ...averageFirstResponseTime,
      name: analytics.agentMetrics.dataCard[0].title,
      loader,
      toolTip: analytics.agentMetrics.dataCard[0].toolTip,
    },
    {
      ...averageResolutionTime,
      name: analytics.agentMetrics.dataCard[1].title,
      loader,
      toolTip: analytics.agentMetrics.dataCard[1].toolTip,
    },
    {
      ...agentAverageResponseTime,
      name: analytics.agentMetrics.dataCard[2].title,
      loader,
      toolTip: analytics.agentMetrics.dataCard[2].toolTip,
    },
  ];

  // Add sales conversion card if applicable
  const updatedSalesCardData = !selectedProject?.has_ecommerce_connected
    ? salesCardData
    : [
        ...salesCardData,
        {
          ...averageSalesConversion,
          name: analytics.agentMetrics.dataCard[3].title,
          loader,
          toolTip: analytics.agentMetrics.dataCard[3].toolTip,
        },
      ];

  // Component for the switch control
  const BusinessHourSwitch: React.FC = () => (
    <div className='flex justify-end w-1/2 p-6'>
      <SwitchComponent
        isEnabled={isSwitchClicked}
        classNames='ltr:ml-auto rtl:mr-auto'
        onChange={() => {
          handleBusinessHourToggle(!isSwitchClicked);
          setIsSwitchClicked(!isSwitchClicked);
        }}
      />
    </div>
  );

  // Component for the header section
  const HeaderSection: React.FC = () => (
    <div className='flex w-full bg-white border border-gray-100 rounded-md shadow'>
      <div className='flex flex-col justify-start w-1/2 p-4 text-base text-gray-900'>
        <label className='text-base font-semibold text-gray-900'>
          {t('Business Hour Only')}
        </label>
        <p className='mt-1 text-sm text-gray-500'>
          {t('See how your agents perform during the business hours')}
        </p>
      </div>
      <BusinessHourSwitch />
    </div>
  );

  // Component for the main content
  const MainContent: React.FC = () => (
    <div className='p-4'>
      <div className='p-3'>
        <HeaderSection />
      </div>
      <DataStack column={4} data={updatedSalesCardData} />
      <AgentDetailsTable
        isSLAConnected={isSLAConnected}
        title={analytics.agentMetrics.agentDetails.title}
        loader={statLoader}
        toolTipInfo={analytics.agentMetrics.agentDetails.toolTip}
        query={searchAgent}
        setQuery={setSearchAgent}
        exportLoader={exportLoader}
        handleExport={() => exportAgentDetails()}
        total={agentStats.length}
        limit={perPageLimit}
        offset={offset}
        setOffset={setOffset}
        hasEcommerceConnected={selectedProject.has_ecommerce_connected}
        tableData={agentStats
          .filter((elem: IAgentStats) =>
            elem.agent_name?.toLowerCase().includes(searchAgent.toLowerCase())
          )
          .slice(offset, offset + perPageLimit)}
        currency={selectedProject?.currency_symbol || ''}
      />
    </div>
  );

  return <MainContent />;
};

export default AgentMetricsContainer;
