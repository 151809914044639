'use client';
import * as React from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';

interface SingleSelectProps<TOption> {
  options: TOption[];
  selectedOption: TOption | undefined;
  onSelectOption: (value: TOption | undefined) => void;
  renderOption: (option: TOption, isSelected: boolean) => React.ReactNode;
  getOptionValue: (option: TOption) => string;
  renderTrigger: (
    option: TOption | undefined,
    isPopoverOpen: boolean
  ) => React.ReactNode;
  classNames?: {
    selectTrigger?: string;
    popoverContent?: string;
    commandInput?: string;
    commandItem?: string;
  };
}

export function SingleSelect<TOption>({
  options,
  selectedOption,
  onSelectOption,
  renderOption,
  getOptionValue,
  renderTrigger,
  classNames,
}: SingleSelectProps<TOption>) {
  const [open, setOpen] = React.useState(false);
  const safeOptions = Array.isArray(options) ? options : [];
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {renderTrigger(selectedOption, open)}
      </PopoverTrigger>
      <PopoverContent
        className={cn('w-[250px] p-0 bg-white', classNames?.popoverContent)}
      >
        <Command>
          <CommandInput
            placeholder='Search options...'
            className={classNames?.commandInput}
          />
          <CommandList>
            <CommandEmpty>No options found.</CommandEmpty>
            <CommandGroup>
              {safeOptions.map((option) => (
                <CommandItem
                  key={getOptionValue(option)}
                  onSelect={() => {
                    onSelectOption(
                      option === selectedOption ? undefined : option
                    );
                    setOpen(false);
                  }}
                  className={cn('px-0', classNames?.commandItem)}
                >
                  {renderOption(
                    option,
                    getOptionValue(selectedOption!) === getOptionValue(option)
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
