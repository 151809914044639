import { DataTable } from './DataTable';
import { FC, useEffect, useState } from 'react';
import { templateOverviewTableColumns } from './TemplateOverviewTableColumn';
import useDebounce from 'components/customHooks/useDebounce';
import useWhatsappMetrics from '../../../hooks/useWhatsappMetrics';
import { TemplateOverviewTableHeader } from './TemplateOverviewTableHeader';
import {
  ColumnFiltersState,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  OnChangeFn,
} from '@tanstack/react-table';
import { IWhatsappTemplateAnalyticsData } from 'pages/reportingV2/interface';
import { formatAnalyticsState } from 'pages/reportingV2/utility/utils';

interface TemplateOverviewTableProps {
  whatsappTemplateList: Pick<
    IWhatsappTemplateAnalyticsData,
    'id' | 'status' | 'name'
  >[];
}

type ITemplateOverviewFilter = {
  id: 'template-id' | 'template-type' | 'template-status';
  value: { label: string; value: string }[];
};

type TemplateSearchFilter = {
  id: 'template-name';
  value: string;
};

export type TemplateFilterState =
  | TemplateSearchFilter
  | ITemplateOverviewFilter;

export const TemplateOverviewTableContainer: FC<TemplateOverviewTableProps> = ({
  whatsappTemplateList,
}) => {
  const [offset, setOffset] = useState(0);
  const [columnFilters, setColumnFilters] = useState<ITemplateOverviewFilter[]>(
    []
  );
  const debounceFilters = useDebounce(columnFilters, 1000);

  const {
    whatsappTemplateMetrics,
    dateRange,
    platformsToFilter,
    isTemplateOverviewLoading,
    totalDataOfTemplateMetrics,
    fetchWhatsappTemplateMetrics,
    isFetchTemplateMetricsLoading,
  } = useWhatsappMetrics();
  const table = useReactTable({
    data: whatsappTemplateMetrics,
    columns:
      templateOverviewTableColumns as ColumnDef<IWhatsappTemplateAnalyticsData>[],
    state: {
      columnFilters,
    },
    getRowId: (originalRow) => originalRow?.id,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex: true,
    rowCount: totalDataOfTemplateMetrics,
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    manualPagination: true,
    manualFiltering: true,
  });

  useEffect(() => {
    try {
      const formattedState = formatAnalyticsState(debounceFilters);
      fetchWhatsappTemplateMetrics({ ...formattedState, offset });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFilters, offset, dateRange, platformsToFilter]);

  return (
    <div className='flex flex-col gap-4'>
      <TemplateOverviewTableHeader
        whatsappTemplateList={whatsappTemplateList}
        table={table}
      />
      <DataTable
        table={table}
        offset={offset}
        setOffset={setOffset}
        isLoading={isTemplateOverviewLoading || isFetchTemplateMetricsLoading}
      />
    </div>
  );
};
