import { Fragment } from 'react';
import {
  DragTypes,
  DropZone,
  arrayMove,
  useActions,
  useTriggerActions,
} from 'pages/datalab/export';

import { Accordion } from 'libraryV2/ui/accordion';
import BlockEdit from '../../formEditor/blockList/blockEdit';

interface IProps {
  groupId: string;
  children?: any[];
}

const GroupBlockList: React.FC<IProps> = ({
  groupId = null,
  children = [],
}) => {
  const { getController } = useActions();
  const { addTempFieldToLabFields, updateDataLabFieldOrder } =
    useTriggerActions();
  const { labId } = getController();

  const handleOnSort = (drag_index: number, hover_index: number) => {
    if (drag_index !== hover_index && hover_index > -1) {
      // handle sorting of fields
      const updatedGroupFields = arrayMove(children, drag_index, hover_index);

      const data = updatedGroupFields.map((field: any, index: number) => ({
        id: field.id,
        serial: index,
      }));

      updateDataLabFieldOrder(labId, data);
    }
  };

  const handleDrop = (dropZone: any, item: any) => {
    if (item.type === DragTypes.FIELD) {
      const field = item.field;
      field.parent_id = groupId;
      field.serial = Number(dropZone.index);
      addTempFieldToLabFields(field);
    } else if (item.type === DragTypes.GROUP_FIELD) {
      handleOnSort(item.index, Number(dropZone.index));
    }
  };

  return (
    <div className='w-full' style={{ minHeight: '6px' }}>
      {children && (
        <Accordion type='single' collapsible>
          {children.map((field: any, index: number) => (
            <Fragment key={field.id}>
              <DropZone
                data={{
                  index: `${index}`,
                  childrenCount: children.length,
                }}
                onDrop={handleDrop}
              />
              <BlockEdit
                index={index}
                key={field.id}
                field={field}
                type={DragTypes.GROUP_FIELD}
              />
            </Fragment>
          ))}
        </Accordion>
      )}

      <DropZone
        data={{
          index: `${children?.length}`,
          childrenCount: children?.length,
        }}
        onDrop={handleDrop}
        style={{ display: 'flex' }}
      />
    </div>
  );
};

export default GroupBlockList;
