import {
  axios,
  config,
  toaster,
  UtilityContents,
  UtilityFunctions,
} from '../export';

const initialState = {
  //flow graph related states
  isNodeDeleteConfirmed: null,
  selectedNodeType: null, // trigger-node/condition-node/action-node
  selectedNodeId: null,
  selectedSourceHandle: null,
  lastNodeId: null,
  lastEdgeId: null,
  automationEdges: [],
  selectedNodeIDForDelete: null,
  selectedAction: null,
  selectedLogSummary: null,
  selectedLogDetials: null,
  automationNodes: [
    // inital nodes for automation ceation
    {
      id: 'node-0',
      type: 'trigger-button',
      position: { x: 0, y: 0 },
      data: { isHandlerNode: true },
    },
  ],

  //side sheet data related states
  showSlider: false,
  userActionFlowList: [],
  selectedTriggerTab: null,
  triggerInfo: null,
  triggerTagList: [],
  selectedTrigger: null,
  selectedConditions: [],
  comparatorTagList: [],
  selectedComparator: {},
  comparatorDetailsTagList: null,
  selectedComparatorTags: [],
  temporaryComparatorOptions: [],
  comparatorTagDetails: [],
  executorTagList: [],
  executorDetails: null,
  selectTemporaryExecutor: [],
  selectedTemporaryExecutorDetailsTag: null,
  selectedSaveConditionList: [],
  executorOfcommonComponents: [],
  selectedSaveActionsData: {
    general: {
      delay: {
        valueOfDelay: null,
        timeOfDelay: 'minute',
      },
    },
    eCommerce: {},
    whatsapp: {
      selectedTemplate: null,
      phoneVariable: null,
      selectedChannelId: null,
    },
  },
  orderStatusChange: {
    statusForm: 'any',
    toStatus: 'any',
  },
  selectedChannel: null,
  channelList: [],
  whatsappTemplateList: [],
  selectedWhatsappData: null,
  addMoreConditionIndex: null,
  conditionallyRenderingTriggerUI: 'triggerElement',
  renderingChangeUIOfCondition: 'addActionElement',
  renderingChangeUIOfActions: 'actionElement',
  ecommerceProducts: [],

  // new flow create pages's header related states
  isNewWorkFlowActive: false,
  automationName: 'Untitled Automation',

  // automation dashboard related states
  automationFlowDisplayViewType: 'dashboard',
  selectedUserActionFlowData: {},
  selectedAutomationLogList: [],
  historyTableOffset: 0,
  isAutomationEditable: false,
  selectedAutomationNodeEditableId: null,
  selectedAutomationId: null,
  isOpenDeleteWorkflowModal: false,
  selectedUserWorkflowMode: null,
  workflowDashboardTableOffset: 0,
  createdTotalWorkflowAutomation: 0,
  totalHistoryData: 0,
  isUpdateAutomationButtonEnable: false,
  isOpenSideSheetCloseAlertModal: false,
  addTagTriggerInfoLeftValue: null,
  integratedEcommerceList: [],
  selectedEcommerce: null,
};

export const workflowAutomation = {
  state: { ...initialState },
  reducers: {
    updateIntegratedEcommerceList(rootState, payload) {
      return { ...rootState, integratedEcommerceList: payload };
    },
    updateSelectedEcommerce(rootState, payload) {
      return { ...rootState, selectedEcommerce: payload };
    },
    updateSelectedTriggerTab(state, selectedTab) {
      return {
        ...state,
        selectedTriggerTab: selectedTab,
      };
    },
    updateSelectedComparator(state, updateData) {
      return {
        ...state,
        selectedComparator: updateData,
      };
    },
    updateSelectedConditions(state, updateData) {
      return {
        ...state,
        selectedConditions: updateData,
      };
    },
    updatePrimitiveStateData(state, updatedData) {
      return { ...state, [updatedData.key]: updatedData.value };
    },
    updateAutomationNode(state, updatedData) {
      return { ...state, automationNodes: updatedData };
    },
    updateAutomationEdge(state, updatedData) {
      return {
        ...state,
        automationEdges: updatedData,
      };
    },
    updateTriggerInfor(state, updateData) {
      return {
        ...state,
        triggerInfo: updateData,
      };
    },
    updateConditionallyRenderingTriggerUI(state, updateData) {
      return {
        ...state,
        conditionallyRenderingTriggerUI: updateData,
      };
    },
    updateTriggerTagList(state, updateData) {
      return {
        ...state,
        triggerTagList: updateData,
      };
    },
    updateSelectedTrigger(state, updateData) {
      return {
        ...state,
        selectedTrigger: updateData,
      };
    },
    updateComparatorTagList(state, updateData) {
      return {
        ...state,
        comparatorTagList: updateData,
      };
    },
    updateComparatorDetailsTagList(state, updateData) {
      return {
        ...state,
        comparatorDetailsTagList: updateData,
      };
    },
    updateComparatorTagDetails(state, updateData) {
      return {
        ...state,
        comparatorTagDetails: updateData,
      };
    },
    updateTemporaryComparatorOptions(state, updateData) {
      return {
        ...state,
        temporaryComparatorOptions: updateData,
      };
    },
    updateSelectedComparatorTags(state, updateData) {
      return {
        ...state,
        selectedComparatorTags: updateData,
      };
    },
    updateOrderStatusChange(state, updateData) {
      return {
        ...state,
        orderStatusChange: updateData,
      };
    },
    updateConditionallyRenderingConditionUI(state, updateData) {
      return {
        ...state,
        renderingChangeUIOfCondition: updateData,
      };
    },
    updateSelectedTemporaryExecutor(state, updateData) {
      return {
        ...state,
        selectTemporaryExecutor: updateData,
      };
    },

    updateHeaderUI(state, updateData) {
      return {
        ...state,
        renderingHeaderUI: updateData,
      };
    },
    updateMoreConditionIndex(state, addIndex) {
      return {
        ...state,
        addMoreConditionIndex: addIndex,
      };
    },
    updateSelectedSaveConditionList(state, updateData) {
      return {
        ...state,
        selectedSaveConditionList: updateData,
      };
    },
    updateUserActionFlowList(state, updateData) {
      return {
        ...state,
        userActionFlowList: updateData,
      };
    },
    updateRenderingChangeUIOfActions(state, updateUI) {
      return {
        ...state,
        renderingChangeUIOfActions: updateUI,
      };
    },

    updateExecutorDetails(state, updateData) {
      return {
        ...state,
        executorDetails: updateData,
      };
    },
    updateSelectedTemporaryExecutorDetailsTag(state, updateData) {
      return {
        ...state,
        selectedTemporaryExecutorDetailsTag: updateData,
      };
    },
    updateExecutorOfCommonComponents(state, updateData) {
      return {
        ...state,
        executorOfcommonComponents: updateData,
      };
    },
    updateSaveSelectedActionsData(state, updateData) {
      return {
        ...state,
        selectedSaveActionsData: updateData,
      };
    },
    updateEcommerceProducts(state, updateData) {
      return {
        ...state,
        ecommerceProducts: updateData,
      };
    },
    updateChannelList(state, updateData) {
      return {
        ...state,
        channelList: updateData,
      };
    },
    updateSelectedChannel(state, updateData) {
      return {
        ...state,
        selectedChannel: updateData,
      };
    },
    updateWhatsappTemplateList(state, updateData) {
      return {
        ...state,
        whatsappTemplateList: updateData,
      };
    },
    updateSelectedWhatsappData(state, updateData) {
      return {
        ...state,
        selectedWhatsappData: updateData,
      };
    },
    updateExecutorTagList(state, updateData) {
      return {
        ...state,
        executorTagList: updateData,
      };
    },
    clearConditionData(state, updateData) {
      return {
        ...state,
        selectedSaveConditionList: updateData,
        renderingChangeUIOfCondition: 'addActionElement',
        selectedComparatorTags: updateData,
        temporaryComparatorOptions: updateData,
        selectedConditions: updateData,
      };
    },
    clearActionData(state, updateData) {
      return {
        ...state,
        renderingChangeUIOfActions: 'actionElement',
        selectTemporaryExecutor: updateData,
        selectedWhatsappData: null,
        selectedSaveActionsData: {
          general: {
            delay: {
              valueOfDelay: null,
              timeOfDelay: 'minute',
            },
          },
          eCommerce: {},
          whatsapp: {
            selectedTemplate: null,
            phoneVariable: null,
            selectedChannelId: null,
          },
        },
      };
    },
    clearState(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchAutomationViewData(data, workflowState) {
      try {
        const { alice_store_id, ecommerce_type } =
          workflowState?.workflowAutomation?.selectedEcommerce;
        const getAllTriggerApiRes = await axios.get(
          config.automationWorkflow.getAllTriggerData(ecommerce_type),
          {
            headers: {
              'Project-Id': data.projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );

        if (getAllTriggerApiRes.status === 200) {
          const { trigger_list, common_components } =
            getAllTriggerApiRes?.data?.dataSource;

          dispatch.workflowAutomation.updateTriggerTagList(trigger_list);
          dispatch.workflowAutomation.updateExecutorOfCommonComponents(
            common_components?.executor
          );
        }
      } catch (err) {
        // Handle the error (e.g., log to the console or show a notification)
        console.error('Error fetching automation view data:', err);
      }
    },
    async fetchSelectedTriggerDetails(data, workflowState) {
      try {
        const { alice_store_id } =
          workflowState?.workflowAutomation?.selectedEcommerce;
        const getSelectedTriggerDetailsApiRes = await axios.get(
          config.automationWorkflow.getSelectedTriggerDetails(
            data?.ecommerceName,
            data?.selectedTriggerCode
          ),
          {
            headers: {
              'Project-Id': data.projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );

        if (getSelectedTriggerDetailsApiRes.status === 200) {
          const {
            executor,
            comparator,
            trigger_info,
            comparator_tag_list,
            executor_tag_list,
          } = getSelectedTriggerDetailsApiRes?.data?.dataSource;

          // Combine the executor list from the API response with the static executor list
          const addedStaticExecutorTags =
            UtilityContents.staticExecutorListData.concat(executor_tag_list);

          dispatch.workflowAutomation.updateComparatorTagList(
            comparator_tag_list
          );
          dispatch.workflowAutomation.updateComparatorTagDetails(comparator);
          dispatch.workflowAutomation.updateExecutorTagList(
            addedStaticExecutorTags
          );
          dispatch.workflowAutomation.updateExecutorDetails(executor);
          dispatch.workflowAutomation.updateTriggerInfor({
            ...trigger_info?.data_map,
            jinja: {
              ...(trigger_info?.template_language_implementation_data_map?.jinja
                ?.access_webhook_data ?? {}),
            },
          });
        }
      } catch (err) {}
    },
    async fetchEcommerceProductData({ teamId, searchValue }) {
      try {
        const getEcommerceProductsApiRes = await axios.get(
          config?.automationWorkflow?.getEcommerceProducts(teamId, searchValue)
        );
        if (getEcommerceProductsApiRes.status === 200) {
          dispatch.workflowAutomation.updateEcommerceProducts(
            UtilityFunctions.convertToProductSaveData(
              getEcommerceProductsApiRes.data.dataSource
            )
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchWhatsappTemplates(platformId) {
      try {
        const apiRes = await axios.get(
          config.automationWorkflow.whatsappTemplates(platformId)
        );
        if (apiRes?.status === 200) {
          const { whatsapp_message_templates } = apiRes?.data?.dataSource;
          // Filter templates based on conditions
          const filteredTemplates = whatsapp_message_templates.filter(
            (template) =>
              template.category !== 'AUTHENTICATION' &&
              template.status === 'approved'
          );

          // Add label property to the filtered templates
          const tempWhatsappTemplateList = filteredTemplates.map(
            (template) => ({
              ...template,
              label: template?.name,
            })
          );
          dispatch.workflowAutomation.updateWhatsappTemplateList(
            tempWhatsappTemplateList
          );
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchUserActionFlowList(data) {
      try {
        const userActionFlowRes = await axios.get(
          config.automationWorkflow.getUserActionFlowList(
            data?.projectId,
            data?.offset,
            data?.limit,
            data?.currency_code
          ),
          {
            headers: {
              'Project-Id': data?.projectId,
            },
          }
        );
        if (userActionFlowRes?.status === 200) {
          dispatch.workflowAutomation.updateUserActionFlowList(
            userActionFlowRes?.data?.dataSource
          );
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'createdTotalWorkflowAutomation',
            value: userActionFlowRes?.data?.total,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createAutomationFlow(data, workflowState) {
      try {
        const { alice_store_id } =
          workflowState?.workflowAutomation?.selectedEcommerce;

        const res = await axios.post(
          config.automationWorkflow.createAutomationFlow(),
          data.payload,
          {
            headers: {
              'Project-Id': data?.projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );
        if (res.status === 201) {
          return true;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async updateSelectedAutomationFlow(
      { payload, selectedId, projectId },
      workflowState
    ) {
      try {
        const { alice_store_id } =
          workflowState?.workflowAutomation?.selectedEcommerce;
        payload.project_id && delete payload.project_id;
        const res = await axios.put(
          config.automationWorkflow.updateSelectedActionFlow(selectedId),
          payload,
          {
            headers: {
              'Project-Id': projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );
        if (res.status === 200 && res?.data?.success) {
          return true;
        }
      } catch (err) {
        throw err;
      }
    },
    async fetchSelectedActionFlow(data, workflowState) {
      try {
        const { alice_store_id } =
          workflowState?.workflowAutomation?.selectedEcommerce;
        const selectedActionFlowApiRes = await axios.get(
          config?.automationWorkflow.getSelectedActionFlow(data.flowId),
          {
            headers: {
              'Project-Id': data.projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );
        if (selectedActionFlowApiRes?.status === 200) {
          const {
            data: {
              dataSource: {
                is_active,
                name,
                notes,
                project_id,
                trigger_code,
                version,
                flow_graph: { edges: automationEdges, nodes: automationNodes },
              },
            },
          } = selectedActionFlowApiRes;
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'selectedUserActionFlowData',
            value: {
              name: name,
              notes: notes,
              trigger_code: trigger_code || 'shopify_order_created',
              version: version,
              is_active: is_active,
              project_id: project_id,
            },
          });

          const formatedAutomationNodes =
            UtilityFunctions.handleSelectedAutomationNodes(automationNodes);

          dispatch.workflowAutomation.updateAutomationNode(
            formatedAutomationNodes
          );
          dispatch.workflowAutomation.updateAutomationEdge(automationEdges);
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'automationFlowDisplayViewType',
            value: 'createWorkflow',
          });
          return automationNodes;
        }
      } catch (err) {
        throw err;
      }
    },

    /**
     * Toggle the activation status of a user action flow.
     *
     * @param payload - The details of the user action flow to be toggled.
     * @returns The updated user action flow data.
     */
    async updateSelectedUserWorkflowInfo(data) {
      try {
        const {
          payload: { trigger_match_unique_ref },
        } = data;
        const apiRes = await axios.patch(
          config.automationWorkflow.updateSelectedUserActionFlowInfo(
            data.payload?.flowId
          ),
          {
            name: data.payload?.name,
            trigger_code: data.payload?.trigger_code,
            is_active: data.payload?.is_active,
          },
          {
            headers: {
              'Project-Id': data.projectId,
              'trigger-match-unique-ref': trigger_match_unique_ref ?? '',
            },
          }
        );
        if (apiRes.status === 200) {
          return apiRes?.data;
        } else {
          return false;
        }
      } catch (err) {
        throw err;
      }
    },

    async cloneUserWorkflow(data) {
      try {
        const { flowId, trigger_match_unique_ref, projectId } = data;
        const apiRes = await axios.post(
          config.automationWorkflow.cloneActionFlow(flowId),
          {},
          {
            headers: {
              'Project-Id': projectId,
              'trigger-match-unique-ref': trigger_match_unique_ref ?? '',
            },
          }
        );
        if (apiRes.status < 300) {
          return apiRes?.data;
        } else {
          return false;
        }
      } catch (err) {
        throw err;
      }
    },

    async getSelectedUserActionFlowLogList(data) {
      try {
        const { id } = data?.flowData;
        const res = await axios.get(
          config.automationWorkflow.getSelectedActionFlowLogListData(
            id,
            data?.offset,
            data?.limit
          ),
          {
            headers: {
              'Project-Id': data?.projectId,
            },
          }
        );
        if (res?.status === 200) {
          const { dataSource: logList, total } = res?.data;
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'selectedAutomationLogList',
            value: logList,
          });
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'totalHistoryData',
            value: total,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async deleteSelectedUserActionWorkflow(data) {
      try {
        const { projectId, trigger_match_unique_ref } = data;
        const deleteRes = await axios.delete(
          config.automationWorkflow.deleteSelectedWorkflow(data.flowId),
          {
            headers: {
              'Project-Id': projectId,
              'trigger-match-unique-ref': trigger_match_unique_ref,
            },
          }
        );
        if (deleteRes?.status === 200 && deleteRes?.data?.success) {
          toaster.success('Your workflow is deleted');
          return true;
        }
      } catch (err) {
        toaster.danger(
          err?.response?.data?.error ||
            'Oops! Something went wrong while deleting the workflow.'
        );
        throw err;
      }
    },
    async getSingleWorkflowLog({ flowId, projectId }, workflowState) {
      try {
        const res = await axios.get(
          config.automationWorkflow.getSingleWorkflowLogDetails(flowId),
          {
            headers: {
              'Project-Id': projectId,
            },
          }
        );
        if (res?.status === 200) {
          const { dataSource } = res?.data;

          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'selectedLogDetials',
            value: dataSource,
          });

          const formatedAutomationNodes =
            UtilityFunctions.handleSelectedAutomationNodes(
              dataSource.user_flow_result.nodes,
              {
                isLogView: true,
              }
            );
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'automationEdges',
            value: dataSource?.user_flow_result?.edges,
          });
          dispatch.workflowAutomation.updatePrimitiveStateData({
            key: 'automationNodes',
            value: formatedAutomationNodes,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    async fetchWhatsappTemplateDetails(payload) {
      // payload = {
      //   templateId: string,
      //   chaannelId: string,
      // }
      try {
        const res = await axios.get(
          config.inbox.whatsappTemplateDetails(
            'edge',
            payload.templateId,
            payload.channelId
          )
        );
        if (res.data.success) {
          // dispatch.inbox.updateWhatsappTemplateDetails(res.data.dataSource);
          return res.data.dataSource ?? [];
        }
        return [];
      } catch (err) {
        console.error(err);
        return [];
      }
    },

    async fetchWebhookSampleData({ projectId, triggerCode }, workflowState) {
      try {
        const { alice_store_id } =
          workflowState?.workflowAutomation?.selectedEcommerce;
        const res = await axios.get(
          config.automationWorkflow.getSampleWebHookData(triggerCode),
          {
            headers: {
              'Project-Id': projectId,
              'trigger-match-unique-ref': alice_store_id,
            },
          }
        );
        if (res?.status === 200) {
          const { dataSource } = res?.data;
          return dataSource;
        }
      } catch (error) {
        console.error('error on webhook sample data:', error);
        return {};
      }
    },

    async renderJinjaCompiledCode({ code, projectId, sampleData }) {
      try {
        const res = await axios.post(
          config.automationWorkflow.renderJinjaCode(),
          {
            jinja_code: code,
            data: { ...sampleData },
          },
          {
            headers: {
              'Project-Id': projectId,
            },
          }
        );
        if (res?.status === 200) {
          const { dataSource } = res?.data;
          return { success: true, data: dataSource };
        }
      } catch (error) {
        console.error('error on webhook sample data:', error);
        return {
          success: false,
          data:
            error?.response?.data?.error ??
            'Unable to render the output. Please check your template for errors or missing variables.',
        };
      }
    },
    async fetchMultiEcommerceData(teamId) {
      try {
        const res = await axios.get(
          config.automationWorkflow.getMultiEcommerceData(teamId)
        );
        if (res.data.success) {
          if (!!res.data.dataSource) {
            dispatch.workflowAutomation.updateIntegratedEcommerceList(
              res.data.dataSource
            );
            return true;
          }
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
  }),
};
