import * as dateFns from 'date-fns';
import { words } from 'lodash';
import { TemplateFilterState } from '../component/WhatsappMetrics/components/TemplateOverView/OverviewTable/TemplateOverviewTableContainer';

/**
 * Append query params to the url.
 * to delete a query param, set the value to null or undefined.
 * default mode is replace.
 * @returns The new url with the query params
 */
export function appendQueryParamsToUrl(
  queries: Record<string, string | number | boolean | null | undefined> = {},
  mode: 'replace' | 'append' = 'replace'
): URL {
  const newUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(newUrl.search);

  for (const [key, value] of Object.entries(queries)) {
    if (value === null || value === undefined) {
      searchParams.delete(key);
    } else if (mode === 'replace') {
      searchParams.set(key, value + '');
    } else {
      searchParams.append(key, value + '');
    }
  }

  newUrl.search = searchParams.toString();
  window.history.replaceState(null, '', newUrl);
  return newUrl;
}

export function convertMinutesToHumanReadable(minutes: number) {
  if (typeof minutes !== 'number' || isNaN(minutes)) {
    return null;
  }
  const now = new Date();
  const futureDate = dateFns.add(now, { minutes });
  // Calculate the difference in days
  const daysDifference = dateFns.differenceInDays(futureDate, now);
  // Adjust 'now' by adding the days to calculate the remaining hours
  const adjustedNow = dateFns.add(now, { days: daysDifference });
  const hoursDifference = dateFns.differenceInHours(futureDate, adjustedNow);
  // Construct the final string
  const daysLabel = daysDifference === 1 ? 'day' : 'days';
  const hoursLabel = hoursDifference === 1 ? 'hour' : 'hours';
  return ` ${daysDifference} ${daysLabel} ${hoursDifference} ${hoursLabel}`;
}

export const truncateText = (text: any, limit = 20) => {
  if (!(typeof text == 'string')) {
    return '';
  }
  return;
};

export const getUserNameInitials = (name: string) =>
  words(name).length > 1
    ? (words(name)[0][0] + words(name).slice(-1)[0][0]).toUpperCase()
    : name.slice(0, 2).toUpperCase();

/**
 * Parse a date string with a fallback to a specific number of days behind today.
 * @param dateString - The date string to parse
 * @param subDays - The number of days to subtract from today.
 * @param format - date-fns format of the date string
 * @returns The parsed date string or the fallback date string
 */
export const parseDateWithFallback = (
  dateString = '',
  subDays = 7,
  time: 'start' | 'end' = 'start',
  format = "yyyy-MM-dd'T'HH:mm:ss"
) => {
  // Attempt to parse the provided date string
  let parsedDate = dateFns.parseISO(dateString);
  if (time === 'start') {
    parsedDate = dateFns.startOfDay(parsedDate);
  } else {
    parsedDate = dateFns.endOfDay(parsedDate);
  }
  // Check if the parsed date is valid
  if (dateFns.isValid(parsedDate)) {
    return dateFns.format(parsedDate, format);
  } else {
    // If invalid, return a date 7 days behind today in specified format
    const fallbackDate = dateFns.subDays(new Date(), subDays);
    return dateFns.format(fallbackDate, format);
  }
};

export const formatAnalyticsState = (state: TemplateFilterState[] = []) => {
  const searchValue = state.find((v) => v.id === 'template-name')?.value || '';
  const templateType =
    state
      .find((v) => v.id === 'template-type')
      ?.value?.map((t) => t.value.toLowerCase()) || '';
  const templateId =
    state
      .find((v) => v.id === 'template-id')
      ?.value?.map((t) => t.value.toLowerCase()) || '';
  const templateStatus =
    state
      .find((v) => v.id === 'template-status')
      ?.value?.map((t) => t.value.toLowerCase()) || '';
  const finalObject: Record<string, string> = {
    searchValue,
    statusFilter: templateStatus,
    typeFilter: templateType,
    templateFilter: templateId,
  };

  return finalObject;
};
