import { FC, useState, useRef, useEffect } from 'react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Button } from 'libraryV2/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverClose,
} from 'libraryV2/ui/popover';
import { Check, ChevronsUpDown, User } from 'lucide-react';
import { cn } from 'libraryV2/utils';
import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import { PlatformInterface } from 'pages/inbox/inboxInterface';
import usePartner from 'components/customHooks/usePartner';
import IomnihubIcon from 'assets/icons/partnerIconSet/iomnihub.svg';

import { getUpdatedPlatformIconOutlined } from 'utilities/utils';
import { Badge } from 'libraryV2/ui/badge';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';

export interface IReprtingPlatforms
  extends Partial<
    Omit<Omit<PlatformInterface, 'platform_type'>, 'platform_type' | 'title'>
  > {
  id: number;
  platform_type: PlatformInterface['platform_type'] | 'all';
  title: string;
}

interface ReportingPlatformMultiSelectProps {
  platformList: IReprtingPlatforms[];
  selectedPlatformList: IReprtingPlatforms[];
  // onPlatformSelect: (selectedPlatform: IReprtingPlatforms) => void;
  onClearFilter?: () => void;
  onApplyFilter: (selectedPlatformList: IReprtingPlatforms[]) => void;
}

export const ReportingPlatformMultiSelect: FC<
  ReportingPlatformMultiSelectProps
> = ({
  platformList,
  selectedPlatformList,
  onClearFilter = null,
  onApplyFilter,
}) => {
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const { isPartnerRestricted, currentDomain } = usePartner();
  const shouldReplaceMyaliceLogo = isPartnerRestricted(
    'ChannelListDropdown/node/channel-logo'
  );

  const [localSelectedPlatformList, setLocalSelectedPlatformList] = useState<
    IReprtingPlatforms[]
  >(selectedPlatformList ?? []);

  const commandListRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [shouldDisableApplyFilter, setShouldDisableApplyFilter] =
    useState(true);

  const sortedPlatformList = (
    allPlatformList: IReprtingPlatforms[],
    selectedPlatforms: IReprtingPlatforms[]
  ) => {
    if (!selectedPlatforms?.length) return allPlatformList;

    return selectedPlatforms.concat(
      allPlatformList.filter(
        (platform) =>
          !selectedPlatforms.some((selected) => selected.id === platform.id)
      )
    );
  };

  const handleLocalPlatformSelect = (platform: IReprtingPlatforms) => {
    if (commandListRef.current) {
      setScrollPosition(commandListRef.current.scrollTop);
    }
    if (shouldDisableApplyFilter) {
      setShouldDisableApplyFilter(false);
    }
    const removedPlatformList = localSelectedPlatformList.filter(
      (p) => p.id !== platform.id
    );
    if (removedPlatformList.length === localSelectedPlatformList.length) {
      setLocalSelectedPlatformList([...localSelectedPlatformList, platform]);
    } else {
      setLocalSelectedPlatformList(removedPlatformList);
    }
  };

  useEffect(() => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = scrollPosition;
    }
  }, [localSelectedPlatformList, scrollPosition]);

  const renderDefaultLogo = () => {
    if (shouldReplaceMyaliceLogo) {
      switch (currentDomain) {
        case 'app.iomnihub.ai':
          return IomnihubIcon;
        default:
          return getUpdatedPlatformIconOutlined('all');
      }
    }
    return getUpdatedPlatformIconOutlined('all');
  };

  const renderPlatformLogo = (type: string) => {
    if (type === 'all') {
      return renderDefaultLogo();
    }
    return getUpdatedPlatformIconOutlined(type);
  };

  const displayValue = () => {
    if (!selectedPlatformList || selectedPlatformList?.length === 0)
      return (
        <div className='flex gap-1 text-sm truncate font-normal leading-5 rounded-lg'>
          <Avatar className='h-5 w-5 flex-shrink-0'>
            <AvatarImage
              className='rounded-full'
              src={renderPlatformLogo('all')}
            />
          </Avatar>
          <p className='truncate'>{'All Channels'}</p>
        </div>
      );

    return (
      <div className='flex gap-1.5 w-full items-center rounded-md'>
        <div className='bg-zinc-100 py-0.5 px-1 truncate flex gap-1 items-center rounded-md'>
          <Avatar className='h-5 w-5 flex-shrink-0'>
            <AvatarImage
              className='rounded-full'
              src={renderPlatformLogo(selectedPlatformList[0]?.platform_type)}
            />
          </Avatar>
          <span className='truncate text-xs font-normal leading-5'>
            {selectedPlatformList[0]?.title}
          </span>
        </div>
        {selectedPlatformList?.length > 1 ? (
          <Badge variant='ghost' className='bg-zinc-100 text-xs flex-shrink-0'>
            +{selectedPlatformList.length - 1}
          </Badge>
        ) : null}
      </div>
    );
  };
  const togglePopOver = () => {
    setIsPopOverOpen((openStatus) => !openStatus);
  };

  const handleClearFilter = () => {
    if (shouldDisableApplyFilter) {
      setShouldDisableApplyFilter(false);
    }
    setLocalSelectedPlatformList([]);
    if (onClearFilter) {
      onClearFilter();
    }
  };

  const handleApplyFilter = () => {
    onApplyFilter(localSelectedPlatformList);
    setIsPopOverOpen(false);
  };

  return (
    <div className='w-[240px] '>
      <Popover
        open={isPopOverOpen}
        onOpenChange={(openStatus) => {
          setIsPopOverOpen(openStatus);
          setShouldDisableApplyFilter(true);
          setLocalSelectedPlatformList(selectedPlatformList);
        }}
      >
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            onClick={togglePopOver}
            role='combobox'
            className='w-full justify-between'
          >
            {displayValue()}
            <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='max-w-[240px] w-fit bg-white p-0'>
          <Command>
            <CommandInput placeholder='Search channels' />
            <CommandList>
              <CommandEmpty>No result found.</CommandEmpty>
              <CommandGroup
                className='max-h-[200px] overflow-auto'
                ref={commandListRef}
              >
                {sortedPlatformList(
                  platformList,
                  localSelectedPlatformList
                )?.map((singlePlatform, _) => {
                  const isSelected =
                    localSelectedPlatformList?.findIndex(
                      (v) => v?.id === singlePlatform?.id
                    ) !== -1;
                  const platformLogoSource = renderPlatformLogo(
                    singlePlatform?.platform_type ?? 'all'
                  );
                  return (
                    <CommandItem
                      className='flex relative text-sm items-center gap-1'
                      key={singlePlatform.title + singlePlatform?.id}
                      onSelect={() => handleLocalPlatformSelect(singlePlatform)}
                    >
                      <div
                        className={cn(
                          'mr-1 flex h-4 w-4 items-center justify-center rounded-md border flex-shrink-0 border-textPrimary',
                          isSelected ? 'bg-primary border-0 ' : 'opacity-50'
                        )}
                      >
                        <Check
                          size={14}
                          strokeWidth={2.5}
                          className={cn('text-white', { hidden: !isSelected })}
                        />
                      </div>
                      {/* platform logo */}
                      {platformLogoSource ? (
                        <Avatar className='h-5 w-5 flex-shrink-0'>
                          <AvatarImage
                            className='rounded-full'
                            src={platformLogoSource}
                          />
                          <AvatarFallback>
                            <User className='w-4 text-textPrimary h-4' />
                          </AvatarFallback>
                        </Avatar>
                      ) : null}
                      {/* platform title */}
                      <TooltipProviderCustomised
                        asChild
                        content={
                          singlePlatform?.title?.length > 20
                            ? singlePlatform?.title
                            : ''
                        }
                      >
                        <span className='text-sm truncate font-medium'>
                          {singlePlatform?.title ?? ''}
                        </span>
                      </TooltipProviderCustomised>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
            <div className='flex justify-between p-2 border-t'>
              <Button
                variant='outline'
                disabled={!localSelectedPlatformList?.length}
                size='sm'
                onClick={handleClearFilter}
              >
                Clear Filter
              </Button>
              <PopoverClose asChild>
                <Button
                  size='sm'
                  onClick={handleApplyFilter}
                  disabled={shouldDisableApplyFilter}
                >
                  Apply Filter
                </Button>
              </PopoverClose>
            </div>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
