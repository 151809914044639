import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { WhatsappBspIcon } from 'pages/integration/utils/content';
import useWhatsappMetrics from '../../../hooks/useWhatsappMetrics';
import { useSelector } from 'react-redux';

interface Props {}

const WhatsappAnalyticsLogsHeader: React.FC<Props> = () => {
  const { whatsappAccountInfo, selectedWhatsappTemplateName } = useSelector(
    (state: any) => state.reportingV2
  );
  const { updateSelectedReportingViewMode } = useWhatsappMetrics();
  const renderAutomationDashboardHeaderView = () => {
    return (
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center text-xl font-bold text-gray-900'>
          <div
            className='flex items-center justify-center border border-[#DFDFE2] w-8 h-8 rounded-md mr-2 cursor-pointer'
            onClick={() => updateSelectedReportingViewMode('reporting')}
          >
            <ChevronLeftIcon className='w-6 h-6 text-[#18181B]' />
          </div>
          {selectedWhatsappTemplateName ?? ''}
        </div>
        {whatsappAccountInfo !== null && (
          <div className='flex items-center'>
            <p className='text-[#18181B] font-medium mr-2'>
              {whatsappAccountInfo?.business_name}
              <span className='text-[#71717A] font-normal ml-1'>
                ({whatsappAccountInfo?.display_phone_number})
              </span>
            </p>
            <div>
              <img src={WhatsappBspIcon} alt='' className='w-5 h-5' />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      {renderAutomationDashboardHeaderView()}
    </div>
  );
};

export default WhatsappAnalyticsLogsHeader;
