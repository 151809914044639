import { useParams } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { IReprtingPlatforms } from '../interface';
import dayjs from 'dayjs';

interface IAgentMetricsPayload {
  projectId: number;
  platformIds: string;
  start: string;
  end: string;
  endpoint: string;
}

export const useAgentMetrics = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const projectId = routeParams?.projectId;

  const dateRange = useSelector((state: any) => state.reportingV2.dateRange);
  const platformsToFilter: IReprtingPlatforms[] = useSelector(
    (state: any) => state.reportingV2.platformsToFilter
  );

  //  reporting metrics states
  const {
    averageQueueTime,
    averageResolutionTime,
    averageFirstResponseTime,
    agentAverageResponseTime,
    averageSalesConversion,
    averageOnlineTime,
    agentStats,
    shouldFetchData,
    isBusinessHourEnabled,
  } = useSelector((state: any) => state.reportingV2);

  // selected project from dashboard
  const selectedProject = useSelector(
    (state: any) => state.dashboard?.selectedProject
  );

  // loading states of reporting metrics effects
  const loadingEffects = useSelector(
    (state: any) => state?.loading?.effects?.reportingV2
  );
  const loader = loadingEffects?.fetchUserReportDataUserMetricsLineGraph;
  const statLoader = loadingEffects?.fetchUserReportDataDefaultAppend;
  const exportLoader = loadingEffects?.exportAgentDetails;

  // agent ticket table states

  const updateStateData = (key: string, value: any) =>
    dispatch.reportingV2.updateStateData({ key, value });
  const platformList = useSelector(
    (state: any) => state.dashboard?.platformList
  );
  const hasSLAConnectionOnAnyPlatform = platformList?.some(
    (platform: IReprtingPlatforms) => platform?.is_sla_connected === true
  );

  // effects
  const fetchUserReportData = (payload: IAgentMetricsPayload) =>
    dispatch.reportingV2.fetchUserReportData(payload);

  const fetchUserReportDataDefaultAppend = (payload: IAgentMetricsPayload) =>
    dispatch.reportingV2.fetchUserReportDataDefaultAppend(payload);
  const exportAgentDetails = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
      isBusinessHourEnabled,
    };
    dispatch.reportingV2.exportAgentDetails(payload);
  };

  // fetch agent matrics
  const fetchAgentMetrics = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
      isBusinessHourEnabled,
    };
    fetchUserReportData({
      ...payload,
      endpoint: 'get_agent_average_first_response_time',
    });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_agent_average_resolution_time',
    });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_agent_average_response_time',
    });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_agent_sales_conversion_ratio',
    });
    /*
     * found in old code but there is no endpoint for this in BE, hence commented incase we need it in future
      fetchUserReportData({
        ...payload,
        endpoint: 'get_agent_average_online_time',
      });
    */
    fetchUserReportDataDefaultAppend({
      ...payload,
      endpoint: 'get-agent-table',
    });
  };

  return {
    projectId,
    platformsToFilter,
    averageQueueTime,
    averageResolutionTime,
    averageFirstResponseTime,
    agentAverageResponseTime,
    averageSalesConversion,
    averageOnlineTime,
    agentStats,
    selectedProject,
    isBusinessHourEnabled,
    loader,
    statLoader,
    exportLoader,
    updateStateData,
    dateRange,
    platformList,
    hasSLAConnectionOnAnyPlatform,
    fetchAgentMetrics,
    shouldFetchData,
    exportAgentDetails,
  };
};
