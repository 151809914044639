import React from 'react';
import Button from '../../../library/button';

import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { navigate } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  channelType: string;
  selectedProject: selectedProjectProps;
  handleButtonOnClick: (navId: number) => void;
}

const ChannelTitle = {
  livechat: 'Livechat',
  shopify: 'Shopify',
  woocommerce: 'Woocommerce',
  facebook_messenger: 'Facebook Messenger',
  facebook_feed: 'Facebook Feed',
  instagram_feed: 'Instagram Feed',
  line_messenger: 'Line',
  telegram_messenger: 'Telegram',
  viber_messenger: 'Viber',
  instagram_messenger: 'Instagram Messenger',
};

const OnboardingModalContent: React.FC<Props> = ({
  channelType,
  selectedProject,
  handleButtonOnClick,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  return (
    <div className='flex flex-col mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-lg'>
      <div className='flex justify-center'>
        <CheckCircleIcon className='w-10 h-10 text-green-400' />
      </div>
      <br />
      <div className='items-center mx-2 mb-3 text-center sm:mx-auto sm:w-full sm:max-w-lg'>
        {isRtlLanguage ? (
          <h2 className='text-2xl font-extrabold text-gray-900'>
            {t('has been integrated')}{' '}
            {t(!!channelType && ChannelTitle[channelType])} {t('successfully')}
          </h2>
        ) : (
          <h2 className='text-2xl font-extrabold text-gray-900'>
            {`${t(!!channelType && ChannelTitle[channelType])} ${t(
              'has been successfully integrated'
            )}`}
          </h2>
        )}
        <p className='text-gray-600 text-md'>
          {t('Your store customers can communicate with you right now!')}
        </p>
      </div>
      <br />
      <>
        <div className='flex justify-center mb-3'>
          <span className='text-xs font-semibold text-gray-500'>
            {t('WHAT TO DO NEXT?')}
          </span>
        </div>
        {['livechat', 'shopify', 'woocommerce'].includes(channelType) && (
          <button
            data-testid='button-element'
            onClick={() => {
              navigate('/integrations/integrated?is_from_onboarding=true');
              handleButtonOnClick(2);
            }}
            className={`inline-flex border border-gray-300 items-center text-center px-4 py-3 w-full justify-between focus:outline-none transition ease-out duration-200 mb-3 text-[13px] text-gray-700 font-medium rounded-full`}
          >
            <span> {t('Customize Livechat')}</span>
            <ChevronRightIcon className='w-4 h-4 text-gray-400' />
          </button>
        )}

        <button
          data-testid='button-element'
          onClick={() => {
            navigate(`projects/${selectedProject.id}/automation`);
            handleButtonOnClick(3);
          }}
          className={`inline-flex border border-gray-300 items-center text-center px-4 py-3 w-full justify-between focus:outline-none transition ease-out duration-200 mb-3 text-[13px] text-gray-700 font-medium rounded-full`}
        >
          <span> {t('Create Automation')}</span>
          <ChevronRightIcon className='w-4 h-4 text-gray-400' />
        </button>

        <Button
          isFullWidth={true}
          isCapsuleButton={true}
          size='lg'
          onClick={() => {
            navigate(`/dashboard`);
            handleButtonOnClick(0);
          }}
          className='mb-3 text-[13px] text-[#0078CF] font-medium'
        >
          {t('Or close this window')}{' '}
        </Button>
      </>
    </div>
  );
};
export default OnboardingModalContent;
