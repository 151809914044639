import { useSelector, useDispatch } from 'react-redux';
import { TicketInterface } from '../inboxInterface';
import { getQueryParameterValueFromFilterData } from '../utils/functions';
import { getIsoDatetimeString } from 'utilities/utils';

const useBulkAction = () => {
  const dispatch = useDispatch();
  const inboxData = useSelector((state: any) => state.inbox);
  const dashboardData = useSelector((state: any) => state.dashboard);

  const getPastTenseFormat = () => {
    switch (inboxData.bulkData?.action) {
      case 'close':
        return 'closed';
      case 'tag':
        return 'tagged';
      case 'assign':
        return 'assigned';
      default:
        return 'updated';
    }
  };

  const showBulkIcon = () => {
    return (
      inboxData?.ticketIsResolvedStatus !== 1 &&
      inboxData?.ticketQueue?.type !== 'private'
    );
  };

  const handleBulkToggle = () => {
    inboxData.bulkData = {
      action: '',
      tickets: [],
      assign_user: 0,
      assign_group: 0,
      tags: [],
    };
    dispatch.inbox.updateBulkData(inboxData.bulkData);
    dispatch.inbox.updateSateData({
      key: 'isBulkActive',
      value: !inboxData.isBulkActive,
    });
  };

  const handleBulkTicketSelection = (ticketId: number) => {
    const filteredTickets = inboxData?.bulkData?.tickets?.filter(
      (tid: number) => tid !== ticketId
    );
    if (
      inboxData?.bulkData?.tickets?.length === filteredTickets.length &&
      inboxData?.bulkData?.tickets?.length < 50
    ) {
      inboxData.bulkData.tickets = [...filteredTickets, ticketId];
    } else {
      inboxData.bulkData.tickets = [...filteredTickets];
    }

    dispatch.inbox.updateBulkData(inboxData.bulkData);
  };

  const isTicketSelectedForBulk = (ticketId: number) => {
    return inboxData?.bulkData?.tickets.includes(ticketId);
  };

  // max 50 tickets are selectable
  const selectUnselectAllTicket = () => {
    if (inboxData?.bulkData?.tickets.length > 0) {
      inboxData.bulkData.tickets = [];
    } else {
      inboxData.bulkData.tickets =
        inboxData.ticketList.length <= 100
          ? [
              ...inboxData.ticketList.map(
                (ticket: TicketInterface) => ticket?.id
              ),
            ]
          : [
              ...inboxData.ticketList
                .slice(0, 100)
                .map((ticket: TicketInterface) => ticket?.id),
            ];
    }

    dispatch.inbox.updateBulkData(inboxData.bulkData);
  };

  const updateBulkAction = async (actionType: string) => {
    await dispatch.inbox.updateBulkData({
      ...inboxData.bulkData,
      action: actionType,
    });
  };

  const assignUser = async (agentId: number, agentGroupId: number) => {
    await dispatch.inbox.updateBulkData({
      ...inboxData.bulkData,
      assign_user: agentId,
      assign_group: agentGroupId,
    });
  };

  const applyBulkAction = async (): Promise<{
    success: boolean;
    message: string;
  }> => {
    const projectId = dashboardData?.selectedProject?.id;

    const bulkActionData = {
      ...inboxData?.bulkData,
      action:
        inboxData?.bulkData?.action === 'close'
          ? 'resolve'
          : inboxData?.bulkData?.action,
    };
    const queue_data = {
      limit: inboxData.limit,
      offset: inboxData.offset,
      order: 'desc',
      queue: inboxData.ticketQueue.type,
      is_resolved: inboxData.ticketIsResolvedStatus,
      channels: getQueryParameterValueFromFilterData(
        'channels',
        inboxData.ticketFilterData
      ),
      agents: getQueryParameterValueFromFilterData(
        'agents',
        inboxData.ticketFilterData
      ),
      groups: getQueryParameterValueFromFilterData(
        'groups',
        inboxData.ticketFilterData
      ),
      tags: getQueryParameterValueFromFilterData(
        'tags',
        inboxData.ticketFilterData
      ),
      start:
        getIsoDatetimeString(
          inboxData.ticketFilterData?.startDate,
          dashboardData?.selectedProject?.timezone_offset
        ) || '',
      end:
        getIsoDatetimeString(
          inboxData.ticketFilterData?.endDate,
          dashboardData?.selectedProject?.timezone_offset
        ) || '',
    };

    const result = await dispatch.inbox.applyBulkAction({
      projectId,
      data: { ...bulkActionData, queue_data },
    });

    if (result.success) {
      dispatch.inbox.updateSateData({
        key: 'ticketList',
        value: result.dataSource[0],
      });
      dispatch.inbox.updateSateData({
        key: 'totalTicketCount',
        value: result.dataSource[1],
      });
      dispatch.inbox.updateSateData({
        key: 'selectedTicket',
        value:
          result.dataSource[0].length !== 0 ? result.dataSource[0][0] : null,
      });
    }

    return {
      success: result.success,
      message: `${result.dataSource[2].success} out of ${
        result.dataSource[2].total_count
      } ${getPastTenseFormat()} successfully`,
    };
  };

  const getTicketsWithNoTags = () => {
    const selectedTickets = inboxData?.ticketList.filter(
      (ticket: TicketInterface) =>
        inboxData.bulkData?.tickets.includes(ticket?.id)
    );
    return selectedTickets.filter(
      (ticket: TicketInterface) => ticket?.tags?.length < 1
    );
  };

  return {
    isBulkActionActive: inboxData.isBulkActive,
    bulkData: inboxData.bulkData,
    handleBulkTicketSelection,
    selectUnselectAllTicket,
    isTicketSelectedForBulk,
    getTicketsWithNoTags,
    updateBulkAction,
    handleBulkToggle,
    applyBulkAction,
    showBulkIcon,
    assignUser,
    agentData: {
      groups: inboxData.agentGroup,
      agents: inboxData.agentList,
    },
  };
};

export default useBulkAction;
