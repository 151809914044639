import { Fragment } from 'react';
import { connect } from 'react-redux';
import { Combobox } from '@headlessui/react';
import React, { useRef, useEffect } from 'react';

import TagCheckBox from './TagCheckBox';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import Button from '../../../../../../library/button';
import TagIcon from '../../../../assets/iconComponent/TagIcon';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  projectId: number;
  hasSupervisorAccess: boolean;
  tagList: TicketTagInterface[];
  currentTicket: TicketInterface;
  fetchAllTicketTag: (projectId: number) => void;
  assignTicketTag: (payload: AssignTicketTagPayload) => void;
  createTicketTag: (payload: CreateTicketTagPayload) => TicketTagInterface;
}

const TagDropDown: React.FC<Props> = ({
  tagList,
  projectId,
  currentTicket,
  hasSupervisorAccess,
  createTicketTag,
  assignTicketTag,
  fetchAllTicketTag,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);
  const [tagFilterData, setTagFilterData] = React.useState<number[]>([]);

  const ref = useRef<HTMLDivElement>(null);

  const handleCloseDropDown = () => {
    setIsOpen(false);
    setQuery('');
    setShowActions(false);
  };

  const handleTagFilterData = (updatedFilterData: number[]) => {
    setTagFilterData(updatedFilterData);
  };

  const assignTicketTagFunction = (selectedTags: number[]) => {
    let tags: TicketTagInterface[] = [];
    tagList.forEach((singleTag: TicketTagInterface) => {
      if (selectedTags.includes(singleTag.id)) {
        tags.push({ id: singleTag.id, name: singleTag.name });
      }
    });
    let payloadData = {
      body: { tags: selectedTags },
      tags: { tagList: tags },
    };
    assignTicketTag(payloadData);
  };

  const createTag = async () => {
    let payload = { name: query };
    let res = await createTicketTag(payload);
    if (!!res) {
      tagList.unshift(res);
      let newTag = [res.id];
      let assignableTags = currentTicket.tags;
      assignableTags.forEach((tag) => {
        newTag.push(tag.id);
      });
      await assignTicketTagFunction(newTag);
      handleCloseDropDown();
    }
  };

  const handleCurrentTagSelection = (tags: TicketTagInterface[]) => {
    let currentTagIds: number[] = [];
    if (!!tags) {
      tags.map((tag: TicketTagInterface) => currentTagIds.push(tag.id));
      handleTagFilterData(currentTagIds);
    }
  };

  const handleTagSelection = (value: number) => {
    let selectedTagIds: number[] = [];
    if (tagFilterData.includes(value)) {
      selectedTagIds = tagFilterData.filter((id: number) => id !== value);
    } else {
      selectedTagIds = [...tagFilterData, value];
    }
    handleTagFilterData(selectedTagIds);
  };

  const fetchData = async () => {
    await fetchAllTicketTag(projectId);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!ref?.current?.contains(event.target)) {
        handleCloseDropDown();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [ref]);

  const getDropDownData = () => {
    if (!!tagList && tagList.length === 0) return [];
    let tagOptions: TicketTagFilterInterface[] = [];
    !!tagList &&
      tagList.forEach((tag: TicketTagInterface) => {
        tagOptions = [
          ...tagOptions,
          {
            value: tag.id,
            label: tag.name,
          },
        ];
      });
    // fieldwise option filteration
    if (query.length !== 0) {
      tagOptions = tagOptions.filter((tag) => {
        return tag.label.toLowerCase().includes(query.toLowerCase());
      });
    }
    return tagOptions;
  };

  React.useEffect(() => {
    if (!!currentTicket) {
      handleCurrentTagSelection(currentTicket?.tags);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Menu as='div' className='relative' ref={ref}>
      <Button
        onClick={() => {
          setIsOpen(!isOpen);
          handleCurrentTagSelection(currentTicket?.tags);
        }}
        className='bg-white mr-1.5'
        isCapsuleButton={true}
      >
        <TagIcon
          width={20}
          height={20}
          fillColor={`${isOpen ? '#10B981' : '#6B7280'}`}
        />
      </Button>
      <Transition
        show={isOpen}
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className='absolute ltr:left-0 rtl:right-0 z-20 mt-2 origin-top-right bg-white rounded-md
          shadow-lg max-w-[650px] min-w-[255px] ring-1 ring-black ring-opacity-5 focus:outline-none'
        >
          <Combobox as='div' onChange={() => ''}>
            <div className='p-3'>
              <div className='flex justify-between'>
                <Combobox.Label className='block text-sm font-semibold text-gray-800'>
                  {t('Tags')}
                </Combobox.Label>
                <XMarkIcon
                  onClick={() => handleCloseDropDown()}
                  className='w-5 h-5 text-gray-500 cursor-pointer'
                  aria-hidden='true'
                />
              </div>
              {/* Search */}
            </div>
            <div className='px-3'>
              <div className='relative w-full'>
                <div className='absolute inset-y-0 flex items-center pointer-events-none ltr:left-0 rtl:right-2 ltr:pl-3'>
                  <MagnifyingGlassIcon
                    className='w-5 h-5 text-gray-400'
                    aria-hidden='true'
                  />
                </div>
                <input
                  key={'tags'}
                  type='text'
                  placeholder={`${
                    !!hasSupervisorAccess
                      ? t('Search or add new tags')
                      : t('Search tags')
                  }`}
                  value={isOpen ? query : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQuery(e.target.value)
                  }
                  maxLength={30}
                  onKeyDown={(e: any) => {
                    e.code === 'Space' && e.stopPropagation();
                  }}
                  className='w-full py-2 pl-10 bg-white border border-gray-300 rounded-md shadow-sm ltr:pr-10 rtl:pr-8 focus:ring-1 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm'
                />
              </div>
            </div>
            {query.length !== 0 && !!hasSupervisorAccess && (
              <div className='px-3 pt-2.5'>
                <div
                  className='text-[#0078CF] cursor-pointer break-words'
                  onClick={createTag}
                >
                  {t(`Create "{{${query}}}" as a new tag.`)}
                </div>
              </div>
            )}
            {/* Tag Box */}
            <div className='bg-white rounded-md shadow-lg sm:text-sm'>
              <TagCheckBox
                query={query}
                showActions={showActions}
                tagList={getDropDownData()}
                selectedTicketTags={tagFilterData}
                handleShowActions={setShowActions}
                handleTagSelection={handleTagSelection}
                assignTicketTag={assignTicketTagFunction}
                handleCloseDropDown={handleCloseDropDown}
                hasSuperVisorAccess={hasSupervisorAccess}
                handleCurrentTagSelection={handleCurrentTagSelection}
              />
            </div>
          </Combobox>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const mapState = (state: any) => ({
  tagList: state.inbox.allTicketTags,
  currentTicket: state.inbox.selectedTicket,
  projectId: state.dashboard.selectedProject?.id,
});

const mapDispatch = (dispatch: any) => ({
  assignTicketTag: (payload: object) => dispatch.inbox.assignTicketTag(payload),
  fetchAllTicketTag: (projectId: number) =>
    dispatch.inbox.fetchAllTicketTag(projectId),
  updateSingleTicket: (ticketData: TicketInterface) =>
    dispatch.inbox.updateSingleTicket(ticketData),
  createTicketTag: (payload: object) => dispatch.inbox.createTicketTag(payload),
});

export default connect(mapState, mapDispatch)(TagDropDown);
