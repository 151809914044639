import { Table } from '@tanstack/react-table';
import { Button } from 'libraryV2/ui/button';
import { Input } from 'libraryV2/ui/input';
import { Download, Search, Star, User } from 'lucide-react';
import { CsatTableFilterMenu } from './CsatTableFilterMenu';
import { Cross2Icon } from '@radix-ui/react-icons';
import useCsatReporting from 'pages/reportingV2/hooks/useCsatReporting';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function CsatTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const {
    exportCSATSData,
    totalResponseCount,
    csatRatingSelectionOptions,
    csatAgentSelectionOptions,
  } = useCsatReporting();
  const tableFilterState = table.getState().columnFilters;

  const removeAgentFromSelection = (agentId: number) => {
    const oldValue = table.getColumn('agent')?.getFilterValue() as string[];
    const newValue = oldValue.filter((v) => v !== agentId.toString());
    table
      .getColumn('agent')
      ?.setFilterValue(newValue.length ? newValue : undefined);
  };
  const removeRatingsFromSelection = (ratingValue: string) => {
    const oldValue = table.getColumn('rating')?.getFilterValue() as string[];
    const newValue = oldValue.filter((v) => v !== ratingValue);
    table
      .getColumn('rating')
      ?.setFilterValue(newValue.length ? newValue : undefined);
  };

  const renderAgentFilterView = () => {
    let agentFilterData = tableFilterState.find((ts) => ts.id === 'agent');
    if (!agentFilterData) {
      return null;
    }
    const agentDetails = (agentFilterData.value as string[]).map((v) => {
      return csatAgentSelectionOptions.find((a) => a.value + '' === v);
    });

    if (!agentDetails || !Array.isArray(agentDetails)) {
      return null;
    }

    return (
      <div className='flex gap-2 items-center'>
        <span className='text-xs leading-5 text-textSecondary'>Agents:</span>
        <div className='flex flex-wrap gap-1'>
          {agentDetails.map((v) => (
            <Button
              key={v?.value}
              className='text-xs bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
              variant={'ghost'}
            >
              <span>{v?.label}</span>
              <Cross2Icon
                onClick={() => removeAgentFromSelection(v?.value!)}
                stroke='2'
                className='w-4 h-4 text-textPrimary-disable stroke-2'
              />
            </Button>
          ))}
        </div>
      </div>
    );
  };

  const renderRatingsFilterView = () => {
    let ratingFilterData = tableFilterState.find((ts) => ts.id === 'rating');
    if (!ratingFilterData) {
      return null;
    }
    const ratingDetails = (ratingFilterData.value as string[]).map((v) => {
      return csatRatingSelectionOptions.find((a) => a.value + '' === v);
    });

    if (
      !ratingDetails ||
      !Array.isArray(ratingDetails) ||
      ratingDetails.length < 1
    ) {
      return null;
    }

    return (
      <div className='flex gap-2 items-center'>
        <span className='text-xs leading-5 text-textSecondary'>Ratings:</span>
        <div className='flex flex-wrap gap-1'>
          <Button
            key={ratingDetails[0]?.value}
            className='text-xs bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
            variant={'ghost'}
          >
            <span>{ratingDetails[0]?.label}</span>
            <Cross2Icon
              onClick={() =>
                removeRatingsFromSelection(ratingDetails[0]?.value!)
              }
              stroke='2'
              className='w-4 h-4 text-textPrimary-disable stroke-2'
            />
          </Button>

          <Popover>
            <PopoverTrigger
              disabled={ratingDetails.length <= 1}
              className={cn({ hidden: ratingDetails.length <= 1 })}
            >
              <Button
                key={ratingDetails[0]?.value}
                className='text-xs bg-zinc-100 text-textPrimary flex items-center h-6 px-2 py-0.5'
                variant={'ghost'}
              >
                <span className='text-xs font-bold'>+</span>
                <span>{ratingDetails.length - 1}</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className='bg-white w-fit'>
              <div className='flex flex-col gap-2'>
                {ratingDetails.slice(1).map((v) => (
                  <Button
                    key={v?.value}
                    className='text-xs w-fit bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
                    variant={'ghost'}
                  >
                    <span>{v?.label}</span>
                    <Cross2Icon
                      onClick={() => removeRatingsFromSelection(v?.value!)}
                      stroke='2'
                      className='w-4 h-4 text-textPrimary-disable stroke-2'
                    />
                  </Button>
                ))}
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>
    );
  };

  const renderSearchText = () => {
    return (
      <div className='flex relative items-center'>
        <Search className='absolute left-2 h-4 w-4 text-zinc-500 transform top-1/2 -translate-y-1/2' />
        <Input
          placeholder='Search'
          value={
            (table.getColumn('customerName')?.getFilterValue() as string) ?? ''
          }
          onChange={(event) =>
            table.getColumn('customerName')?.setFilterValue(event.target.value)
          }
          className='pl-7 h-8 mt-0 w-[150px] lg:w-[250px]'
        />
      </div>
    );
  };

  const renderAgentFilterSelectionMenu = () => {
    return (
      <CsatTableFilterMenu
        column={table.getColumn('agent')}
        title='Search Agents'
        filterScope='agents'
      >
        <Button
          className='border py-1.5 px-[10px] border-border border-dashed h-8 text-textPrimary text-xs space-x-1'
          variant={'ghost'}
        >
          <User className='h-4 w-4' />
          <span>Agents</span>
        </Button>
      </CsatTableFilterMenu>
    );
  };

  const renderRatingsFilterSelectionMenu = () => {
    return (
      <CsatTableFilterMenu
        column={table.getColumn('rating')}
        title='Ratings'
        filterScope='ratings'
      >
        <Button
          className='border py-1.5 px-[10px] border-border border-dashed h-8 text-textPrimary text-xs space-x-2'
          variant={'ghost'}
        >
          <Star className='h-4 w-4' />
          <span>Ratings</span>
        </Button>
      </CsatTableFilterMenu>
    );
  };

  const renderClearFilterButton = () => {
    const channelAndRatingFilter = tableFilterState.filter(
      (f) => f.id !== 'customerName'
    );

    const hasFilter = channelAndRatingFilter.length;

    if (!hasFilter) {
      return null;
    }
    return (
      <Button
        variant={'ghost'}
        onClick={() =>
          table.setColumnFilters(
            tableFilterState.filter((f) => f.id === 'customerName')
          )
        }
        className='flex  gap-2 items-center bg-zinc-100 h-8 px-2.5 py-1.5'
      >
        <Cross2Icon className='w-4 h-4' />
        <span className='text-xs text-textPrimary'>Clear Filter</span>
      </Button>
    );
  };

  const renderExportTableDataButton = () => {
    return (
      <Button
        disabled={totalResponseCount < 1}
        onClick={() => {
          exportCSATSData(table.getState().columnFilters);
        }}
        className='border bg-white py-1.5 px-[10px] border-border h-8 text-textPrimary text-xs space-x-2'
        variant={'ghost'}
      >
        <Download className='w-4 h-4' />
        <span>Export</span>
      </Button>
    );
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <div className='flex items-center justify-between'>
        <div className='flex flex-1 items-center space-x-2'>
          {renderSearchText()}
          {renderAgentFilterSelectionMenu()}
          {renderRatingsFilterSelectionMenu()}
          {renderClearFilterButton()}
        </div>
        {renderExportTableDataButton()}
      </div>
      <div className='w-full flex  gap-3 flex-wrap'>
        {renderAgentFilterView()}
        {renderRatingsFilterView()}
      </div>
    </div>
  );
}
