import { IPolicy, Priority } from '../interface';

const usePolicy = () => {
  let tableHeaderClass = 'px-3 py-2 text-left subtitle-medium';
  let inputFieldClass =
    'w-full px-3 py-1 h-8 text-sm border bg-white rounded-md border-border-light focus:border-primary placeholder:subtitle-regular focus:ring-0';
  let defaultPolicies: IPolicy[] = [
    {
      priority: 3,
      first_response_time: 10,
      resolution_time: 30,
      should_consider_business_hour: true,
    },
    {
      priority: 2,
      first_response_time: 20,
      resolution_time: 60,
      should_consider_business_hour: true,
    },
    {
      priority: 1,
      first_response_time: 30,
      resolution_time: 120,
      should_consider_business_hour: true,
    },
    {
      priority: 0,
      first_response_time: 60,
      resolution_time: 240,
      should_consider_business_hour: true,
    },
  ];

  const getPriorityLabel = (priority: Priority): string => {
    switch (priority) {
      case Priority.Low:
        return 'Low';
      case Priority.Medium:
        return 'Medium';
      case Priority.High:
        return 'High';
      case Priority.Urgent:
        return 'Urgent';
      default:
        return 'Unknown';
    }
  };

  return {
    inputFieldClass,
    defaultPolicies,
    tableHeaderClass,
    getPriorityLabel,
  };
};

export default usePolicy;
