/* eslint-disable @typescript-eslint/no-unused-vars */
import useTranslation from 'components/customHooks/useTranslation';
import WhatsappFileUploader from 'components/utilityComponent/WhatsappFileUploader';
import {
  analyzeTemplateBody,
  handleTemplateBodyVariableAutoComplete,
} from '../utils/whatsappUtility';
import useWhatsappTemplateData, {
  IWhatsappCarouselCard,
} from 'pages/integration/hooks/useWhatsappTemplateData';
import { sequenceProps } from 'pages/integration/interface';
import { WhatsappTemplateActionType } from '../../components/CreateWhatsAppTemplateV2';
import React, { FC, useRef, useState, useEffect } from 'react';
import CarouselTemplateButtonBlock from './CarouselTemplateButtonBlock';
import WhatsappTemplateSampleVariables from './WhatsappTemplateSampleVariables';
import { PlusIcon } from '@heroicons/react/24/solid';

interface CarouselTemplateCardProps {
  actionType: WhatsappTemplateActionType;
  cardId: number;
  activeCard?: IWhatsappCarouselCard;
  sequence: sequenceProps[];
  setHasInvalidBodyVariable: (value: boolean) => void;
}
const MAX_CAROUSEL_CARD_TEXT_SIZE = 160;

const CarouselTemplateCard: FC<CarouselTemplateCardProps> = ({
  cardId,
  actionType,
  sequence,
  setHasInvalidBodyVariable,
}) => {
  const { t } = useTranslation();
  const {
    whatsappTemplateState,
    updateCarouselTemplateCardsData,
    updateSingleCarouselCardData,
  } = useWhatsappTemplateData();
  const cardList = whatsappTemplateState.cards;
  const activeCard = cardList[cardId - 1];
  const isTemplateActionView = actionType === 'VIEW';
  const cardBody = activeCard.body ?? '';
  const [isValidTemplateBody, setIsValidTemplateBody] = useState(true);
  const [cursorPosition, setCursorPosition] = useState(cardBody.length);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [maxCount, setMaxCount] = useState(1);

  const handleCardHeaderUpload = (fileUrl: string) => {
    // find & update active header value
    const updatedCards = cardList?.map((c) => {
      if (c?.id !== activeCard?.id) {
        return c;
      }
      c.header.value = fileUrl;
      return c;
    });
    updateCarouselTemplateCardsData(updatedCards);
  };

  const handleCardHeaderDelete = () => {
    if (isTemplateActionView) {
      return;
    }
    handleCardHeaderUpload('');
  };

  const renderCardHeaderUploader = () => {
    const headerType = whatsappTemplateState?.headerType;
    return (
      <div key={activeCard.id} className='flex flex-col gap-1'>
        <p className='capitalize text-gray-700 font-medium'>
          <span>{t(headerType ?? '')}</span>
          <span className='text-red-500'>*</span>{' '}
        </p>
        {headerType === 'image' ? (
          <WhatsappFileUploader
            file={activeCard?.header?.value || ''}
            handleFileChange={handleCardHeaderUpload}
            handleClear={handleCardHeaderDelete}
            templateHeaderType='image'
            fileMimeTypes={['image/png', 'image/jpeg']}
          />
        ) : (
          <WhatsappFileUploader
            file={activeCard?.header?.value || ''}
            handleFileChange={handleCardHeaderUpload}
            handleClear={handleCardHeaderDelete}
            templateHeaderType='video'
            fileMimeTypes={['video/mp4']}
          />
        )}
      </div>
    );
  };

  const handleCardBodyChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = ev.target.value;
    const currentCursorPosition = ev.target.selectionStart;
    setCursorPosition(currentCursorPosition);
    if (!value.length) {
      updateSingleCarouselCardData({
        ...activeCard,
        body: value,
      });
      return;
    }
    const finalTemplateBody = handleTemplateBodyVariableAutoComplete({
      currentText: value,
      position: currentCursorPosition,
      previousText: cardBody,
      nextCount: maxCount,
    });
    updateSingleCarouselCardData({
      ...activeCard,
      body: finalTemplateBody,
    });
  };

  const processCardBody = () => {
    const analyzedData = analyzeTemplateBody({
      bodyText: cardBody,
      position: cursorPosition,
    });

    setIsValidTemplateBody(analyzedData.invalidVariablelist.length === 0);
    setHasInvalidBodyVariable(analyzedData.invalidVariablelist.length === 0);
    setMaxCount(analyzedData.maxVariable + 1);

    const newBodyVariables = Object.keys(
      analyzedData.uniqueVariableList
    ).reduce((acc, key) => {
      acc[key] = activeCard.bodyVariables[key] ?? '';
      return acc;
    }, {});
    updateSingleCarouselCardData({
      ...activeCard,
      bodyVariables: { ...newBodyVariables },
    });
  };

  const handleAddVariable = () => {
    const value = cardBody + '{{' + maxCount + '}}'
    updateSingleCarouselCardData({
      ...activeCard,
      body: value,
    });
    textAreaRef?.current?.focus();
  };

  useEffect(() => {
    processCardBody();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    whatsappTemplateState?.category,
    whatsappTemplateState?.language,
    cardBody,
  ]);

  const renderCardBody = () => {
    return (
      <div className='flex flex-col gap-1'>
        <p>
          <span className='text-gray-700 font-medium text-sm'>
            {t('Carousel Text')}
          </span>
          <span className='text-red-500'>*</span>
        </p>
        <div
          className={`
         relative border border-gray-300 shadow-sm rounded px-3 py-2
         ${
           isValidTemplateBody
             ? 'focus-within:border-green-500 focus-within:ring-1 focus-within:ring-green-500'
             : 'focus-within:border-red-500 focus-within:ring-1 focus-within:ring-red-500'
         }
        ${
          isTemplateActionView
            ? 'bg-gray-100 cursor-not-allowed'
            : ' cursor-default bg-white'
        }`}
        >
          <textarea
            ref={textAreaRef}
            maxLength={MAX_CAROUSEL_CARD_TEXT_SIZE}
            id={`wa-card-body`}
            name='text-input-name'
            placeholder={t('Type your message here')}
            value={activeCard?.body || ''}
            rows={3}
            disabled={isTemplateActionView}
            className={`resize-y text-sm text-gray-700 font-normal border-0 border-none rounded w-full
           ${
             isTemplateActionView
               ? 'bg-gray-100 cursor-not-allowed'
               : 'focus:ring-0 focus:border-none bg-white'
           } text-gray-500 focus:outline-none focus:ring-1`}
            onChange={handleCardBodyChange}
          />
          <div className='h-6 flex gap-1 items-center w-full'>
            <div className='flex w-[50%] justify-start items-center'></div>
            <div className='flex w-[50%] justify-end'>
              <div className={`rounded-md p-1 bg-green-100 text-green-800`}>
                {activeCard.body?.length} /{MAX_CAROUSEL_CARD_TEXT_SIZE}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            isValidTemplateBody ? 'hidden' : 'visible'
          } w-full text-red-500`}
        >
          {`Please make sure your variables are in order e.g. {{1}}, {{2}}, {{3}}, and have their curly brackets on! e.g. {{1}}.`}
        </div>
        <div className='flex justify-end'>
          <button
            disabled={isTemplateActionView}
            onClick={handleAddVariable}
            className={`flex items-center gap-1 disabled:cursor-not-allowed px-2 py-1 rounded-md ${
              isTemplateActionView
                ? 'text-gray-500 bg-gray-100'
                : 'text-indigo-400 bg-indigo-50'
            }`}
          >
            <PlusIcon
              className={`w-5 font-medium h-5 ${
                isTemplateActionView ? 'text-gray-500' : 'text-indigo-400'
              }`}
            />
            <span>{t('Add Variables')}</span>
          </button>
        </div>
        <div className={`${maxCount === 1 ? 'hidden' : 'visible'}`}>
          <hr className='border-t border-gray-300' />
          <WhatsappTemplateSampleVariables actionType={actionType} activeCard={activeCard} />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderCardHeaderUploader()}
      {renderCardBody()}
      <CarouselTemplateButtonBlock
        actionType={actionType}
        sequence={sequence}
        activeCard={activeCard}
      />
    </>
  );
};

export default CarouselTemplateCard;
