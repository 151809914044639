export const webhookOptions = [
  { value: 'ticket.created', label: 'Ticket Created', list: 'channelList' },
  { value: 'ticket.resolved', label: 'Ticket Resolved', list: 'channelList' },
  { value: 'ticket.tagged', label: 'Ticket Tagged', list: 'channelList' },
  { value: 'ticket.reopened', label: 'Ticket Reopened', list: 'channelList' },
  { value: 'customer.created', label: 'Customer Created', list: 'channelList' },
  { value: 'message.failed', label: 'Message Failed', list: 'channelList' },
  { value: 'message.sent', label: 'Message Sent', list: 'channelList' },
  {
    value: 'message.delivered',
    label: 'Message Delivered',
    list: 'channelList',
  },
  { value: 'message.read', label: 'Message Read', list: 'channelList' },
  {
    value: 'form.entry.created',
    label: 'Form Entry Created',
    list: 'datalabFormList',
  },
  {
    value: 'form.entry.updated',
    label: 'Form Entry Updated',
    list: 'datalabFormList',
  },
  {
    value: 'form.entry.deleted',
    label: 'Form Entry Deleted',
    list: 'datalabFormList',
  },
];

// export const validateUrl = (url: string): boolean => {
//   const urlPattern =
//     /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
//   return urlPattern.test(url);
// };

export const initialWebhookState = {
  event: '',
  endpoint: '',
  filter_resource_id: [],
  filter_resource_type: 'platform',
  platforms: [],
  datalabs: [],
};

export const getModalContents = (actionType: 'CREATE' | 'EDIT') => {
  switch (actionType) {
    case 'CREATE': {
      return {
        title: 'Create New Webhook',
        description: 'Enter details below to create a new webhook.',
        primaryActionText: 'Create New Webhook',
        secondaryActionText: 'Cancel',
      };
    }
    case 'EDIT': {
      return {
        title: 'Edit Webhook',
        description: 'Edit details to make changes to this webhook.',
        primaryActionText: 'Save Changes',
        secondaryActionText: 'Cancel',
      };
    }
  }
};
