import React from 'react';
import { Badge } from 'libraryV2/ui/badge';
import { Textarea } from 'libraryV2/ui/textarea';
import { cn } from 'libraryV2/utils';
import { Play } from 'lucide-react';
import { CircleLoader } from 'pages/inbox/assets/iconComponent/Spinner';

interface CodeEditorProps {
  code: string;
  output: string;
  errorMsg?: string;
  isLoading?: boolean;
  classNames?: string;
  handleRunCode: () => void;
  setCode: (value: string) => void;
}

const CodeEditorPanel: React.FC<CodeEditorProps> = React.memo(
  ({
    code,
    output,
    setCode,
    handleRunCode,
    errorMsg = '',
    isLoading = false,
    classNames = 'w-full h-full',
  }) => {
    const handleClickRunCode = () => {
      if (!isLoading) {
        handleRunCode();
      }
    };

    return (
      <div className={classNames}>
        {/* Code Writing Section */}
        <div className='flex justify-between items-start'>
          <label className='font-semibold'>Write Code</label>
          {!isLoading ? (
            <button
              onClick={handleClickRunCode}
              className='flex justify-center items-center cursor-pointer'
            >
              Run <Play className='w-5 h-5 ml-2' />
            </button>
          ) : (
            <CircleLoader variant='secondary' size='sm' />
          )}
        </div>

        {/* Code Input Area */}
        <div className='flex flex-col space-y-2'>
          <Textarea
            placeholder='Enter your Jinja template code here...'
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className='w-full h-60 overflow-y-auto focus:ring-0 focus:ring-transparent'
          />
        </div>

        {/* Output Section */}
        <div className='relative flex flex-col space-y-2 mt-4'>
          <label className='font-semibold'>Return Results</label>
          {(isLoading || errorMsg) && (
            <div
              className={cn(
                'top-4 left-0 w-full flex items-center justify-center absolute z-50 rounded-md',
                errorMsg ? 'bg-red-100 h-60' : 'bg-transparent h-full'
              )}
            >
              {isLoading && <CircleLoader size='md' />}
              {errorMsg && (
                <Badge variant='ghost' className='text-red-500'>
                  {errorMsg}
                </Badge>
              )}
            </div>
          )}
          {!errorMsg && (
            <div className='w-full rounded-md text-left p-2 text-gray-800 min-h-[240px] max-h-[300px] overflow-y-auto bg-gray-100'>
              {output}
            </div>
          )}
        </div>
      </div>
    );
  }
);

CodeEditorPanel.displayName = 'CodeEditorPanel';

export default CodeEditorPanel;
