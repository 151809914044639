import {
  CustomerInfoInterface,
  EcommerceCustomerInterface,
  ICustomerOrderProperty,
  TicketInterface,
} from 'pages/inbox/inboxInterface';
import React from 'react';
import { connect } from 'react-redux';
import EmptyScreen from './EmptyScreen';
import OrderCard from './OrderCard';
import OrderDetails from './OrderDetails';
import OrderList from './OrderList';
import { sortOrdersFirstByStatus } from 'pages/inbox/utils/functions';
import useTranslation from 'components/customHooks/useTranslation';
import {
  EmailIcon,
  FullNameIcon,
  PhoneIcon,
} from 'pages/inbox/assets/iconComponent/CustomerInformationIconSet';
import { Button } from 'library';

import { AccordionItem, Accordion } from 'libraryV2/ui/accordion';
import { AccordionContent, AccordionTrigger } from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';

interface Props {
  userId: number;
  selectedAliceStoreId: number;
  customerInfo: CustomerInfoInterface;
  selectedTicket: TicketInterface;
  customerInformationLoading: boolean;
  customerOrderList: ICustomerOrderProperty[];
  ecommerceCustomerDetails: EcommerceCustomerInterface;
  handleCustomerConnection: (value: string) => void;
  fetchEcommerceCustomerDetails: (payload: {
    customerId: number;
  }) => Promise<{ success: boolean; data: EcommerceCustomerInterface }>;
  fetchCustomerOrder: (
    payload:
      | {
          customerId: number;
          orderId: number;
        }
      | { customerId: number }
  ) => void;
}

const EcommerceOrderHistory: React.FC<Props> = ({
  userId,
  customerInfo,
  selectedTicket,
  customerOrderList,
  fetchCustomerOrder,
  selectedAliceStoreId,
  ecommerceCustomerDetails,
  customerInformationLoading,
  handleCustomerConnection,
  fetchEcommerceCustomerDetails,
}) => {
  const { t } = useTranslation();
  const [displayScreen, setDisplayScreen] = React.useState('');
  const [selectedOrder, setSelectedOrder] = React.useState<string | null>(null);
  const [isAccordionOpen, setIsAccordionOpen] = React.useState<string>('');

  const renderCustomerConnectButton = () => {
    let isConectedWithEcommeceAccount =
      customerInfo?.is_linked_with_ecommerce_account && !!selectedAliceStoreId;

    return (
      <>
        {!isConectedWithEcommeceAccount && (
          <>
            <div className='relative group'>
              <div className='invisible group-hover:visible '>
                <span className='absolute left-[-160px] bottom-[23px] w-[225px] text-xs text-white bg-gray-600 px-2 py-1 rounded-[3px]'>
                  {t('Click to connect the customer to an e-commerce account')}
                </span>
                <span className='absolute left-5 bottom-[17px] w-[0px] h-[0px] border-l-[7px] border-r-[7px] border-t-[10px] border-l-transparent border-r-transparent border-t-gray-600'></span>
              </div>
              {!selectedTicket?.is_resolved && (
                <span
                  onClick={() =>
                    handleCustomerConnection(
                      isConectedWithEcommeceAccount ? 'disconnect' : 'connect'
                    )
                  }
                  className='font-medium text-sm leading-5 text-[#0078CF] cursor-pointer capitalize'
                >
                  {t('connect')}
                </span>
              )}
            </div>
          </>
        )}

        {isConectedWithEcommeceAccount && (
          <div className='flex items-center bg-green-100 px-2 rounded-[40px]'>
            <span className='rounded-[50%] h-2 w-2 bg-green-400 mt-0.5'></span>
            <p className='ml-1 font-medium text-sm leading-5 text-green-800'>
              {t('connected')}
            </p>
          </div>
        )}
      </>
    );
  };

  const getCustomerDetails = async () => {
    await fetchEcommerceCustomerDetails({
      customerId: selectedTicket?.customer_id,
    });
  };

  const getCustomerOrderList = async () => {
    await fetchCustomerOrder({
      customerId: selectedTicket?.customer_id,
      orderId: parseInt(''),
    });
  };

  React.useEffect(() => {
    setDisplayScreen('');
    getCustomerDetails();
    getCustomerOrderList();
    //eslint-disable-next-line
  }, [selectedTicket?.id]);

  const getCustomerEcommerceInfo = () => {
    let isConectedWithEcommeceAccount =
      customerInfo?.is_linked_with_ecommerce_account;

    return (
      isConectedWithEcommeceAccount && (
        <div className='flex flex-col w-full gap-3 pt-4 px-3 font-normal text-sm leading-5 text-gray-900'>
          <div className='flex gap-3 items-center w-full h-4'>
            <div>{FullNameIcon('#0078CF')}</div>
            <span>
              {ecommerceCustomerDetails?.full_name
                ? ecommerceCustomerDetails?.full_name
                : '--'}
            </span>
          </div>
          <div className='flex gap-3 items-center w-full h-4'>
            <div>{EmailIcon('#0078CF')}</div>
            <span>
              {ecommerceCustomerDetails?.ecommerce_account_email || '--'}
            </span>
          </div>
          <div className='flex gap-3 items-center w-full h-4'>
            <div>{PhoneIcon('#0078CF')}</div>
            <span>
              {ecommerceCustomerDetails?.ecommerce_account_phone
                ? ecommerceCustomerDetails?.ecommerce_account_phone
                : '--'}
            </span>
          </div>

          <div className='pt-3'>
            <hr />
          </div>
        </div>
      )
    );
  };

  const renderDisconnectButton = () => {
    let isConectedWithEcommeceAccount =
      customerInfo?.is_linked_with_ecommerce_account;

    return (
      isConectedWithEcommeceAccount &&
      !selectedTicket?.is_resolved && (
        <div className='flex items-center justify-center pt-0 mb-2'>
          <Button
            className='text-sm leading-5 p-2 pt-0 font-medium text-red-600 capitalize rounded-md border-0 cursor-pointer'
            onClick={() => handleCustomerConnection('disconnect')}
          >
            {t('Disconnect')}
          </Button>
        </div>
      )
    );
  };

  const renderOrderListView = () => {
    if (customerOrderList.length < 1) {
      return !selectedAliceStoreId ? (
        <EmptyScreen displayText='Please connect this channel to an ecommerce' />
      ) : (
        <EmptyScreen />
      );
    }
    return (
      <>
        {sortOrdersFirstByStatus(customerOrderList)
          ?.slice(0, 2)
          ?.map((order) => {
            return (
              <OrderCard
                order={order}
                setDisplayScreen={setDisplayScreen}
                setSelectedOrder={setSelectedOrder}
                key={order.id}
              />
            );
          })}

        <div className='flex justify-center p-2 mb-2'>
          <button
            className='w-full hover:bg-gray-100 p-2 font-medium text-gray-700 capitalize border border-gray-200 rounded-md'
            onClick={() => setDisplayScreen('order_list')}
          >
            {t('View All orders')}
          </button>
        </div>
      </>
    );
  };

  const renderOrderFlowScreens = () => {
    switch (displayScreen) {
      case 'order_list':
        return (
          <OrderList
            setDisplayScreen={setDisplayScreen}
            setSelectedOrder={setSelectedOrder}
          />
        );
      case 'order_details':
        const orderInfo = customerOrderList?.filter(
          (item) => item.id === selectedOrder
        )[0];
        return (
          <OrderDetails
            setDisplayScreen={(payload) => {
              setDisplayScreen(payload);
              if (payload === '') {
                setIsAccordionOpen('item-1');
              }
            }}
            orderInfo={orderInfo}
          />
        );
      default: {
        return (
          <Accordion
            type='single'
            collapsible
            value={isAccordionOpen}
            onClick={() => {
              setIsAccordionOpen((old: string) => {
                if (old) {
                  return '';
                }
                return 'item-1';
              });
            }}
            className='flex w-auto h-auto flex-column'
          >
            <AccordionItem value='item-1' className='w-full border-b-0'>
              <AccordionTrigger className='AccordionTrigger flex w-full items-center justify-between px-4 py-3'>
                <div className='font-semibold text-sm leading-5 text-gray-900 cursor-default'>
                  {t('Order History')}
                </div>
                <div className='flex items-center gap-2'>
                  {selectedTicket?.assigned_agent === userId &&
                    !!selectedAliceStoreId &&
                    !customerInformationLoading &&
                    renderCustomerConnectButton()}
                  <ChevronDown
                    className='text-gray-400 AccordionChevron'
                    height={16}
                    width={16}
                  />
                </div>
              </AccordionTrigger>
              <AccordionContent>
                {getCustomerEcommerceInfo()}
                {renderOrderListView()}
                {renderDisconnectButton()}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        );
      }
    }
  };

  return (
    <div className='flex w-auto h-auto border rounded-md flex-column'>
      {renderOrderFlowScreens()}
    </div>
  );
};

const mapState = (state: any) => ({
  userId: state.auth?.id,
  selectedTicket: state.inbox.selectedTicket,
  ecommerceCustomerDetails: state.ecommerce.ecommerceCustomerDetails,
  customerOrderList: state.ecommerce.customerOrderList,
  customerInformationLoading:
    state.loading.effects.inbox.getCustomerInformation,
  selectedAliceStoreId: state.ecommerce.selectedAliceStoreId,
});

const mapDispatch = (dispatch: any) => ({
  fetchEcommerceCustomerDetails: (payload: { customerId: number }) =>
    dispatch.ecommerce.fetchEcommerceCustomerDetails(payload),
  fetchCustomerOrder: (
    payload: { customerId: number; orderId: number } | { customerId: number }
  ) => dispatch.ecommerce.fetchCustomerOrder(payload),
});

export default connect(mapState, mapDispatch)(EcommerceOrderHistory);
