// RowCheckbox.tsx
import React from 'react';
import { Checkbox } from 'libraryV2/ui/checkbox';
import useTableRowActions from '../../hooks/useTableRowActions';
import { IUserManagement } from '../../interface';
import { Row } from '@tanstack/react-table';

interface RowCheckboxProps {
  rowId: string;
  row: Row<IUserManagement>;
  isSelected: boolean;
  toggleSelected: (value: boolean) => void;
}

const RowCheckbox: React.FC<RowCheckboxProps> = ({
  rowId,
  row,
  isSelected,
  toggleSelected,
}) => {
  const { selectedProject } = useTableRowActions();
  const isDisabled = selectedProject?.creator?.id === row.original.id;

  return (
    !isDisabled && (
      <Checkbox
        checked={isSelected}
        onCheckedChange={(value) => toggleSelected(!!value)}
        disabled={isDisabled}
        aria-label='Select row'
        className={`translate-y-[2px] ${
          isSelected
            ? 'bg-primary text-white ring-0 border-0'
            : 'bg-white text-zinc-900'
        }`}
      />
    )
  );
};

export default RowCheckbox;
