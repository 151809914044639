import React from 'react';
import { useDrag } from 'react-dnd';

import { GripVerticalIcon, XIcon } from 'lucide-react';
import { DragTypes, platinum_color, Input, cn } from 'pages/datalab/export';
import { Button } from 'libraryV2/ui/button';
import { Popover, PopoverTrigger, PopoverContent } from 'libraryV2/ui/popover';

interface IChoiceProps {
  index: number;
  colors: { colorCode: string; shade: string }[];
  choice: { [key: string]: any };
  onDeleteChoice: (index: number) => void;
  updateChoiceList: (index: number, color: string) => void;
}

const Choice: React.FC<IChoiceProps> = (props) => {
  const { index, choice, onDeleteChoice, updateChoiceList, colors } = props;

  const [{ isChoiceDragging }, drag] = useDrag({
    type: DragTypes.MULTI_CHOICE,
    item: { choice, index, type: DragTypes.MULTI_CHOICE },
    collect: (monitor) => ({
      isChoiceDragging: monitor.isDragging(),
    }),
  });

  const dragStyles = isChoiceDragging
    ? {
        opacity: 0.5,
        background: '#fafafa',
        cursor: 'grabbing',
        border: `1px dotted ${platinum_color}`,
        borderRadius: '4px',
      }
    : { opacity: 1, background: 'transparent' };

  return (
    <div
      className={`flex justify-between items-center mt-1 ml-4 mb-1`}
      ref={drag}
      style={dragStyles}
    >
      <GripVerticalIcon
        color={platinum_color}
        style={{
          background: 'transparent',
          cursor: isChoiceDragging ? 'grabbing' : 'pointer',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
      />

      <div
        className='flex relative items-center w-full'
        style={{
          width: '100%',
          background: 'transparent',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
      >
        <Popover>
          <PopoverTrigger asChild>
            <div
              className='absolute left-2 mt-[1px] h-3 w-3 rounded transform top-1/2 -translate-y-1/2 cursor-pointer'
              style={{ backgroundColor: choice?.color ?? colors[0].colorCode }}
            ></div>
          </PopoverTrigger>
          <PopoverContent
            className='flex items-center h-20 w-44 shadow-lg bg-white z-30 divide-y divide-gray-100 rounded-md'
            side='top'
            align='start'
            sideOffset={16}
            alignOffset={-10}
          >
            <div className='grid grid-cols-5 gap-1'>
              {colors.map((color, color_index) => (
                <div
                  key={color_index}
                  className={cn(
                    'w-7 h-7 rounded-full flex items-center justify-center cursor-pointer',
                    choice?.color === color.colorCode ? 'border-2' : ''
                  )}
                  style={{
                    borderColor:
                      choice?.color === color.colorCode ? color.shade : '',
                  }}
                  onClick={() => {
                    updateChoiceList(index, color.colorCode);
                  }}
                >
                  <div
                    className='w-5 h-5 rounded-full'
                    style={{ backgroundColor: color.colorCode }}
                  ></div>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
        <Input
          name='choices'
          value={choice?.name || ''}
          className='pl-7 mt-0'
          readOnly
        />
      </div>
      <Button
        variant='outline'
        size='icon'
        className='ml-3 border-0'
        style={{
          background: '#FFF0F0',
          color: '#FF0000',
          visibility: isChoiceDragging ? 'hidden' : 'visible',
        }}
        onClick={() => onDeleteChoice(index)}
      >
        <XIcon className='h-4 w-4' />
      </Button>
    </div>
  );
};

export default Choice;
