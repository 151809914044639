import { useEffect, useState } from 'react';
import useWhatsappMetrics from './hooks/useWhatsappMetrics';
import {
  WhatsappMetricsTabs,
  WhatsappMetricTabs,
} from './components/WhatsappMetricsTab';
import { WhatsappReportingTab } from 'pages/reportingV2/interface';
import { appendQueryParamsToUrl } from 'pages/reportingV2/utility/utils';
import { IReprtingPlatforms } from '../Nav/ReportingPlatformMultiSelect';
import { getQueryParameters } from 'utilities/utils';

const allowedWhatsappMetricsTabs: string[] = WhatsappMetricsTabs.map(
  (tab) => tab.value
);

const WhatsappMetrics = () => {
  const [currentWhatsappMetricsTab, setCurrentWhatsappMetricsTab] =
    useState<WhatsappReportingTab>('whatsapp-account-overview');

  const {
    platformsToFilter,
    updateShouldFetchWhatsappMetricsData,
    whatsappPlatformList,
    updatePlatformsToFilter,
  } = useWhatsappMetrics();

  useEffect(() => {
    // get current wa metrics tab  from url
    const currentSubTab = getQueryParameters('subTab');

    let selectedWaPlatforms =
      platformsToFilter?.filter(
        (platform: IReprtingPlatforms) =>
          platform?.platform_type === 'whatsapp_bsp'
      ) || [];

    // get first WA platform from among the first one otherwise get the first one from all the WA platform list
    const finalWaPlatform =
      selectedWaPlatforms.length === 0
        ? whatsappPlatformList[0]
        : selectedWaPlatforms[0];

    updatePlatformsToFilter(finalWaPlatform);
    updateShouldFetchWhatsappMetricsData(true);

    if (currentSubTab && allowedWhatsappMetricsTabs.includes(currentSubTab)) {
      setCurrentWhatsappMetricsTab(currentSubTab as WhatsappReportingTab);
    }
    appendQueryParamsToUrl({
      platforms: finalWaPlatform?.id,
      subTab: currentSubTab || currentWhatsappMetricsTab,
    });
    return () => {
      appendQueryParamsToUrl({ subTab: null });
      updateShouldFetchWhatsappMetricsData(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateCurrentWhatsappMetricsTab = (tab: WhatsappReportingTab) => {
    setCurrentWhatsappMetricsTab(tab);
    appendQueryParamsToUrl({ subTab: tab });
  };
  return (
    <WhatsappMetricTabs
      currentWhatsappMetricsTab={currentWhatsappMetricsTab}
      updateCurrentWhatsappMetricsTab={handleUpdateCurrentWhatsappMetricsTab}
    />
  );
};

export default WhatsappMetrics;
