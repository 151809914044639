import { useParams } from '@reach/router';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IReprtingPlatforms } from '../interface';
import dayjs from 'dayjs';

interface ICustomerMetricsPayload {
  projectId: number;
  platformIds: string;
  start: string;
  end: string;
  endpoint: string;
  isBusinessHourEnabled: boolean;
}

export const useCustomerMetrics = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const projectId = routeParams?.projectId;

  const [currentKeywordsIndex, setCurrentKeywordsIndex] = React.useState({
    id: 0,
    name: 'Keywords',
  });
  const [currentButtonsIndex, setCurrentButtonsIndex] = React.useState({
    id: 0,
    name: 'Buttons',
  });

  const dateRange = useSelector((state: any) => state.reportingV2.dateRange);
  const currentReportingTab = useSelector(
    (state: any) => state.reportingV2.currentTab
  );
  const shouldFetchData = useSelector(
    (state: any) => state.reportingV2.shouldFetchData
  );

  const isBusinessHourEnabled = useSelector(
    (state: any) => state.reportingV2.isBusinessHourEnabled
  );
  const platformsToFilter: IReprtingPlatforms[] = useSelector(
    (state: any) => state.reportingV2.platformsToFilter
  );

  const fetchUserReportDataOnTabClick = (endpoint: string) => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
      endpoint: endpoint,
    };
    return dispatch.reportingV2.fetchUserReportDataOnTabClick(payload);
  };

  const fetchUserReportData = (payload: ICustomerMetricsPayload) =>
    dispatch.reportingV2.fetchUserReportData(payload);

  const fetchUserReportDataUserMetricsLineGraph = (
    payload: ICustomerMetricsPayload
  ) => dispatch.reportingV2.fetchUserReportDataUserMetricsLineGraph(payload);

  const fetchUserReportDataDefaultAppend = (payload: ICustomerMetricsPayload) =>
    dispatch.reportingV2.fetchUserReportDataDefaultAppend(payload);

  const fetchUserReportHeatmap = (
    payload: Omit<ICustomerMetricsPayload, 'endpoint'>
  ) => dispatch.reportingV2.fetchUserReportHeatmap(payload);

  const fetchCustomerMetrics = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).join(','),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
      isBusinessHourEnabled: isBusinessHourEnabled,
    };
    fetchUserReportData({ ...payload, endpoint: 'get_new_customer_count' });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_interactive_customer_count',
    });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_triggered_message_count/referral',
    });
    fetchUserReportData({
      ...payload,
      endpoint: 'get_triggered_message_count/intent',
    });
    fetchUserReportDataUserMetricsLineGraph({
      ...payload,
      endpoint: 'get_language_metrics',
    });
    fetchUserReportDataUserMetricsLineGraph({
      ...payload,
      endpoint: 'get_timezone_metrics',
    });
    fetchUserReportDataUserMetricsLineGraph({
      ...payload,
      endpoint: 'get_country_metrics',
    });

    fetchUserReportHeatmap(payload);
    fetchUserReportDataDefaultAppend({
      ...payload,
      endpoint: 'get_gender_metrics',
    });
    fetchUserReportDataDefaultAppend({
      ...payload,
      endpoint: 'get_postback_and_text_message_count',
    });
    if (currentKeywordsIndex?.id === 0) {
      fetchUserReportDataDefaultAppend({
        ...payload,
        endpoint: 'get_triggered_message_list/keyword',
      });
    } else if (currentKeywordsIndex?.id === 1) {
      fetchUserReportDataDefaultAppend({
        ...payload,
        endpoint: 'get_triggered_message_list/intent',
      });
    }
    //   1
    if (currentButtonsIndex.id === 0) {
      fetchUserReportDataDefaultAppend({
        ...payload,
        endpoint: 'get_triggered_message_list/postback',
      });
    } else if (currentButtonsIndex.id === 1) {
      fetchUserReportDataDefaultAppend({
        ...payload,
        endpoint: 'get_triggered_message_list/referral',
      });
    }
  };

  return {
    projectId,
    shouldFetchData,
    platformsToFilter,
    fetchUserReportDataOnTabClick,
    fetchCustomerMetrics,
    currentKeywordsIndex,
    setCurrentKeywordsIndex,
    currentButtonsIndex,
    setCurrentButtonsIndex,
    currentReportingTab,
    dateRange,
  };
};
