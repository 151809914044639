import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import {
  AlarmClockIcon,
  Badge,
  Button,
  EmptyPage,
  useRemindersUtils,
  useSLAConfig,
} from '../../export';
import { IReminder } from '../../interface';
import ReminderRule from './ReminderRule';

interface Props {
  reminders: IReminder[];
}
const ReminderSetup: React.FC<Props> = ({ reminders = [] }) => {
  const { t } = useTranslation();
  const { handleRemindersDataOnChange } = useSLAConfig();
  const { handleReminderRuleAddition, handleReminderRuleDeletion } =
    useRemindersUtils();

  return (
    <>
      {reminders.length === 0 ? (
        <EmptyReminderView
          handleReminderRuleAddition={handleReminderRuleAddition}
        />
      ) : (
        <>
          <h5 className='py-3 rtl:text-right'>
            {t('Set up reminders for agents before SLA due times:')}
          </h5>
          <div className='bg-white border border-dashed rounded-lg border-border-light'>
            {reminders.map((rule: IReminder, index) => (
              <div
                className={`relative px-4 py-1 bg-background-container border-b border-dashed border-border-light ${
                  index === 0
                    ? 'rounded-t-lg'
                    : index === reminders.length - 1
                    ? 'rounded-b-lg'
                    : ''
                }`}
              >
                <ReminderRule
                  key={index}
                  index={index}
                  reminder={rule}
                  lastIndex={reminders.length - 1}
                  handleRemindersDataOnChange={(
                    key: keyof IReminder,
                    value: string | number | Array<string | number>
                  ) => handleRemindersDataOnChange(index, key, value)}
                  onDelete={() => handleReminderRuleDeletion(index)}
                />
                {index !== reminders.length - 1 && (
                  <Badge
                    variant='outline'
                    className='absolute z-10 -bottom-3 left-[50%] -translate-x-1/2 bg-gray-50'
                  >
                    {t('And')}
                  </Badge>
                )}
              </div>
            ))}
            <div className='flex p-4 flex-start'>
              <Button
                variant='outline'
                onClick={() => handleReminderRuleAddition()}
              >
                <AlarmClockIcon className='text-textPrimary h-4 w-4 ltr:mr-[6px] rtl:ml-[6px]' />
                {t('Add New Reminder')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const EmptyReminderView = ({ handleReminderRuleAddition }: any) => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center justify-center p-6 mt-4 bg-white border border-dashed rounded-lg border-border-light'>
      <EmptyPage
        subtitle='Set up reminders for agents before SLA due times.'
        iconClass='h-[56px] w-[87.93px]'
      >
        <Button variant='outline' onClick={() => handleReminderRuleAddition()}>
          <AlarmClockIcon className='text-textPrimary h-4 w-4 ltr:mr-[6px] rtl:ml-[6px]' />
          {t('Add New Reminder')}
        </Button>
      </EmptyPage>
    </div>
  );
};

export default ReminderSetup;
