import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { Input } from 'libraryV2/ui/input';
import { Label } from 'libraryV2/ui/label';
import { ChevronsUpDownIcon, GripVerticalIcon } from 'lucide-react';

import useTranslation from 'components/customHooks/useTranslation';
import {
  capitalizeFirstLetter,
  DeleteCofirmationDialog,
  DiscardCofirmationDialog,
  FormFields,
  InputText,
  platinum_color,
  SaveCofirmationDialog,
  useActions,
  useState,
  useTriggerActions,
} from 'pages/datalab/export';

interface IProps {
  fieldProperties: any;
  onFieldUpdate: (e: any) => void;
  onCheckboxChange: (name: string) => void;
  onSave: () => void;
  onRemove: () => void;
  onBlockFieldClick: () => void;
  onDiscard: () => void;
  checkFieldIsDirty: () => void;
  shouldDisableSubmitButton: () => boolean;
  accordionTriggerStyle: { color: string };
  isDragging: boolean;
  isSmall?: boolean;
  selectedFieldId?: string | number;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const FieldCommonConfigComp: React.FC<IProps> = ({
  fieldProperties,
  onFieldUpdate,
  onCheckboxChange,
  onSave,
  onRemove,
  onBlockFieldClick,
  onDiscard,
  accordionTriggerStyle,
  isDragging = false,
  isSmall = false,
  selectedFieldId,
  nodeRef,
  checkFieldIsDirty,
  shouldDisableSubmitButton,
}) => {
  const { t } = useTranslation();
  const {
    id,
    name = '',
    label_agent = '',
    placeholder = '',
    help_text = null,
    max_length = null,
    min_length = null,
    is_required = false,
    is_hidden = false,
    type = 'text',
  } = fieldProperties;
  const { getController } = useActions();
  const { createDataLabField, updateUnsaveTracker } = useTriggerActions();

  const [errors, setErrors] = useState({});

  const isNewField =
    typeof id === 'string' && id.startsWith('random') && !fieldProperties.slug;

  const { icon } = FormFields[type] ?? FormFields['text'];
  const draggingStyles = isDragging
    ? { opacity: 0, backGraound: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  const handleOnCreateField = () => {
    const { labId } = getController();
    createDataLabField(labId, fieldProperties);
  };

  const handleOnSaveOrCreateField = () => {
    updateUnsaveTracker({ isDirty: false, field: {} });
    if (isNewField) {
      handleOnCreateField();
    } else {
      if (name) {
        setErrors({});

        onSave();
      } else {
        setErrors({
          ...errors,
          name: 'This field is required',
        });
      }
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: 'This field is required',
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const fieldName = capitalizeFirstLetter(name);

  return (
    <AccordionItem
      key={id}
      value={id}
      className='bg-white border rounded-md'
      ref={nodeRef}
      onBlur={checkFieldIsDirty}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        onClick={onBlockFieldClick}
        defaultChecked={selectedFieldId === id}
        isShowUpDownArrow={false}
      >
        <div className='flex items-center justify-between'>
          <GripVerticalIcon color={platinum_color} />
          <span className='mx-2 border rounded p-1 text-zinc-200'>{icon}</span>
          <span className='text-sm' style={accordionTriggerStyle}>
            {t(fieldName)}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className='px-4 py-4'>
        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='name'
            label={t('Label for customers')}
            value={name}
            onChange={onFieldUpdate}
            required
            error={errors['name']}
            onBlur={handleOnBlur}
            characterLimit={128}
          />

          <InputText
            name='label_agent'
            label={t('Label for agents')}
            value={label_agent}
            onChange={onFieldUpdate}
            characterLimit={128}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='placeholder'
            label={t('Placeholder')}
            value={placeholder}
            onChange={onFieldUpdate}
            placeholder={t('Enter placeholder')}
          />

          <InputText
            name='help_text'
            label={t('Help Text')}
            value={help_text}
            onChange={onFieldUpdate}
            placeholder={t('Enter help text')}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <div className='grid items-center gap-1.5' style={{ width: '268px' }}>
            <Label htmlFor='min_length'>{t('Min. length')}</Label>
            <div className='relative w-full'>
              <Input
                type='number'
                id='min_length'
                name='min_length'
                value={min_length}
                onChange={onFieldUpdate}
                placeholder={t('Min. length')}
                className='ltr:pr-9 rtl:pl-9 focus:ring-primary focus:border-primary sm:text-sm'
              />
              <ChevronsUpDownIcon className='absolute ltr:right-0 rtl:left-0 top-0 m-2.5 h-4 w-4 text-muted-foreground' />
            </div>
          </div>

          <div className='grid items-center gap-1.5' style={{ width: '268px' }}>
            <Label htmlFor='max_length'>{t('Max. length')}</Label>
            <div className='relative w-full'>
              <Input
                className='ltr:pr-9 rtl:pl-9 focus:ring-primary focus:border-primary sm:text-sm'
                type='number'
                id='max_length'
                name='max_length'
                value={max_length}
                onChange={onFieldUpdate}
                placeholder={t('Max. length')}
              />
              <ChevronsUpDownIcon className='absolute ltr:right-0 rtl:left-0 top-0 m-2.5 h-4 w-4 text-muted-foreground' />
            </div>
          </div>
        </div>
      </AccordionContent>
      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div className='flex items-center ltr:space-x-2 rtl:mr-2'>
              <Checkbox
                id='is_required'
                className='rd-input-style-checkbox'
                name='is_required'
                checked={is_required}
                onCheckedChange={() => {
                  onCheckboxChange('is_required');
                }}
              />
              <label
                htmlFor='is_required'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as required')}
              </label>
            </div>

            <div className='flex items-center ltr:ml-4 rtl:mr-4 ltr:space-x-2 rtl:mr-2'>
              <Checkbox
                id='is_hidden'
                className='rd-input-style-checkbox'
                name='is_hidden'
                checked={is_hidden}
                onCheckedChange={() => {
                  onCheckboxChange('is_hidden');
                }}
              />
              <label
                htmlFor='is_hidden'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as hidden')}
              </label>
            </div>
          </div>

          <div className='flex'>
            <DeleteCofirmationDialog
              type={name}
              onDelete={onRemove}
              name={fieldName}
            />

            <DiscardCofirmationDialog onDiscard={onDiscard} />

            <SaveCofirmationDialog
              onClick={handleOnSaveOrCreateField}
              isNewField={isNewField}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default FieldCommonConfigComp;
