import { FC, useEffect } from 'react';
import CustomerMetricsContainer from './CustomerMetrics';
import { useCustomerMetrics } from 'pages/reportingV2/hooks/useCustomerMetrics';
interface Props {
  selectedProject: any;
}

export const CustomerMetricsV2: FC<Props> = ({ selectedProject }) => {
  const {
    fetchUserReportDataOnTabClick,
    fetchCustomerMetrics,
    currentKeywordsIndex,
    setCurrentKeywordsIndex,
    currentButtonsIndex,
    setCurrentButtonsIndex,
    platformsToFilter,
    shouldFetchData,
    dateRange,
  } = useCustomerMetrics();

  const handleCustomerMetricsOnTabChange = (tab: any, pointer: number) => {
    if (pointer === 0) {
      setCurrentKeywordsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick('get_triggered_message_list/keyword');
          break;
        case 1:
          fetchUserReportDataOnTabClick('get_triggered_message_list/intent');
          break;
      }
    } else if (pointer === 1) {
      setCurrentButtonsIndex(tab);
      switch (tab.id) {
        case 0:
          fetchUserReportDataOnTabClick('get_triggered_message_list/postback');
          break;
        case 1:
          fetchUserReportDataOnTabClick('get_triggered_message_list/referral');
          break;
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(platformsToFilter) && shouldFetchData) {
      fetchCustomerMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  return (
    <CustomerMetricsContainer
      currentKeywordsIndex={currentKeywordsIndex}
      currentButtonsIndex={currentButtonsIndex}
      onTabChange={(tab: any, pointer: number) =>
        handleCustomerMetricsOnTabChange(tab, pointer)
      }
      selectedProject={selectedProject}
    />
  );
};
