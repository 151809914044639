import useTranslation from 'components/customHooks/useTranslation';
import useWhatsappMetrics from '../../hooks/useWhatsappMetrics';
import EmptyReportTemplateTableIcon from '../../../../assets/images/EmptyReportTemplateTableIcon.svg';
import { IWhatsappTemplateAnalyticsMetricsTable } from 'pages/reporting/interface';
import Pagination from '../../../CommonComponent/Pagination';
import {
  WhatsappTemplateAnalyticsApiDataLimit,
  WhatsappTemplateMetricsCategoriesData,
  WhatsappTemplateMetricsStatusData,
} from 'pages/reporting/utility/content';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import useDebounce from 'components/customHooks/useDebounce';
import CirclePlusIcon from '../../../../assets/images/CirclePlusIcon.svg';
import CloseIcon from '../../../../assets/images/CloseIcon.svg';
import EmptySearchIcon from '../../../../assets/images/EmptySearchIcon.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { LinearLoaderComponent } from 'utilities/utils';
import useWhatsappAnalyticsLogs from '../../hooks/useWhatsappAnalyticsLogs';

const TemplateAnalytics = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [templateMetricsTableOffset, setTemplateMetricsTableOffset] =
    useState<number>(0);

  const {
    selectedProject,
    capitalizeWords,
    whatsappTemplateMetrics,
    totalDataOfTemplateMetrics,
    fetchWhatsappTemplateMetrics,
    isFetchTemplateMetricsLoading,
    updateSelectedReportingViewMode,
    updateSelectedWhatsappTemplateName,
  } = useWhatsappMetrics();

  const { updateSelectedAnalyticsId } = useWhatsappAnalyticsLogs();
  const searchDebounce = useDebounce(searchKeyword, 700);

  const fetchMetrics = useCallback(() => {
    fetchWhatsappTemplateMetrics({
      searchValue: searchDebounce, // Use debounced value
      statusFilter: selectedStatus,
      typeFilter: selectedTypes,
      offset: templateMetricsTableOffset,
    });

    //eslint-disable-next-line
  }, [
    searchDebounce,
    selectedStatus,
    selectedTypes,
    templateMetricsTableOffset,
  ]);

  // Fetch metrics whenever filters or search debounce changes
  useEffect(() => {
    fetchMetrics();
  }, [fetchMetrics]);

  const { t } = useTranslation();
  const headerTitle = [
    'Templates name',
    'Template ID',
    'Status',
    'Sent',
    'Delivered',
    'Read',
    'Cost',
    'Order',
    'Sales',
    'AVG. sales conversion',
    'Type',
    '',
  ];

  const renderAccountStatusView = (type: string) => {
    switch (type) {
      case 'approved':
        return (
          <div className='border border-[#DFDFE2] text-green-500 py-0.5 px-2 rounded-md text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-green-500 mr-1'></span>{' '}
            Approved
          </div>
        );
      case 'rejected':
        return (
          <div className='border border-[#DFDFE2] text-red-500 py-0.5 px-2 rounded-md text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-red-500 mr-1'></span>{' '}
            Rejected
          </div>
        );
      case 'pending':
        return (
          <div className='border border-[#DFDFE2] text-gray-500 py-0.5 px-2 rounded-md text-sm font-medium flex items-center'>
            <span className='w-1.5 h-1.5 rounded-full bg-gray-500 mr-1'></span>{' '}
            Pending
          </div>
        );

      default:
        return null;
    }
  };

  // Function to handle checkbox changes
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterType: 'status' | 'type'
  ) => {
    const { id, checked } = event.target;

    if (filterType === 'status') {
      setSelectedStatus((prev) =>
        checked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    } else if (filterType === 'type') {
      setSelectedTypes((prev) =>
        checked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    }
    setTemplateMetricsTableOffset(0);
  };

  // Function to clear all filters
  const clearFilters = (filterType: 'status' | 'type') => {
    if (filterType === 'status') {
      setSelectedStatus([]);
    } else {
      setSelectedTypes([]);
    }
    setTemplateMetricsTableOffset(0);
  };

  const getTextColor = (value: string) => {
    if (value === 'approved') {
      return 'text-green-500';
    } else if (value === 'rejected') {
      return 'text-red-500';
    } else {
      return 'text-gray-500';
    }
  };
  const getBgColor = (value: string) => {
    if (value === 'approved') {
      return 'bg-green-500';
    } else if (value === 'rejected') {
      return 'bg-red-500';
    } else {
      return 'bg-gray-500';
    }
  };

  const removeSelectedFilterItem = (
    type: 'status' | 'types',
    value: string
  ) => {
    if (type === 'status' && selectedStatus.includes(value)) {
      const updatedData = selectedStatus.filter(
        (item: string) => item !== value
      );
      setSelectedStatus(updatedData ?? []);
    } else {
      const updatedData = selectedTypes.filter(
        (item: string) => item !== value
      );
      setSelectedTypes(updatedData ?? []);
    }
    setTemplateMetricsTableOffset(0);
  };

  return (
    <div className='mt-6 pb-6'>
      <div className='mb-4'>
        <h3 className='text-[#111827] text-base font-medium leading-6 mb-1'>
          WhatsApp Template Analytics
        </h3>
        <p className='text-[#6B7280] text-sm mb-0'>
          Track your WhatsApp template activity and performance.
        </p>
      </div>
      {((searchKeyword.length === 0 && whatsappTemplateMetrics.length !== 0) ||
        searchKeyword.length !== 0 ||
        selectedStatus.length !== 0 ||
        selectedTypes.length !== 0) && (
        <div className='mb-4 flex justify-between items-center'>
          <div className='flex items-center'>
            <div className='relative rounded-md shadow-sm h-full mr-2'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='search'
                name='search'
                id='search'
                className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder={t('Search')}
                value={searchKeyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchKeyword(e.target.value);
                  setTemplateMetricsTableOffset(0);
                }}
              />
            </div>
            <div className='mr-2'>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className='flex items-center px-3 py-2.5 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                    <div>
                      <img src={CirclePlusIcon} alt='' />
                    </div>
                    <p className='text-sm font-medium ml-1'>Status</p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side='bottom'
                  align='start'
                  className='shadow-lg bg-white z-30 rounded-md p-0'
                >
                  <div className=''>
                    <div className='py-2 px-1'>
                      <div className='flex flex-col gap-3 p-1.5'>
                        {WhatsappTemplateMetricsStatusData.map(
                          ({ id, label }) => (
                            <div className='flex items-center' key={id}>
                              <input
                                type='checkbox'
                                readOnly
                                id={id}
                                checked={selectedStatus.includes(id)}
                                onChange={(event) =>
                                  handleCheckboxChange(event, 'status')
                                }
                                className={`${
                                  selectedStatus.includes(id)
                                    ? 'text-green-500 focus:ring-green-700 focus:border-primary'
                                    : ''
                                } flex items-center pl-3 rounded w-4 h-4 border border-[#18181B] outline-none`}
                              />
                              <label htmlFor={id} className='ml-2'>
                                <div
                                  className={`border border-[#DFDFE2] ${getTextColor(
                                    id
                                  )}  py-0.5 px-2 rounded-md text-sm font-medium flex items-center`}
                                >
                                  <span
                                    className={`w-1.5 h-1.5 rounded-full ${getBgColor(
                                      id
                                    )}  mr-1`}
                                  ></span>{' '}
                                  {label}
                                </div>
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {selectedStatus.length > 0 && (
                      <div
                        className='border-t p-2 flex justify-center cursor-pointer'
                        onClick={() => clearFilters('status')}
                      >
                        <p className=''>Clear Filters</p>
                      </div>
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className='mr-2'>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className='flex items-center px-3 py-2.5 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                    <div>
                      <img src={CirclePlusIcon} alt='' />
                    </div>
                    <p className='text-sm font-medium ml-1'>Type</p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side='bottom'
                  align='start'
                  className='shadow-lg bg-white z-30 rounded-md p-0'
                >
                  <div className=''>
                    <div className='py-2 px-1'>
                      <div className='flex flex-col gap-3 p-1.5'>
                        {WhatsappTemplateMetricsCategoriesData.map(
                          ({ id, label }) => (
                            <div className='flex items-center' key={id}>
                              <input
                                type='checkbox'
                                id={id}
                                checked={selectedTypes.includes(id)}
                                onChange={(event) =>
                                  handleCheckboxChange(event, 'type')
                                }
                                className='w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary outline-none'
                              />
                              <label
                                htmlFor={id}
                                className='ml-2 text-sm text-[#18181B] capitalize'
                              >
                                {label}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {selectedTypes.length > 0 && (
                      <div
                        className='border-t p-2 flex justify-center cursor-pointer'
                        onClick={() => clearFilters('type')}
                      >
                        <p className=''>Clear Filters</p>
                      </div>
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {(selectedStatus.length > 0 || selectedTypes.length > 0) && (
              <div
                className='flex items-center px-3 py-2.5 rounded-md bg-[#F4F4F5] cursor-pointer'
                onClick={() => {
                  setSelectedStatus([]);
                  setSelectedTypes([]);
                }}
              >
                <div>
                  <img src={CloseIcon} alt='' />
                </div>
                <p className='text-sm font-medium ml-1'>Clear Filter</p>
              </div>
            )}
          </div>
        </div>
      )}

      {(selectedStatus.length > 0 || selectedTypes.length > 0) && (
        <div className='mb-4 flex items-center'>
          {selectedStatus.length > 0 && (
            <div className='flex items-center'>
              <div className='text-xs text-[#71717A] mr-1'>Status:</div>
              <div className='flex items-center'>
                <div className='bg-[#F4F4F5] py-1 px-2 rounded-md flex items-center justify-between'>
                  <span className='mr-2 capitalize text-xs font-medium'>
                    {selectedStatus[0]}
                  </span>
                  <div
                    className='cursor-pointer'
                    onClick={() =>
                      removeSelectedFilterItem('status', selectedStatus[0])
                    }
                  >
                    <img src={CloseIcon} alt='' />
                  </div>
                </div>
                {selectedStatus.length > 1 && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <div className='ml-1 bg-[#F4F4F5] py-1 px-2 rounded-md text-xs font-medium'>
                        +{selectedStatus.length - 1}
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      side='bottom'
                      align='start'
                      className='shadow-lg bg-white z-30 rounded-md p-2'
                    >
                      <div className='flex flex-col gap-2'>
                        {selectedStatus
                          .slice(1)
                          .map((item: string, index: number) => (
                            <div key={index}>
                              <div className='bg-[#F4F4F5] py-1 px-2 rounded-md inline-flex'>
                                <span className='mr-2 capitalize text-xs font-medium mr-1'>
                                  {item}
                                </span>
                                <div
                                  className='cursor-pointer mt-0.5'
                                  onClick={() =>
                                    removeSelectedFilterItem('status', item)
                                  }
                                >
                                  <img src={CloseIcon} alt='' />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
          )}
          {selectedTypes.length > 0 && (
            <div className='flex items-center ml-3'>
              <div className='text-xs text-[#71717A] mr-1'>Type:</div>
              <div className='flex items-center'>
                <div className='bg-[#F4F4F5] py-1 px-2 rounded-md flex items-center justify-between'>
                  <span className='mr-2 capitalize text-xs font-medium'>
                    {selectedTypes[0]}
                  </span>
                  <div
                    className='cursor-pointer'
                    onClick={() =>
                      removeSelectedFilterItem('types', selectedTypes[0])
                    }
                  >
                    <img src={CloseIcon} alt='' />
                  </div>
                </div>
                {selectedTypes.length > 1 && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <div className='ml-1 bg-[#F4F4F5] py-1 px-2 rounded-md text-xs font-medium'>
                        +{selectedTypes.length - 1}
                      </div>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      side='bottom'
                      align='start'
                      className='shadow-lg bg-white z-30 rounded-md p-2'
                    >
                      <div className='flex flex-col gap-2'>
                        {selectedTypes
                          .slice(1)
                          .map((item: string, index: number) => (
                            <div key={index}>
                              <div className='bg-[#F4F4F5] py-1 px-2 rounded-md inline-flex'>
                                <span className='mr-2 capitalize text-xs font-medium mr-1'>
                                  {item}
                                </span>
                                <div
                                  className='cursor-pointer mt-0.5'
                                  onClick={() =>
                                    removeSelectedFilterItem('types', item)
                                  }
                                >
                                  <img src={CloseIcon} alt='' />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <>
        {isFetchTemplateMetricsLoading && <LinearLoaderComponent />}
        {!isFetchTemplateMetricsLoading && (
          <div className='flex flex-col overflow-x-auto overflow-y-hidden border rounded-md'>
            <div className='inline-block min-w-full align-middle'>
              {(whatsappTemplateMetrics !== null ||
                whatsappTemplateMetrics.length !== 0) && (
                <>
                  <table className='w-full min-h-full bg-white rounded-lg'>
                    <thead className='border-b'>
                      <tr>
                        {((searchKeyword.length !== 0 &&
                          whatsappTemplateMetrics.length !== 0) ||
                          searchKeyword.length === 0) &&
                          headerTitle.map((head) => (
                            <th
                              scope='col'
                              className='px-5 py-1 text-sm font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 capitalize'
                            >
                              {t(head)}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {!!whatsappTemplateMetrics &&
                        whatsappTemplateMetrics.map(
                          (
                            template: IWhatsappTemplateAnalyticsMetricsTable,
                            index: number
                          ) => (
                            <tr key={index} className='px-6 py-3 '>
                              <td className='px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.name ?? '--'}
                              </td>
                              <td className=' px-6 py-3 text-sm text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.id ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {renderAccountStatusView(template?.status)}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.sent ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.delivered ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.read ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.cost ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.order ?? '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.sales !== undefined
                                  ? `${selectedProject?.currency_code} ${template.sales}`
                                  : '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.avg_sales_conversion !== undefined
                                  ? `${template.avg_sales_conversion} %`
                                  : '--'}
                              </td>
                              <td className='px-6 py-3 text-sm font-normal text-gray-900 whitespace-nowrap rtl:text-right capitalize'>
                                {capitalizeWords(template?.category)}
                              </td>
                              <td
                                className='px-6 py-3 text-sm font-medium text-blue-500 whitespace-nowrap rtl:text-right capitalize cursor-pointer'
                                onClick={() => {
                                  updateSelectedAnalyticsId(template?.id);
                                  updateSelectedReportingViewMode(
                                    'analyticsLogs'
                                  );
                                  updateSelectedWhatsappTemplateName(
                                    template?.name
                                  );
                                }}
                              >
                                View Logs
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                  {!isFetchTemplateMetricsLoading &&
                    whatsappTemplateMetrics?.length !== 0 && (
                      <Pagination
                        offset={templateMetricsTableOffset}
                        tableDataLength={whatsappTemplateMetrics?.length}
                        total={totalDataOfTemplateMetrics}
                        limit={WhatsappTemplateAnalyticsApiDataLimit}
                        handleNextButton={() =>
                          setTemplateMetricsTableOffset(
                            templateMetricsTableOffset +
                              WhatsappTemplateAnalyticsApiDataLimit
                          )
                        }
                        handlePreviousButton={() =>
                          setTemplateMetricsTableOffset(
                            templateMetricsTableOffset -
                              WhatsappTemplateAnalyticsApiDataLimit
                          )
                        }
                      />
                    )}
                </>
              )}
              {!isFetchTemplateMetricsLoading &&
                whatsappTemplateMetrics.length === 0 &&
                searchKeyword.length === 0 && (
                  <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white'>
                    <img
                      src={EmptyReportTemplateTableIcon}
                      alt={'empty table'}
                      className={'w-32 mt-8'}
                    />
                    <div className='mt-6 mb-8 text-center'>
                      <p className='text-[#18181B] text-lg font-semibold mb-2'>
                        {t(t('No Reports Found'))}
                      </p>
                      <p className='text-[#71717A] font-medium text-sm w-[290px] mx-auto'>
                        {t(
                          'Start sending templates to see your activity and performance metrics here.'
                        )}
                      </p>
                    </div>
                  </div>
                )}
              {!isFetchTemplateMetricsLoading &&
                searchKeyword.length !== 0 &&
                (whatsappTemplateMetrics.length === 0 ||
                  whatsappTemplateMetrics === null) && (
                  <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white shadow'>
                    <img
                      src={EmptySearchIcon}
                      alt={'empty table'}
                      className={'w-32 mt-8'}
                    />
                    <div className='mt-6 mb-8 text-center'>
                      <p className='text-[#18181B] text-lg font-semibold mb-2'>
                        {t(t('No Results Found'))}
                      </p>
                      <p className='text-[#71717A] font-medium text-sm mx-auto'>
                        {t('Please search with different keyword. ')}
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default TemplateAnalytics;
