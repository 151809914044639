import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';

interface Prop {
  open: boolean;
  children: any;
  closeOnExternalClick?: boolean;
}

const OnboardingModal: React.FC<Prop> = ({
  open,
  children,
  closeOnExternalClick = false,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      {/*add `overflow-y-auto` in dialog for let modal scroll outside overlay*/}
      <Dialog
        as='div'
        static
        className='fixed inset-0 z-20 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {}}
      >
        <div className='flex items-center justify-center min-h-screen p-0 px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div className='items-center justify-center inline-block w-full max-w-3xl my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl'>
              {/*header starts*/}
              <div className='h-auto my-8 overflow-y-auto'>{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OnboardingModal;
