import { useDispatch, useSelector } from 'react-redux';
import { CSAT_METRICES_CONTENT } from 'pages/reportingV2/utility/content';
import { ICsatTableRowInfo, ITeamAgent } from '../interface';
import { ICsatDist } from '../component/CSAT/components/CustomerSatisfactionSummary';
import { IReprtingPlatforms } from 'pages/reportingV2/component/Nav/ReportingPlatformMultiSelect';
import { useParams } from '@reach/router';
import dayjs from 'dayjs';

interface ICsatStateData {
  csatResponseDist: ICsatDist[];
  csatResponseList: ICsatTableRowInfo[];
  csatResponseListTotal: number;
  totalCsatResponseWithoutFilter: number;
  agentList: ITeamAgent[];
  isCsatTableDataLoading: boolean;
  currentReportingTab: any;
  shouldFetchData: boolean;
}

const useCsatReporting = () => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const projectId = routeParams.projectId;

  const platformsToFilter: IReprtingPlatforms[] = useSelector(
    (state: any) => state?.reportingV2?.platformsToFilter
  );

  const dateRange = useSelector((state: any) => state?.reportingV2?.dateRange);

  const updatePlatformsToFilter = (plist: any[]) => {
    if (!Array.isArray(plist)) {
      return;
    }
    dispatch.reportingV2.updateStateData({
      key: 'platformsToFilter',
      value: plist,
    });
  };

  const {
    csatResponseDist = [],
    csatResponseList: csatTableData = [],
    csatResponseListTotal: totalResponseCount = 0,
    totalCsatResponseWithoutFilter,
    agentList = [],
    isCsatTableDataLoading,
    currentReportingTab,
    shouldFetchData,
  }: ICsatStateData = useSelector((state: any) => ({
    csatResponseDist: state.reportingV2?.csatResponseDist,
    csatResponseList: state.reportingV2?.csatResponseList,
    csatResponseListTotal: state.reportingV2?.csatResponseListTotal,
    totalCsatResponseWithoutFilter:
      state.reportingV2?.csatTotalResponseCount?.current_value,
    agentList: state.reportingV2?.agentList,
    currentReportingTab: state.reportingV2?.currentReportingTab,
    shouldFetchData: state.reportingV2?.shouldFetchData,
    isCsatTableDataLoading:
      state?.loading?.effects?.reportingV2?.fetchCsatResponseList,
  }));

  const csatRatingSelectionOptions = CSAT_METRICES_CONTENT.ratings.map((r) => ({
    label: r.text,
    value: r.rating.toString(),
  }));

  const csatAgentSelectionOptions = Array.from(agentList).map((a) => ({
    label: a.full_name.slice(0, 20),
    value: a.id,
    avatar: a.avatar,
  }));

  const handleCsatTableFilter = (
    filterConfig: any,
    paginationState: { pageIndex: number; pageSize: number }
  ) => {
    const filterFormat = filterConfig?.reduce(
      (
        p: Record<string, string | string[]>,
        c: { id: string; value: string | string[] }
      ) => {
        p[c.id] = c.value;
        return p;
      },
      {}
    );

    const filterDataParams = {
      teamId: projectId,
      search: filterFormat?.customerName,
      limit: paginationState?.pageSize ?? 10,
      offset: paginationState?.pageIndex * paginationState?.pageSize,
      agentsIds: filterFormat?.agent ?? [],
      ratings: filterFormat.rating ?? [],
    };

    dispatch.reportingV2.fetchCsatResponseList(filterDataParams);
    return;
  };

  const exportCSATSummary = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).toString(),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
    };
    return dispatch.reportingV2?.exportCSATSummary(payload);
  };

  const exportCSATSData = (tableFilterConfig: any) => {
    const filterFormat = tableFilterConfig?.reduce(
      (
        p: Record<string, string | string[]>,
        c: { id: string; value: string | string[] }
      ) => {
        p[c.id] = c.value;
        return p;
      },
      {}
    );

    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).toString(),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
    };
    const filterDataParams = {
      ...payload,
      search: filterFormat?.customerName,
      offset: 0,
      agentsIds: filterFormat?.agent?.toString() ?? '',
      ratings: filterFormat.rating?.toString() ?? '',
    };
    return dispatch.reportingV2?.exportCSAT(filterDataParams);
  };

  const fetchAllCsatData = async (isChannelFilter = false) => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter.map((platform) => platform.id).toString(),
      start: dayjs(dateRange.from).format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange.to).format('YYYY-MM-DDTHH:mm:ss'),
    };
    dispatch.reportingV2?.fetchCsatStack({
      ...payload,
      endpoint: 'csat-total-count',
    });
    dispatch.reportingV2?.fetchCsatStack({
      ...payload,
      endpoint: 'csat-responded-count',
    });
    dispatch.reportingV2?.fetchCsatStack({
      ...payload,
      endpoint: 'csat-average-rating',
    });
    dispatch.reportingV2?.fetchCsatStack({
      ...payload,
      endpoint: 'csat-response-count',
    });

    if (isChannelFilter) {
      dispatch.reportingV2?.fetchCsatResponseList({
        ...payload,
        teamId: projectId,
        search: '',
        limit: 10,
        offset: 0,
      });
    }

    dispatch.reportingV2?.fetchCsatResponseDist(payload);
  };

  return {
    platformsToFilter,
    updatePlatformsToFilter,
    csatTableData,
    fetchAllCsatData,
    exportCSATSummary,
    exportCSATSData,
    csatResponseDist,
    handleCsatTableFilter,
    csatRatingSelectionOptions,
    csatAgentSelectionOptions,
    projectId,
    isCsatTableDataLoading,
    totalResponseCount,
    totalCsatResponseWithoutFilter,
    dateRange,
    shouldFetchData,
    currentReportingTab,
  };
};

export default useCsatReporting;
