import useTranslation from 'components/customHooks/useTranslation';
import { FC } from 'react';

interface PriorityLabelProps {
  priority: number | string;
  labelTag?: boolean;
  htmlFor?: string;
}

const enum PriorityLevels {
  Low = 0,
  Medium = 1,
  High = 2,
  Urgent = 3,
}

const priorityLabelMap: Record<PriorityLevels, string> = {
  [PriorityLevels.Low]: 'Low',
  [PriorityLevels.Medium]: 'Medium',
  [PriorityLevels.High]: 'High',
  [PriorityLevels.Urgent]: 'Urgent',
};

const priorityBackgroundClassMap: Record<PriorityLevels, string> = {
  [PriorityLevels.Low]: 'bg-[#04B25F]',
  [PriorityLevels.Medium]: 'bg-indigo-500',
  [PriorityLevels.High]: 'bg-amber-500',
  [PriorityLevels.Urgent]: 'bg-error',
};

const priorityTextClassMap: Record<PriorityLevels, string> = {
  [PriorityLevels.Low]: 'text-[#04B25F]',
  [PriorityLevels.Medium]: 'text-indigo-500',
  [PriorityLevels.High]: 'text-amber-500',
  [PriorityLevels.Urgent]: 'text-error',
};

const TicketPriorityLabel: FC<PriorityLabelProps> = ({
  priority,
  labelTag,
  htmlFor,
}) => {
  const { t } = useTranslation();
  const label = priorityLabelMap[priority as PriorityLevels];
  const backgroundClass =
    priorityBackgroundClassMap[priority as PriorityLevels];
  const textClass = priorityTextClassMap[priority as PriorityLevels];

  return labelTag ? (
    <label
      htmlFor={htmlFor}
      className='flex items-center px-2 py-[1.5px] ltr:space-x-2 rtl:gap-1 border rounded-md border-border-light h-[22px] w-fit'
    >
      <span
        className={`w-[6.25px] h-[6.25px] rounded-full ${backgroundClass}`}
      />
      <span className={`text-xs ${textClass}`}>{t(label)}</span>
    </label>
  ) : (
    <div className='flex items-center px-2 py-[1.5px] ltr:space-x-2 rtl:gap-1 border rounded-md border-border-light h-[22px] w-fit'>
      <span
        className={`w-[6.25px] h-[6.25px] rounded-full ${backgroundClass}`}
      />
      <span className={`text-xs ${textClass}`}>{t(label)}</span>
    </div>
  );
};

export default TicketPriorityLabel;
