import React, { useState, Fragment } from 'react';
import { LayoutListIcon, GripVerticalIcon, CornerDownLeft } from 'lucide-react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';
import { Popover, PopoverTrigger, PopoverContent } from 'libraryV2/ui/popover';
import { Checkbox } from 'libraryV2/ui/checkbox';

import { Button } from 'libraryV2/ui/button';

import {
  InputText,
  SaveCofirmationDialog,
  DiscardCofirmationDialog,
  DeleteCofirmationDialog,
  useActions,
  useTriggerActions,
  LabelSeparator,
  DropZone,
  DragTypes,
  arrayMove,
  platinum_color,
  capitalizeFirstLetter,
  IDataLabField,
  TRIGGER_PRIMARY_COLOR,
  Input,
  cn,
  TooltipProviderCustomised,
  isDirtyDataLabField,
} from 'pages/datalab/export';
import Choice from './choice';
import useTranslation from 'components/customHooks/useTranslation';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const MultiSelect: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { field, isDragging, isSmall, nodeRef } = props;
  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();

  const {
    createDataLabField,
    updateDataLabField,
    deleteDataLabField,
    updateUnsaveTracker,
  } = useTriggerActions();

  const colors = [
    { colorCode: '#313135', shade: '#636367' },
    { colorCode: '#6366F1', shade: '#8C8FF8' },
    { colorCode: '#3B82F6', shade: '#67A0FB' },
    { colorCode: '#84CC16', shade: '#A8E15A' },
    { colorCode: '#04B25F', shade: '#36D287' },
    { colorCode: '#F59E0B', shade: '#FAB954' },
    { colorCode: '#F97316', shade: '#FB914F' },
    { colorCode: '#F43F5E', shade: '#F76F88' },
    { colorCode: '#EC4899', shade: '#F175B5' },
    { colorCode: '#FF2323', shade: '#FF5C5C' },
  ];

  const [fieldProperties, setFieldProperties] = useState(field);
  const [currChoice, setCurrChoice] = useState({
    value: '',
    error: '',
    color: colors[0].colorCode,
  });
  const [bgColor, setBgColor] = useState(colors[0].colorCode);

  const {
    id: fieldId,
    name,
    label_agent,
    placeholder,
    help_text,
    is_required,
    is_hidden,
    choices = [],
  } = fieldProperties;

  const isNewField =
    typeof fieldId === 'string' && fieldId.startsWith('random');

  const selectedFieldId = getSelectedFieldId();

  const [errors, setErrors] = useState({});

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: t('This field is required'),
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleOnUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFieldProperties({ ...fieldProperties, [name]: value });
  };

  const shouldDisableSubmitButton = () => {
    return !(
      fieldProperties?.name ||
      fieldProperties?.label_agent ||
      fieldProperties?.help_text ||
      fieldProperties?.placeholder
    );
  };

  const handleOnChoiceUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const { value } = e.target;
    setCurrChoice((prev) => {
      return { ...prev, value: value, error: '' };
    });
  };

  const updateChoiceList = (index: number, color: string) => {
    if (!choices || index < 0 || index >= choices.length) {
      return;
    }
    let temp = [...choices];
    temp[index].color = color || '';

    setFieldProperties({
      ...fieldProperties,
      choices: temp,
    });
  };

  const handleOnAddChoice = () => {
    if (currChoice.value) {
      const updatedChoices = choices;

      updatedChoices.push({ name: currChoice.value, color: currChoice.color });

      setFieldProperties({
        ...fieldProperties,
        choices: updatedChoices,
      });

      setCurrChoice({
        ...currChoice,
        value: '',
        error: '',
        color: colors[0].colorCode,
      });
      setBgColor(colors[0].colorCode);
    } else {
      setCurrChoice({
        ...currChoice,
        value: '',
        error: t('Please enter minimum one option.'),
      });
    }
  };

  const handleOnDeleteChoice = (index: number) => {
    const choices = fieldProperties.choices || [];
    choices.splice(index, 1);
    setFieldProperties({
      ...fieldProperties,
      choices,
    });
  };

  const handleOnCheckboxChange = (name: string) => {
    setFieldProperties({
      ...fieldProperties,
      [name]: !fieldProperties[name],
    });
  };

  const handleOnBlurChoice = () => {
    if (currChoice.error) {
      setCurrChoice({ ...currChoice, error: '' });
    }
  };

  const getFieldPropertiesWithCurrChoice = () => {
    const updatedFieldProperties = { ...fieldProperties };

    if (currChoice.value) {
      const updatedChoices = choices;

      updatedChoices.push({ name: currChoice.value });

      updatedFieldProperties.choices = updatedChoices;

      setCurrChoice({ ...currChoice, value: '', error: '' });
    }

    return updatedFieldProperties;
  };

  const handleOnCreateField = () => {
    const { labId } = getController();

    const updatedFieldProperties = getFieldPropertiesWithCurrChoice();

    createDataLabField(labId, updatedFieldProperties);
  };

  const handleOnSaveOrCreate = () => {
    updateUnsaveTracker({ isDirty: false, field: {} });
    if (isNewField) {
      handleOnCreateField();
    } else {
      if (name) {
        setErrors({});

        const { labId } = getController();
        const updatedFieldProperties = getFieldPropertiesWithCurrChoice();
        setFieldProperties({ ...fieldProperties, serial: field?.serial });
        updateDataLabField(labId, fieldId, {
          ...updatedFieldProperties,
          serial: field?.serial,
        });
      } else {
        setErrors({
          ...errors,
          name: 'This field is required',
        });
      }
    }
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId, field?.parent_id);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = TRIGGER_PRIMARY_COLOR;
  }

  const handleOnDiscard = () => {
    setFieldProperties(field);
    setSelectedFieldId(null);
  };

  const handleOnReOrdering = (dropZone: any, item: any) => {
    if (item.type === DragTypes.MULTI_CHOICE) {
      const drag_index = Number(item.index);
      const hover_index = Number(dropZone.index);

      if (drag_index !== hover_index && hover_index > -1) {
        // handle sorting of fields
        const updatedChoices = arrayMove(choices, drag_index, hover_index);

        setFieldProperties({
          ...fieldProperties,
          choices: updatedChoices,
        });
      }
    }
  };

  const checkFieldIsDirty = () => {
    const isDirty = isDirtyDataLabField(fieldProperties, field);

    const updatedField = isDirty
      ? { ...fieldProperties, serial: field?.serial }
      : {};

    updateUnsaveTracker({ isDirty, field: updatedField });
  };

  const fieldName = capitalizeFirstLetter(name);

  const draggingStyles = isDragging
    ? { opacity: 0, background: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  return (
    <AccordionItem
      value={fieldId}
      className='bg-white border rounded-md'
      ref={nodeRef}
      onBlur={checkFieldIsDirty}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        defaultChecked={selectedFieldId === fieldId}
        onClick={handleOnBlockFieldClick}
        isShowUpDownArrow={false}
      >
        <div className='flex items-center justify-between'>
          <GripVerticalIcon color={platinum_color} />
          <span className='mx-2 border rounded p-1 text-zinc-200'>
            <LayoutListIcon />
          </span>
          <span className='text-sm' style={accordionTriggerStyle}>
            {t(fieldName)}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className='px-4 py-2 border-t'>
        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='name'
            label={t('Label for customers')}
            value={name}
            onChange={handleOnUpdate}
            required
            error={errors['name']}
            onBlur={handleOnBlur}
            characterLimit={128}
          />

          <InputText
            name='label_agent'
            label={t('Label for agents')}
            value={label_agent}
            onChange={handleOnUpdate}
            characterLimit={128}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='placeholder'
            label={t('Placeholder')}
            value={placeholder}
            onChange={handleOnUpdate}
            placeholder={t('Enter placeholder')}
          />

          <InputText
            name='help_text'
            label={t('Help Text')}
            value={help_text}
            onChange={handleOnUpdate}
            placeholder={t('Enter help text')}
          />
        </div>
      </AccordionContent>
      <AccordionContent className='px-2'>
        <LabelSeparator label={t('Option')} />
        {choices.map((choice: any, index: number) => (
          <Fragment key={index}>
            <DropZone
              data={{
                index: `${index}`,
                childrenCount: choices.length,
              }}
              onDrop={handleOnReOrdering}
              style={{ display: 'flex', width: '542px', marginLeft: 'auto' }}
            />
            <Choice
              colors={colors}
              choice={choice}
              index={index}
              onDeleteChoice={handleOnDeleteChoice}
              updateChoiceList={updateChoiceList}
            />
          </Fragment>
        ))}

        <DropZone
          data={{
            index: `${choices?.length}`,
            childrenCount: choices?.length,
          }}
          onDrop={handleOnReOrdering}
          style={{ display: 'flex', width: '542px', marginLeft: 'auto' }}
        />

        <div className='flex items-center justify-between ml-10'>
          <div className='flex relative items-center w-full'>
            <Popover>
              <PopoverTrigger asChild>
                <div
                  className='absolute left-2 mt-[1px] h-3 w-3 rounded transform top-1/2 -translate-y-1/2 cursor-pointer'
                  style={{ backgroundColor: bgColor }}
                ></div>
              </PopoverTrigger>
              <PopoverContent
                className='flex items-center h-20 w-44 shadow-lg bg-white z-30 divide-y divide-gray-100 rounded-md'
                side='top'
                align='start'
                sideOffset={16}
                alignOffset={-10}
              >
                <div className='grid grid-cols-5 gap-1'>
                  {colors.map((color, index) => (
                    <div
                      key={index}
                      className={cn(
                        'w-7 h-7 rounded-full flex items-center justify-center cursor-pointer',
                        bgColor === color?.colorCode ? 'border-2' : ''
                      )}
                      style={{
                        borderColor:
                          bgColor === color?.colorCode ? color?.shade : '',
                      }}
                      onClick={() => {
                        setBgColor(color.colorCode);
                        setCurrChoice((prev) => {
                          return { ...prev, color: color.colorCode };
                        });
                      }}
                    >
                      <div
                        className={`w-5 h-5 rounded-full`}
                        style={{ backgroundColor: color.colorCode }}
                      />
                    </div>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
            <Input
              name='choices'
              value={currChoice.value}
              onChange={handleOnChoiceUpdate}
              onBlur={handleOnBlurChoice}
              placeholder={t('Add options')}
              // required
              className={
                currChoice.error ? 'pl-7 border-red-600 mt-0' : 'pl-7 mt-0'
              }
            />
          </div>
          <TooltipProviderCustomised
            content={
              currChoice.value
                ? t('Add Option')
                : t('Enter value to add an option')
            }
            side='top'
            align='end'
          >
            <Button
              variant='outline'
              size='icon'
              className='ltr:ml-3 rtl:mr-3'
              style={{
                background: 'transparent',
                cursor: currChoice.error ? 'not-allowed' : 'pointer',
              }}
              onClick={handleOnAddChoice}
            >
              <CornerDownLeft className='w-4 h-4 text-textPrimary-disable' />
            </Button>
          </TooltipProviderCustomised>
        </div>

        {currChoice.error ? (
          <span
            className='mt-3 mb-2 text-red-600'
            style={{ marginLeft: '2.2rem' }}
          >
            {currChoice.error}
          </span>
        ) : null}
      </AccordionContent>

      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div className='flex items-center ltr:space-x-2 rtl:ml-2'>
              <Checkbox
                id='is_required'
                className='rd-input-style-checkbox'
                name='is_required'
                checked={is_required}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_required');
                }}
              />
              <label
                htmlFor='is_required'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as required')}
              </label>
            </div>

            <div className='flex items-center ltr:space-x-2 ltr:ml-4 rtl:mr-4'>
              <Checkbox
                id='is_hidden'
                className='rd-input-style-checkbox'
                name='is_hidden'
                checked={is_hidden}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_hidden');
                }}
              />
              <label
                htmlFor='is_hidden'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 rtl:mr-2'
              >
                {t('Set as hidden')}
              </label>
            </div>
          </div>

          <div className='flex'>
            <DeleteCofirmationDialog
              type={name}
              onDelete={handleOnRemove}
              name={fieldName}
            />

            <DiscardCofirmationDialog onDiscard={handleOnDiscard} />

            <SaveCofirmationDialog
              onClick={handleOnSaveOrCreate}
              isNewField={isNewField}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default MultiSelect;
