import React from 'react';
import CustomerHeatmapGrid from './CustomerHeatmapGrid';
import PieGraph from '../CommonComponent/PieGraph';
import ActionsReportTable from './ActionsReportTable';
import { connect } from 'react-redux';
import DataStack from '../CommonComponent/DataStack';
import DataGraph from '../CommonComponent/DataGraph';
import { analytics } from '../../../../utilities/content';
import { navigate } from '@reach/router';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Button } from 'evergreen-ui';
import { SelectedTeamInterface } from '../../../../index';
import useTranslation from 'components/customHooks/useTranslation';

interface props {
  userCount: dataStackProps;
  interactiveUserCount: dataStackProps;
  intentGrowthData: dataStackProps;
  referralGrowthData: dataStackProps;
  languageData: dataGraphProps;
  timezoneData: dataGraphProps;
  countryData: dataGraphProps;
  heatmapData: [];
  heatmapDataLoader: boolean;
  genderData: dataProps[];
  postbackVTextData: dataProps[];
  triggeredMsgKeywordIntent: triggeredDataProps[];
  triggeredMsgPostbackReferral: triggeredDataProps[];
  currentButtonsIndex: { id: number; name: string };
  currentKeywordsIndex: { id: number; name: string };
  onTabChange: (tab: any, pointer: number) => void;
  linegraphLoader: boolean;
  defaultDataLoader: boolean;
  loader: boolean;
  selectedProject: SelectedTeamInterface;
}

interface dataStackProps {
  current_value: number | string;
  growth_direction: '+' | '-' | null;
  growth_value: string;
  previous_value: number | string;
  span: number;
  name?: string;
  value?: number | string;
}

interface dataGraphProps {
  data: dataProps;
  max: dataStackProps;
}

interface dataProps {
  name: string;
  value: number | string;
}

interface triggeredDataProps {
  title: string;
  webhook_count: number;
  customer_count: number;
}
const CustomerMetrics: React.FC<props> = ({
  userCount,
  loader,
  interactiveUserCount,
  intentGrowthData,
  referralGrowthData,
  heatmapData,
  heatmapDataLoader,
  genderData,
  postbackVTextData,
  languageData,
  timezoneData,
  countryData,
  triggeredMsgKeywordIntent,
  currentKeywordsIndex,
  currentButtonsIndex,
  onTabChange,
  triggeredMsgPostbackReferral,
  linegraphLoader,
  defaultDataLoader,
  selectedProject,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const perPageLimit = 6;
  const [keywordOffset, setKeywordOffset] = React.useState(0);
  const [buttonOffset, setButtonOffset] = React.useState(0);
  const [keywordSearch, setKeywordSearch] = React.useState('');
  const [buttonSearch, setButtonSearch] = React.useState('');
  const subscriptionPlanType = selectedProject?.subscription_plan?.plan_type;

  const handleOnchangeKeywordSearch = (val: string) => {
    setKeywordSearch(val);
    setKeywordOffset(0);
  };

  const handleOnchangeButtonSearch = (val: string) => {
    setButtonSearch(val);
    setButtonOffset(0);
  };

  const overlay = (selectedProject: SelectedTeamInterface) => {
    return (
      <div
        className='z-10 absolute w-full h-full'
        aria-labelledby='modal-title'
        role='dialog'
        aria-modal='true'
      >
        <div className='flex flex-col items-center justify-center h-full bg-white bg-opacity-95 sm:rounded-lg'>
          <LockClosedIcon className='w-10 h-10 p-2.5 m-5 text-blue-900 bg-blue-100 rounded-[50%]' />
          <p className='text-sm font-medium text-gray-900'>
            {t('Upgrade to Premium')}
          </p>
          <p className='text-sm font-normal text-gray-500'>
            {t('Add Superpowers to your support arsenal!')}
          </p>
          {selectedProject.is_creator ? (
            <Button
              onClick={() => navigate('/settings/billing')}
              className='button-alice__gradient m-5'
            >
              {t('Upgrade Now')}
            </Button>
          ) : (
            <p className='text-sm font-normal text-gray-500'>
              {t('Contact your team owner to upgrade subscription plan.')}
            </p>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className='p-4'>
      {subscriptionPlanType === 'free' ? (
        <DataStack
          column={2}
          data={[
            {
              ...userCount,
              name: analytics.customerMetrics.dataCard[0].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[0].toolTip,
            },
            {
              ...interactiveUserCount,
              name: analytics.customerMetrics.dataCard[1].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[1].toolTip,
            },
          ]}
        />
      ) : (
        <DataStack
          column={4}
          data={[
            {
              ...userCount,
              name: analytics.customerMetrics.dataCard[0].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[0].toolTip,
            },
            {
              ...interactiveUserCount,
              name: analytics.customerMetrics.dataCard[1].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[1].toolTip,
            },
            {
              ...referralGrowthData,
              name: analytics.customerMetrics.dataCard[2].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[2].toolTip,
            },
            {
              ...intentGrowthData,
              name: analytics.customerMetrics.dataCard[3].title,
              loader: loader,
              toolTip: analytics.customerMetrics.dataCard[3].toolTip,
            },
          ]}
        />
      )}
      <DataGraph
        data={[
          {
            ...languageData,
            name: analytics.customerMetrics.graphDataCard[0].title,
            toolTip: analytics.customerMetrics.graphDataCard[0].toolTip,
          },
          {
            ...timezoneData,
            name: analytics.customerMetrics.graphDataCard[1].title,
            toolTip: analytics.customerMetrics.graphDataCard[1].toolTip,
          },
          {
            ...countryData,
            name: analytics.customerMetrics.graphDataCard[2].title,
            toolTip: analytics.customerMetrics.graphDataCard[2].toolTip,
          },
        ]}
        linegraphLoader={linegraphLoader}
        column={3}
      />
      <CustomerHeatmapGrid
        isRTL={isRtlLanguage}
        data={heatmapData}
        loader={heatmapDataLoader}
        toolTipInfo={t(analytics.customerMetrics.customerHeatmapGrid.toolTip)}
      />
      <div className='grid grid-cols-1 gap-5 m-3 my-6 md:grid-cols-2'>
        <div className='inline-block'>
          <PieGraph
            color='#EF7711'
            loader={defaultDataLoader}
            title={analytics.customerMetrics.gender.title}
            toolTipInfo={analytics.customerMetrics.gender.toolTip}
            data={genderData}
          />
        </div>
        <div className='relative inline-block'>
          {subscriptionPlanType === 'free' && overlay(selectedProject)}
          <PieGraph
            color='#EF7711'
            loader={defaultDataLoader}
            title={analytics.customerMetrics.textVsButton.title}
            toolTipInfo={analytics.customerMetrics.textVsButton.toolTip}
            data={postbackVTextData}
          />
        </div>
      </div>
      <div className='grid grid-cols-1 gap-5 m-3 mb-6 md:grid-cols-2 h-[500px] relative inline-block'>
        {subscriptionPlanType === 'free' && overlay(selectedProject)}
        <ActionsReportTable
          tabData={[
            { id: 0, name: t('Keywords') },
            { id: 1, name: t('Intents') },
          ]}
          currentTab={currentKeywordsIndex}
          onTabChange={(tab: any) => onTabChange(tab, 0)}
          setSearchKeyword={handleOnchangeKeywordSearch}
          searchKeyword={keywordSearch}
          offset={keywordOffset}
          setOffset={setKeywordOffset}
          limit={perPageLimit}
          total={triggeredMsgKeywordIntent?.length}
          tableData={
            !!triggeredMsgKeywordIntent
              ? triggeredMsgKeywordIntent
                  .filter(
                    (elem: any) =>
                      !!elem.title &&
                      elem.title
                        .toLowerCase()
                        .includes(keywordSearch.toLowerCase())
                  )
                  .slice(keywordOffset, keywordOffset + perPageLimit)
              : []
          }
        />
        <ActionsReportTable
          tabData={[
            { id: 0, name: t('Buttons') },
            { id: 1, name: t('Referrals') },
          ]}
          currentTab={currentButtonsIndex}
          onTabChange={(tab: any) => onTabChange(tab, 1)}
          setSearchKeyword={handleOnchangeButtonSearch}
          searchKeyword={buttonSearch}
          offset={buttonOffset}
          setOffset={setButtonOffset}
          limit={perPageLimit}
          total={triggeredMsgPostbackReferral?.length}
          tableData={
            !!triggeredMsgPostbackReferral
              ? triggeredMsgPostbackReferral
                  .filter(
                    (elem: any) =>
                      !!elem.title &&
                      elem.title
                        .toLowerCase()
                        .includes(buttonSearch.toLowerCase())
                  )
                  .slice(buttonOffset, buttonOffset + perPageLimit)
              : []
          }
        />
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  userCount: state.reportingV2.userCount,
  interactiveUserCount: state.reportingV2.interactiveUserCount,
  intentGrowthData: state.reportingV2.intentGrowthData,
  referralGrowthData: state.reportingV2.referralGrowthData,
  loader: state.loading.effects.reportingV2.fetchUserReportData,
  linegraphLoader:
    state.loading.effects.reportingV2.fetchUserReportDataUserMetricsLineGraph,
  defaultDataLoader:
    state.loading.effects.reportingV2.fetchUserReportDataDefaultAppend,
  heatmapData: state.reportingV2.heatmapData,
  heatmapDataLoader: state.loading.effects.reportingV2.fetchUserReportHeatmap,
  genderData: state.reportingV2.genderData,
  postbackVTextData: state.reportingV2.postbackVTextData,
  languageData: state.reportingV2.languageData,
  timezoneData: state.reportingV2.timezoneData,
  countryData: state.reportingV2.countryData,
  triggeredMsgKeywordIntent: state.reportingV2.triggeredMsgKeywordIntent,
  triggeredMsgPostbackReferral: state.reportingV2.triggeredMsgPostbackReferral,
});

const mapDispatch = (dispatch: any) => ({});

const CustomerMetricsContainer = connect(
  mapState,
  mapDispatch
)(CustomerMetrics);

export default CustomerMetricsContainer;
