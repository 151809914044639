import { Row } from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import {
  AlertDialog,
  Button,
  Dialog,
  DialogContent,
  DialogTrigger,
  EllipsisIcon,
  navigate,
  Select,
  SelectContent,
  SelectTrigger,
  Separator,
  Settings2Icon,
  SLAEdit,
  SquarePenIcon,
  TrashIcon,
} from '../../export';
import { ISLAData } from '../../interface';

interface DataWithId {
  id: string;
  name: string;
  description: string;
  platforms: Array<string | number>;
}

interface RowActionsProps<TData extends DataWithId> {
  row: Row<TData>;
  hasReadAccess: boolean;
  hasWriteAccess: boolean;
  hasDeleteAccess: boolean;
  handleDeleteSla: (slaId: string) => { status: boolean; data: any };
  isSLADeleteLoading: boolean;
  handleSLAConfigurationClick: (slaData: ISLAData) => void;
}

export function TableRowActions<TData extends DataWithId>({
  row,
  hasReadAccess,
  hasWriteAccess,
  hasDeleteAccess,
  handleDeleteSla,
  isSLADeleteLoading,
  handleSLAConfigurationClick,
}: RowActionsProps<TData>) {
  const { t, isRtlLanguage } = useTranslation();
  //@ts-ignore
  const slaData: ISLAData = { ...row.original };
  const [shouldRenderWarningModal, setShouldRenderWarningModal] =
    React.useState(false);

  return (
    <>
      <Select>
        <SelectTrigger className='w-0 h-0 p-0 ltr:mr-4 rtl:ml-4 border-0'>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 data-[state=open]:bg-muted focus:border-traparent focus:border-0 focus:ring-0 focus:outline-none focus:bg-background-container rounded-lg'
          >
            <EllipsisIcon className='w-4 h-4' />
            <span className='sr-only'>{t('Open menu')}</span>
          </Button>
        </SelectTrigger>
        <SelectContent
          className='rtl:w-52 w-40 bg-white rounded-lg rtl:text-right'
          side={isRtlLanguage ? 'right' : 'left'}
        >
          <Dialog modal={true}>
            <DialogTrigger asChild>
              <button
                className={
                  'text-left hover:bg-gray-100 rounded-lg flex items-center p-1 w-full rtl:flex-row-reverse'
                }
                onSelect={(e) => e.preventDefault()}
                disabled={!hasWriteAccess}
              >
                <SquarePenIcon className='rtl:w-8 rtl:h-8 h-4 ltr:pr-2 rtl:pl-2' />

                {t('Edit basic info')}
              </button>
            </DialogTrigger>
            <DialogContent className='sm:max-w-[425px] bg-white'>
              <SLAEdit slaData={slaData} />
            </DialogContent>
          </Dialog>
          <button
            className={`flex items-center my-1 p-1 rtl:flex-row-reverse w-full
              ${
                !hasWriteAccess
                  ? 'text-gray-200 cursor-not-allowed'
                  : 'ltr:text-left rtl:text-right hover:bg-gray-100 rounded-lg'
              }`}
            onClick={() => {
              //@ts-ignore
              let slaData: ISLAData = { ...row.original };
              handleSLAConfigurationClick(slaData);
              navigate('/settings/sla-configuration');
            }}
            disabled={!hasWriteAccess}
          >
            <Settings2Icon className='rtl:w-8 rtl:h-8 h-4 ltr:pr-2 rtl:pl-2' />
            {t('Configure SLA')}
          </button>
          <Separator />
          <AlertDialog
            open={shouldRenderWarningModal}
            onOpenChange={setShouldRenderWarningModal}
          >
            <button
              onClick={async () => {
                await handleDeleteSla(row.original.id);
                setShouldRenderWarningModal(false);
              }}
              disabled={!hasDeleteAccess}
              className={`flex rtl:flex-row-reverse items-center p-1 w-full mt-1
              ${
                !hasWriteAccess
                  ? 'text-gray-200 cursor-not-allowed'
                  : 'text-left hover:bg-gray-100 rounded-lg'
              }`}
            >
              <TrashIcon className='rtl:w-8 rtl:h-8 h-4 ltr:pr-2 rtl:pl-2' />
              {t('Delete')}
            </button>
          </AlertDialog>
        </SelectContent>
      </Select>
    </>
  );
}
