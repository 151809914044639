import useTranslation from 'components/customHooks/useTranslation';
import { Badge } from 'libraryV2/ui/badge';
import { Button } from 'libraryV2/ui/button';
import { Checkbox } from 'libraryV2/ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'libraryV2/ui/command';
import { Label } from 'libraryV2/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { ChevronDown, X } from 'lucide-react';
import { AvailableIntegrations } from 'pages/integration/utils/content';
import React from 'react';
import { truncateText } from 'utilities/utils';
import telegramGroupIcon from '../../../../assets/icons/channelIconSet/telegram_group.svg';
import webChatIcon from '../../../../assets/icons/channelIconSet/webchat_messenger.svg';
import whatsappMessengerIcon from '../../../../assets/icons/channelIconSet/whatsapp_messenger.svg';
import { useSourceSelection } from '../hooks/useSourceSelection';

interface WebhookSourceChannel {
  alice_store_id: number;
  ecommerce_type: string;
  has_ecommerce_connected: boolean;
  id: string;
  is_connected: boolean;
  name: string;
  platform_type: string;
  title: string;
}

interface WebhookSourceForm {
  id: string;
  title: string;
  logo?: string;
  type: string;
  description?: string;
}

export type Source = WebhookSourceChannel | WebhookSourceForm;
export type SourceList = Source[];

interface Props {
  sourceList: SourceList;
  sourceType: 'channelList' | 'formList';
  selectedSourceIds: number[];
  onSourceChange: (ids: number[]) => void;
  placeholder?: string;
}

export function WebhookSourceDropdown({
  sourceList,
  sourceType,
  selectedSourceIds,
  onSourceChange,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { selectedIds, toggleSource } = useSourceSelection(
    selectedSourceIds || [],
    onSourceChange
  );
  const [searchQuery, setSearchQuery] = React.useState('');

  const getSelectedSources = React.useCallback(() => {
    if (!selectedIds) return [];
    return sourceList.filter((source) =>
      selectedIds.includes(Number(source.id))
    );
  }, [sourceList, selectedIds]);

  const sortSourceList = React.useCallback(
    (list: SourceList) => {
      return list.sort((a, b) => {
        if (
          selectedIds.includes(Number(a.id)) &&
          !selectedIds.includes(Number(b.id))
        )
          return -1;
        if (
          !selectedIds.includes(Number(a.id)) &&
          selectedIds.includes(Number(b.id))
        )
          return 1;
        return 0;
      });
    },
    [selectedIds]
  );

  const filteredSourceList = React.useMemo(() => {
    let filtered =
      sourceType === 'channelList'
        ? sourceList.filter(
            (source) =>
              !('platform_type' in source) || source.platform_type !== 'gmail'
          )
        : sourceList;

    if (searchQuery) {
      filtered = filtered.filter((source) =>
        source.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return sortSourceList(filtered);
  }, [sourceList, searchQuery, sortSourceList, sourceType]);

  const renderSelectedBadges = () => {
    const selectedSources = getSelectedSources();

    if (selectedSources.length === 0) {
      return (
        <span className='text-textSecondary font-normal'>
          {sourceType === 'channelList'
            ? t('Select channels')
            : t('Select forms')}
        </span>
      );
    }

    if (selectedSources.length === 1) {
      const source = selectedSources[0];
      return (
        <Badge
          key={source.id}
          variant='secondary'
          className='first-of-type:ml-0 rtl:first-of-type:mr-0 bg-gray-100 hover:bg-gray-200 rounded-md flex items-center'
        >
          <TooltipProviderCustomised
            content={source.title.length > 25 ? source.title : ''}
            asChild={true}
          >
            <span>{truncateText(source.title, 25)}</span>
          </TooltipProviderCustomised>
          <X
            className='ltr:ml-1 rtl:mr-1 h-3 w-3 cursor-pointer'
            onClick={(e) => {
              e.stopPropagation();
              toggleSource(Number(source.id));
            }}
          />
        </Badge>
      );
    }

    return (
      <TooltipProviderCustomised
        content={
          <div className='flex flex-col gap-y-2'>
            {selectedSources.map((source) => (
              <div
                key={source.id}
                className='flex items-center justify-between w-full'
              >
                <span className='flex-1'>{source.title}</span>
                <X
                  className='ltr:ml-2 rtl:mr-2 hover:text-textSecondary-hover h-3 w-3 cursor-pointer'
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSource(Number(source.id));
                  }}
                />
              </div>
            ))}
          </div>
        }
      >
        <Badge
          variant='secondary'
          className='rounded-md bg-gray-100 hover:bg-gray-200 flex items-center'
        >
          {selectedSources.length}{' '}
          {sourceType === 'channelList'
            ? t('channels selected')
            : t('forms selected')}
        </Badge>
      </TooltipProviderCustomised>
    );
  };

  const getChannelIcon = (platformType: string) => {
    const iconMap = {
      webchat: webChatIcon,
      telegram_group: telegramGroupIcon,
      whatsapp_messenger: whatsappMessengerIcon,
    };

    const availableChannel = AvailableIntegrations.Channel.find(
      (channel) => channel.type === platformType
    );

    if (availableChannel) {
      return (
        <img
          src={availableChannel.icon}
          alt={availableChannel.title}
          className='w-6 h-6'
        />
      );
    }

    const iconSrc = iconMap[platformType as keyof typeof iconMap];
    if (iconSrc) {
      return (
        <img src={iconSrc} alt={`${platformType} icon`} className='w-6 h-6' />
      );
    }

    return <div className='w-6 h-6 bg-gray-200 rounded-full' />;
  };

  return (
    <div className='flex flex-col gap-y-2'>
      <Label htmlFor='webhook_type'>
        {sourceType === 'channelList' ? t('Channels') : t('Forms')}{' '}
        <span className='text-red-500 text-sm'>*</span>
      </Label>

      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className={cn(
              'w-full justify-between border-gray-300 shadow-none hover:bg-transparent',
              open && 'border-primary'
            )}
          >
            <div className='flex flex-wrap gap-1 py-1'>
              {renderSelectedBadges()}
            </div>
            <ChevronDown className='ltr:ml-2 rtl:mr-2 h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>

        <PopoverContent className='w-[430px] p-0 bg-white'>
          <Command className='overflow-hidden max-h-[250px]'>
            <CommandInput
              placeholder={t('Search')}
              value={searchQuery}
              onValueChange={setSearchQuery}
              className='focus:border-none focus:ring-0 focus:outline-none'
            />
            <CommandList>
              {filteredSourceList.length === 0 && (
                <CommandEmpty className='py-4 text-center'>
                  {t('No results found.')}
                </CommandEmpty>
              )}
            </CommandList>
            <CommandList>
              <CommandGroup>
                {filteredSourceList.map((source) => (
                  <CommandItem
                    key={source.id}
                    value={source.title}
                    onSelect={() => toggleSource(Number(source.id))}
                    className='cursor-pointer w-full hover:bg-gray-100 rounded-md'
                  >
                    <div
                      className='flex items-center ltr:space-x-2 rtl:space-x-0 rtl:gap-2 w-full'
                      onClick={(e) => {
                        e.preventDefault();
                        toggleSource(Number(source.id));
                      }}
                    >
                      <Checkbox
                        id={`source-${source.id}`}
                        checked={selectedIds.includes(Number(source.id))}
                        className={cn(
                          selectedIds.includes(Number(source.id))
                            ? 'bg-primary text-white border-primary'
                            : 'border-gray-600',
                          'rounded shadow-none'
                        )}
                      />
                      {'platform_type' in source && (
                        <>{getChannelIcon(source.platform_type)}</>
                      )}
                      <TooltipProviderCustomised
                        content={source.title.length > 50 ? source.title : ''}
                        asChild={true}
                      >
                        <label
                          htmlFor={`source-${source.id}`}
                          className='text-sm font-medium cursor-pointer line-clamp-1 flex-1'
                        >
                          {source.title}
                        </label>
                      </TooltipProviderCustomised>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default WebhookSourceDropdown;
