import { Tabs, TabsContent, TabsList, TabsTrigger } from 'libraryV2/ui/tabs';
import CsatMetrics from '../CSAT';
import WhatsappMetrics from '../WhatsappMetrics';
import { CustomerMetricsV2 } from '../CustomerMetrics';
import AutomationMetricsV2 from '../AutomationMetrics';
import AgentMetricsV2 from '../AgentMetrics';

import { ReportingTab } from 'pages/reportingV2/interface';
import { cn } from 'libraryV2/utils';

export const reportingTabs: { value: ReportingTab; label: string }[] = [
  {
    value: 'customer-metrics',
    label: 'Customer Metrics',
  },
  {
    value: 'automation-metrics',
    label: 'Automation Metrics',
  },
  {
    value: 'agent-metrics',
    label: 'Agent Metrics',
  },
  {
    value: 'csat-metrics',
    label: 'CSAT Metrics',
  },
  {
    value: 'whatsapp-metrics',
    label: 'Whatsapp Metrics',
  },
];

const ReportingMatricesTabs = ({
  selectedProject,
  updateCurrentReportingTab,
  currentReportingTab,
}: {
  selectedProject: any;
  updateCurrentReportingTab: (tab: ReportingTab) => void;
  currentReportingTab: ReportingTab;
}) => {
  return (
    <Tabs value={currentReportingTab} className='bg-white'>
      <TabsList className='flex w-full rounded-none h-[48px] sticky bg-white z-10 top-[52px] px-4 border-b justify-start border-border'>
        {reportingTabs.map((tab) => (
          <TabsTrigger
            className={cn(
              'text-sm text-textPrimary leading-5 font-medium',
              currentReportingTab === tab.value && 'bg-background-hover rounded'
            )}
            value={tab.value}
            onClick={() => updateCurrentReportingTab(tab.value as ReportingTab)}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>

      <TabsContent value='customer-metrics' className='flex-1 w-full p-0 m-0'>
        <div
          data-testid='customer-metrics-container'
          className='w-full bg-white flex flex-col gap-6 relative'
        >
          <CustomerMetricsV2 selectedProject={selectedProject} />
        </div>
      </TabsContent>
      <TabsContent value='automation-metrics' className='flex-1 w-full p-0 m-0'>
        <div
          data-testid='customer-metrics-container'
          className='w-full bg-white flex flex-col gap-6 relative'
        >
          <AutomationMetricsV2 selectedProject={selectedProject} />
        </div>
      </TabsContent>
      <TabsContent value='agent-metrics' className='flex-1 w-full p-0 m-0'>
        <div
          data-testid='customer-metrics-container'
          className='w-full bg-white flex flex-col gap-6 relative'
        >
          <AgentMetricsV2 />
        </div>
      </TabsContent>
      <TabsContent value='csat-metrics' className='flex-1 w-full p-0 m-0'>
        <CsatMetrics selectedProject={selectedProject} />
      </TabsContent>
      <TabsContent
        value='whatsapp-metrics'
        data-testid={'wa-metrics-container-tab'}
        className='flex-1 w-full p-0 m-0'
      >
        <WhatsappMetrics />
      </TabsContent>
    </Tabs>
  );
};

export default ReportingMatricesTabs;
